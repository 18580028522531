import React, { useEffect, useState } from 'react'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import axios from '../../API/axios';
import { Link } from 'react-router-dom';
const EndorseModal = ({ showModal, handleCloseModal, skillId }) => {
    console.log('skillId', skillId);
    const [endorseData, setEndorseData] = useState([])
    const GetUserSkillEndorsements = async () => {
        console.log("comment reply res");
        try {
            await axios
                .post(
                    `UserSkill/GetUserSkillEndorsements?DeviceType=Web`, { usrSkllId: skillId }
                )
                .then((res) => {
                    console.log("res====>", res.data.responseData);
                    setEndorseData(res.data.responseData);
                });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (showModal) {
            GetUserSkillEndorsements();
        }
    }, [showModal]);
    return (
        <>
            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Endorsements</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                />
                            </div>

                            <div className="reaction-cont">
                                {endorseData &&
                                    endorseData.map((item) => {
                                        return (
                                            <div className="post-like-details-cont p-2 d-flex justify-content-between">
                                                <Link to={`/User/${item.userinfo.userUid}`}>
                                                    <div className="like-header-cont d-flex align-items-center">
                                                        <div className="like-image-cont me-3">
                                                            <img src={item.userinfo.profilPic} alt="" width={50} height={50} srcset="" />
                                                        </div>

                                                        <div>
                                                            <div className="liked-user-name f-700 f-14 mt-2">
                                                                {item.feedback}
                                                            </div>
                                                            <div className="liked-user-name f-700 f-12">
                                                                {item.userinfo.fullName}
                                                            </div>
                                                            <div className="f-12">
                                                                <p>
                                                                    {item.userinfo.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="like-selected-imoji mt-2 ms-2">
                                                            {/* <div className="like-container pointer d-flex align-items-center">
                                                                {item?.likeType === 1 ? (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.LikeGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                ) : item?.likeType === 2 ? (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.LoveGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                ) : item?.likeType === 3 ? (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.LaughingGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                ) : item?.likeType === 4 ? (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.WowGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                ) : item?.likeType === 5 ? (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.AngryGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="liked-imoji">
                                                                            <img src={post.LikeGif} width={30} />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </Link>

                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default EndorseModal