import React, { useEffect, useState } from "react";
import { ShimmerSocialPost, ShimmerTitle } from "react-shimmer-effects";
import axios from "../../API/axios";
// import { NewsFeed, people } from "../../assets/Images/ImagesLink";
import PostComment from "../NewsFeed/PostComment";
import PostEngagement from "../NewsFeed/PostEngagement";
import PostInteractionModal from "../NewsFeed/PostInteractionModal";
import AddPost from "../NewsFeed/AddPost";
import moment from "moment";
import { Link } from "react-router-dom";
import ImageSliderModal from "../NewsFeed/ImageSliderModal";
import { post } from "../../assets/Images/ImagesLink";
import { ShimmerDiv } from "shimmer-effects-react";
import Comment from "../NewsFeed/Comment";
import pin from "../../assets/Images/svg/pin.svg";
import PostComponent from "../NewsFeed/PostComponent";
import { slice } from "lodash";
import LikesDetails from "../NewsFeed/LikesDetails";
const PersonalPost = ({ uid, getUserProfileStat, showDeletePost, setShowDeletePost }) => {
  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem('userUid')
  //************************ All Post List  ************************//
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [singlepostId, setSinglePostId] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);

  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const AllPostList = async () => {
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 24
      // sessionId: sessionId,
    };
    setNextBatchLoading(true);
    try {
      await axios
        .post(`Posts/GetUserPosts?UID=${uid}`, data)
        .then((resData) => {
          console.log('resDataPersonalPost', resData);
          if (resData.status === 204) {
            setIsError(true);
          } else {
            setTotalPages(resData.data.responseData.paging.totalPages);
            const newPosts = resData.data.responseData.postdetails;
            setPostList((prev) => {
              const postSet = new Set(prev.map((post) => post.postId));
              const uniqueNewPosts = newPosts.filter(
                (post) => !postSet.has(post.postId)
              );
              return [...prev, ...uniqueNewPosts];
            });
            // setPostList(resData.data.responseData.reverse());
            if (page >= resData.data.responseData.paging.totalPages) {
              setHasMoreData(false);
            }
          }
          setIsLoading(false);
          setNextBatchLoading(false);
        });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.error(error);
      }
      setNextBatchLoading(false);
    }
  };





  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  // console.log(postList);
  //************************ All Post List  ************************//
  const updateLikeCount = (postId, delta) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId ? { ...post, likeCount: post.likeCount + delta } : post
      )
    );
  };

  const updateCommentCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, commentCount: post.commentCount + change }
          : post
      )
    );
  };
  const updateShareCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, shareCount: post.shareCount + change }
          : post
      )
    );
  };
  const guestModeData = localStorage.getItem("guestMode");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const openModal = (images) => {
    // console.log('clicke');
    setModalImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isError, setIsError] = useState(false);
  const [singlePostContent, setSinglePostContent] = useState('')
  const [ispostmodalOpen, setIspostmodalOpen] = useState(false)
  const [dLike, setDLike] = useState(0);

  const [emojiList, setemojiList] = useState();
  const GetEmoji = async () => {
    try {
      await axios.get("Master/GetEmojiDDL").then((res) => {
        // console.log("share", res);
        setemojiList(res.data.responseData);
        // updateShareCount(postId, 1);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetEmoji();
  }, []);


  useEffect(() => {
    if (page <= totalPages) {
      AllPostList(page);
    }
  }, [page]);
  console.log('postlist', postList);
  const [showModal, setShowModal] = useState(false);
  const [postLikedType, setPostLikedType] = useState(false);
  const [postId, setPostId] = useState("");
  const handleShowModal = (item) => {
    setPostId(item.postId);
    setPostLikedType(item.likedType);
    setShowModal(true);
  };
  const handleLikesCloseModal = () => {
    setShowModal(false);
    // setSelectedItem(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open');
    };
  }, [isModalOpen]);

  return (
    <>
      {userUid === uid && <AddPost AllPostList={getUserProfileStat} PostOriginType={"User"} setPostList={setPostList} postOriginId={userUid} />}
      {isLoading ? (
        <div className="mt-3">
          {/* <ShimmerThumbnail height={250} rounded /> */}
          {/* <ShimmerSocialPost type="image" /> */}
          <div className="shimmer-cont">
            <div className="shimmer-header d-flex">
              <ShimmerDiv mode="light" height={60} width={60} rounded={50} />
              <div className="shimmer-title ms-3">
                <ShimmerTitle mode="light" line={2} gap={8} />
              </div>
            </div>
            <div className="square-shimmer mt-2">
              <ShimmerDiv mode="light" height={100} width={100} />
            </div>
            {/* <ShimmerCategoryItems mode="light" imageRounded={50} /> */}
          </div>
        </div>
      ) : isError ? (
        <div className="mt-2 text-center">
          <img src={post.errorSmall} alt="404 Not Found" width={200} />
        </div>
      ) : (
        postList &&
        postList
          .filter((item) => !item.isDeleted)
          ?.map((item, index) => {
            console.log(item.postMediaCount);

            return (
              console.log('itemmmmm', item),
              <div
                className="featured-card user-postOnlyContent p-2 mb-3"
                key={index}
              >
                <div className="feature-content p-2 d-flex justify-content-between">
                  <Link to={`/User/${item.userUid}`}>
                    <div className="d-flex align-items-center">
                      <div className="ImgAvatar">
                        <img
                          src={item?.userAvatar}
                          alt=""
                          style={{ borderRadius: "50px" }}
                        />
                      </div>
                      <div className="ms-2">
                        <h4 className="f-14 fw-bold mb-0">{item.userName}</h4>
                        <p className="f-12">
                          {/* {moment(item.creationTime).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )} */}
                          {moment
                            .utc(item.creationTime)
                            .local()
                            .format("DD MMMM YYYY [at] h:mm a")}
                        </p>
                      </div>
                    </div>
                  </Link>
                  {guestModeData === "true" ? (
                    ""
                  ) : (
                    <div className="row">
                      <div>
                        <PostInteractionModal
                          postId={item?.postId}
                          AllPostList={AllPostList}
                          postList={postList}
                          setPage={setPage}
                          setPostList={setPostList}
                          page={page}
                          setTotalPages={setTotalPages}
                          totalPages={totalPages}
                          item={item}
                          PostOriginType={"User"}
                          postOriginId={userUid}
                          setShowDeletePost={setShowDeletePost}
                          showDeletePost={showDeletePost}
                          ispinned={item?.isPinned}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* {item?.postContent && <p className="f-14 p-2 postContent" >{item?.postContent}</p>} */}
                <PostComponent item={item} />
                <div
                  className="feed-post"
                  style={{
                    paddingTop: item?.postMediaCount === 0 ? "60px" : "4px",
                  }}
                >
                  <div className="row">
                    {item.mediaFilesList.slice(0, 4).map((file, idx) => (
                      <div
                        key={idx}
                        className={`col-${item.mediaFilesList.length === 1 ? "12" : "6"
                          } ${item.mediaFilesList.length === 3 && idx === 0
                            ? "col-12"
                            : ""
                          }`}
                        onClick={() => {
                          setInitialSlideIndex(idx);
                          setImageArray(item.mediaFilesList);
                          console.warn("===========1============>>", idx);
                          let imageData = item.mediaFilesList.map(
                            (file) => file.mediaName
                          );
                          openModal(imageData, idx);
                          setSinglePostContent(item);
                          setSinglePostId(item?.postId);
                        }}
                        style={{
                          position: "relative",
                          padding:
                            item.mediaFilesList.length === 3 && idx === 0
                              ? "0 0 5px 0"
                              : item.mediaFilesList.length === 3 && idx === 1
                                ? "0 5px 5px 0"
                                : item.mediaFilesList.length === 4 && idx === 2
                                  ? "0 5px 0 0"
                                  : item.mediaFilesList.length === 4 && idx === 0
                                    ? "0 5px 5px 0"
                                    : item.mediaFilesList.length === 2 && idx === 0
                                      ? "0 5px 5px 0"
                                      : "",
                        }}
                      >
                        <img
                          src={file.mediaName}
                          alt=""
                          className="img-fluid"
                          width={"100%"}
                        />
                        {idx === 3 && item.postMediaCount > 4 && (
                          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                            +{item.postMediaCount - 4}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {guestModeData === "true" ? (
                    ""
                  ) : (
                    <div className="row comment-gradient">
                      <div className="addComment">
                        <PostComment
                          item={item}
                          postId={item?.postId}
                          AllPostList={AllPostList}
                          postList={postList}
                          setPage={setPage}
                          setPostList={setPostList}
                          page={page}
                          setTotalPages={setTotalPages}
                          totalPages={totalPages}
                          updateCommentCount={updateCommentCount}
                          ispostmodalOpen={ispostmodalOpen}
                          setIspostmodalOpen={setIspostmodalOpen}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className={`postData d-flex align-items-center ${item?.likeCount === 0 && item?.commentCount === 0 && item?.shareCount === 0
                  ? ""
                  : "border-custom1"
                  }`}>
                  <div className="ms-2">
                    {item?.likeCount === 0 ? (
                      ""
                    ) : (
                      <p
                        className="f-10 like-details"
                        onClick={() => handleShowModal(item)}
                      >
                        {item?.likeCount + dLike} likes
                      </p>
                    )}
                  </div>
                  <div className="ms-auto d-flex">
                    {item?.commentCount === 0 ? (
                      ""
                    ) : (
                      <p
                        className="f-10 me-2 pointer"
                        onClick={() => {
                          openModal(
                            item.mediaFilesList.map((file) => file.mediaName)
                          );
                          setSinglePostContent(item);
                          setSinglePostId(item?.postId);
                        }}
                      >
                        {" "}
                        {item?.commentCount} comments{" "}
                      </p>
                    )}

                    <p className="f-10">
                      {item?.shareCount === 0
                        ? ""
                        : `&  ${item?.shareCount} Shares`}
                    </p>
                  </div>
                </div>
                <div className="postengagement mb-3">
                  <PostEngagement
                    item={item}
                    postId={item?.postId}
                    AllPostList={getUserProfileStat}
                    postList={postList}
                    setPage={setPage}
                    setPostList={setPostList}
                    page={page}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    updateLikeCount={updateLikeCount}
                    updateShareCount={updateShareCount}
                    openModal={openModal}
                    setSinglePostContent={setSinglePostContent}
                    setSinglePostId={setSinglePostId}
                    ispostmodalOpen={ispostmodalOpen}
                    setIspostmodalOpen={setIspostmodalOpen}
                    emojiList={emojiList}
                  />
                </div>
                <Comment
                  item={item}
                  isOwnedPost={item?.isOwned}
                  postId={item?.postId}
                  AllPostList={AllPostList}
                  postList={postList}
                  setPage={setPage}
                  setPostList={setPostList}
                  page={page}
                  setTotalPages={setTotalPages}
                  totalPages={totalPages}
                  emojiList={emojiList}
                />
              </div>
            );
          })
      )}
      {hasMoreData ? (''
      ) : (
        <p className="text-center">No more data available</p >
      )}
      {nextBatchLoading && (
        <div className="text-center mb-4">
          <img src={post?.loader} width={50} />
        </div>
      )}
      <ImageSliderModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        images={modalImages}
        item={singlePostContent}
        singlepostId={singlepostId}
        setSinglePostId={setSinglePostId}
        updateShareCount={updateShareCount}
        updateLikeCount={updateLikeCount}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        page={page}
        setPostList={setPostList}
        setPage={setPage}
        postList={postList}
        AllPostList={AllPostList}
        PostEngagement={PostEngagement}
        dLike={dLike}
        updateCommentCount={updateCommentCount}
        ispostmodalOpen={ispostmodalOpen}
        setIspostmodalOpen={setIspostmodalOpen}
        openModal={openModal}
        setSinglePostContent={setSinglePostContent}
        setDLike={setDLike}
        initialSlideIndex={initialSlideIndex}
        setInitialSlideIndex={setInitialSlideIndex}
        imageArray={imageArray}
      />
      {/* {showModal &&  */}
      <LikesDetails
        showModal={showModal}
        handleLikesCloseModal={handleLikesCloseModal}
        postId={postId}
        postLikedType={postLikedType}
        setShowModal={setShowModal}
      />
      {/* } */}
    </>
  );
};

export default PersonalPost;
