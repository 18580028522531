import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import { people } from "../../assets/Images/ImagesLink";
import MessageInteraction from "./MessageInteraction";
import pin from "../../assets/Images/svg/pin.svg";
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
const MessageList = ({
  showClear,
  setShowClear,
  showDelete,
  setShowDelete,
  isShown,
  getChatList,
  archiveMessage,
  item,
  index,
  active,
  setActive,
  setToUserId,
  UserId,
  setMsgId,
  getArchivedList,
  getMessageList,
  setIsShown
}) => {
  console.log("iteeem", item);
  const sessionId = localStorage.getItem("sessionId");
  const [archivedList, setArchivedList] = useState([]);
  //const formattedTime = moment(item.lastMsgTime).fromNow();
  //const formattedTime = {moment.utc(item.lastMsgTime).local().format("LT")}
  // console.log(item);
  return (
    <>
      <Link to={`/Message/${item?.chatInfo?.toUserUid}`} onClick={() => { }}>
        <div
          onClick={() => {
            setActive(index);
            // setToUserId(item.chatInfo.toUserUid)
            setMsgId(item.chatInfo.msgID);
            setIsShown(current => !current)
          }}
          className={`${isShown ? "d-block" : "d-md-block d-none"} pointer`}
        >
          <div
            key={index}
            className={`${active === index ? "activeMsg" : ""
              } message-cards d-flex align-items-center mb-2`}
          >
            {/* <div className="empty-msg-card ms-3 me-3"></div> */}
            <div className="me-3 ms-2">
              <img
                src={
                  item.chatInfo.toUserImage === ""
                    ? people.DefaultProfile
                    : item.chatInfo.toUserImage
                }
                alt=""
                width={40}
                height={40}
                style={{ borderRadius: "50px" }}
              />
            </div>
            <div className="msg-content w-100 me-2">
              <div className="name-time d-flex justify-content-between align-items-baseline">
                <div className="message-user-name darkmode f-12 f-600">
                  {item.chatInfo.toUserFullName}{" "}
                  {item?.isPined ?
                    <img src={pin} width={20} /> : ""}{" "}
                </div>
                <div className="message-time  darkmode f-10 f-400 ">
                  {moment.utc(item.lastMsgTime).local().format("LT")}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`${item.lastMsgIsRead === false
                    ? "fw-bolder f-14"
                    : "fw-normal f-10"
                    } messageText darkmode `}
                >

                  <p className="messageText">
                    {item?.isOwned && 'You: '}
                    {item.lastMsg.split(' ').slice(0, 5).join(' ')}
                    {item.lastMsg.split(' ').length > 5 && '...'}
                  </p>
                </div>
                <div className="d-flex">
                  {item.isPined === true ? (
                    <PushPinSharpIcon className="material-symbols-sharp" />
                  ) : (
                    ""
                  )}

                  <MessageInteraction
                    showClear={showClear}
                    setShowClear={setShowClear}
                    showDelete={showDelete}
                    setShowDelete={setShowDelete}
                    chatId={item.chatID}
                    isRead={item.lastMsgIsRead}
                    isPined={item.isPined}
                    getChatList={getChatList}
                    archiveMessage={archiveMessage}
                    msgId={item.chatInfo.msgID}
                    UserId={item.chatInfo.toUserUid}
                    getArchivedList={getArchivedList}
                    getMessageList={getMessageList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default MessageList;
