import React, { useEffect, useState } from "react";
import { NewsFeed, post } from "../../assets/Images/ImagesLink";
import ModalForGuest from "../ModalForGuest";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Link } from "react-router-dom";
import { ShimmerDiv } from "shimmer-effects-react";

const Followers = ({ CompUid }) => {
  const guestModeData = localStorage.getItem("guestMode");
  const [guestModeModal, setGuestModeModal] = useState(false);

  const closeModalData = () => {
    setGuestModeModal(false);
  };

  // const FollowUser = () => { };

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const sessionId = localStorage.getItem("sessionId");
  //************************User Profile Stats Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [followerListError, setfollowerListError] = useState(false);
  const [page, setPage] = useState(1);
  const [followerList, setFollowerList] = useState([]);
  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  console.log("nextBatchLoading", nextBatchLoading, page);
  const getFollowerList = () => {
    setNextBatchLoading(true);
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 20,
      uiD_ID: CompUid
    };
    axios
      .post(`Entity/GetFollowedList/Company`, data)
      .then((response) => {
        setIsLoading(false);
        console.log("getFollowerList", response);
        const res = response.data.responseData.followersList;
        // setFollowerList(res);

        setFollowerList((prev) => {
          const postSet = new Set(prev.map((post) => post.userUid)); // Assuming job objects have a unique 'id' field
          const uniqueNewPosts = res.filter(
            (post) => !postSet.has(post.userUid)
          );
          return [...prev, ...uniqueNewPosts];
        });
        setNextBatchLoading(false);
      })

      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setfollowerListError(true);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setNextBatchLoading(false);
      });
  };
  useEffect(() => {
    getFollowerList(page);
  }, [page, CompUid]);

  //************************User Profile Stats End  ************************//
  //************************On page scroll  ************************//

  const handelInfiniteScroll = async () => {
    // console.log("scrollHeight" + document.documentElement.scrollHeight);
    // console.log("innerHeight" + window.innerHeight);
    // console.log("scrollTop" + document.documentElement.scrollTop);
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  // const handelInfiniteScroll = (e) => {
  //   const target = e.target; // Detect the scrollable container
  //   if (
  //     target.scrollHeight - target.scrollTop <=
  //     target.clientHeight + 1
  //   ) {
  //     setPage((prev) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   const userProductContainer = document.querySelector(".userProductContainer");

  //   if (userProductContainer) {
  //     userProductContainer.addEventListener("scroll", handelInfiniteScroll);
  //   }

  //   return () => {
  //     if (userProductContainer) {
  //       userProductContainer.removeEventListener("scroll", handelInfiniteScroll);
  //     }
  //   };
  // }, []);


  //************************ UnFollow Company Start  ************************//

  const UnFollow = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: id,
    };
    try {
      await axios.post("Follow/Unfollow/Company", data).then((resData) => {
        console.log("resData", resData);
        getFollowerList();
        // toast.success(resData.data.message);
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ UnFollow Company End  ************************//

  // const [jobModal, setJobModal] = useState(false);
  // const closeModalData = () => {
  //   setJobModal(false);
  // };
  //************************ Follow Company Start  ************************//

  // const FollowCompany = async (id) => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //     uiD_ID: CompUid,
  //   };
  //   try {
  //     await axios.post("Entity/Follow/Company", data).then((resData) => {
  //       console.log("followed");
  //       getFollowerList();
  //       setToastMessage(resData.data.message);
  //       setShowToast(true);
  //     });
  //   } catch (e) {
  //     if (e.response && e.response.status === 404) {
  //       setWarningToastMessage(e.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(e.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };
  const FollowUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        console.log("resDataaaaa", resData);
        getFollowerList()
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Follow Company End  ************************//

  return (
    <>
      <div className="followers-main-container">
        {isLoading ? (
          <div className="mt-3">
            {/* <ShimmerThumbnail height={250} rounded /> */}
            {/* <ShimmerDiv mode="light" height={200} width={'100%'} /> */}
            <div className="text-center">
              <img src={post?.loader} width={50} />
            </div>
          </div>
        ) : followerListError ? (
          <div className="mt-2 error-image-cont">
            <img src={post.errorbig} alt="404 Not Found" />
          </div>
        ) : (
          followerList &&
          followerList?.map((item, index) => {
            return (
              <div className="aboutMe mb-3 p-3 " key={index}>
                <div className="d-flex align-items-center">
                  {/* <Link to={`/User/${item.userUid}`}> */}
                  <div className="ImgAvatar">
                    <img
                      src={item?.profilPic}
                      alt=""
                      width={60}
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  {/* </Link> */}
                  <div className="ms-2">
                    <h4 className="f-14 fw-bold mb-0">{item?.fullName} </h4>
                    <p className="f-12">{item?.info}</p>
                    <p className="f-12"> {item?.followersCount} Followers</p>
                  </div>
                  <div className="ms-auto">
                    {item?.isFollowedByYou === false && <button className='mainBtn1 me-1' style={{ width: '90px' }} onClick={() =>
                      guestModeData === "true"
                        ? setGuestModeModal(true)
                        : FollowUser(item?.userUid)
                    }>
                      Follow</button>}


                  </div>
                </div>
              </div>
            );
          })
        )}
        {nextBatchLoading && (
          <div className="text-center">
            <img src={post?.loader} width={50} />
          </div>
        )}

      </div>
      {guestModeModal && <ModalForGuest closeModalData={closeModalData} />}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default Followers;
