import React, { useEffect, useState } from "react";
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import axios from "../../API/axios";
import { NewsFeed, post } from "../../assets/Images/ImagesLink";
// import AddPost from "./AddPost";
// import PostInteractionModal from "./PostInteractionModal";
import { Col, Modal, Row } from "react-bootstrap";
// import PostComment from "./PostComment";
// import PostEngagement from "./PostEngagement";
import Carousel from "react-bootstrap/Carousel";
import Loading from "../Blogs/Loading";
import moment from "moment";
import PostEngagement from "../NewsFeed/PostEngagement";
import PostComment from "../NewsFeed/PostComment";
import PostInteractionModal from "../NewsFeed/PostInteractionModal";
import AddPost from "../NewsFeed/AddPost";
import { Link } from "react-router-dom";
import Comment from "../NewsFeed/Comment";
import ImageSliderModal from "../NewsFeed/ImageSliderModal";
import { ShimmerDiv } from "shimmer-effects-react";
import PostComponent from "../NewsFeed/PostComponent";
import pin from "../../assets/Images/svg/pin.svg";
import LikesDetails from "../NewsFeed/LikesDetails";



const Posts = ({ CompUid, companyDetails }) => {
  const guestModeData = localStorage.getItem("guestMode");

  //************************ All Post List  ************************//
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isfollowed, setIsfollowed] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singlePostContent, setSinglePostContent] = useState("");
  const [singlepostId, setSinglePostId] = useState("");
  const [ispostmodalOpen, setIspostmodalOpen] = useState(false);
  const [postId, setPostId] = useState("");
  const [postLikedType, setPostLikedType] = useState(false);
  const [dLike, setDLike] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const [imageArray, setImageArray] = useState([]);
  const [emojiList, setemojiList] = useState();
  const GetEmoji = async () => {
    try {
      await axios.get("Master/GetEmojiDDL").then((res) => {
        // console.log("share", res);
        setemojiList(res.data.responseData);
        // updateShareCount(postId, 1);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetEmoji();
  }, []);
  const handleLikesCloseModal = () => {
    setShowModal(false);
    // setSelectedItem(null);
  };
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  });
  const openModal = (mediaFiles, initialSlide) => {
    setSliderSettings((prevSettings) => ({
      ...prevSettings,
      initialSlide: initialSlide,
    }));
    setMediaFiles(mediaFiles);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log("isError", companyDetails);
  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const AllPostList = async (page) => {
    setNextBatchLoading(true);
    const data = {
      "deviceType": "Web",
      "pageNumber": page,
      "pageSize": 24
    }
    try {
      setLoading(true);
      await axios
        .post(
          `Posts/GetCompanyPosts?CompanyUid=${CompUid}`, data
        )
        .then((resData) => {
          console.log("resDataNew", resData);
          setIsLoading(false);
          setTotalPages(resData.data.responseData.paging.totalPages);
          // setTotalPages(resData.data.responseData.paging.totalPages);
          // setPostList((prev) => [...prev, ...resData.data.responseData.postdetails]);
          if (resData.status === 204) {
            setIsError(true)
          } else {
            const newPosts = resData.data.responseData.postdetails;

            // // Ensure only unique jobs are added to the job list
            setPostList((prev) => {
              const postSet = new Set(prev.map((post) => post.postId)); // Assuming job objects have a unique 'id' field
              const uniqueNewPosts = newPosts.filter(
                (post) => !postSet.has(post.postId)
              );
              return [...prev, ...uniqueNewPosts];
            });
          }
          // setPostList(resData.data.responseData)
          console.log("all post data", resData.data);
          setLoading(false);
          setNextBatchLoading(false);
          if (page >= resData.data.responseData.paging.totalPages) {
            setHasMoreData(false);
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }

      setIsLoading(false);
      setNextBatchLoading(false);
    }
  };

  useEffect(() => {
    // console.log('page', page, totalPages);
    if (page <= totalPages) {
      AllPostList(page);
    }
  }, [page, CompUid]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  //************************ All Post List  ************************//
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showModal]);

  const updateLikeCount = (postId, delta) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, likeCount: post.likeCount + delta }
          : post
      )
    );
  };

  const updateCommentCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, commentCount: post.commentCount + change }
          : post
      )
    );
  };

  const updateShareCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, shareCount: post.shareCount + change }
          : post
      )
    );
  };

  const handleShowModal = (item) => {
    console.log('gsadhfs', item);

    setPostId(item?.postId);
    setPostLikedType(item?.likedType);
    setShowModal(true);
  };
  const [showDeletePost, setShowDeletePost] = useState(false)
  console.log('isModalOpen', isModalOpen);




  return (
    <>
      {companyDetails?.isOwned && <AddPost AllPostList={AllPostList} PostOriginType={"Company"} postOriginId={CompUid} setPostList={setPostList} />}


      {isLoading ? (
        <div className="mt-3">

          <div className="text-center">
            <img src={post?.loader} width={50} />
          </div>
        </div>
      )
        : isError ? (
          <div className="mt-2 text-center">
            <img src={post.errorSmall} alt="404 Not Found" width={300} />
          </div>
        ) : (
          postList &&
          postList
            ?.map((item, index) => {
              console.log('gsadfghdsa', item);

              return (

                <div
                  className="featured-card user-postOnlyContent p-2 mb-3"
                  key={index}
                >
                  <div className="feature-content p-2 d-flex justify-content-between">
                    <Link to={`/Company/${item?.userUid}`}>
                      <div className="d-flex align-items-center">
                        <div className="ImgAvatar">
                          <img
                            src={item?.userAvatar}
                            alt=""
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                        <div className="ms-2">
                          <h4 className="f-14 fw-bold mb-0">{item?.userName} </h4>
                          <p className="f-12">{item?.title}</p>
                          <p className="f-12">{item?.additionalInfo}</p>
                          <p className="f-10 label">

                            {moment
                              .utc(item?.creationTime)
                              .local()
                              .format("DD MMMM YYYY [at] h:mm a")}
                          </p>
                        </div>
                      </div>
                    </Link>

                    {guestModeData === "true" ? (
                      ""
                    ) : (
                      <div className="row">
                        <div>
                          <PostInteractionModal
                            postId={item?.postId}
                            AllPostList={AllPostList}
                            postList={postList}
                            setPage={setPage}
                            setPostList={setPostList}
                            page={page}
                            setTotalPages={setTotalPages}
                            totalPages={totalPages}
                            item={item}
                            PostOriginType={"Company"}
                            postOriginId={item?.userUid}
                            isfollowed={isfollowed}
                            setIsfollowed={setIsfollowed}
                            setShowDeletePost={setShowDeletePost}
                            showDeletePost={showDeletePost}
                            ispinned={item?.isPinned}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <PostComponent item={item} />
                  <div
                    className="feed-post"
                    style={{
                      paddingTop: item?.postMediaCount === 0 ? "60px" : "4px",
                    }}
                  >

                    <div className="row">
                      {console.warn(
                        "===========0============>>",
                        item.mediaFilesList
                      )}
                      {item.mediaFilesList.map((file, idx) => (
                        <div
                          key={idx}
                          className={`col-${item.mediaFilesList.length === 1 ? "12" : "6"
                            } ${item.mediaFilesList.length === 3 && idx === 0
                              ? "col-12"
                              : ""
                            }`}
                          onClick={() => {
                            setInitialSlideIndex(idx);
                            setImageArray(item.mediaFilesList);
                            console.warn("===========1============>>", idx);
                            let imageData = item.mediaFilesList.map(
                              (file) => file.mediaName
                            );
                            openModal(imageData, idx);
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                          style={{
                            position: "relative",
                            padding:
                              item.mediaFilesList.length === 3 && idx === 0
                                ? "0 0 5px 0"
                                : item.mediaFilesList.length === 3 && idx === 1
                                  ? "0 5px 5px 0"
                                  : item.mediaFilesList.length === 4 && idx === 2
                                    ? "0 5px 0 0"
                                    : item.mediaFilesList.length === 4 && idx === 0
                                      ? "0 5px 5px 0"
                                      : item.mediaFilesList.length === 2 && idx === 0
                                        ? "0 5px 5px 0"
                                        : "",
                          }}
                        >
                          <img
                            src={file.mediaName}
                            alt=""
                            className="img-fluid"
                            width={"100%"}
                          />
                          {idx === 3 && item.postMediaCount > 4 && (
                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                              +{item.postMediaCount - 4}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {guestModeData === "true" ? (
                      ""
                    ) : (
                      <div className="row comment-gradient">
                        <div
                          className="addComment"
                          onClick={() => {
                            openModal(
                              item?.mediaFilesList.map((file) => file.mediaName),
                              0
                            );
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                        >
                          <div
                            className="postCommentWrapper"
                            onClick={(event) => event.stopPropagation()}
                          >
                            <PostComment
                              item={item}
                              postId={item?.postId}
                              AllPostList={AllPostList}
                              postList={postList}
                              setPage={setPage}
                              setPostList={setPostList}
                              page={page}
                              setTotalPages={setTotalPages}
                              totalPages={totalPages}
                              updateCommentCount={updateCommentCount}
                              ispostmodalOpen={ispostmodalOpen}
                              setIspostmodalOpen={setIspostmodalOpen}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={`postData d-flex align-items-center ${item?.likeCount === 0 && item?.commentCount === 0 && item?.shareCount === 0
                    ? ""
                    : "border-custom1"
                    }`}>
                    <div className="ms-2">
                      {item?.likeCount === 0 ? (
                        ""
                      ) : (
                        <p
                          className="f-10 like-details"
                          onClick={() => handleShowModal(item)}
                        >
                          {item?.likeCount + dLike} likes
                        </p>
                      )}
                    </div>
                    <div className="ms-auto d-flex">
                      {item?.commentCount === 0 ? (
                        ""
                      ) : (
                        <p
                          className="f-10 me-2 pointer"
                          onClick={() => {
                            openModal(
                              item.mediaFilesList.map((file) => file.mediaName)
                            );
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                        >
                          {" "}
                          {item?.commentCount} comments{" "}
                        </p>
                      )}

                      <p className="f-10">
                        {item?.shareCount === 0
                          ? ""
                          : `&  ${item?.shareCount} Shares`}
                      </p>
                    </div>
                  </div>
                  <div className="postengagement mb-3">
                    <PostEngagement
                      item={item}
                      postId={item?.postId}
                      AllPostList={AllPostList}
                      postList={postList}
                      setPage={setPage}
                      setPostList={setPostList}
                      page={page}
                      setTotalPages={setTotalPages}
                      totalPages={totalPages}
                      updateLikeCount={updateLikeCount}
                      updateShareCount={updateShareCount}
                      openModal={openModal}
                      setSinglePostContent={setSinglePostContent}
                      setSinglePostId={setSinglePostId}
                      ispostmodalOpen={ispostmodalOpen}
                      setIspostmodalOpen={setIspostmodalOpen}
                      emojiList={emojiList}
                    />
                  </div>
                  <Comment
                    item={item}
                    postId={item?.postId}
                    AllPostList={AllPostList}
                    postList={postList}
                    setPage={setPage}
                    setPostList={setPostList}
                    page={page}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    emojiList={emojiList}
                  />
                </div>
              );
            })
        )}
      {hasMoreData ? (''
      ) : (
        <p className="text-center">No more data available</p >
      )}
      {nextBatchLoading && (
        <div className="text-center mb-4">
          <img src={post?.loader} width={50} />
        </div>
      )}
      {isModalOpen && (
        <ImageSliderModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          images={modalImages}
          item={singlePostContent}
          singlepostId={singlepostId}
          setSinglePostId={setSinglePostId}
          updateShareCount={updateShareCount}
          updateLikeCount={updateLikeCount}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={page}
          setPostList={setPostList}
          setPage={setPage}
          postList={postList}
          AllPostList={AllPostList}
          PostEngagement={PostEngagement}
          dLike={dLike}
          updateCommentCount={updateCommentCount}
          ispostmodalOpen={ispostmodalOpen}
          setIspostmodalOpen={setIspostmodalOpen}
          openModal={openModal}
          setSinglePostContent={setSinglePostContent}
          setDLike={setDLike}
          setShowModal={showModal}
          handleLikesCloseModal={handleLikesCloseModal}
          isfollowed={isfollowed}
          setIsfollowed={setIsfollowed}
          initialSlideIndex={initialSlideIndex}
          setInitialSlideIndex={setInitialSlideIndex}
          imageArray={imageArray}
        />
      )}
      <LikesDetails
        showModal={showModal}
        handleLikesCloseModal={handleLikesCloseModal}
        postId={postId}
        postLikedType={postLikedType}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default Posts;
