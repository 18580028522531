import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import UpdateCompany from "../CreateCompany/UpdateCompany";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const CompanyActions = ({ showUpdateModal, setShowUpdateModal, compUid, getCompanyList, item }) => {
  console.log(item);
  const sessionId = localStorage.getItem("sessionId");
  const [showDropdown, setShowDropdown] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useRef(null);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Company/DeleteCompany

  //************************Remove Skill start  ************************//
  // const [compUid, setCompUid] = useState('');
  const [show, setShow] = useState(false);
  const handleRemoveSkillClose = () => {
    setShow(false);
  };

  const openUpdateModal = () => {
    setShowUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const [skillId, setSkillId] = useState("");

  const removeSkills = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      compUid,
    };
    try {
      await axios.post(`Company/DeleteCompany`, responseData).then((res) => {
        console.log(res.data);
        setShow(!show);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        getCompanyList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Skill End  ************************//

  const DisableCompany = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      compUid,
    };
    try {
      await axios.post(`Company/DisableCompany`, responseData).then((res) => {
        console.log(res.data);
        setToastMessage(res.data.message);
        setShowToast(true);
        getCompanyList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const EnableCompany = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      compUid,
    };
    try {
      await axios.post(`Company/EnableCompany`, responseData).then((res) => {
        console.log(res.data);
        setToastMessage(res.data.message);
        setShowToast(true);
        getCompanyList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show]);
  return (
    <>
      <div
        className="postMenu pointer"
        onClick={(event) => {
          setShowDropdown(!showDropdown)
          event.stopPropagation();
        }}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp mycompanyAction" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
            onClick={(event) => event.stopPropagation()}
          >
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={(e) => {
                  // e.preventDefault();
                  toggleDropdown();
                  openUpdateModal();
                  // toggleMenu()
                }}
              >
                <div className="d-flex align-items-center">
                  <UpdateSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Update Company</span>
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={() => {
                  toggleDropdown();
                  setShow(!show);
                  // setCompUid(item.compUid);
                }}
              >
                <div className="d-flex align-items-center">
                  <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Delete Company</span>
                </div>
              </Link>
            </Dropdown.Item>
            {item?.status === 1 ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    toggleDropdown();
                    DisableCompany();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <DisabledByDefaultSharpIcon className="material-symbols-sharp me-2" />

                    <span className="dropdownTxt f-14">Disable Company</span>
                  </div>
                </Link>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    toggleDropdown();
                    EnableCompany();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <DisabledByDefaultSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Enable Company</span>
                  </div>
                </Link>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </div>


      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2" onClick={(e) => e.stopPropagation()}>
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to delete Company?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeSkills()}
                >
                  Delete
                </button>
              </div>

            </div>
          </div>
        </div>
      )}

      <UpdateCompany
        openUpdateModal={openUpdateModal}
        showUpdateModal={showUpdateModal}
        closeUpdateModal={closeUpdateModal}
        compUid={compUid}
      />

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default CompanyActions;
