import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from '../../API/axios';
import ErrorToast from '../ErrorToast';
import WarningToast from '../WarningToast';
import SuccessToast from '../SuccessToast';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const PeopleInteraction = ({ userUid, isFollowedByYou, getUserProfileList, index }) => {
    const guestModeData = localStorage.getItem("guestMode");
    const sessionId = localStorage.getItem("sessionId");

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleToggleDropdown = (index) => {
        setShowDropdown((prev) => (prev === index ? null : index));
    };
    const handleUnfollowCloseModal = () => {
        setShowModal(false);
        // setSelectedItem(null);
    };
    const handleShowModal = () => {
        // setSelectedItem(item);
        // console.log("unfollow item", item);
        // setSelectedUserId(item.userUid);
        setShowModal(true);
    };
    const [followModal, setFollowModal] = useState(false);
    const closeModalData = () => {
        setFollowModal(false);
    };
    const [showModal, setShowModal] = useState(false);
    const [checkFollow, setCheckFollow] = useState(isFollowedByYou);
    const FollowUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios.post("UserFollow/FollowUser", data).then((resData) => {
                // setFollowedCompanies((prev) => ({ ...prev, [id]: true }));
                setToastMessage(resData.data.message);
                setShowToast(true);
                setCheckFollow(true)
                // getUserProfileList()
                // Optionally, you can update the userList or searchuserList state if needed
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };


    const unFollowUser = async () => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: userUid,
        };
        try {
            await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
                // setFollowedCompanies((prev) => ({ ...prev, [selectedUserId]: false }));
                setToastMessage(res.data.message);
                setShowToast(true);
                setShowModal(false);
                setCheckFollow(false)
                // Optionally, you can update the userList or searchuserList state if needed
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            {/* <p>{checkFollow === true}followers</p> */}
            {checkFollow === true ? <div className="d-flex justify-content-end">
                <div
                    onClick={() => handleToggleDropdown(index)}
                    className="postMenu"
                >
                    <MoreHorizSharpIcon className="material-symbols-sharp" />
                    {showDropdown === index && (
                        <Dropdown.Menu
                            show={showDropdown === index}
                            ref={dropdownRef}
                            className="InteractionModal"
                            align="start"
                        >
                            <Link
                                onClick={() => {
                                    handleShowModal();
                                    // setFollowedId(item?.userUid);
                                }}
                            >
                                <Dropdown.Item
                                    as="button"
                                    className="dropdownList p-0"
                                >
                                    <div className="d-flex align-items-center">
                                        <ReportGmailerrorredSharpIcon className="material-symbols-sharp me-2" />

                                        <span className="dropdownTxt f-14">
                                            Unfollow
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Menu>
                    )}
                </div>
            </div> : <button
                className="wljPrimaryBtn"
                style={{ width: '100px' }}
                onClick={() => {
                    guestModeData === "true"
                        ? setFollowModal(true)
                        : FollowUser(userUid);
                    // setFollowedId(item?.userUid);
                }}
            >
                Follow
            </button>}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>
                                    Are you sure you want to unfollow
                                    {/* <b>{selectedItem?.fullName} ?</b> */}
                                </h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleUnfollowCloseModal();
                                    }}
                                />
                            </div>

                            <div className="text-end mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => unFollowUser()}
                                >
                                    Unfollow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default PeopleInteraction