import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/userAuth.css";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "../../API/axios";
import { debounce } from "lodash";
import Select from "react-select";
import UseDebounce from "./UseDebounce";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
const DraftJob = ({
  onClose,
  data,
  showUserAuthModal,
  fetchData,
  openModal,
  showModal,
  closeModal,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const fileInputRef = useRef(null);
  const fileInputRefBg = useRef(null);
  const [display, setDisplay] = useState(false);

  //   state for Next Previous Button
  const [activeStep, setActiveStep] = useState(1);
  // Functions
  console.log('activeStep', activeStep);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  //************************Designation Start  ************************//
  const [value, setValue] = useState(null);
  const [page, setPage] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  console.log("options", options);

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetDesignationDDL?search=${typed}&pageNumber=${page + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log("options", response);
        const data = response.data.responseData.data.map((item) => ({
          value: item.dsgntnId,
          label: item.name,
        }));
        setPage((prevPage) => prevPage + 1);
        setIsLoading(false);
        setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPages(response.data.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setPage(1);
  };

  const handleChange = (value) => {
    console.log("value===========>", value);

    setValue(value);
    setPage(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (page < totalPages) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  //************************ Designation End  ************************//

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City List End  ********//
  //********Job Type List Start  ********//
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const getJobType = () => {
    axios
      .get(`Master/GetJobTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbTypId,
          label: item.name,
        }));
        setJobTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getJobType();
  }, []);
  //********Job Type List End  ********//

  //********Company List Start  ********//
  const [companyData, setCompanyData] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const getCompany = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Company/GetOwnCompaniesList`, data)
      .then((response) => {
        console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.compUid,
          label: item.compName,
        }));
        setCompanyData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompany();
  }, []);
  //********Company List End  ********//

  //********Work Location Type List Start  ********//
  const [workLocationData, setWorkLocationData] = useState([]);
  const [workLocationId, setWorkLocationId] = useState(null);
  const getWorkLocationType = () => {
    axios
      .get(`Master/GetWorkLocationTypesDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkLocationType();
  }, []);
  //********Work Location Type List End  ********//

  //********Work Level Type List Start  ********//
  const [workTypeData, setWorkTypeData] = useState([]);
  const [workTypeId, setWorkTypeId] = useState(null);
  const getWorkType = () => {
    axios
      .get(`Master/GetWorkLevelTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkType();
  }, []);
  //********Work Level Type List End  ********//

  //********Salary Type List Start  ********//
  const [salaryData, setsalaryData] = useState([]);
  const [salaryId, setsalaryId] = useState(null);
  const getsalaryType = () => {
    axios
      .get(`Master/GetSalaryTypesDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbSlryTypId,
          label: item.name,
        }));
        setsalaryData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getsalaryType();
  }, []);
  //********Salary Type List End  ********//

  const [jobTitle, setjobTitle] = useState("");
  const [minimumSalary, setMinimumSalary] = useState(null);
  const [maximumSalary, setMaximumSalary] = useState(null);
  const [hashtags, setHashtags] = useState("");
  const [jobDescription, setjobDescription] = useState("");
  const [jobId, setJobId] = useState("");

  const debouncedMinimumSalary = UseDebounce(minimumSalary, 1000); // Adjust delay as needed
  useEffect(() => {
    if (debouncedMinimumSalary) {
      UpdateDraft();
    }
  }, [debouncedMinimumSalary]);

  const debouncedMaximumSalary = UseDebounce(maximumSalary, 1000); // Adjust delay as needed
  useEffect(() => {
    if (debouncedMaximumSalary) {
      UpdateDraft();
    }
  }, [debouncedMaximumSalary]);

  const debouncedhashtags = UseDebounce(hashtags, 2000); // Adjust delay as needed
  useEffect(() => {
    if (debouncedhashtags) {
      UpdateDraft();
    }
  }, [debouncedhashtags]);

  const debouncedJobDescription = UseDebounce(jobDescription, 1000); // Adjust delay as needed
  useEffect(() => {
    if (debouncedJobDescription) {
      UpdateDraft();
    }
  }, [debouncedJobDescription]);
  const SaveDraft = async () => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        compUid: companyId,
        locationId: null,
        cityID: cityId,
        jbTypId: jobTypeId,
        workLevelType: workTypeId,
        workLocationType: workLocationId,
        designationId: value.value,
        // designationId: 1,
        salaryTypeId: salaryId,
        minSalary: minimumSalary,
        maxSalary: maximumSalary,
        currency: null,
        title: jobTitle,
        description: jobDescription,
        hashTags: hashtags,
        sourceTypeId: null,
        receiveApplicantsType: 1,
      };
      // console.log('body', body);
      console.log("description", body)

      const res = await axios.post("/Job/SaveJobAsDraft", body);
      console.log("response: ", res.data.responseData.id);
      setJobId(res.data.responseData.id);
      setToastMessage(res.data.message);
      setShowToast(true);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [updateMessage, setUpdateMessage] = useState('')
  const UpdateDraft = async () => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: jobId,
        compUid: companyId,
        locationId: null,
        cityID: cityId,
        jbTypId: jobTypeId,
        workLevelType: workTypeId,
        workLocationType: workLocationId,
        // "designationId": value,
        designationId: 1,
        salaryTypeId: salaryId,
        minSalary: minimumSalary,
        maxSalary: maximumSalary,
        currency: null,
        title: jobTitle,
        description: jobDescription,
        hashTags: hashtags,
        sourceTypeId: null,
        receiveApplicantsType: 1,
      };
      console.log("body", body);
      const res = await axios.put("/Job/UpdateDraftJob", body);
      console.log('response:');
      console.log("response: ", res);
      setUpdateMessage(res.data.message)
      // setToastMessage(res.data.message);
      // setShowToast(true);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const UpdateJob = async () => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: jobId,

      };
      console.log("body", body);
      const res = await axios.put("/Job/CompleteJobPosting", body);
      console.log("response: ", res);
      //   toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      closeModal();
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [jobData, setJobData] = useState("");
  const JobDetails = async () => {
    try {
      // setLoading(true);
      await axios
        .get(`Job/GetJobDetails?DeviceType=Web&JobId=${jobId}`)
        .then((resData) => {
          console.log("hjsddsd", resData.data.responseData);
          setJobData(resData.data.responseData.viewJob);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  console.log("jobData", jobData);



  return (
    <div>
      {openModal && (
        <div
          className="modal fade show postAdd jobCreate"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around ">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Create Job</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {

                    if (activeStep >= 2) {
                      UpdateDraft().then(() => {
                        setToastMessage("Draft updated successfully");
                        setShowToast(true);
                        setTimeout(() => {
                          closeModal();
                        }, 200); // Adjust the delay if necessary
                      });
                    } else {
                      closeModal(); // If not on step 2, just close the modal normally
                    }
                  }
                  } />
              </div>
              <div className="bs-stepper">
                <div className="bs-stepper-content p-0">
                  <div
                    id="logins-part"
                    className={`content ${activeStep === 1 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="logins-part-trigger"
                  >
                    <Row>
                      <Col md={12}>
                        <Form>
                          <div className="row p-4 pt-0 settings CreateCompany  p-0">
                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Company Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                defaultValue={companyId}
                                onChange={(e) => {
                                  setCompanyId(e.value);
                                }}
                                options={companyData}
                                placeholder={"Select Company"}
                                styles={customStyles}
                              />
                              {display && !companyId ? (
                                <span className="error_msg_lbl">
                                  Select Company Name{" "}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Designation{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <Select
                                value={value}
                                options={options}
                                optionRenderer={renderOption}
                                placeholder={"Search and Select Designation"}
                                onOpen={handleOpen}
                                onChange={handleChange}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                onInputChange={handleInputChange}
                                isClearable
                                styles={customStyles}
                              />
                              {display && !value ? (
                                <span className="error_msg_lbl">
                                  Select Designation{" "}
                                </span>
                              ) : null}
                            </div>

                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Job Title{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                value={jobTitle}
                                onChange={(e) => setjobTitle(e.target.value)}
                              />
                              {display && !jobTitle ? (
                                <span className="error_msg_lbl">
                                  Enter Job Title{" "}
                                </span>
                              ) : null}
                            </div>

                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                City Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                defaultValue={cityId}
                                onChange={(selectedOption) =>
                                  setCityId(selectedOption.value)
                                }
                                options={cityData}
                                placeholder={"Search and Select City"}
                                onInputChange={(inputValue) => {
                                  setCitySearch(inputValue);
                                }}
                                styles={customStyles}
                              />
                              {display && !cityId ? (
                                <span className="error_msg_lbl">
                                  Select City{" "}
                                </span>
                              ) : null}
                            </div>

                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Job Type <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                defaultValue={jobTypeId}
                                onChange={(e) => {
                                  setJobTypeId(e.value);
                                }}
                                options={jobTypeData}
                                placeholder={"Select Job Type"}
                                styles={customStyles}
                              />
                              {display && !jobTypeId ? (
                                <span className="error_msg_lbl">
                                  Select Job Type{" "}
                                </span>
                              ) : null}
                            </div>



                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Work Level Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                defaultValue={workTypeId}
                                onChange={(e) => {
                                  setWorkTypeId(e.value);
                                }}
                                options={workTypeData}
                                placeholder={"Select Work Level Type"}
                                styles={customStyles}
                              />
                              {display && !workTypeId ? (
                                <span className="error_msg_lbl">
                                  Select Work Level Type{" "}
                                </span>
                              ) : null}
                            </div>

                            <div className="col-md-12 mt-2">
                              <label htmlFor="about">
                                Work Location Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {/* <input type='text' /> */}
                              <Select
                                defaultValue={workLocationId}
                                onChange={(e) => {
                                  setWorkLocationId(e.value);
                                }}
                                options={workLocationData}
                                styles={customStyles}
                                placeholder={"Select Work Location Type"}
                              />
                              {display && !workLocationId ? (
                                <span className="error_msg_lbl">
                                  Select Work Location Type{" "}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="p-4 col-md-12 text-end mt-4">
                            <button
                              type="button"
                              className="mainBtn1"
                              onClick={() => {
                                if (
                                  jobTitle === "" ||
                                  cityId === "" ||
                                  jobTypeId === "" ||
                                  companyId === "" ||
                                  workTypeId === "" ||
                                  workLocationId === ""
                                ) {
                                  setWarningToastMessage(
                                    "Please fill all fields"
                                  );
                                  setDisplay(true);
                                  setShowWarningToast(true);
                                } else {
                                  handleNext();
                                  SaveDraft();
                                }
                              }}
                              disabled={
                                cityId === null ||
                                jobTypeId === null ||
                                companyId === null ||
                                workTypeId === null ||
                                workLocationId === null ||
                                jobTitle === ""
                              }
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                  <div
                    id="information-part"
                    className={`content ${activeStep === 2 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="information-part-trigger"
                  >
                    <Row>
                      <Col md={12}>
                        <div className="form-progress-cont mb-4 d-flex align-items-center px-3 progress-bar-blue">

                        </div>
                        <div className="row p-4 pt-0 settings CreateCompany  p-0">
                          <div className="col-md-12 mt-2">
                            <label htmlFor="about">Job Description *</label>
                            <textarea
                              id="about"
                              name="about"
                              rows="4"
                              cols="50"
                              value={jobDescription}
                              onChange={(e) =>
                                setjobDescription(e.target.value)
                              }
                            ></textarea>
                            {display && !jobDescription ? (
                              <span className="error_msg_lbl">
                                EnterJob Description{" "}
                              </span>
                            ) : null}
                          </div>

                          <div className="col-md-12 mt-2">
                            <label htmlFor="about">Salary Type </label>
                            <Select
                              defaultValue={salaryId}
                              onChange={(e) => {
                                setsalaryId(e.value);
                                UpdateDraft();
                              }}
                              options={salaryData}
                              styles={customStyles}
                              placeholder={"Select Salary Type"}
                            />
                            {/* {display && !salaryId ? (
                              <span className="error_msg_lbl">
                                Select Salary Type{" "}
                              </span>
                            ) : null} */}
                          </div>

                          <div className="col-md-12 mt-2">
                            <label htmlFor="about">Minimum Salary($)</label>
                            <input
                              type="number"
                              value={minimumSalary}
                              onChange={(e) => {
                                setMinimumSalary(e.target.value);
                                // UpdateDraft();
                              }}
                            />
                            {/* {display && !minimumSalary ? (
                              <span className="error_msg_lbl">
                                Enter Minimum Salary{" "}
                              </span>
                            ) : null} */}
                          </div>

                          <div className="col-md-12 mt-2">
                            <label htmlFor="about">Maximum Salary($)</label>
                            <input
                              type="number"
                              value={maximumSalary}
                              onChange={(e) => setMaximumSalary(e.target.value)}
                            />
                            {/* {display && !maximumSalary ? (
                              <span className="error_msg_lbl">
                                Enter Maximum Salary{" "}
                              </span>
                            ) : null} */}
                          </div>

                          {/* <div className="col-md-12 mt-2">
                            <label htmlFor="about">Hashtags </label>
                            <input
                              type="text"
                              value={hashtags}
                              onChange={(e) => setHashtags(e.target.value)}
                            />
                            {display && !hashtags ? (
                              <span className="error_msg_lbl">
                                Enter Hashtags{" "}
                              </span>
                            ) : null}
                          </div> */}

                          <div className="text-end mt-4 d-flex justify-content-between">
                            <div className="previousBtnCont d-flex align-items-center">
                              <ArrowBackIosNewSharpIcon className="material-symbols-sharp" />
                              <button
                                type="button"
                                className="previousBtn"
                                onClick={handlePrev}
                              >
                                Previous
                              </button>
                            </div>
                            <button
                              type="button"
                              className="mainBtn1"
                              onClick={() => {
                                if (
                                  // salaryId === "" ||
                                  // minimumSalary === "" ||
                                  // maximumSalary === "" ||
                                  jobDescription === ""
                                  // hashtags === ""
                                ) {
                                  setWarningToastMessage(
                                    "Please fill all fields"
                                  );
                                  setDisplay(true);
                                  setShowWarningToast(true);
                                } else {
                                  handleNext();
                                  UpdateDraft();
                                  JobDetails();
                                }
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    id="information-part"
                    className={`content ${activeStep === 3 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="information-part-trigger"
                  >

                    <Row>
                      <Col md={12}>
                        <div className="jobdata-content-cont d-flex flex-column p-3 pb-0">
                          <p className="fw-bold">{jobData.description}</p>
                          <p className="fw-bold">{jobData.title}</p>
                          <p>{jobData.compName}</p>
                          <p>({jobData.workLocationTypeName})</p>
                          <p>{jobData.maxSalary}</p>
                        </div>
                      </Col>

                      <Col md={12}>
                        <Form>
                          <div className="row p-4 pt-0 settings CreateCompany">
                            <div className="text-end mt-4 d-flex justify-content-between">
                              <div className="previousBtnCont d-flex align-items-center">
                                <ArrowBackIosNewSharpIcon className="material-symbols-sharp" />
                                <button
                                  type="button"
                                  className="previousBtn"
                                  onClick={handlePrev}
                                >
                                  Previous
                                </button>
                              </div>
                              <button
                                type="submit"
                                className="mainBtn1 jobPosting"
                                onClick={(e) => {
                                  e.preventDefault();
                                  UpdateJob();
                                }}
                              >
                                Complete Job Posting
                              </button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </div>
  );
};

export default DraftJob;
