import React, { useEffect, useState } from 'react'
import axios from '../../API/axios';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import { people, post, Profile } from '../../assets/Images/ImagesLink';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { ShimmerDiv } from 'shimmer-effects-react';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';



const Reviews = ({ CompanyDetails, CompUid, companyDetails, setJobModal, handleShowReview, CompanyStatsCount }) => {
    console.log('companyDetails', companyDetails);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);


    const [showEditReviewModal, setShowEditReviewModal] = useState(false);
    const handleCloseEditReviewModal = () => setShowEditReviewModal(false);
    // };
    const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false);
    const handleCloseDeleteReviewModal = () => setShowDeleteReviewModal(false);
    const handleShowDeleteReview = () => setShowDeleteReviewModal(true);

    const [reviewContent, setReviewContent] = useState("");
    const [reviewId, setReviewId] = useState("");

    const sessionId = localStorage.getItem("sessionId");
    const [reviews, setReviews] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const getreviews = async () => {
        const reviewData = {
            deviceType: "Web",
            compUid: CompUid,
        };
        try {
            await axios.post(`Company/GetCompanyReviews`, reviewData).then((res) => {
                console.log("item review", res);
                setReviews(res.data.responseData);
            });
            setIsLoading(false)
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.error(error);
            }
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getreviews();
    }, [CompUid]);



    const EditReview = async () => {
        const reviewData = {
            deviceType: "Web",
            sessionId: sessionId,
            compUid: CompUid,
            review: reviewContent,
            rating: 0,
            compRvwId: reviewId,
        };
        try {
            await axios
                .post("Company/UpdateCompanyReview", reviewData)
                .then((res) => {
                    console.log("company edit review", res);
                    setToastMessage(res.data.message);
                    setShowToast(true);
                    // setWarningToastMessage(res.data.message);
                    // setShowWarningToast(true);
                    setShowEditReviewModal(false);
                    // setReviewContent();
                    getreviews();
                });
        } catch (error) {
            console.log(error);
        }
    };

    const deleteReview = async () => {
        const deleteReview = {
            deviceType: "Web",
            sessionId: sessionId,
            //   compRvwId: 0,
            compUid: CompUid,
        };
        try {
            await axios
                .post(`Company/DeleteCompanyReview`, deleteReview)
                .then((res) => {
                    console.log("delete res", res);
                    setToastMessage(res.data.message);
                    setShowToast(true);
                    getreviews();
                    CompanyDetails()
                    setShowDeleteReviewModal(false);
                    CompanyStatsCount();
                });
        } catch (error) {
            console.log(error);
        }
    };
    console.log('hghzjxcghjcx', reviews);
    useEffect(() => {
        if (showEditReviewModal || showDeleteReviewModal) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showEditReviewModal, showDeleteReviewModal]);


    const guestModeData = localStorage.getItem("guestMode");

    return (
        <>

            <div className="aboutMe mb-3 p-3" >
                <div className="d-flex align-items-center justify-content-between mb-4 border-custom pb-2">
                    <div className="ms-2">
                        <h4 className="f-14 fw-bold  mb-0">All Reviews</h4>
                    </div>
                    {companyDetails?.isReviewedByYou === false ?
                        <AddSharpIcon
                            className="material-symbols-sharp me-1 pointer"
                            onClick={() => {
                                guestModeData === "true"
                                    ? setJobModal(true)
                                    : handleShowReview(true);
                            }}
                            style={{ width: '20px' }}
                        />
                        : ''}

                </div>

                {isLoading ? (
                    <div className="mt-3">
                    </div>
                ) : isError ? (
                    <div className="mt-2 error-image-cont">
                        <img src={post.errorSmall} alt="404 Not Found" />
                    </div>
                ) : (
                    reviews?.length === 0 || reviews === undefined ? <div className="mt-2 text-center">
                        <img src={post.errorSmall} alt="404 Not Found" width={200} />
                    </div> : reviews && reviews.map((item, index) => {
                        console.log('item review', item);
                        const isLastItem = index === reviews.length - 1;
                        return (
                            <div className={`analytic-sub mt-2 mb-1 ${isLastItem ? '' : 'border-custom'} pb-3`}>
                                <div className='d-flex align-items-center'>
                                    <div className="ImgAvatar">
                                        <img
                                            src={item?.userAvatar}
                                            alt=""
                                            width={60}
                                            style={{ borderRadius: "50%" }}
                                        />
                                    </div>
                                    <div className="ms-2">
                                        <h4 className="f-14 fw-bold mb-0 name ">{item?.userName}</h4>

                                        <p className="f-12 mt-0 ">{item.review}</p>
                                    </div>
                                    {item?.isOwn === true ? <div className="ms-auto">

                                        <EditSharpIcon
                                            className="material-symbols-sharp me-1 pointer "
                                            style={{ width: '20px' }}
                                            onClick={() => {
                                                setShowEditReviewModal(!showEditReviewModal);
                                                setReviewId(item?.compRvwRtngId);
                                                setReviewContent(item?.review);
                                            }} />

                                        <DeleteOutlineSharpIcon
                                            className="material-symbols-sharp pointer delete-icon"
                                            style={{ width: '20px' }}
                                            onClick={() => {
                                                handleShowDeleteReview(true);
                                            }} />

                                    </div> : ''}
                                </div>

                                {/* <h4 className="f-14  mb-0 mt-2 userCommentdetail ms-4"  >{item?.review}</h4> */}

                            </div>
                        );
                    })
                )}

            </div>
            {showEditReviewModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Edit Review</h6>

                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseEditReviewModal();
                                    }}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseEditReviewModal();
                                    }}
                                />
                            </div>

                            <div className="col-md-12 mt-2 textAreaCont">
                                <textarea
                                    type="text"
                                    style={{ width: "100%", height: "100px" }}
                                    defaultValue={reviewContent}
                                    // defaultValue={reviewContent}
                                    onChange={(e) => setReviewContent(e.target.value)}
                                    className="textReview"
                                ></textarea>
                            </div>

                            <div className="col-md-12 text-end">
                                <button type="button" className="mainBtn1" onClick={EditReview}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}



            {showDeleteReviewModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Are you sure want to delete ?</h6>

                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseDeleteReviewModal();
                                    }}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseDeleteReviewModal();
                                    }}
                                />
                            </div>

                            {/* <div className="col-md-12 mt-2 textAreaCont">
                                <h4>Are you sure want to delete ?</h4>
                            </div> */}

                            <div className="col-md-12 text-center mt-2 ">
                                <button
                                    type="button"
                                    className="mainBtn1"
                                    onClick={deleteReview}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default Reviews