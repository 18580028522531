import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from '../../API/axios';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';


const CompanyInteraction = ({ item, isCompanyFollowed, index, followedCompanies, setFollowedCompanies, getCompanyList }) => {
    const sessionId = localStorage.getItem("sessionId");
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggleDropdown = (index) => {
        setShowDropdown((prev) => (prev === index ? null : index));
    };

    //************************ Follow User Start  ************************//
    const [isfollowed, setIsfollowed] = useState(isCompanyFollowed);

    console.log('resData', followedCompanies);

    //************************ Follow Company Start  ************************//

    const FollowCompany = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            uiD_ID: id,
        };
        try {
            await axios.post("Entity/Follow/Company", data).then((resData) => {
                console.log("followed");
                getCompanyList();
                setIsfollowed(true);
                setFollowedCompanies((prev) => ({ ...prev, [id]: true }));
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ Follow Company End  ************************//

    //************************ UnFollow Company Start  ************************//
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [compUID, setCompUID] = useState("");
    const UnFollow = async () => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            uiD_ID: compUID,
        };
        try {
            await axios.post("Entity/Unfollow/Company", data).then((resData) => {
                console.log("resData", resData);
                getCompanyList();
                // toast.success(resData.data.message);
                setIsfollowed(false);
                setFollowedCompanies((prev) => ({ ...prev, [compUID]: false }));
                setToastMessage(resData.data.message);
                setShowToast(true);
                handleCloseModal();
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ UnFollow Company End  ************************//


    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showModal]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            {isfollowed === true ? (
                <div
                    onClick={() => handleToggleDropdown(index)}
                    className="postMenu"
                >
                    <MoreHorizSharpIcon className="material-symbols-sharp" />


                    {showDropdown === index && (
                        <Dropdown.Menu
                            show={showDropdown === index}
                            ref={dropdownRef}
                            className="InteractionModal"
                            align="start"
                        >

                            <Dropdown.Item
                                as="button"
                                className="dropdownList p-0"
                            >
                                <Link
                                    onClick={() => {
                                        handleShow();
                                        setCompUID(item.compUid);
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <ReportGmailerrorredSharpIcon
                                            className="material-symbols-sharp me-2" />
                                        <span className="dropdownTxt f-14">
                                            Unfollow company
                                        </span>
                                    </div>
                                </Link>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    )}
                </div>
            ) : (
                <button
                    className="wljPrimaryBtn"
                    style={{ width: '100px' }}
                    onClick={() => {
                        FollowCompany(item.compUid);
                    }}
                >
                    Follow
                </button>
                // <button
                //     className="button company-followBtn"
                //     onClick={() => {
                //         FollowCompany(item.compUid);
                //     }
                //     }
                // >
                //     <span>Follow</span>
                // </button>
            )}
            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>Unfollow Company</h6>

                                {/* <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                >
                                    close
                                </span> */}
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                />

                            </div>

                            <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="mainBtn1"
                                    onClick={() => UnFollow()}
                                >
                                    Unfollow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default CompanyInteraction