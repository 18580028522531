import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import "../../assets/css/PersonalProfile.css";
import axios from "../../API/axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { debounce } from "lodash";
import { Form, Input, } from "reactstrap";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Button, Overlay, Popover } from "react-bootstrap";
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

const ProfileEducationUpdate = ({
  showEducationUpdate,
  setShowEducationUpdate,
  handleUpdateEducationClose,
  getUserProfile,
  educationData,
  userUId
}) => {
  const [display, setDisplay] = useState(false);
  const sessionId = localStorage.getItem("sessionId");

  const [startDate, setStartDate] = useState("");
  // const [startDate, setStartDate] = useState(() => {
  //   if (educationData?.startMonth && educationData?.startYear) {
  //     return new Date(educationData.startYear, educationData.startMonth - 1);
  //   }
  //   return '';
  // });
  const [endDate, setEndDate] = useState("");
  // const [endDate, setEndDate] = useState(() => {
  //   if (educationData?.endMonth && educationData?.endYear) {
  //     return new Date(educationData.endYear, educationData.endMonth - 1);
  //   }
  //   return '';
  // });

  const [field, setField] = useState("");
  const [grade, setGrade] = useState("");
  const [degree, setDegree] = useState("");
  const [activities, setActivities] = useState("");
  const [present, setPresent] = useState(false);
  const [description, setDescription] = useState("");
  const [valueIns, setValueIns] = useState(null);
  const [getOrgInsTId, setGenOrgInstId] = useState('');
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [checkCurrent, setCheckCurrent] = useState('')
  console.log('checkCurrent', checkCurrent);

  const CheckCurrentEducationStatus = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`UserData/CheckCurrentEducationStatus`, responseData)
        .then((res) => {
          setCheckCurrent(res?.data?.responseData)
        });
    } catch (error) {
      console.log(error.response.data.message);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  useEffect(() => {
    CheckCurrentEducationStatus()
  }, [])

  const addOptionsIns = () => {
    axios
      .get(
        `Master/GetAllInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
        }&pageSize=100&DeviceType=Web`
      )
      .then((response) => {
        console.log(response.data.responseData.list);
        const data = response.data.responseData.list.map((item) => ({
          value: item.uiDno,
          label: item.name,
          isOrgVrfd: item.isVerified,
          isOrgRgd: item.isRgstrd,

        }));
        setPageIns((prevPage) => prevPage + 1);
        setOptionsIns((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesIns(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateEducation = async (event) => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      cityId: cityId,
      startYear: startDate === "" ? "" : moment(startDate).format("YYYY"),
      endYear: endDate === "" ? "" : moment(endDate).format("YYYY"),
      isPresent: present,
      locationId: null,
      startMonth: startDate === "" ? "" : moment(startDate).format("MM"),
      endMonth: endDate === "" ? "" : moment(endDate).format("MM"),
      description: description,
      institutionUid: valueIns === null ? JSON.stringify(getOrgInsTId) : valueIns,
      field: field,
      degree: degree,
      grade: grade,
      activities: activities,
      userEductnId: educationData?.userEductnId
    };
    try {
      await axios.put(`UserData/UpdateEducationInfo`, data).then((resData) => {
        console.log("resData", resData);
        setToastMessage(resData.data.message);
        setShowToast(true);
        getUserProfile();
        setShowEducationUpdate(false);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [specData, setSpecData] = useState([]);
  const [specId, setSpecId] = useState(null);
  const getSpecialization = () => {

    axios
      .get(`Master/GetSpecializationsDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.spclznId,
          label: item.name,
        }));
        setSpecData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSpecialization();
  }, []);

  const getUserInfoList = () => {
    const userInfoData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    console.log("userInfoData===========>", userInfoData);
    axios.get(`UserData/GetUserEducationInfoList`, userInfoData)
      .then((response) => {
        console.log("userInfoData response", response);
        const res = response.data.responseData;
        console.log("user info lst", res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [qualiData, setQualiData] = useState([]);
  const [qualiId, setQualiId] = useState(null);
  const getQualification = () => {
    axios
      .get(`Master/GetQualificationsDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.qlfctnId,
          label: item.name,
        }));
        setQualiData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getQualification();
  }, []);

  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState("");
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  const handleOpenIns = () => {
    setPageIns(1);
  };


  const handleChangeIns = (valueIns) => {
    if (valueIns === null) {
      setValueIns(null);
    } else {
      setValueIns(valueIns.value);
    }
    setPageIns(0);
  };


  const handleInputChangeIns = (typedIns) => {
    logValueIns(typedIns);
    if (!typedIns) setPageIns(0);
  };

  const handleMenuScrollToBottomIns = () => {
    if (pageIns < totalPagesIns) {
      addOptionsIns();
    }
  };
  const [pageIns, setPageIns] = useState(0);
  const [typedIns, setTypedIns] = useState("");
  const [optionsIns, setOptionsIns] = useState([]);
  const [totalPagesIns, setTotalPagesIns] = useState(0);
  // console.log('value', value);
  const logValueIns = debounce((typedIns) => {
    setTypedIns(typedIns);
  }, 250);

  const renderOptionIns = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typedIns) {
      addOptionsIns();
    }
  }, [typedIns]);

  useEffect(() => {
    if (showEducationUpdate) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showEducationUpdate]);

  console.log('educationData', educationData);

  useEffect(() => {
    if (educationData) {
      setTypedIns(educationData?.institutionName)
      setValueIns(educationData?.institutionUid)
      // setGenOrgInstId(educationData?.institutionName)
      setSpecId(educationData?.specilizationId)
      setQualiId(educationData?.qualificationId)
      setCityId(educationData?.cityId);
      setCitySearch(educationData?.cityName)
      setField(educationData?.field)
      setDegree(educationData?.degree)
      setGrade(educationData?.grade)
      setActivities(educationData?.activities)
      setDescription(educationData?.description)
      // setStartDate(educationData?.startMonth, educationData?.startYear)
      // setEndDate(educationData?.endMonth, educationData?.endYear)
      if (educationData.startMonth && educationData.startYear) {
        setStartDate(new Date(educationData.startYear, educationData.startMonth - 1));
      } else {
        setStartDate(null);
      }

      if (educationData.endMonth && educationData.endYear) {
        setEndDate(new Date(educationData.endYear, educationData.endMonth - 1));
      } else {
        setEndDate(null);
      }
      setPresent(educationData.isPresent)
    }
  }, [educationData]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [OrganizationName, setOrganizationName] = useState("");
  const [InsName, setInsName] = useState("");
  console.log('InsName', InsName);

  const targetRef = useRef(null);
  const toggleTooltip = () => {
    console.log("Toggling Tooltip");
    setShowTooltip((prev) => !prev);
  };

  const [fileName, setFileName] = useState(""); // State to store selected filename
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFileName(file.name); // Set the filename in state
    }
  };


  const [newOrgType, setnewOrgType] = useState(null);
  const [orgCity, setOrgCity] = useState(null);
  const handleSaveInstitution = async () => {
    // setDisplay(true);

    // const formData = new FormData();
    // formData.append("orgName", OrganizationName);
    // formData.append("OrgType", "UnRegdInstitution");
    // formData.append("CityID", orgCity);
    // formData.append("OrgLogo", fileName);
    // formData.append("sessionId", sessionId);
    // console.log("formData", formData);

    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      gnrlOrgName: OrganizationName,
      type: 2,
      cityId: cityId,
      logoImage: fileName,
      locationId: null
    }

    try {
      const res = await axios.post("/General/AddGeneralOrgByUser", data);
      console.log("resposne new", res);
      setGenOrgInstId(res.data.responseData.id);
      setnewOrgType(res.data.responseData.value);
      // toast.success(res.data.message);
      setShowTooltip(false);
      setOrganizationName("");
      setOrgCity(null);
      setFileName("");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // toast.warning(error.response.data.message);
      } else {
        // toast.error(error.response?.data?.message || "An error occurred");
      }
    }
  };

  //************************Enable Education  ************************//
  const [status, setStatus] = useState('')
  const EnableEducation = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/EnableEducationInfo`, responseData)
        .then((res) => {
          // getUserProfile();
          setToastMessage(res.data.message);
          setShowToast(true);
          setStatus(1)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Education End  ************************//
  //************************Disable Education  ************************//
  const DisableEducation = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/DisableEducationInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          // getUserProfile();
          setStatus(0)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Education End  ************************//

  //************************Remove Education Start  ************************//

  const [showEducationDelete, setShowEducationDelete] = useState(false);
  const handleRemoveEducationClose = () => {
    setShowEducationDelete(false);
  };


  const [EducationId, setEducationId] = useState("");
  const removeEducation = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: EducationId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteEducationInfo`, { data: responseData })
        .then((res) => {
          setShowEducationDelete(!showEducationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Education End  ************************//

  console.log('educationData', educationData);

  return (
    <>
      {showEducationUpdate && (
        <div className="profile-education-cont">
          <div
            className="modal fade show modal-lg"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="setting-header d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0 me-3">Education Info</h6>
                    <div className="form-switch form-check-primary ms-2">
                      <Input
                        type="switch"
                        defaultChecked={educationData?.status === 1 || status === 1}
                        name="icon-primary"
                        onClick={() => {
                          if (educationData?.status === 1 || status === 1) {
                            DisableEducation(educationData.userEductnId);
                          } else {
                            EnableEducation(educationData.userEductnId);
                          }
                        }}
                        className=""
                      />
                    </div>
                    <DeleteOutlineSharpIcon
                      className="material-symbols-sharp pointer delete-icon"
                      onClick={() => {
                        setShowEducationDelete(!showEducationDelete);
                        setEducationId(educationData.userEductnId);
                      }}
                    />
                  </div>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-3 pointer"
                    onClick={handleUpdateEducationClose}
                  />
                </div>
                <Form>
                  <div className="row modal-p settings CreateCompany">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about" for="institute">
                        Institution<span className="text-danger ms-2">*</span>
                      </label>
                      <a
                        href="#"
                        ref={targetRef}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleTooltip();
                        }}
                        className="createModal f-14 ms-2"
                      // isDisabled={!selectedIndustryId} // Disable until industry is selected
                      >
                        Create New
                      </a>
                      <Overlay
                        target={targetRef.current}
                        show={showTooltip}
                        placement="bottom"
                        rootClose
                        container={document.querySelector(".modal")}
                        onHide={() => setShowTooltip(false)} // Close when clicking outside
                      >
                        <Popover id="popover-basic">
                          <Popover.Header as="h3" className="Dark-Body-Card-group">Create Institute</Popover.Header>
                          <Popover.Body className="Dark-Body-Card-group">
                            <Form>
                              <div>
                                <label className="mb-1 Text-mb-Create">Institute name</label>
                                <input
                                  type="text"
                                  value={OrganizationName}
                                  onChange={(e) => {
                                    setOrganizationName(e.target.value);
                                    setInsName(e.target.value);
                                  }}
                                  placeholder="Enter Institute name"
                                  className="mb-2 form-control Dark-Input-from-group"
                                />
                              </div>
                              <div>
                                <label className="mb-1 Text-mb-Create">City</label>
                                <Select
                                  defaultValue={cityId}
                                  onChange={(selectedOption) =>
                                    setCityId(selectedOption.value)
                                  }
                                  options={cityData}
                                  placeholder={"Search and Select City"}
                                  onInputChange={(inputValue) => {
                                    setCitySearch(inputValue);

                                  }}
                                  invalid={display && cityId === ""}
                                  styles={customStyles}
                                />
                              </div>
                              <div className=" mt-2 mb-2 Text-mb-Create">
                                <label className="mb-1">Institute Logo</label>
                                <input
                                  type="file"
                                  id="lastName"
                                  // className="form-control Dark-Input-from-group"
                                  placeholder=""
                                  onChange={handleFileChange}
                                //   onChange={(e) => {
                                //     handleProfileImageChange(e);
                                //   }}
                                //   invalid={displays && profileImage === ""}
                                />
                                {/* {displays && !profileImage ? (
                          <span className="error_msg_lbl text-red">
                            Upload Image{" "}
                          </span>
                        ) : null} */}
                              </div>
                              <div className="d-flex justify-content-end">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={handleSaveInstitution}
                                  className="me-2"
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => setShowTooltip(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                      <CreatableSelect
                        value={optionsIns.find((option) => option.value === valueIns)}
                        options={optionsIns}
                        styles={customStyles}
                        optionRenderer={renderOptionIns}
                        onOpen={handleOpenIns}
                        onChange={handleChangeIns}
                        onMenuScrollToBottom={handleMenuScrollToBottomIns}
                        onInputChange={handleInputChangeIns}
                        isClearable
                        className="iconsDropdown"
                        invalid={display && valueIns === ""}
                        placeholder={"Search and Select Institution"}
                        formatOptionLabel={(option) => (
                          <div>
                            {option.label}

                            {option.isOrgVrfd === true ? (
                              <img src={resume.verified} width={15} className="ms-1" />
                            ) : option.isOrgRgd === true ? (
                              <img src={resume.register} width={15} className="ms-1" />
                            ) : (
                              <img src={resume.unregister} width={15} className="ms-1" />
                            )}
                          </div>
                        )}
                      />
                      {display && !valueIns ? (
                        <span className="error_msg_lbl">
                          Please Select Institution{" "}
                        </span>
                      ) : null}
                    </div>



                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        City<span className="text-danger ms-2">*</span>
                      </label>
                      <Select
                        value={cityData.find((option) => option.value === cityId)}
                        onChange={(selectedOption) =>
                          selectedOption === null ? setCityId(null) : setCityId(selectedOption.value)

                        }
                        options={cityData}
                        placeholder={"Search and Select City"}
                        onInputChange={(inputValue) => {
                          setCitySearch(inputValue);
                        }}
                        invalid={display && citySearch === ""}
                        styles={customStyles}
                        isClearable
                      />
                      {display && !citySearch ? (
                        <span className="error_msg_lbl">Select City </span>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Field<span className="text-danger ms-2">*</span>
                      </label>
                      <Input
                        type="text"
                        value={field}
                        placeholder="Enter field"
                        onChange={(e) => setField(e.target.value)}
                        invalid={display && field === ""}
                      />
                      {display && !field ? (
                        <span className="error_msg_lbl">Enter Field </span>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Degree<span className="text-danger ms-2">*</span>
                      </label>
                      <Input
                        type="text"
                        value={degree}
                        onChange={(e) => setDegree(e.target.value)}
                        invalid={display && degree === ""}
                      //   onChange={handleInputChanges}
                      />
                      {display && !degree ? (
                        <span className="error_msg_lbl">Enter Degree </span>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Grade
                      </label>
                      <Input
                        type="text"
                        value={grade}
                        onChange={(e) => setGrade(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Activities
                      </label>
                      <Input
                        type="text"
                        value={activities}
                        onChange={(e) => setActivities(e.target.value)}
                      />
                    </div>
                    {present === true || checkCurrent === false ? <div className="col-md-12 mt-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        style={{ width: "20px", height: "20px", marginRight: '5px' }}
                        //   id={`check-${item.id}`}
                        checked={present}
                        onChange={() => setPresent(!present)}
                      />
                      <label htmlFor="about">IsPresent</label>
                    </div> : ''}


                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Start Year<span className="text-danger ms-2">*</span>
                      </label>

                      <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }} />
                      {display && !startDate ? (
                        <span className="error_msg_lbl">
                          Please Select Start Year{" "}
                        </span>
                      ) : null}
                    </div>

                    {/* <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        End Year<span className="text-danger ms-2">*</span>
                      </label>

                      <Flatpickr className='form-control' value={endDate} onChange={date => setEndDate(date[0])} id='dob' options={{
                        maxDate: "today" // Disables future dates
                      }} />
                      {display && !endDate ? (
                        <span className="error_msg_lbl">
                          Please Select End Year{" "}
                        </span>
                      ) : null}
                    </div> */}

                    {present === true ? '' : <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        End Year
                      </label>
                      {/* <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      invalid={display && endDate === ""}
                    /> */}
                      <Flatpickr className='form-control' value={endDate} onChange={date => setEndDate(date[0])} id='dob' options={{
                        maxDate: "today",// Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }} />
                      {/* {display && !endDate ? (
                      <span className="error_msg_lbl">
                        Please Select End Year{" "}
                      </span>
                    ) : null} */}
                    </div>}

                    <div className="col-md-12 mt-2">
                      <label htmlFor="about">
                        Description
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        rows="4"
                        cols="50"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="text-end mt-4 updateBtn">
                      <button
                        type="button"
                        className="closebtn me-2"
                        onClick={handleUpdateEducationClose}
                        style={{ width: "60px" }}
                      >
                        close
                      </button>
                      <button
                        type="button"
                        className="mainBtn1"
                        onClick={UpdateEducation}
                        style={{ width: "70px" }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {showEducationDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are You Sure You want to Delete Education?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeEducation()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileEducationUpdate;
