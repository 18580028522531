import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { NewsFeed, resume } from "../../assets/Images/ImagesLink";
import AddSkill from "./AddSkill";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Connection from "./Connection";
import { Link } from "react-router-dom";
import UpdateProfile from "./UpdateProfile";
import ProfileEducation from "./ProfileEducation";
import ProfileExperience from "./ProfileExperience";
import ProfileCertification from "./ProfileCertification";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import ProfileAward from "./ProfileAward";
import { Input } from "reactstrap";
import ProfileEducationUpdate from "./ProfileEducationUpdate";
import ProfileExperienceUpdate from "./ProfileExperienceUpdate";
import ProfileCertificateUpdate from "./ProfileCertificateUpdate";
import ProfileAwardUpdate from "./ProfileAwardUpdate";
import AddSkillModal from "./AddSkill";
import UpdateSkills from "./UpdateSkills";
import ProfileNegativeActions from "./ProfileNegativeActions";
import Skills from "./Skills";
import Flatpickr from 'react-flatpickr';
import ReactSelect from "react-select";
import Select from "react-select";
// import ProfileAchiementExperience from "./ProfileAchiementExperience";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddExperienceAchivement from "./AddExperienceAchivement";
import EditExperienceAchivement from "./EditExperienceAchivement";
import AddExperienceRole from "./AddExperienceRole";
import EditExperienceRole from "./EditExperienceRole";
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import MyLocationSharpIcon from '@mui/icons-material/MyLocationSharp';
import WcSharpIcon from '@mui/icons-material/WcSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SupervisedUserCircleSharpIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import WorkspacePremiumSharpIcon from '@mui/icons-material/WorkspacePremiumSharp';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import SyncSharpIcon from '@mui/icons-material/SyncSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import EndorseModal from "./EndorseModal";
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';

const About = ({ uid, userData, getUserProfile }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      className: state.isFocused
        ? "react-select__option--is-focused"
        : state.isSelected
          ? "react-select__option--is-selected"
          : "",
    }),
  };
  // console.log("userData.viewEducationInfo", userData.viewEducationInfo);
  console.log("uid", uid);
  const guestModeData = localStorage.getItem("guestMode");

  const [showAddSkill, setShowAddSkill] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  // const [userSkillId, setUserSkillId] = useState("");
  const [comment, setComment] = useState("");

  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);

  const userUid = localStorage.getItem("userUid");
  const sessionId = localStorage.getItem("sessionId");
  //************************User Skill Get Start  ************************//
  const [skillList, setSkillList] = useState([]);
  // console.log('skillList', skillList);
  const getUserSkill = () => {
    axios
      .post(`UserSkill/GetUserLinkedSkills?DeviceType=Web&UID=${uid}`)
      .then((response) => {
        const res = response.data.responseData;
        setSkillList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserSkill();
  }, [userData]);

  //************************User Skill Get End  ************************//

  //************************Remove Skill start  ************************//
  const [show, setShow] = useState(false);
  const [showMoreSkills, setShowMoreSkills] = useState(false);
  const handleRemoveSkillClose = () => {
    setShow(false);
  };
  const [skillId, setSkillId] = useState("");

  const [skillsData, setSkillsData] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const handleUpdateSkillClose = () => {
    setShowSkills(false);
  };

  const removeSkills = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      // skllId: skillId
    };
    try {
      await axios.post(`UserSkill/UnlinkSkill`, responseData).then((res) => {
        setShow(!show);
        // toast.success("Skill Deleted");
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserSkill();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Skill End  ************************//
  const [showCertificateUpdate, setShowCertificateUpdate] = useState(false);
  const handleUpdateCertificateClose = () => {
    setShowCertificateUpdate(false);
  };
  const [certificateData, setCertificateData] = useState([]);



  const [showEducationUpdate, setShowEducationUpdate] = useState(false);
  const handleUpdateEducationClose = () => {
    setShowEducationUpdate(false);
  };


  //************************Remove Education Start  ************************//

  const [showAwardUpdate, setShowAwardUpdate] = useState(false);
  const [showAwardData, setAwardData] = useState([]);
  const handleUpdateAwardClose = () => {
    setShowAwardUpdate(false);
  };



  //************************Remove Education End  ************************//

  const [ExperienceData, setExperienceData] = useState([]);
  const [ExperienceId, setExperienceId] = useState("");
  const [showExperienceUpdate, setShowExperienceUpdate] = useState(false);
  const handleUpdateExperienceClose = () => {
    setShowExperienceUpdate(false);
    setExperienceData([])
  };

  //************************Endorse User  ************************//
  const [showEndorseModal, setShowEndorseModal] = useState(false);
  const handleClose = () => {
    setShowEndorseModal(false);
  };
  const EndorseData = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      feedback: comment,
    };
    // console.log("user feedback data", data);
    if (comment === "") {
      setWarningToastMessage("Please Add Feedback");
      setShowWarningToast(true);
    } else {
      try {
        await axios.post("UserSkill/EndorseSkill", data).then((resData) => {
          // console.log("resData", resData);
          setComment("");
          setShowEndorseModal(false);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserSkill();
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };
  //************************Endorse User  ************************//

  //************************Undo Endorse User  ************************//
  const [showUndoEndorseModal, setShowUndoEndorseModal] = useState(false);
  const handleUndoEndorse = () => {
    setShowUndoEndorseModal(false);
  };
  const UndoEndorseData = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      feedback: comment,
    };
    // console.log("user feedback data", data);
    if (comment === "") {
      setWarningToastMessage("Please Add Feedback");
      setShowWarningToast(true);
    } else {
      try {
        await axios.post("UserSkill/UndoEndorseSkill", data).then((resData) => {
          // console.log("resData", resData);
          setComment("");
          setShowUndoEndorseModal(false);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          getUserSkill();
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
  };
  //************************Endorse User  ************************//

  //************************Followed User  ************************//
  const [isfollowed, setIsfollowed] = useState(false);

  const FollowedUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        // console.log("resData", resData);
        // toast.success(resData.data.message);
        // getUserProfileList();
        setIsfollowed(true);
        setToastMessage(resData.data.message);
        setShowToast(true);
        getUserProfile();
        // CheckFollowedUser();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  console.log("isfollowed", isfollowed);
  //************************Followed User  ************************//

  //************************Check Followed User  ************************//
  // const [checkFollowedUser, setCheckFollowedUser] = useState("");
  // // api removed from backend
  // const CheckFollowedUser = async (id) => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //     userUID: uid,
  //   };
  //   try {
  //     await axios.post("CheckConnection/CheckFollowing", data).then((resData) => {
  //       // console.log("checkresData", resData.data);
  //       setCheckFollowedUser(resData.data.status);
  //     });
  //   } catch (error) {
  //     if (error.response && error.response.status === 404) {
  //       setWarningToastMessage(error.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(error.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   CheckFollowedUser();
  // }, []);

  //************************Check Followed User  ************************//

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  //************************ Connect User Start  ************************//

  const ConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios
        .post("Connection/SendConnectionRequest", data)
        .then((resData) => {
          // console.log("resData", resData.data.message);
          // toast.success(resData.data.message);
          setToastMessage(resData.data.message);
          setShowToast(true);
          // CheckFollowedUser();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Connect User  End  ************************//

  //************************Check Connect User Start  ************************//
  const [connectionCheck, setConnectionCheck] = useState("");
  console.log('connectionCheck', connectionCheck);

  const [connectionId, setConnectionId] = useState("");
  // api removed from backend
  const CheckConnectUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: uid,
    };
    try {
      await axios.post("Connection/CheckConnection", data).then((resData) => {
        console.log("checkFollowedUser", resData.data);
        if (resData.data.status === "Warning") {
          setConnectionCheck("Connect");
        } else {
          setConnectionCheck(resData.data.responseData.value);
          setConnectionId(resData.data.responseData.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //************************ Connect User  End  ************************//

  const [educationData, setEducationData] = useState([]);
  const [showUSEREducation, setShowUSEREducation] = useState(false);

  const handleOpenUSEREducation = () => setShowUSEREducation(true);
  const handleCloseUSEREducation = () => setShowUSEREducation(false);

  const [showUserExperience, setShowUserExperience] = useState(false);
  const [showAchievementUserExperience, setshowAchievementUserExperience] = useState(false);

  const handleOpenUserExperience = () => setShowUserExperience(true);
  const handleCloseUserExperience = () => setShowUserExperience(false);

  const handleAchievementUserExperience = () => setshowAchievementUserExperience(true);
  const handleAchievementCloseUserExperience = () => setshowAchievementUserExperience(false);



  const [showUserCertification, setShowUserCertification] = useState(false);

  const handleOpenUserCertification = () => setShowUserCertification(true);
  const handleCloseUserCertification = () => setShowUserCertification(false);

  const [showUserAward, setShowUserAward] = useState(false);
  const handleOpenUserAward = () => setShowUserAward(true);
  const handleCloseUserAward = () => setShowUserAward(false);




  //************************ DOB   ************************//
  const [startDate, setStartDate] = useState("");
  const [dob, setDob] = useState(false);
  const handledob = () => {
    setDob(false);
  };
  const DobUpload = async () => {
    try {
      if (startDate !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append(
          "Dob",
          startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD")
        );
        const res = await axios.post("/UserAccount/SetDob", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setDob(false);
        getUserProfile()
        // userConfig();
        // getUserProfilePer();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ DOB  End ************************//


  //************************ Gender   ************************//
  const [genderModal, setGenderModal] = useState(false);
  const handleGenderModal = () => {
    setGenderModal(false);
  };
  const [genderValue, setGenderValue] = useState(null);
  const [genderOptionList, setGenderOptionList] = useState([]);
  const getGenderData = () => {
    try {
      axios.get(`/Master/GetGenderDDL`).then((response) => {
        // console.log("gender data", response.data.responseData);
        setGenderOptionList(response.data.responseData);
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  let genderOptions = genderOptionList.map((val) => {
    return { value: val.gndrId, label: val.name, id: val.gndrId };
  });

  const selectGender = (val) => {
    // console.log("gender", val.value);
    setGenderValue(val.value);
  };

  useEffect(() => {
    getGenderData();
  }, []);

  const GenderUpload = async () => {
    try {
      if (genderValue !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("GndrId", genderValue);
        const res = await axios.post("/UserAccount/SetGender", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setGenderModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Gender End   ************************//
  //************************ About   ************************//
  const [length, setLength] = useState(userData?.profileInfo?.about?.length);
  const [aboutContent, setAboutContent] = useState("");
  const [aboutModal, setAboutModal] = useState(false);
  const handleAboutModal = () => {
    setAboutModal(false);
  };
  const AboutUpload = async () => {
    try {
      if (aboutContent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("AboutContent", aboutContent);
        const res = await axios.post("/UserAccount/SetAbout", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setAboutModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ About  End ************************//

  //************************ Website   ************************//
  const [websitecontent, setWebsitecontent] = useState("");
  const [error, setError] = useState("");
  const validateWebsite = (value) => {
    // Regex to detect invalid prefixes and ensure a valid domain extension
    const invalidPrefixes = /^(http:|https:|http:\/\/|https:\/\/|www\.)/i;
    const validDomainExtension = /\.[a-z]{2,}$/i; // Matches ".com", ".org", ".net", etc.

    console.log("Input value:", value);
    console.log("Invalid prefix test result:", invalidPrefixes.test(value));
    console.log("Valid domain extension test result:", validDomainExtension.test(value));

    if (invalidPrefixes.test(value)) {
      setError("Website URL should not start with 'http', 'https', or 'www'.");
    } else if (!validDomainExtension.test(value)) {
      setError("Website URL must include a valid domain extension (e.g., '.com', '.org').");
    } else {
      setError(""); // Clear the error if the input is valid
    }

    setWebsitecontent(value); // Update the state with the user input
  };


  const handleSubmit = () => {
    // Prevent submission if there's an error or the input is empty
    if (!websitecontent.trim() || error) {
      alert("Please provide a valid website URL.");
      return;
    }
    websiteUpload(); // Call the submit function if valid
  };
  // const isSubmitDisabled = error !== "" || websitecontent.trim() === "";
  const [websiteModal, setWebsiteModal] = useState(false);
  const handlewebsiteModal = () => {
    setWebsiteModal(false);
  };
  const websiteUpload = async () => {
    try {
      if (websitecontent !== '') {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("Website", websitecontent);
        const res = await axios.post("/UserAccount/SetWebsite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setWebsiteModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Website  End ************************//





  //************************ City   ************************//
  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City List End  ********//
  const [languageModal, setlanguageModal] = useState(false);
  const handlelanguageModal = () => {
    setlanguageModal(false);
  };
  // const [languageValue, setlanguageValue] = useState(null);
  // const [languageOptionList, setlanguageOptionList] = useState([]);
  // const getlanguageData = () => {
  //   try {
  //     axios.get(`/Master/GetLanguageDDL`).then((response) => {
  //       // console.log("language data", response.data.responseData);
  //       setlanguageOptionList(response.data.responseData);
  //       // setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // let languageOptions = languageOptionList.map((val) => {
  //   return { value: val.langId, label: val.name, id: val.langId };
  // });

  // const selectlanguage = (val) => {
  //   // console.log("language", val.value);
  //   setlanguageValue(val.value);
  // };

  // useEffect(() => {
  //   getlanguageData();
  // }, []);

  const languageUpload = async () => {
    try {
      if (cityId !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("CityID", cityId);
        const res = await axios.post("/UserAccount/SetCity", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setlanguageModal(false);
        // userConfig();
        // getUserProfilePer();
        getUserProfile()
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ City End   ************************//

  useEffect(() => {
    setStartDate(userData?.profileInfo?.dob)
    setGenderValue(userData?.profileInfo?.genderId)
    setCityId(userData?.profileInfo?.cityID)
    setWebsitecontent(userData?.profileInfo?.website)
    setAboutContent(userData?.profileInfo?.about)
  }, [userData?.profileInfo])


  useEffect(() => {
    if (
      websiteModal ||
      aboutModal ||
      languageModal ||
      genderModal ||
      dob
    ) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [
    websiteModal,
    aboutModal,
    languageModal,
    genderModal,
    dob
  ]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };
  //....................Add Experience Achiewement
  const [showExperienceAchivement, setShowExperienceAchivement] =
    useState(false);
  const handleOpenExperienceAchivement = () =>
    setShowExperienceAchivement(true);
  const handleCloseExperienceAchivement = () =>
    setShowExperienceAchivement(false);

  //....................Update Experience Achiewement
  const [experienceAchivementData, setExperienceAchivementData] = useState("");
  const [showEditExperienceAchivement, setShowEditExperienceAchivement] =
    useState(false);
  const handleOpenEditExperienceAchivement = () =>
    setShowEditExperienceAchivement(true);
  const handleCloseEditExperienceAchivement = () =>
    setShowEditExperienceAchivement(false);


  //....................Delete Experience Achiewement
  const [ExperienceAchivement, setExperienceAchivement] = useState("");
  const [ExperienceAchivementId, setExperienceAchivementId] = useState("");
  const [showExperienceAchievementDelete, setShowExperienceAchievementDelete] =
    useState(false);
  const handleExperienceAchievementClose = () => {
    setShowExperienceAchievementDelete(false);
  };
  const handleRemoveExperienceAchivementClose = () => {
    setShowExperienceAchievementDelete(false);
  };
  const DeleteExperienceAchivement = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: ExperienceAchivementId
      // id: ExperienceId,
      // // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteExperienceAcheivement`, { data: responseData })
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          handleRemoveExperienceAchivementClose();
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //....................Add Experience Role
  const [showExperienceRole, setShowExperienceRole] =
    useState(false);
  const handleOpenExperienceRole = () =>
    setShowExperienceRole(true);
  const handleCloseExperienceRole = () =>
    setShowExperienceRole(false);

  //....................Update Experience Role
  const [experienceRoleData, setExperienceRoleData] = useState("");
  const [showEditExperienceRole, setShowEditExperienceRole] =
    useState(false);
  const handleOpenEditExperienceRole = () =>
    setShowEditExperienceRole(true);
  const handleCloseEditExperienceRole = () =>
    setShowEditExperienceRole(false);


  //....................Delete Experience Role
  const [ExperienceRole, setExperienceRole] = useState("");
  const [ExperienceRoleId, setExperienceRoleId] = useState("");
  const [showExperienceRoleDelete, setShowExperienceRoleDelete] =
    useState(false);
  const handleExperienceRoleClose = () => {
    setShowExperienceRoleDelete(false);
  };
  const handleRemoveExperienceRoleClose = () => {
    setShowExperienceRoleDelete(false);
  };
  const DeleteExperienceRole = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: ExperienceRoleId
      // id: ExperienceId,
      // // skllId: skillId
    };
    try {
      await axios
        .delete(`/UserData/DeleteExperienceRole`, { data: responseData })
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          handleRemoveExperienceRoleClose();
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  useEffect(() => {
    if (
      showUndoEndorseModal ||
      showEndorseModal ||
      // showAwardDelete ||
      // showExperienceDelete ||
      // showEducationDelete ||
      // showCertificateDelete ||
      show ||
      showExperienceAchievementDelete
    ) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [
    showUndoEndorseModal,
    showEndorseModal,
    // showAwardDelete,
    // showExperienceDelete,
    // showEducationDelete,
    // showCertificateDelete,
    show,
    showExperienceAchievementDelete
  ]);



  const syncEducation = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: id
    };
    try {
      await axios
        .post(`/UserData/SyncEducationInfoToResume`, responseData)
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const syncExperience = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: id
    };
    try {
      await axios
        .post(`/UserData/SyncExperienceInfoToResume`, responseData)
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const syncCertificate = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: id
    };
    try {
      await axios
        .post(`/UserData/SyncCertificationInfoToResume`, responseData)
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const syncAward = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: id
    };
    try {
      await axios
        .post(`/UserData/SyncAwardInfoToResume`, responseData)
        .then((res) => {
          setTimeout(() => {
            getUserProfile();
          }, 500);
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  function checkResumeExperiences(resumeExperiences) {

    const presentCount = resumeExperiences?.filter(exp => exp.isPresent === true).length;
    console.log('resumeExperiences', presentCount);
    if (presentCount >= 2) {
      // console.log('Warning: More than two experiences are marked as present.');
      return <p className="warningText">Warning: More than two experiences are marked as present.</p>;

      // console.warn('Warning: More than two experiences are marked as present.');
    }
  }
  function checkResumeEducation(resumeExperiences) {

    const presentCount = resumeExperiences?.filter(exp => exp.isPresent === true).length;
    console.log('resumeExperiences', presentCount);
    if (presentCount >= 2) {
      // console.log('Warning: More than two experiences are marked as present.');
      return <p className="warningText">Warning: More than two education are marked as present.</p>;

      // console.warn('Warning: More than two experiences are marked as present.');
    }
  }
  console.log('getUserProfile', userData);

  return (
    <>
      {userData?.profileInfo?.isOwn ? (
        <UpdateProfile getUserProfile={getUserProfile} />
      ) : guestModeData === "true" ? (
        ""
      ) : (
        <div className="d-flex mb-3 userprofileactions">
          <Connection
            uid={uid}
            connectionCheck={connectionCheck}
            connectionId={connectionId}
            CheckConnectUser={CheckConnectUser}
          />

          {userData?.profileInfo?.isConnectedByYou === true ? (
            ""
          ) : userData?.profileInfo?.isFollowedByYou === true ||
            isfollowed === true ? (
            ""
          ) : (
            <div className="unFollowBtn ms-3">
              <button type="button" className="mainBtn1" onClick={FollowedUser}>
                Follow
              </button>
            </div>
          )}
          <Link to={`/Message/${userData?.profileInfo?.userUid}`}>
            <button type="button" className="mainBtn1 ms-3 me-3">
              Message
            </button>
          </Link>

          <ProfileNegativeActions
            userData={userData}
            uid={uid}
            getUserProfile={getUserProfile}
            connectionCheck={connectionCheck}
            CheckConnectUser={CheckConnectUser}
          />
        </div>
      )}

      {/* Basic Info */}
      {userData?.profileInfo?.profileDataNote &&
        <p className="warningText d-flex my-3"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {userData?.profileInfo?.profileDataNote}</p>
      }
      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-2 border-custom pb-2">
          <p className="f-16 fw-bold">Basic Info</p>
          {/* <Link to={`/Resume/${uid}`} target="_blank" className="me-3">
            <p className="f-14 ms-1">View Resume</p>
          </Link> */}
        </div>
        {(!userData?.profileInfo?.dob && !userData?.profileInfo?.cityName && !userData?.profileInfo?.genderName && !userData?.profileInfo?.website && !userData?.profileInfo?.about) && (
          <p className="text-center">No Basic Info Added</p>
        )}
        {userData?.profileInfo?.dob &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-2`}

          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex verified">
                  <CalendarMonthSharpIcon className="HeadingIcon" />
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Date Of Birth
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.dob}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setDob(true);
                    }}
                  />

                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.cityName &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-2`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex  verified">
                  <MyLocationSharpIcon className="HeadingIcon" />
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    City
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.cityName}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setlanguageModal(true)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.genderName &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-2`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex verified">
                  <WcSharpIcon className="HeadingIcon" />
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Gender
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.genderName}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">
                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setGenderModal(true);
                    }}
                  />

                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.website &&
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-2`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex verified">
                  <LanguageSharpIcon className="HeadingIcon" />
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    Website
                  </p>
                </div>
                <a
                  className="ms-1 f-12"
                  href={
                    userData?.profileInfo?.website?.startsWith('http://') || userData?.profileInfo?.website?.startsWith('https://')
                      ? userData?.profileInfo?.website
                      : `http://${userData?.profileInfo?.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userData?.profileInfo?.website}
                </a>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">
                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setWebsiteModal(true)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
        {userData?.profileInfo?.about &&
          <div
            className={`analytic-sub mt-2 mb-1 pb-2`}
          >
            <div className="d-flex align-items-start justify-content-between">
              <div className="ms-2">
                <div className="d-flex verified">

                  <InfoOutlinedIcon className="HeadingIcon" />
                  <p className="f-14 ms-1 pb-1 fw-bold me-2">
                    About
                  </p>
                </div>

                <p className="ms-1 f-12">
                  {userData?.profileInfo?.about}
                </p>
              </div>
              {userData?.profileInfo?.isOwn && (
                <div className="d-flex align-items-center updateActions">

                  <EditSharpIcon
                    className="material-symbols-sharp pointer me-2"
                    onClick={() => {
                      setAboutModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        }
      </div>
      {/* Education */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-2 border-custom pb-2">
          <div className="d-flex">
            <SchoolOutlinedIcon className="HeadingIcon" />
            <p className="f-16 fw-bold ms-1">Education</p>
          </div>
          {userData?.profileInfo?.isOwn ? (
            <div>
              <div>
                <AddSharpIcon
                  className="material-symbols-sharp pointer"
                  onClick={handleOpenUSEREducation}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {!userData?.viewEducationInfo?.length && (
          <p className="text-center">No Education Added</p>
        )}
        {checkResumeEducation(userData?.viewEducationInfo)}
        {userData?.viewEducationInfo &&
          userData?.viewEducationInfo?.map((item, index) => {
            const isLastItem = index === userData?.viewEducationInfo.length - 1;
            console.log('fnsvnbd', item);

            return (
              <>
                {item?.note &&
                  <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                <div
                  className={`analytic-sub mt-3 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  key={index}
                >

                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16  fw-bold me-2">
                          {item.institutionName && (
                            <>
                              {item.institutionName}
                              {item?.isOrgVrfd === true ? (
                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                              ) : item?.isOrgRgd === true ? (
                                <img src={resume.register} width={18} height={18} className="ms-1" />
                              ) : (
                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                              )}
                              {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="ms-3">
                        <div className="d-flex">
                          {item.field && <p className="me-1 f-14 fw-bold">Field: {item.field}</p>}
                          {item.field && <div className="dot"></div>}
                          {item.degree && <p className="ms-2 f-14 fw-bold">{item.degree}</p>}
                        </div>
                        {item.activities && <p className="f-12">Activity: {item.activities}</p>}
                        {item.grade && <p className="f-12">Grade: {item.grade}</p>}
                        {item.description && <p className="f-12">{item.description}</p>}
                        <div className="d-flex align-items-center">
                          {item.cityName && (
                            <>
                              <PlaceSharpIcon className="material-symbols-sharp locationIcon" />
                              <p className="ms-1 me-1 f-12">{item.cityName}</p>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">
                                ({moment(item.startYear).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endYear).format("YYYY")})
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {userData?.profileInfo?.isOwn && (
                      <div>

                        <div className="d-flex align-items-center updateActions">

                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                          <div className="text-end">
                            {userData?.userConfig?.hasDob && userData?.userConfig?.hasGender && userData?.userConfig?.hasCity && userData?.userConfig?.hasCcv === true && item?.isSyncedWithResume === false &&
                              <button
                                className="wljSecondaryBtn me-2 syncBtn"
                                style={{ height: "20px" }}
                                onClick={() => syncEducation(item.userEductnId)}
                              >
                                <SyncSharpIcon style={{ fontSize: '14px' }} /> Sync
                              </button>
                            }
                          </div>
                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowEducationUpdate(!showEducationUpdate);
                              setEducationData(item);
                            }}
                          />

                        </div>

                      </div>

                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* Experience */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <div className="d-flex">
            <BusinessCenterOutlinedIcon className="HeadingIcon" />
            <p className="f-16 fw-bold ms-1">Experience</p>
          </div>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserExperience}
              />
            </div>
          )}
        </div>
        {!userData?.viewExperienceInfo?.length && (
          <p className="text-center">No Experience Added</p>
        )}
        {/* <div className='analytics d-flex justify-content-between mt-3'> */}
        {/* viewExperienceInfo */}
        {checkResumeExperiences(userData?.viewExperienceInfo)}
        {userData?.viewExperienceInfo &&
          userData?.viewExperienceInfo?.map((item, index) => {
            console.log("itejfdjfjfjm", item);
            const isLastItem =
              index === userData?.viewExperienceInfo.length - 1;
            return (
              <>
                {item?.note &&
                  <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16 fw-bold me-2">
                          {item.jobTitle && (
                            <>
                              {item.jobTitle}
                              {item?.isOrgVrfd === true ? (
                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                              ) : item?.isOrgRgd === true ? (
                                <img src={resume.register} width={18} height={18} className="ms-1" />
                              ) : (
                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                              )}
                              {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="ms-3">
                        {item.companyName && <p className="me-1 f-14 fw-bold">{item.companyName}</p>}
                        <div className="d-flex align-items-center">
                          {item.jobTypeName && <p className="me-2 f-12">{item.jobTypeName}</p>}
                          {item.cityName && (
                            <>
                              <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                              <p className="ms-1 me-1 f-12">{item.cityName}</p>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">
                                ({moment(item.startDate).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endDate).format("YYYY")})
                              </p>
                            </>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {item.positionHeadline && <> <p className="f-12 me-1">{item.positionHeadline}</p></>}
                          {item.workLevelTypeName && item.positionHeadline && <div className="dot"></div>}
                          {item.workLevelTypeName && <> <p className="f-12 ms-2 me-1">{item.workLevelTypeName}</p></>}
                          {item.workLevelTypeName && item.workLocationTypeName && <div className="dot"></div>}
                          {item.workLocationTypeName && <> <p className="f-12 m-2">{item.workLocationTypeName}</p></>}
                        </div>
                        {item.description && <p className="f-12">{item.description}</p>}
                      </div>
                    </div>
                    {userData?.profileInfo?.isOwn && (
                      <div>

                        <div className="d-flex align-items-center updateActions">
                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                          <div className="text-end">
                            {userData?.userConfig?.hasDob && userData?.userConfig?.hasGender && userData?.userConfig?.hasCity && userData?.userConfig?.hasCcv === true && item?.isSyncedWithResume === false &&
                              <button
                                className="wljSecondaryBtn me-2 syncBtn"
                                style={{ height: "20px" }}
                                onClick={() => syncExperience(item.userExpId)}
                              >
                                <SyncSharpIcon style={{ fontSize: '14px' }} /> Sync
                              </button>
                            }
                          </div>
                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowExperienceUpdate(!showEducationUpdate);
                              setExperienceData(item);
                            }}
                          />

                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-3 ARCard">
                    <div className={`d-flex justify-content-between ${item.viewExperienceAchivements.length === 0 ? '' : 'border-custom'}`}>
                      <div className=" d-flex">
                        <EmojiEventsOutlinedIcon className="HeadingIcon" style={{ marginTop: '2px' }} />
                        <h6 className="f-14 ms-1">
                          Experience Achievement
                        </h6>
                      </div>
                      {userData?.profileInfo?.isOwn && (
                        <div className="addicon pointer">
                          <AddSharpIcon
                            onClick={() => {
                              handleOpenExperienceAchivement();
                              setExperienceData(item);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {item.viewExperienceAchivements &&
                      item.viewExperienceAchivements
                        .filter((item) => !item.isDeleted)
                        ?.map((roleItem, index) => {
                          return (
                            <div
                              className="d-flex align-items-start justify-content-between mt-2"
                              key={index}
                            >
                              <div className="ms-2">
                                <div className="d-flex verified">
                                  <p className="f-14 ms-1  me-2 m-0">
                                    {roleItem.achievement}
                                  </p>
                                  <p className="f-14 ms-1  me-2 m-0">
                                    {roleItem.achivementDate}
                                  </p>
                                </div>
                              </div>
                              {userData?.profileInfo?.isOwn && (
                                <div className="d-flex align-items-center updateActions">

                                  <EditSharpIcon
                                    className="material-symbols-sharp pointer ms-2 me-2 locationIcon"
                                    onClick={() => {
                                      handleOpenEditExperienceAchivement();
                                      setExperienceAchivementData(
                                        roleItem
                                      );
                                      setExperienceData(item);
                                    }}
                                  />
                                  <DeleteOutlineSharpIcon
                                    className="material-symbols-sharp pointer delete-icon  text-red"
                                    onClick={() => {
                                      setShowExperienceAchievementDelete(
                                        !showExperienceAchievementDelete
                                      );
                                      setExperienceId(
                                        item.userExpId
                                      );
                                      setExperienceAchivementId(
                                        roleItem.expAchvmntId
                                      );
                                      setExperienceAchivement(
                                        roleItem.achievement
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                  <div className="mt-3 ARCard">
                    <div className={`d-flex justify-content-between ${item.viewExperienceRoles.length === 0 ? '' : 'border-custom'} `}>
                      <div className="d-flex" >
                        <SupervisedUserCircleSharpIcon className="HeadingIcon" style={{ marginTop: '2px' }} />
                        <h6 className="f-14 ms-1">
                          Experience Role
                        </h6>
                      </div>
                      {userData?.profileInfo?.isOwn && (
                        <div className="addicon pointer">
                          <AddSharpIcon
                            onClick={() => {
                              handleOpenExperienceRole();
                              setExperienceData(item);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {item.viewExperienceRoles &&
                      item.viewExperienceRoles
                        .filter((item) => !item.isDeleted)
                        ?.map((roleItem, index) => {
                          return (
                            <div
                              className="d-flex align-items-start justify-content-between mt-2"
                              key={index}
                            >
                              <div className="ms-2">
                                <div className="d-flex verified">
                                  <p className="f-14 ms-1  me-2 m-0">
                                    {roleItem.role}
                                  </p>
                                </div>
                              </div>
                              {userData?.profileInfo?.isOwn && (
                                <div className="d-flex align-items-center updateActions">

                                  <EditSharpIcon
                                    className="material-symbols-sharp pointer ms-2 me-2 locationIcon"
                                    onClick={() => {
                                      handleOpenEditExperienceRole();
                                      setExperienceRoleData(
                                        roleItem
                                      );
                                      setExperienceData(item);
                                    }}
                                  />
                                  <DeleteOutlineSharpIcon
                                    className="material-symbols-sharp pointer delete-icon  text-red"
                                    onClick={() => {
                                      setShowExperienceRoleDelete(
                                        !showExperienceRoleDelete
                                      );
                                      setExperienceId(
                                        item.userExpId
                                      );
                                      setExperienceRoleId(
                                        roleItem.expRlId
                                      );
                                      setExperienceRole(
                                        roleItem.role
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </>
            );

          })}



      </div>

      {/* Certificate */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <div className="d-flex">
            <WorkspacePremiumSharpIcon className="HeadingIcon" />
            <p className="f-16 fw-bold ms-1">Certification</p>
          </div>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserCertification}
              />
            </div>
          )}
        </div>
        {!userData?.viewCertificationInfo?.length && (
          <p className="text-center">No Certification Added</p>
        )}

        {/* <div className='analytics d-flex justify-content-between mt-3'> */}
        {userData?.viewCertificationInfo &&
          userData?.viewCertificationInfo?.map((item, index) => {
            console.log('itemghjghjgh', item);
            const isLastItem =
              index === userData?.viewCertificationInfo.length - 1;
            return (
              <>
                {item?.note &&
                  <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16 fw-bold me-2">
                          {item.name && (
                            <>
                              {item.name}
                              {item?.isOrgVrfd === true ? (
                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                              ) : item?.isOrgRgd === true ? (
                                <img src={resume.register} width={18} height={18} className="ms-1" />
                              ) : (
                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                              )}
                              {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="ms-3">
                        {item.orgName && <p className="me-1 f-14 fw-bold">{item.orgName}</p>}
                        <div className="d-flex align-items-center">
                          {item.recognition && <p className="me-1 f-12">{item.recognition}</p>}
                          {item.credentialId && (
                            <>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">ID: {item.credentialId}</p>
                            </>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {item.cityName && (
                            <>
                              <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                              <p className="ms-1 me-1 f-12">{item.cityName}</p>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">
                                ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {userData?.profileInfo?.isOwn && (
                      <div>
                        <div className="d-flex align-items-center updateActions">
                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                          <div className="text-end">
                            {userData?.userConfig?.hasDob && userData?.userConfig?.hasGender && userData?.userConfig?.hasCity && userData?.userConfig?.hasCcv === true && item?.isSyncedWithResume === false &&
                              <button
                                className="wljSecondaryBtn me-2 syncBtn"
                                style={{ height: "20px" }}
                                onClick={() => syncCertificate(item.userCrtfctnId)}
                              >
                                <SyncSharpIcon style={{ fontSize: '14px' }} /> Sync
                              </button>
                            }
                          </div>
                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowCertificateUpdate(!showCertificateUpdate);
                              setCertificateData(item);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* Award */}

      <div className="UpdateProfile mb-3 p-3">
        <div className="d-flex justify-content-between mb-4 border-custom pb-2">
          <div className="d-flex">
            <MilitaryTechSharpIcon className="HeadingIcon" />
            <p className="f-16 fw-bold ms-1">Awards</p>
          </div>
          {userData?.profileInfo?.isOwn && (
            <div>
              <AddSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUserAward}
              />
            </div>
          )}
        </div>

        {!userData?.viewAwardsInfo?.length && (
          <p className="text-center">No Award Added</p>
        )}
        {userData?.viewAwardsInfo &&
          userData?.viewAwardsInfo?.map((item, index) => {
            console.log('itemmgjhghg', item);
            const isLastItem = index === userData?.viewAwardsInfo.length - 1;
            return (
              <>
                {item?.note &&
                  <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16 fw-bold me-2">
                          {item.name && (
                            <>
                              {item.name}
                              {item?.isOrgVrfd === true ? (
                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                              ) : item?.isOrgRgd === true ? (
                                <img src={resume.register} width={18} height={18} className="ms-1" />
                              ) : (
                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                              )}
                              {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="ms-3">
                        {item.orgName && <p className="me-1 f-14 fw-bold">{item.orgName}</p>}
                        <div className="d-flex align-items-center">
                          {item.recognition && <p className=" me-1 f-12">{item.recognition}</p>}
                          {item.description && (
                            <>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">{item.description}</p>
                            </>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {item.cityName && (
                            <>
                              <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                              <p className="ms-1 me-1 f-12">{item.cityName}</p>
                              <div className="dot"></div>
                              <p className="ms-2 f-12">
                                ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {userData?.profileInfo?.isOwn && (
                      <div>
                        <div className="d-flex align-items-center updateActions">
                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} height={15} />}
                          <div className="text-end">
                            {userData?.userConfig?.hasDob && userData?.userConfig?.hasGender && userData?.userConfig?.hasCity && userData?.userConfig?.hasCcv === true && item?.isSyncedWithResume === false &&
                              <button
                                className="wljSecondaryBtn me-2 syncBtn"
                                style={{ height: "20px" }}
                                onClick={() => syncAward(item.userAwardId)}
                              >
                                <SyncSharpIcon style={{ fontSize: '14px' }} /> Sync
                              </button>
                            }
                          </div>
                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowAwardUpdate(!showAwardUpdate);
                              setAwardData(item);
                            }}
                          />

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* Skills */}

      {guestModeData === "true" ? (
        ""
      ) : (
        <div className="UpdateProfile p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <div className="d-flex">
              <PsychologySharpIcon className="HeadingIcon" />
              <p className="f-16 fw-bold ms-1">Skills</p>
            </div>
            <div>
              {userData?.profileInfo?.isOwn ? (
                <AddSharpIcon
                  className="material-symbols-sharp pointer f-24 "
                  onClick={handleOpenAddSkill}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {!userData?.viewSkillInfo?.length && (
            <p className="text-center">No Skill Added</p>
          )}

          {userData?.viewSkillInfo && (
            <>
              {userData?.viewSkillInfo.slice(0, 2).map((item, index, arr) => {
                console.log("skills", item);
                const isLastItem = index === arr.length - 1;
                return (
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                    key={index}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <div className="d-flex align-items-center verified ">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16 pb-0 fw-bold  me-2">
                          {item.skillName}
                        </p>
                      </div>
                      {userData?.profileInfo?.isOwn && (
                        <div className="d-flex align-items-center updateActions">
                          {item?.isSyncedWithResume && <img src={resume.synced} width={15} className="me-2" />}

                          <EditSharpIcon
                            className="material-symbols-sharp pointer me-2 ms-2"
                            onClick={() => {
                              setShowSkills(!showSkills);
                              setSkillsData(item);
                            }}
                          />
                          <DeleteOutlineSharpIcon
                            className="material-symbols-sharp pointer delete-icon"
                            onClick={() => {
                              setShow(!show);
                              setSkillId(item.usrSkllId);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <p className="ms-1 f-12">
                      {item.firmIndustryTypeName}
                    </p>
                    {item.endorsedCount > 0 && <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShow()
                        setSkillId(item.usrSkllId);
                        // toggleTooltip();
                      }}
                      className="createModal f-14 ms-2"
                    >
                      {item.endorsedCount} endorsement
                    </a>
                      // <p className="ms-1 f-12" onClick={()=>{

                      // }}>
                      //   {item.endorsedCount} endorsement
                      // </p>
                    }
                    {uid !== userUid && (
                      <div className="endorseBtn mt-3">
                        {item.isEndorsedByYou === true ? (
                          <button
                            className="button followStyle"
                            onClick={() => {
                              setShowUndoEndorseModal(!showUndoEndorseModal);
                              setSkillId(item.usrSkllId);
                            }}
                            style={{ width: "100px" }}
                          >
                            Endorsed
                          </button>
                        ) : (
                          <button
                            className="button followStyle"
                            onClick={() => {
                              setShowEndorseModal(!showEndorseModal);
                              setSkillId(item.usrSkllId);
                            }}
                          >
                            Endorse
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}

      {userData?.viewSkillInfo?.length > 2 ? (
        <div className="empty-container d-flex justify-content-center align-items-center mb-2">
          <button>
            <span
              className="d-flex justify-content-center align-items-center skills-more-cont"
              onClick={() => {
                setShowMoreSkills(!showMoreSkills);
              }}
            >
              {/* <Link to={`/User/${uid}/Skills`} className="me-1"> */}
              Show all {userData?.viewSkillInfo?.length} skills
              {/* </Link> */}
              <ArrowForwardSharpIcon className="material-symbols-sharp" />
            </span>
          </button>
        </div>
      ) : (
        ""
      )}

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to delete skill?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }}
                />

              </div>
              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeSkills()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMoreSkills && (
        <div
          className="modal fade show postAdd modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0 me-2"> All Skills</h6>
                  <div>
                    {uid === userUid ? (
                      <AddSharpIcon
                        className="material-symbols-sharp pointer f-24 "
                        onClick={handleOpenAddSkill}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowMoreSkills(false);
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <Skills
                  userData={userData}
                  showUndoEndorseModal={showUndoEndorseModal}
                  setShowUndoEndorseModal={setShowUndoEndorseModal}
                  setShowEndorseModal={setShowEndorseModal}
                  showEndorseModal={showEndorseModal}
                  setSkillId={setSkillId}
                  skillId={skillId}
                  skillList={userData?.viewSkillInfo}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showExperienceAchievementDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Experience Achievement?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleExperienceAchievementClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => DeleteExperienceAchivement()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showExperienceRoleDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Experience Role?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleExperienceRoleClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => DeleteExperienceRole()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}




      {showEndorseModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> FeedBack</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>

              <div>
                <div className="lableStyle pt-3">
                  <textarea
                    id="address"
                    name="about"
                    rows="4"
                    cols="55"
                    className="border"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 "
                  onClick={EndorseData}
                >
                  Endorse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUndoEndorseModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Undo Endorse</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUndoEndorse();
                  }}
                />
              </div>

              <div>
                <div className="lableStyle">
                  <textarea
                    id="address"
                    name="about"
                    rows="4"
                    cols="55"
                    className="border"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={UndoEndorseData}
                >
                  UnEndorse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <AddSkill
        show={showAddSkill}
        handleClose={handleCloseAddSkill}
        getUserSkill={getUserSkill}
        getUserProfile={getUserProfile}
      />

      <ProfileEducation
        showUSEREducation={showUSEREducation}
        handleCloseUSEREducation={handleCloseUSEREducation}
        // getUserUser={getUserUser}
        userUId={userUid}
        getUserProfile={getUserProfile}
        userData={userData}
      />
      <ProfileEducationUpdate
        showEducationUpdate={showEducationUpdate}
        setShowEducationUpdate={setShowEducationUpdate}
        handleUpdateEducationClose={handleUpdateEducationClose}
        educationData={educationData}
        // getUserUser={getUserUser}
        userUId={userUid}
        getUserProfile={getUserProfile}
      />
      <ProfileExperience
        showUserExperience={showUserExperience}
        handleCloseUserExperience={handleCloseUserExperience}
        userUId={userUid}
        getUserProfile={getUserProfile}
        userData={userData}
      />
      {/* <ProfileAchiementExperience
        showAchievementUserExperience={showAchievementUserExperience}
        handleAchievementCloseUserExperience={handleAchievementCloseUserExperience}
        userUId={userUid}
        getUserProfile={getUserProfile}
      /> */}
      <ProfileExperienceUpdate
        showExperienceUpdate={showExperienceUpdate}
        handleUpdateExperienceClose={handleUpdateExperienceClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        ExperienceData={ExperienceData}
      />

      <ProfileCertification
        showUserCertification={showUserCertification}
        handleCloseUserCertification={handleCloseUserCertification}
        userUId={userUid}
        getUserProfile={getUserProfile}
        userData={userData}
      />
      <ProfileCertificateUpdate
        showCertificateUpdate={showCertificateUpdate}
        handleUpdateCertificateClose={handleUpdateCertificateClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        certificateData={certificateData}
      />
      <ProfileAward
        showUserAward={showUserAward}
        handleCloseUserAward={handleCloseUserAward}
        userUId={userUid}
        getUserProfile={getUserProfile}
        userData={userData}
      />
      <ProfileAwardUpdate
        showAwardUpdate={showAwardUpdate}
        handleUpdateAwardClose={handleUpdateAwardClose}
        userUId={userUid}
        showAwardData={showAwardData}
        getUserProfile={getUserProfile}
      />
      <UpdateSkills
        showSkills={showSkills}
        handleUpdateSkillClose={handleUpdateSkillClose}
        userUId={userUid}
        getUserProfile={getUserProfile}
        skillsData={skillsData}
      />

      {/* //  *******************************    EXPERIENCE ACHIEVEMENT COMPONENTS ************************************************ */}
      <AddExperienceAchivement
        showExperienceAchivement={showExperienceAchivement}
        handleCloseExperienceAchivement={handleCloseExperienceAchivement}
        ExperienceData={ExperienceData}
        getUserProfile={getUserProfile}
      />
      <AddExperienceRole
        showExperienceRole={showExperienceRole}
        handleCloseExperienceRole={handleCloseExperienceRole}
        ExperienceData={ExperienceData}
        getUserProfile={getUserProfile}
      />


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {dob && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>DOB</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handledob();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex align-items-center flex-column">
                  {/* <h5>DOB</h5> */}
                  <div className="dateofbirth">
                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={(date) => setStartDate(date[0])}
                      id="dob"
                      placeholder="Select Date"
                      options={{
                        maxDate: "today", // Disables future dates
                        onReady: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Call the function to stop scroll on year
                        },
                        onOpen: (selectedDates, dateStr, instance) => {
                          preventYearScroll(instance); // Also prevent scroll when the picker opens
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={DobUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {genderModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Gender</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleGenderModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Gender</h4> */}
                  <div className="col-md-12">
                    {/* <label htmlFor="about">Gender</label> */}
                    <ReactSelect
                      defaultValue={genderOptions.find((option) => option.value === genderValue)}
                      options={genderOptions}
                      onChange={selectGender}
                      loadOptions={selectGender}
                      placeholder="Select Gender"
                      styles={customStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={GenderUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {aboutModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>About</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleAboutModal();
                  }}
                />
              </div>

              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 about-textArea">
                    <textarea
                      id="address"
                      name="about"
                      rows="4"
                      cols="55"
                      value={aboutContent}
                      onChange={(e) => {
                        setAboutContent(e.target.value)
                        setLength(e.target?.value.length)
                      }}
                      maxLength={500}
                    ></textarea>
                    <p className='info'>{aboutContent?.length && length}/500</p>
                  </div>
                </div>
              </div>
              <p className="p-2 text-danger f-14">
                About content should be less than 500 words
              </p>
              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={AboutUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {websiteModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Website</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handlewebsiteModal}
                />
              </div>
              <div className="d-flex modalBorder pb-4 justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  <div className="col-md-12 mt-2 web-input">
                    <div className="input-group input-group-alt">
                      <div className="input-group-prepend">
                        <span className="input-group-text">http://</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="pi3"
                        placeholder="example.com"
                        value={websitecontent}
                        onChange={(e) => validateWebsite(e.target.value)}
                      />
                    </div>
                    {error && <div style={{ color: "red", marginTop: "5px", fontSize: '12px' }}>{error}</div>}
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={handleSubmit}
                  disabled={!!error || !websitecontent.trim()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        // <div
        //   className="modal fade show postAdd"
        //   tabIndex="-1"
        //   role="dialog"
        //   style={{ display: "block" }}
        // >
        //   <div className="modal-dialog  modal-dialog-centered " role="document">
        //     <div className="modal-content d-flex justify-content-around p-2">
        //       <div className="modalBorder d-flex justify-content-between align-items-center p-2">
        //         <h6>Website</h6>
        //         <CloseSharpIcon
        //           className="material-symbols-sharp me-2 pointer"
        //           onClick={() => {
        //             handlewebsiteModal();
        //           }}
        //         />
        //       </div>
        //       <div className="d-flex modalBorder pb-4 d-flex justify-content-center p-2">
        //         <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
        //           <div className="col-md-12 mt-2 web-input">
        //             <div className="input-group input-group-alt">
        //               <div className="input-group-prepend">
        //                 <span className="input-group-text">http://</span>
        //               </div><input type="text" className="form-control" id="pi3" placeholder="example.com" value={websitecontent}
        //                 // onChange={(e) => setWebsitecontent(e.target.value)} 
        //                 onChange={(e) => validateWebsite(e.target.value)}
        //               />
        //               {error && <div style={{ color: "red", marginTop: "5px" }}>{error}</div>}
        //             </div>
        //             {/* <label htmlFor="about" className="web-text">Website </label> */}
        //             {/* <input
        //               type="text"
        //               style={{ width: "100%" }}
        //               value={websitecontent}
        //               onChange={(e) => setWebsitecontent(e.target.value)}
        //             /> */}
        //           </div>
        //         </div>
        //       </div>

        //       <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
        //         <button
        //           type="button"
        //           className="mainBtn1 me-4"
        //           onClick={websiteUpload}
        //           disabled={isSubmitDisabled}
        //         >
        //           Submit
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}

      {languageModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>City</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlelanguageModal();
                  }}
                />
              </div>
              <div className="d-flex modalBorder pb-2 d-flex justify-content-center p-2">
                <div className="col-md-12 mt-2 ps-0 mb-2 userInput d-flex flex-column align-items-center">
                  {/* <h4 htmlFor="about">Language</h4> */}
                  <div className="col-md-12 mt-2">
                    {/* <label htmlFor="about">Language</label> */}
                    {/* <ReactSelect
                      options={languageOptions}
                      onChange={selectlanguage}
                      loadOptions={selectlanguage}
                      placeholder="Select Language"
                      styles={customStyles}
                    /> */}
                    <Select
                      defaultValue={cityData.find((option) => option.value === cityId)}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={languageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      <EditExperienceAchivement
        handleCloseEditExperienceAchivement={
          handleCloseEditExperienceAchivement
        }
        showEditExperienceAchivement={showEditExperienceAchivement}
        experienceAchivementData={experienceAchivementData}
        ExperienceData={ExperienceData}
        getUserProfile={getUserProfile}
      />
      <EditExperienceRole
        handleCloseEditExperienceRole={
          handleCloseEditExperienceRole
        }
        showEditExperienceRole={showEditExperienceRole}
        experienceRoleData={experienceRoleData}
        ExperienceData={ExperienceData}
        getUserProfile={getUserProfile}
      />
      <EndorseModal
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        skillId={skillId}
      />
    </>
  );
};

export default About;
