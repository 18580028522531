import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import toast from "react-hot-toast";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const CCVAttachement = ({
  showCCVAttachement,
  handleCloseCCVAttachement,
  getUserCcv,
  resumeId,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const [attachement, setAttachement] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [display, setDisplay] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  // console.log('profilePic', profilePic);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setProfilePic(selectedFile);
    // setProfilePic(event.target.files[0].name);
    setSelectedImage(event.target.files[0].name);
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const AddAttachement = async () => {
    setDisplay(true);
    const data = {
      // "deviceType": "Web",
      // "sessionId": sessionId,
      // "ccvExpId": ExperienceId,
      // "attachement": attachement
    };
    try {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append("RsmId", resumeId);
      formData.append("AttachmentFile", profilePic);
      const res = await axios.post("ResumeAction/AddAttachment", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true)
      getUserCcv();
      handleCloseCCVAttachement();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  useEffect(() => {
    if (showCCVAttachement) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showCCVAttachement]);
  return (
    <>
      {showCCVAttachement && (
        <div
          className="modal fade show"
          tabIndex="-1"
          attachement="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            attachement="document"
          >
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Attachement</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={handleCloseCCVAttachement}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-12 mt-2">
                    <div className="d-flex">
                      <div>
                        {/* <div className="upload-image-text f-12 f-400 mb-2">
                          Attachement
                        </div> */}
                        <div className="uploadImage-cont">
                          <img
                            src={userAuthImage.dummy}
                            alt="Upload Image"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                            onClick={handleClick}
                          />
                          <p>Choose File</p>
                        </div>
                        {/* <div className="upload-image-text f-12 f-400 mt-2 mb-2">
                          Picture caption
                        </div> */}
                        <div className="empty-line"></div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                          accept="/*"
                          invalid={display && selectedImage === ""}
                        />
                        {display && !selectedImage ? (
                          <span className="error_msg_lbl">
                            Please Select picture{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="uploaded-image" style={{ width: "100%" }}>
                      {selectedImage && <p>{selectedImage}</p>}
                      {/* <img src={data?.profilPic} alt="Selected" /> */}
                    </div>
                  </div>
                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={AddAttachement}
                      style={{ width: "170px" }}
                    >
                      Add Attachement
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

    </>
  );
};

export default CCVAttachement;
