import { useState } from 'react';
import axios from '../../API/axios';
import Linkify from 'linkify-react';

const PostComponent = ({ item }) => {
    const [postContentMap, setPostContentMap] = useState({});

    const readMore = async (id) => {
        try {
            const res = await axios.get(`/Posts/GetPostMainContent/?DeviceType=Web&PostId=${id}`);
            setPostContentMap((prevMap) => ({
                ...prevMap,
                [id]: res.data.responseData
            }));
        } catch (error) {
            console.log(error);
        }
    };


    const addTargetBlankToAnchors = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        const anchors = div.querySelectorAll('a');
        anchors.forEach(anchor => {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
        });

        return div.innerHTML;
    };

    const linkifyContent = (htmlContent) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return htmlContent.replace(urlPattern, function (url) {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };

    return (
        <div>

            {item?.postContent && (
                <div className="f-14 p-2 postContent">
                    <div
                        className='postContentDiv'
                        dangerouslySetInnerHTML={{
                            __html: linkifyContent(
                                addTargetBlankToAnchors(postContentMap[item?.postId] ? postContentMap[item?.postId] : item?.postContent)
                            )
                        }}
                    />

                    {!postContentMap[item?.postId] && item?.readMore && (
                        <a onClick={() => readMore(item?.postId)} className='pointer'>
                            Read More
                        </a>
                    )}
                </div>
            )}

        </div>
    );
};

export default PostComponent;
