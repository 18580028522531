import React, { useEffect, useRef } from 'react'
import Select from "react-select";
import { customStyles } from '../../selectStyles';
import NavigationSharpIcon from '@mui/icons-material/NavigationSharp';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import StoreSharpIcon from '@mui/icons-material/StoreSharp';
import PostAddSharpIcon from '@mui/icons-material/PostAddSharp';
import { NewsFeed } from '../../assets/Images/ImagesLink';
const SearchHeader = ({
    setReset,
    setSelectedCountryData,
    country,
    setSelectedCity,
    city,
    setSelectedCompanyData,
    companyList,
    setSelectedDesignationData,
    designationList

}) => {

    //************************ Country Filter  ************************//
    let countryOptions = country?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handlecountryChange(selectedOptions) {
        setReset(true);
        const selectedValues = selectedOptions?.map((item) => item.value);
        // console.log("country got", selectedValues);
        setSelectedCountryData(selectedValues);
        // AllJobList();
    }
    //************************ Country Filter  ************************//

    //************************ City Filter  ************************//
    let cityOptions = city?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handleCityChange(selectedOptions) {
        setReset(true);
        const selectedValues = selectedOptions?.map((item) => item.value);
        // console.log("city got", selectedValues);
        setSelectedCity(selectedValues);
        // AllJobList();
    }
    //************************ City Filter  ************************//


    //************************ Company Filter  ************************//
    let companyListOptions = companyList?.map((val) => {
        return { value: val.id, label: val.name };
    });
    function handleCompanyChange(selectedOptions) {
        setReset(true);
        // AllJobList();
        const selectedValues = selectedOptions?.map((item) => item.value);
        setSelectedCompanyData(selectedValues);
        // AllJobList();
    }
    //************************ Company Filter  ************************//


    //************************ Designation Filter  ************************//
    let designationListOptions = designationList?.map((val) => {
        return { value: val.id, label: val.name };
    });

    function handleDesignationChange(selectedOptions) {
        setReset(true); // Set reset to true first
        const selectedValues = selectedOptions?.map((item) => item.value);
        setSelectedDesignationData(selectedValues);
    }
    //************************ Designation Filter  ************************//

    const adInitialized = useRef(false);

    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.async = true;
    //     script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
    //     script.crossOrigin = "anonymous";
    //     document.body.appendChild(script);

    //     if (!adInitialized.current) {
    //         window.adsbygoogle = window.adsbygoogle || [];
    //         window.adsbygoogle.push({});
    //         adInitialized.current = true;
    //     }

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    useEffect(() => {
        // Dynamically add the adsbygoogle script
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);

        // Initialize the ad
        if (!adInitialized.current) {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
            adInitialized.current = true;
        }

        // Add event listener to open ads in a new tab
        const handleAdClick = (event) => {
            const target = event.target;
            if (target.tagName === "A" && !target.hasAttribute("target")) {
                target.setAttribute("target", "_blank");
            }
        };

        // Attach listener to the entire document for dynamic ads
        document.addEventListener("click", handleAdClick);

        return () => {
            // Cleanup script and listener on component unmount
            document.body.removeChild(script);
            document.removeEventListener("click", handleAdClick);
        };
    }, []);
    return (
        <>

            <div className="searchjobAds">
                <div
                    className="Adwrapper"
                    style={{
                        display: 'flex', // Flexbox helps center the content
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        width: '100%',
                        height: '150px', // Fixed height for the wrapper
                        overflow: 'hidden', // Ensure content doesn't exceed the wrapper
                    }}
                >
                    <div
                        style={{
                            display: 'block', // Block-level element
                            width: '100%',
                            height: '150px', // Ensures the ad respects this height
                        }}
                    >
                        <ins
                            className="adsbygoogle"
                            style={{
                                display: 'block',
                                width: '100%', // Full-width responsive
                                height: '150px', // Matches the wrapper height
                                textAlign: 'center',
                                margin: '0 auto', // Centers the ad content horizontally
                            }}
                            data-ad-client="ca-pub-8691062301030113"
                            data-ad-slot="5695666532"
                            data-adtest="on" // Use "on" for testing; remove for production
                            data-full-width-responsive="true"
                        ></ins>
                    </div>
                </div>

            </div>
            <div className="d-block d-md-flex jobSearch jobdropdown mb-2">
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <NavigationSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={countryOptions}
                            placeholder="Select Country"
                            onChange={handlecountryChange}
                            loadOptions={handlecountryChange}
                            styles={customStyles}

                        />
                    </div>
                </div>
                <div className="col-md-3 mb-1">

                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <ApartmentSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={cityOptions}
                            onChange={handleCityChange}
                            loadOptions={handleCityChange}
                            placeholder="Select city"
                            styles={customStyles}
                        />

                    </div>
                </div>
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex p-2 justify-content-center align-items-center">
                        <StoreSharpIcon className="material-symbols-sharp" />
                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={companyListOptions}
                            onChange={handleCompanyChange}
                            loadOptions={handleCompanyChange}
                            placeholder="Select company"
                            styles={customStyles}

                        />
                    </div>
                </div>
                <div className="col-md-3 mb-1">
                    <div className="searchInput d-flex justify-content-center align-items-center p-2">
                        <PostAddSharpIcon className="material-symbols-sharp" />

                        <Select
                            closeMenuOnSelect={true}
                            isMulti
                            options={designationListOptions}
                            placeholder="Designation"
                            onChange={handleDesignationChange}
                            loadOptions={handleDesignationChange}
                            styles={customStyles}

                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchHeader