import { useEffect, useState } from "react";

import {
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
// import { Button, Badge, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux'
// import PerfectScrollbar from 'react-perfect-scrollbar'

// import toast from 'react-hot-toast'
import moment from "moment";
import axios from "../../API/axios";
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import WorkOutlineSharpIcon from '@mui/icons-material/WorkOutlineSharp';
import ChatBubbleOutlineSharpIcon from '@mui/icons-material/ChatBubbleOutlineSharp';
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';

export default function TopNotification() {
  const sessionId = localStorage.getItem("sessionId");

  const [notifi, setNotifi] = useState([]);
  const [notifiCount, setNotifiCount] = useState("");

  const NotificationList = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      // setLoading(true);
      await axios
        .post(`General/GetTop5Notifications`, data)
        .then((resData) => {
          console.log("resDataNew", resData.data.responseData);
          setNotifi(resData.data.responseData);
          // setIsLoading(false);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // setIsError(true);
      } else {
        console.log(error);
      }

      // setLoading(false);
    }
  };
  const ReadNotification = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`General/MarkNotificationAsRead?ID=${id}`, responseData)
        .then((res) => {
          NotificationList();
        });
    } catch (e) {
      console.log(e.response);
    }
  };
  const navigate = useNavigate();
  const [unauthorisedModal, setUnauthorisedModal] = useState(false);
  const NotificationCount = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      // setLoading(true);
      await axios
        .post(`General/GetUnReadNotificationCount`, data)
        .then((resData) => {
          // console.log("resDataNew", resData.data.responseData);
          setNotifiCount(resData.data.responseData);
          // setIsLoading(false);
        });
    } catch (error) {
      // if ((error.code === 'ERR_NETWORK' && sessionId) || (error.code === "ERR_BAD_REQUEST" && sessionId)) {
      //   setUnauthorisedModal(true)
      // }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      NotificationCount();
    }, 10000); // 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  const renderNotificationItems = () => {
    return (
      <div className="notificationscroller">
        {notifi &&
          notifi?.map((item, index) => {
            const now = moment();
            const itemTime = moment.utc(item?.time);
            const duration = moment.duration(now.diff(itemTime));

            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();

            let result = "";

            if (years > 0) {
              result = `${years} year${years > 1 ? "s" : ""} ago`;
            } else if (months > 0) {
              result = `${months} month${months > 1 ? "s" : ""} ago`;
            } else if (days > 0) {
              result = `${days} day${days > 1 ? "s" : ""} ago`;
            } else if (hours > 0) {
              result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
            } else if (minutes > 0) {
              result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
            } else {
              result = "just now";
            }
            return item?.isView === false ? (
              item?.type === 3 ||
                item?.type === 12 ||
                item?.type === 13 ||
                item?.type === 14 ||
                item?.type === 15 ||
                item?.type === 16 ? (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      to={`/User/${item?.fromUserUid}`}
                      onClick={(e) => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />

                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              ) : item?.type === 1 ? (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      onClick={() => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      to={`/Company/${item?.fromUserUid}`}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">

                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              ) : item?.type === 2 ? (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      onClick={(e) => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      to={`/Message/${item.fromUserUid}`}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">

                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              ) : item?.type === 4 ||
                item?.type === 5 ||
                item?.type === 6 ||
                item?.type === 7 ||
                item?.type === 8 ||
                item?.type === 9 ||
                item?.type === 10 ||
                item?.type === 11 ||
                item?.type === 24 ||
                item?.type === 18 ? (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      onClick={() => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      to={`/Post/${item?.refLinkId}`}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">

                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              ) : item?.type === 17 ||
                item?.type === 19 ||
                item?.type === 20 ||
                item?.type === 21 ||
                item?.type === 22 ||
                item?.type === 23 ||
                item?.type === 25 ||
                item?.type === 26 ? (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      onClick={() => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      to={`/JobDetail/${item?.refLinkId}`}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              ) : (
                <li
                  className="dropdown-menu-header unreadNotification"
                  key={index}
                >
                  {
                    <DropdownItem
                      onClick={() => {
                        ReadNotification(item?.ntfctnId);
                      }}
                      to={`/User/${item?.fromUserUid}`}
                      tag={Link}
                      className="topnotification d-flex align-items-center"
                    >
                      <img
                        src={item?.fromProfilPic}
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                        className="me-3"
                        alt="Profile Icon"
                      />
                      <div className="me-3">
                        <h2 className="notification-message mb-0">
                          <span>{item?.fromUserFname}</span>
                        </h2>
                        <h6 className="mb-0 content-text">
                          <span>{item?.content}</span>
                        </h6>
                        <span className="side-text ms-0">{result}</span>
                      </div>
                      {item?.typeName === "JobPost" ? (
                        <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                          <WorkOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Message" ? (
                        <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                          <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : item?.typeName === "Like" ? (
                        <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                          <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                        </div>
                      ) : (
                        ""
                      )}
                    </DropdownItem>
                  }
                </li>
              )
            ) : item?.type === 3 ||
              item?.type === 12 ||
              item?.type === 13 ||
              item?.type === 14 ||
              item?.type === 15 ||
              item?.type === 16 ? (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/User/${item?.fromUserUid}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            ) : item?.type === 1 ? (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/Company/${item?.fromUserUid}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            ) : item?.type === 2 ? (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/Message/${item?.fromUserUid}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            ) : item?.type === 4 ||
              item?.type === 5 ||
              item?.type === 6 ||
              item?.type === 7 ||
              item?.type === 8 ||
              item?.type === 9 ||
              item?.type === 10 ||
              item?.type === 11 ||
              item?.type === 24 ||
              item?.type === 18 ? (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/Post/${item?.refLinkId}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            ) : item?.type === 17 ||
              item?.type === 19 ||
              item?.type === 20 ||
              item?.type === 21 ||
              item?.type === 22 ||
              item?.type === 23 ||
              item?.type === 25 ||
              item?.type === 26 ? (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/JobDetail/${item?.refLinkId}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            ) : (
              <li className="dropdown-menu-header " key={index}>
                {
                  <DropdownItem
                    to={`/User/${item?.fromUserUid}`}
                    tag={Link}
                    className="topnotification d-flex align-items-center"
                  >
                    <img
                      src={item?.fromProfilPic}
                      width={30}
                      height={30}
                      style={{ borderRadius: "50px" }}
                      className="me-3"
                      alt="Profile Icon"
                    />
                    <div className="me-3">
                      <h2 className="notification-message mb-0">
                        <span>{item?.fromUserFname}</span>
                      </h2>
                      <h6 className="mb-0 content-text">
                        <span>{item?.content}</span>
                      </h6>
                      <span className="side-text ms-0">{result}</span>
                    </div>
                    {item?.typeName === "JobPost" ? (
                      <div className="customIcon ms-auto d-flex justify-content-center align-items-center me-2">
                        <WorkOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Message" ? (
                      <div className="customIcon ms-auto messageIcon d-flex justify-content-center align-items-center me-2">
                        <ChatBubbleOutlineSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : item?.typeName === "Like" ? (
                      <div className="customIcon ms-auto heartCont d-flex justify-content-center align-items-center me-2">
                        <FavoriteBorderSharpIcon className="material-symbols-outlined" />
                      </div>
                    ) : (
                      ""
                    )}
                  </DropdownItem>
                }
              </li>
            );
          })}
      </div>
    );
  };

  // useEffect(() => {
  //     // notificationList()
  //     // companynotificationList()
  // }, [loadList])

  return (
    <>
      <UncontrolledDropdown
        tag=""
        className="dropdown-notification nav-item me-25"
      >
        <DropdownToggle
          tag="a"
          className="nav-link"
          href="/"
          onClick={(e) => e.preventDefault()}
        >

          <NotificationsNoneSharpIcon className="material-symbols-sharp" onClick={() => {
            NotificationList();
          }} />
          {notifiCount > 0 ? (
            <Badge pill color="danger" className="badge-up notifictaionBadge">
              {notifiCount}
            </Badge>
          ) : (
            ""
          )}
        </DropdownToggle>
        <DropdownMenu end tag="ul" className="dropdown-menu-media  mt-0 notificationDropdown">
          {/* {notifi.length === 0 ? <h4 className="notification-title mb-0 me-auto text-center">No Notification</h4> : */}
          <>
            <li className="dropdown-menu-header">
              <DropdownItem className="d-flex" tag="div" header>
                <h4 className="notification-title mb-0 me-auto">Notifications</h4>
                <div className="badge badge-notificationCount me-3 mb-1">
                  {notifiCount} New
                </div>
              </DropdownItem>
            </li>
            {notifi.length === 0 ? '' :
              renderNotificationItems()}
            <li className="dropdown-menu-footer">
              <DropdownItem tag={Link} to="/Notification">
                <button
                  type="submit"
                  className="wljPrimaryBtn d-flex align-items-center mt-3 noti-btn"
                >
                  View all notifications
                </button>
              </DropdownItem>
            </li> </>
          {/* } */}
        </DropdownMenu>
      </UncontrolledDropdown>
      {
        unauthorisedModal && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div>
                    <h4 className="text-center AdBlocker">Warning!</h4>
                    <p className="text-center ad-p">
                      Your session has expired or you are not authorized to access this resource. Please log in again to continue.
                    </p>
                    <div className="text-center m-auto mt-3">
                      <button
                        type="button"
                        className="mainBtn1 "
                        onClick={() => {
                          setUnauthorisedModal(!unauthorisedModal)
                          navigate("/SignIn");
                          localStorage.clear();
                          sessionStorage.removeItem('hasModalShown');
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );

}
