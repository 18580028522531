import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../API/axios";
import "../../assets/css/ManageResume.css";
import { resume } from "../../assets/Images/ImagesLink";
import CCVEducation from "./CCVEducation";
import CCVExperience from "./CCVExperience";
import CCVSKill from "./CCVSKill";
import UpdateCCV from "./UpdateCCV";
import moment from "moment";
import CCVCertification from "./CCVCertification";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Input } from "reactstrap";
import CCVRole from "./CCVRole";
import CCVAchievement from "./CCVAchievement";
import CCVAward from "./CCVAward";
import CCVAttachement from "./CCVAttachement";
import CCVEducationUpdate from "./CCVEducationUpdate";
import CCVExperienceUpdate from "./CCVExperienceUpdate";
import CCVCertificationUpdate from "./CCVCertificationUpdate";
import CCVSkillUpdate from "./CCVSkillUpdate";
import CCVAwardUpdate from "./CCVAwardUpdate";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import CCVExperienceAchievementAdd from "./CCVExperienceAchievementAdd";
import CCVExperienceAchievemenUpdate from "./CCVExperienceAchievemenUpdate";
import CCVExpRoleUpdate from "./CCVExpRoleUpdate";
import CCVExpRoleAdd from "./CCVExpRoleAdd";
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import MyLocationSharpIcon from '@mui/icons-material/MyLocationSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SupervisedUserCircleSharpIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import WorkspacePremiumSharpIcon from '@mui/icons-material/WorkspacePremiumSharp';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import PhoneIphoneSharpIcon from '@mui/icons-material/PhoneIphoneSharp';
import SummarizeSharpIcon from '@mui/icons-material/SummarizeSharp';
import TitleSharpIcon from '@mui/icons-material/TitleSharp';
import SubtitlesSharpIcon from '@mui/icons-material/SubtitlesSharp';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';

const ManageResume = () => {
  const guestModeData = localStorage.getItem("guestMode");
  const userUid = localStorage.getItem("userUid");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const { UserId } = useParams();
  const [userCcv, setUserCcv] = useState([]);
  const sessionId = localStorage.getItem("sessionId");

  const getUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUid: UserId,
    };
    try {
      await axios.post(`Resume/GetUserResume`, data).then((resData) => {
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getUserCcv();
  }, []);

  const [showAddSkill, setShowAddSkill] = useState(false);
  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);
  const [showUpdateCCV, setShowUpdateCCV] = useState(false);
  const handleOpenUpdateCCV = () => setShowUpdateCCV(true);
  const handleCloseCCV = () => setShowUpdateCCV(false);
  const [showCCVEducation, setShowCCVEducation] = useState(false);
  const handleOpenCCVEducation = () => setShowCCVEducation(true);
  const handleCloseCCVEducation = () => setShowCCVEducation(false);
  const [showCCVExperience, setShowCCVExperience] = useState(false);
  const handleOpenCCVExperience = () => setShowCCVExperience(true);
  const handleCloseCCVExperience = () => setShowCCVExperience(false);
  const [showCCVAward, setShowCCVAward] = useState(false);
  const handleOpenCCVAward = () => setShowCCVAward(true);
  const handleCloseCCVAward = () => setShowCCVAward(false);
  const [showCCVAttachement, setShowCCVAttachement] = useState(false);
  const handleOpenCCVAttachement = () => setShowCCVAttachement(true);
  const handleCloseCCVAttachement = () => setShowCCVAttachement(false);
  const [showCCVCertification, setShowCCVCertification] = useState(false);
  const handleOpenCCVCertification = () => setShowCCVCertification(true);
  const handleCloseCCVCertification = () => setShowCCVCertification(false);

  const [showCCVRole, setShowCCVRole] = useState(false);

  const handleOpenCCVRole = () => setShowCCVRole(true);
  const handleCloseCCVRole = () => setShowCCVRole(false);

  const [showCCVAchivement, setShowCCVAchivement] = useState(false);

  const handleOpenCCVAchivement = () => setShowCCVAchivement(true);
  const handleCloseCCVAchivement = () => setShowCCVAchivement(false);

  //************************Remove Certification Start  ************************//

  const [showExperienceDelete, setShowExperienceDelete] = useState(false);
  const handleRemoveExperienceClose = () => {
    setShowExperienceDelete(false);
  };
  const [ExperienceId, setExperienceId] = useState("");
  const [usrExpId, setUsrExpId] = useState("");
  const [experienceSynced, setExperienceSynced] = useState(false);
  const removeExperience = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(experienceSynced
        ? { usrExpId: usrExpId }
        : { rsmExpId: ExperienceId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmExperienceInfo`, { data: responseData })
        .then((res) => {
          setShowExperienceDelete(!showExperienceDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Certificat End  ************************//

  //************************Remove Skill Start  ************************//

  const [showSkillDelete, setShowSkillDelete] = useState(false);
  const handleRemoveSkillClose = () => {
    setShowSkillDelete(false);
  };
  const [SkillId, setSkillId] = useState("");
  const [usrSkllId, setUsrSkllId] = useState("");
  const [skillSynced, setSkillSynced] = useState(false);
  const [skillDataId, setSkillDataId] = useState("");

  const removeSkill = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmId: userCcv?.resumeInfo?.resumeId,
      // ccvSkllId: SkillId,
      // rsmSkllId: SkillId,
      ...(skillSynced
        ? { usrSkllId: usrSkllId }
        : { rsmSkllId: SkillId }),
      // rsmSkllUsrDataLnkdID: skillDataId
    };
    try {
      await axios.delete(`ResumeData/UnLinkRsmSkill`, { data: responseData }).then((res) => {
        setShowSkillDelete(!showSkillDelete);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Skill End  ************************//

  //************************Remove Education Start  ************************//
  const [educationData, setEducationData] = useState([]);
  const [showEducationUpdate, setShowEducationUpdate] = useState(false);
  const handleUpdateEducationClose = () => {
    setShowEducationUpdate(false);
    setEducationData([])
  };
  const [showEducationDelete, setShowEducationDelete] = useState(false);
  const handleRemoveEducationClose = () => {
    setShowEducationDelete(false);
  };
  const [EducationId, setEducationId] = useState("");
  const [usrEductnId, setUsrEductnId] = useState("");
  const [educationSynced, setEducationSynced] = useState(false);
  const removeEducation = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      // rsmEductnId: EducationId,
      // usrEductnId:usrEductnId,
      ...(educationSynced
        ? { usrEductnId: usrEductnId }
        : { rsmEductnId: EducationId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmEducationInfo`, { data: responseData })
        .then((res) => {
          setShowEducationDelete(!showEducationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Education End  ************************//

  //************************Remove Certification Start  ************************//

  const [showCertificationDelete, setShowCertificationDelete] = useState(false);
  const handleRemoveCertificationClose = () => {
    setShowCertificationDelete(false);
  };
  const [CertificationId, setCertificationId] = useState("");
  const [usrCerId, setUsrCerId] = useState("");
  const [certificateSynced, setCertificateSynced] = useState(false);
  const removeCertification = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(certificateSynced
        ? { usrCrtfctnId: usrCerId }
        : { rsmCrtfctnId: CertificationId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeData/DeleteRsmCertificationInfo`, { data: responseData })
        .then((res) => {
          setShowCertificationDelete(!showCertificationDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Certification End  ************************//
  //************************Remove Award Start  ************************//

  const [showAwardDelete, setShowAwardDelete] = useState(false);
  const handleRemoveAwardClose = () => {
    setShowAwardDelete(false);
  };
  const [AwardId, setAwardId] = useState("");
  const [usrAwrId, setUsrAwrId] = useState("");
  const [awardSynced, setAwardSynced] = useState(false);
  const removeAward = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ...(awardSynced
        ? { usrAwardId: usrAwrId }
        : { rsmAwardId: AwardId }),
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.delete(`ResumeData/DeleteRsmAwardInfo`, { data: responseData }).then((res) => {
        setShowAwardDelete(!showAwardDelete);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Award End  ************************//

  //************************Remove Award Start  ************************//

  const [showAttachementDelete, setShowAttachementDelete] = useState(false);
  const handleRemoveAttachementClose = () => {
    setShowAttachementDelete(false);
  };
  const [AttachementId, setAttachementId] = useState("");

  const removeAttachement = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: AttachementId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeAction/DeleteAttachment`, {
          data: responseData
        })
        .then((res) => {
          setShowAttachementDelete(!showAttachementDelete);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Remove Attachement End  ************************//

  //************************Enable Certification  ************************//
  const EnableExperience = async (ccvExpId, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ccvExpId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userExprncId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/EnableWorkExperienceInfo`
      : `ResumeData/EnableRsmExperienceInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios.put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableExperience = async (ccvExpId, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ccvExpId,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userExprncId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/DisableWorkExperienceInfo`
      : `ResumeData/DisableRsmExperienceInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      // await axios.put(`ResumeData/DisableRsmExperienceInfo`, responseData)
      await axios.put(apiUrl, payload)

        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Skill  ************************//
  const EnableSkill = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ccvSkllId: Id,
      resumeId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.post(`CcvAction/EnableCcvSkill`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Skill End  ************************//
  //************************Disable Skill  ************************//
  const DisableSkill = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      ccvSkllId: Id,
      resumeId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .post(`CcvAction/DisableCcvSkill`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Skill End  ************************//

  //************************Enable Education  ************************//
  const EnableEducation = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmEductnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.usrEductnId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/EnableEducationInfo`
      : `ResumeData/EnableRsmEducationInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios
        .put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Education End  ************************//
  //************************Disable Education  ************************//
  const DisableEducation = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmEductnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.usrEductnId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/DisableEducationInfo`
      : `ResumeData/DisableRsmEducationInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios
        .put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Education End  ************************//

  //************************Enable Certification  ************************//
  const EnableCertification = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmCrtfctnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userCrtfctnId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/EnableCertificationInfo`
      : `ResumeData/EnableRsmCertificationInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios
        .put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableCertification = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmCrtfctnId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userCrtfctnId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/DisableCertificationInfo`
      : `ResumeData/DisableRsmCertificationInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios
        .put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//
  //************************Enable Certification  ************************//
  const EnableAttachement = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeAction/EnableAttachment`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Certification End  ************************//
  //************************Disable Certification  ************************//
  const DisableAttachement = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAttchmntId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .put(`ResumeAction/DisableAttachment`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Certification End  ************************//

  //************************Enable Award  ************************//
  const EnableAwards = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAwardId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userAwardId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/EnableAwardInfo`
      : `ResumeData/EnableAwardInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios.put(apiUrl, payload).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Award End  ************************//
  //************************Disable Award  ************************//
  const DisableAwards = async (Id, item) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmAwardId: Id,
      rsmId: userCcv?.resumeInfo?.resumeId,
    };
    const responseData1 = {
      deviceType: "Web",
      sessionId: sessionId,
      id: item.userAwardId,
    };
    const apiUrl = item.isSyncedWithUserData
      ? `UserData/DisableAwardInfo`
      : `ResumeData/DisableAwardInfo`;

    const payload = item.isSyncedWithUserData ? responseData1 : responseData;
    try {
      await axios
        .put(apiUrl, payload)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Award End  ************************//

  //************************Enable Userccv  ************************//
  const EnableUserCCV = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.put(`ResumeAction/EnableResume`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Userccv End  ************************//
  //************************Disable Userccv  ************************//
  const DisableUserCCV = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios.put(`ResumeAction/DisableResume`, responseData).then((res) => {
        setToastMessage(res.data.message);
        setShowToast(true);
        getUserCcv();
      });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Userccv End  ************************//



  useEffect(() => {
    if (showExperienceDelete || showSkillDelete || showEducationDelete || showCertificationDelete || showAwardDelete || showAttachementDelete) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showExperienceDelete || showSkillDelete, showEducationDelete, showCertificationDelete, showAwardDelete, showAttachementDelete]);



  const [resumeStatus, setResumeStatus] = useState();
  useEffect(() => {
    setResumeStatus(userCcv?.resumeInfo?.status === "Active")
  }, [userCcv])

  const handleToggle = () => {
    if (resumeStatus) {
      DisableUserCCV().then(() => {
        setResumeStatus(false);
      });
    } else {
      EnableUserCCV().then(() => {
        setResumeStatus(true);
      });
    }
  };
  const [ExperienceData, setExperienceData] = useState([]);
  const [showExperienceUpdate, setShowExperienceUpdate] = useState(false);
  const handleUpdateExperienceClose = () => {
    setShowExperienceUpdate(false);
  };

  const [showCertificateUpdate, setShowCertificateUpdate] = useState(false);
  const handleUpdateCertificateClose = () => {
    setShowCertificateUpdate(false);
  };
  const [certificateData, setCertificateData] = useState([]);

  const [skillsData, setSkillsData] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const handleUpdateSkillClose = () => {
    setShowSkills(false);
  };

  const [showAwardUpdate, setShowAwardUpdate] = useState(false);
  const [showAwardData, setAwardData] = useState([]);
  const handleUpdateAwardClose = () => {
    setShowAwardUpdate(false);
  };

  const [showResumeDelete, setShowResumeDelete] = useState(false)
  const handleRemoveResumeClose = () => {
    setShowResumeDelete(false)
  }

  const navigate = useNavigate();
  const removeResume = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmID: userCcv?.resumeInfo?.resumeId,
    };
    try {
      await axios
        .delete(`ResumeAction/DeleteResume`, {
          data: responseData, // Ensure data is sent in the configuration object
          // headers: {
          //     'Content-Type': 'application/json', // Set content type if necessary
          // },
        })
        .then((res) => {
          setShowResumeDelete(!showResumeDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserCcv();
          setTimeout(() => {
            navigate(`/User/${userUid}`);
          }, 2000);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [userPer, setUserPer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        const res = response.data.responseData;
        setUserPer(res.percentage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingalert(false); // Set loading to false once the data is fetched
      });
  };

  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
  const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

  // Condition to show the alert
  const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;


  //....................Add Experience Achiewement
  const [showExperienceAchivement, setShowExperienceAchivement] =
    useState(false);
  const handleOpenExperienceAchivement = () =>
    setShowExperienceAchivement(true);
  const handleCloseExperienceAchivement = () =>
    setShowExperienceAchivement(false);

  //....................Update Experience Achiewement
  const [experienceAchivementData, setExperienceAchivementData] = useState("");
  const [showEditExperienceAchivement, setShowEditExperienceAchivement] =
    useState(false);
  const handleOpenEditExperienceAchivement = () =>
    setShowEditExperienceAchivement(true);
  const handleCloseEditExperienceAchivement = () =>
    setShowEditExperienceAchivement(false);


  //....................Delete Experience Achiewement
  const [ExperienceAchivement, setExperienceAchivement] = useState("");
  const [ExperienceAchivementId, setExperienceAchivementId] = useState("");
  const [showExperienceAchievementDelete, setShowExperienceAchievementDelete] =
    useState(false);
  const handleExperienceAchievementClose = () => {
    setShowExperienceAchievementDelete(false);
  };
  const handleRemoveExperienceAchivementClose = () => {
    setShowExperienceAchievementDelete(false);
  };
  const DeleteExperienceAchivement = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ExperienceId,
      id: ExperienceAchivementId,
      // // skllId: skillId
    };
    try {
      await axios
        .delete(`ResumeData/DeleteExperienceAcheivement`, { data: responseData })
        .then((res) => {
          setTimeout(() => {
            getUserCcv();
          }, 500);
          handleRemoveExperienceAchivementClose();
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //....................Add Experience Role
  const [showExperienceRole, setShowExperienceRole] =
    useState(false);
  const handleOpenExperienceRole = () =>
    setShowExperienceRole(true);
  const handleCloseExperienceRole = () =>
    setShowExperienceRole(false);

  //....................Update Experience Role
  const [experienceRoleData, setExperienceRoleData] = useState("");
  const [showEditExperienceRole, setShowEditExperienceRole] =
    useState(false);
  const handleOpenEditExperienceRole = () =>
    setShowEditExperienceRole(true);
  const handleCloseEditExperienceRole = () =>
    setShowEditExperienceRole(false);


  //....................Delete Experience Role
  const [ExperienceRole, setExperienceRole] = useState("");
  const [ExperienceRoleId, setExperienceRoleId] = useState("");
  const [showExperienceRoleDelete, setShowExperienceRoleDelete] =
    useState(false);
  const handleExperienceRoleClose = () => {
    setShowExperienceRoleDelete(false);
  };
  const handleRemoveExperienceRoleClose = () => {
    setShowExperienceRoleDelete(false);
  };
  const DeleteExperienceRole = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      rsmExpId: ExperienceId,
      id: ExperienceRoleId,
      // // skllId: skillId
    };
    try {
      await axios
        .delete(`/ResumeData/DeleteExperienceRole`, { data: responseData })
        .then((res) => {
          setTimeout(() => {
            getUserCcv();
          }, 500);
          handleRemoveExperienceRoleClose();
          setToastMessage(res.data.message);
          setShowToast(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  function checkResumeExperiences(resumeExperiences) {

    const presentCount = resumeExperiences?.filter(exp => exp.isPresent === true).length;
    if (presentCount >= 2) {
      return <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: More than two experiences are marked as present.</p>;
    }
  }
  function checkResumeEducation(resumeExperiences) {

    const presentCount = resumeExperiences?.filter(exp => exp.isPresent === true).length;
    if (presentCount >= 2) {
      return <p className="warningText d-flex"><WarningAmberSharpIcon style={{ fontSize: '18px' }} /> Warning: More than two education are marked as present.</p>;
    }
  }
  return (
    <>
      {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )}
      <div className="p-3">
        {userCcv?.resumeInfo?.resumeNote && <p className="warningText d-flex my-3"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {userCcv?.resumeInfo?.resumeNote}</p>}
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <p className="f-16 fw-bold">Basic Info</p>
            <div className="d-flex align-items-center">
              {userCcv?.length === 0 ? <span className="f-14 me-3">View Resume</span> : <Link
                to={`/Resume/${UserId}`}
                target="_blank"
                className="me-3"
              >
                <p className="f-14 ms-1">View Resume</p>
              </Link>}
              <EditSharpIcon
                className="material-symbols-sharp pointer"
                onClick={handleOpenUpdateCCV} />
              <DeleteOutlineSharpIcon
                className="material-symbols-sharp pointer delete-icon"
                onClick={() => {
                  setShowResumeDelete(!showResumeDelete);
                }} />
            </div>
          </div>
          <div
            className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
          >
            <div className="ms-2">
              <div className="d-flex align-items-center verified">
                <p className="f-14 ms-1 pb-1 fw-bold me-2">
                  Visibility
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-switch form-check-primary CCVvisibility ms-3 ">
                  <Input
                    type="switch"
                    checked={resumeStatus}
                    onChange={handleToggle}
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row border-custom">
            <div className="col-md-6">
              {userCcv?.resumeInfo?.fullName &&
                <div
                  className={`analytic-sub mt-2 mb-1  pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <PersonSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          Full Name
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.fullName}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              {userCcv?.resumeInfo?.title &&
                <div
                  className={`analytic-sub mt-2 mb-1  pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <SubtitlesSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          Title
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.title}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="row border-custom">
            <div className="col-md-6">
              {userCcv?.resumeInfo?.dob &&
                <div
                  className={`analytic-sub mt-2 mb-1 pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <CalendarMonthSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          Date Of Birth
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.dob}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              {userCcv?.resumeInfo?.currentCity &&
                <div
                  className={`analytic-sub mt-2 mb-1 pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <MyLocationSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          City
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.currentCity}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="row border-custom">
            <div className="col-md-6">
              {userCcv?.resumeInfo?.email &&
                <div
                  className={`analytic-sub mt-2 mb-1  pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <MailOutlineSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          Email
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.email}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              {userCcv?.resumeInfo?.phoneNumber &&
                <div
                  className={`analytic-sub mt-2 mb-1 pb-3`}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="ms-2">
                      <div className="d-flex verified">
                        <PhoneIphoneSharpIcon className="HeadingIcon material-symbols-sharp" />
                        <p className="f-14 ms-1 pb-1 fw-bold me-2">
                          Phone Number
                        </p>
                      </div>
                      <p className="ms-1 f-12">
                        {userCcv?.resumeInfo?.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>



          {userCcv?.resumeInfo?.webSiteLink &&
            <div
              className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex verified">
                    <LanguageSharpIcon className="HeadingIcon material-symbols-sharp" />
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Website
                    </p>
                  </div>
                  <a
                    className="ms-1 f-12"
                    href={
                      userCcv?.resumeInfo?.webSiteLink?.startsWith('http://') || userCcv?.resumeInfo?.webSiteLink?.startsWith('https://')
                        ? userCcv?.resumeInfo?.webSiteLink
                        : `http://${userCcv?.resumeInfo?.webSiteLink}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {userCcv?.resumeInfo?.webSiteLink}
                  </a>
                </div>

              </div>
            </div>
          }
          {userCcv?.resumeInfo?.summary &&
            <div
              className={`analytic-sub mt-2 mb-1 pb-3`}
            >
              <div className="d-flex align-items-start justify-content-between">
                <div className="ms-2">
                  <div className="d-flex verified">
                    <SummarizeSharpIcon className="HeadingIcon material-symbols-sharp" />
                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                      Summary
                    </p>
                  </div>

                  <p className="ms-1 f-12">
                    {userCcv?.resumeInfo?.summary}
                  </p>
                </div>

              </div>
            </div>
          }
        </div>


        <div className="UpdateProfile mb-3 p-3">
          {checkResumeEducation(userCcv?.resumeEducations)}
          <div className="d-flex justify-content-between mb-4 border-custom pb-2 mt-2">
            <div className="d-flex">
              <SchoolOutlinedIcon className="HeadingIcon material-symbols-sharp" />
              <p className="f-16 fw-bold ms-1">Education</p>
            </div>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVEducation} />
            </div>
          </div>
          {userCcv?.resumeEducations &&
            userCcv?.resumeEducations?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeEducations?.length - 1;
              const visibleIndex = userCcv?.resumeEducations
                .slice(0, index)
                .filter((prevItem) => prevItem.status !== 0).length + 1;
              return (
                <>
                  {item?.note &&
                    <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                    key={index}
                  >
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="ms-2">
                        <div className="d-flex verified">
                          {item.status === 1 && <p className="f-16 fw-bold me-1">{visibleIndex}.</p>}
                          <p className="f-16 fw-bold me-2">
                            {item.institutionName && (
                              <>
                                {item.institutionName}
                                {item?.isOrgVrfd === true ? (
                                  <img src={resume.verified} width={18} height={18} className="ms-1" />
                                ) : item?.isOrgRgd === true ? (
                                  <img src={resume.register} width={18} height={18} className="ms-1" />
                                ) : (
                                  <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                )}
                                {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="ms-3">
                          <div className="d-flex">
                            {item.field && <p className=" me-1 f-14 fw-bold">Field: {item.field}</p>}
                            {item.field && <div className="dot"></div>}
                            {item.degree && <p className="ms-2 f-14 fw-bold">{item.degree}</p>}
                          </div>
                          {item.activities && <p className="f-12">Activity: {item.activities}</p>}
                          {item.grade && <p className="f-12">Grade: {item.grade}</p>}
                          {item.description && <p className="f-12">{item.description}</p>}
                          <div className="d-flex align-items-center">
                            {item.cityName && (
                              <>
                                <PlaceSharpIcon className="material-symbols-sharp locationIcon" />
                                <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                <div className="dot"></div>
                                <p className="ms-2 f-12">
                                  ({moment(item.startYear).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endYear).format("YYYY")})
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {userData?.profileInfo?.isOwn && ( */}
                      <div className="d-flex align-items-center updateActions">
                        {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                        {/* {item.isSyncedWithUserData ? '' : <> */}
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableEducation(item.rsmEductnId, item);
                              } else {
                                EnableEducation(item.rsmEductnId, item);
                              }
                            }}
                            className=""
                          />
                        </div>
                        <EditSharpIcon className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowEducationUpdate(!showEducationUpdate);
                            setEducationData(item);
                          }} />
                        {/* </>} */}
                        <DeleteOutlineSharpIcon
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            setShowEducationDelete(!showEducationDelete);
                            setEducationSynced(item.isSyncedWithUserData)
                            setEducationId(item.rsmEductnId);
                            setUsrEductnId(item.usrEductnId);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>

              );
            })}
        </div>


        <div className="UpdateProfile mb-3 p-3">
          {checkResumeExperiences(userCcv?.resumeExperiences)}
          <div className="d-flex justify-content-between mb-2 border-custom mt-2">
            <div className="d-flex">
              <BusinessCenterOutlinedIcon className="HeadingIcon material-symbols-sharp" />
              <p className="f-16 fw-bold ms-1">Experience</p>
            </div>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVExperience}
              />
            </div>
          </div>

          {userCcv?.resumeExperiences &&
            userCcv?.resumeExperiences?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeExperiences.length - 1;
              const visibleIndex = userCcv?.resumeExperiences
                .slice(0, index)
                .filter((prevItem) => prevItem.status !== 0).length + 1;
              return (
                <>
                  {item?.note &&
                    <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  >
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="ms-2">
                        <div className="d-flex verified">
                          {item.status === 1 && <p className="f-16 fw-bold me-1">{visibleIndex}.</p>}
                          <p className="f-16 fw-bold me-2">
                            {item.jobTitle && (
                              <>
                                {item.jobTitle}
                                {item?.isOrgVrfd === true ? (
                                  <img src={resume.verified} width={18} height={18} className="ms-1" />
                                ) : item?.isOrgRgd === true ? (
                                  <img src={resume.register} width={18} height={18} className="ms-1" />
                                ) : (
                                  <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                )}
                                {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="ms-3">
                          {item.companyName && <p className="me-1 f-14 fw-bold">{item.companyName}</p>}
                          <div className="d-flex align-items-center">
                            {item.jobTypeName && <p className="me-2 f-12">{item.jobTypeName}</p>}
                            {item.cityName && (
                              <>
                                <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                <div className="dot"></div>
                                <p className="ms-2 f-12">
                                  ({moment(item.startDate).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endDate).format("YYYY")})
                                </p>
                              </>
                            )}
                          </div>
                          <div className="d-flex align-items-center">
                            {item.positionHeadline && <> <p className="f-12 me-1">{item.positionHeadline}</p></>}
                            {item.workLevelTypeName && item.positionHeadline && <div className="dot"></div>}
                            {item.workLevelTypeName && <> <p className="f-12 ms-2 me-1">{item.workLevelTypeName}</p></>}
                            {item.workLevelTypeName && item.workLocationTypeName && <div className="dot"></div>}
                            {item.workLocationTypeName && <> <p className="f-12 m-2">{item.workLocationTypeName}</p></>}
                          </div>
                          {item.description && <p className="f-12">{item.description}</p>}
                        </div>
                      </div>
                      {/* {userData?.profileInfo?.isOwn && ( */}
                      <div className="d-flex align-items-center updateActions">
                        {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                        {/* {item.isSyncedWithUserData ? '' : <> */}
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableExperience(item.rsmExpId, item);
                              } else {
                                EnableExperience(item.rsmExpId, item);
                              }
                            }}
                            className=""
                          />
                        </div>
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowExperienceUpdate(!showExperienceUpdate);
                            setExperienceData(item);
                          }}
                        />
                        {/* </>} */}
                        <DeleteOutlineSharpIcon
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            setShowExperienceDelete(!showExperienceDelete);
                            setExperienceId(item.rsmExpId);
                            setUsrExpId(item.userExprncId)
                            setExperienceSynced(item.isSyncedWithUserData)
                          }}
                        />
                      </div>
                      {/* )} */}
                    </div>
                    <div className="mt-3 ARCard">
                      <div className="d-flex justify-content-between border-custom">
                        <div className=" d-flex">
                          <EmojiEventsOutlinedIcon className="HeadingIcon material-symbols-sharp" style={{ marginTop: '2px' }} />
                          <h6 className="f-14 ms-1">
                            Experience Achievement
                          </h6>
                        </div>
                        {/* {item.isSyncedWithUserData ? '' : <> */}
                        <div className="addicon pointer">
                          <AddSharpIcon
                            className="material-symbols-sharp"
                            onClick={() => {
                              handleOpenExperienceAchivement();
                              setExperienceData(item);
                            }}
                          />
                        </div>
                        {/* </>
                      } */}
                      </div>

                      {item.rsmExperienceAchievements &&
                        item.rsmExperienceAchievements
                          .filter((item) => !item.isDeleted)
                          ?.map((roleItem, index) => {
                            return (
                              <div
                                className="d-flex align-items-start justify-content-between mt-2"
                                key={index}
                              >
                                <div className="ms-2">
                                  <div className="d-flex verified">
                                    <p className="f-14 ms-1  me-2 m-0">
                                      {roleItem.achievement}
                                    </p>
                                  </div>
                                  <p className="f-14 ms-1 me-2 m-0">
                                    {roleItem.achivementDate}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center updateActions">
                                  {/* {item.isSyncedWithUserData ? '' : <> */}
                                  <EditSharpIcon
                                    className="material-symbols-sharp pointer ms-2 me-2"
                                    onClick={() => {
                                      handleOpenEditExperienceAchivement();
                                      setExperienceAchivementData(
                                        roleItem
                                      );
                                      setExperienceData(item);
                                    }}
                                  />
                                  {/* </>
                                } */}
                                  <DeleteOutlineSharpIcon
                                    className="material-symbols-sharp pointer delete-icon  text-red"
                                    onClick={() => {
                                      setShowExperienceAchievementDelete(
                                        !showExperienceAchievementDelete
                                      );
                                      setExperienceId(
                                        item.rsmExpId
                                      );
                                      setExperienceAchivementId(
                                        roleItem.id
                                      );
                                      setExperienceAchivement(
                                        roleItem.achievement
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                    </div>
                    <div className="mt-3 ARCard">
                      <div className="d-flex justify-content-between border-custom">
                        <div className="d-flex" >
                          <SupervisedUserCircleSharpIcon className="HeadingIcon material-symbols-sharp" style={{ marginTop: '2px' }} />
                          <h6 className="f-14 ms-1">
                            Experience Role
                          </h6>
                        </div>
                        {/* {item.isSyncedWithUserData ? '' : <> */}
                        <div className="addicon pointer">
                          <AddSharpIcon
                            className="material-symbols-sharp"
                            onClick={() => {
                              handleOpenExperienceRole();
                              setExperienceData(item);
                            }}
                          />
                        </div>
                        {/* </>
                      } */}
                      </div>

                      {item.rsmExperienceRoles &&
                        item.rsmExperienceRoles
                          .filter((item) => !item.isDeleted)
                          ?.map((roleItem, index) => {
                            return (
                              <div
                                className="d-flex align-items-start justify-content-between mt-2"
                                key={index}
                              >
                                <div className="ms-2">
                                  <div className="d-flex verified">
                                    <p className="f-14 ms-1 me-2 m-0">
                                      {roleItem.role}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center updateActions">
                                  {/* {item.isSyncedWithUserData ? '' : <> */}
                                  <EditSharpIcon
                                    className="material-symbols-sharp pointer ms-2 me-2"
                                    onClick={() => {
                                      handleOpenEditExperienceRole();
                                      setExperienceRoleData(
                                        roleItem
                                      );
                                      setExperienceData(item);
                                    }}
                                  />
                                  {/* </>
                                } */}
                                  <DeleteOutlineSharpIcon
                                    className="material-symbols-sharp pointer delete-icon  text-red"
                                    onClick={() => {
                                      setShowExperienceRoleDelete(
                                        !showExperienceRoleDelete
                                      );
                                      setExperienceId(
                                        item.rsmExpId
                                      );
                                      setExperienceRoleId(
                                        roleItem.id
                                      );
                                      setExperienceRole(
                                        roleItem.role
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </>
              );
            })}
        </div>


        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <div className="d-flex">
              <WorkspacePremiumSharpIcon className="HeadingIcon material-symbols-sharp" />
              <p className="f-16 fw-bold ms-1">Certification</p>
            </div>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVCertification}
              />
            </div>
          </div>
          {/* <div className='analytics d-flex justify-content-between mt-3'> */}
          {/* ccvCertifications */}
          {userCcv?.resumeCertifications &&
            userCcv?.resumeCertifications?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeCertifications.length - 1;
              const visibleIndex = userCcv?.resumeCertifications
                .slice(0, index)
                .filter((prevItem) => prevItem.status !== 0).length + 1;
              return (
                <>
                  {item?.note &&
                    <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  >
                    <div className="d-flex align-items-start justify-content-between">
                      {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                      <div className="ms-2">
                        <div className="d-flex verified">
                          {item.status === 1 && <p className="f-16 fw-bold me-1">{visibleIndex}.</p>}
                          <p className="f-16 ms-1 fw-bold me-2">
                            {item.name && (
                              <>
                                {item.name}
                                {item?.isOrgVrfd === true ? (
                                  <img src={resume.verified} width={18} height={18} className="ms-1" />
                                ) : item?.isOrgRgd === true ? (
                                  <img src={resume.register} width={18} height={18} className="ms-1" />
                                ) : (
                                  <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                )}
                                {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="ms-3">
                          {item.orgName && <p className="me-1 f-14 fw-bold">{item.orgName}</p>}
                          <div className="d-flex align-items-center">
                            {item.recognition && <p className="me-1 f-12">{item.recognition}</p>}
                            {item.credentialId && (
                              <>
                                <div className="dot"></div>
                                <p className="ms-2 f-12">ID: {item.credentialId}</p>
                              </>
                            )}
                          </div>
                          <div className="d-flex align-items-center">
                            {item.cityName && (
                              <>
                                <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                <div className="dot"></div>
                                <p className="ms-2 f-12">
                                  ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {userData?.profileInfo?.isOwn && ( */}
                      <div className="d-flex align-items-center updateActions">
                        {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                        {/* {item.isSyncedWithUserData ? '' : <> */}
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableCertification(item.rsmCertfctnId, item);
                              } else {
                                EnableCertification(item.rsmCertfctnId, item);
                              }
                            }}
                            className=""
                          />
                        </div>
                        <EditSharpIcon className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowCertificateUpdate(!showCertificateUpdate);
                            setCertificateData(item);
                          }}
                        />
                        {/* </>} */}
                        <DeleteOutlineSharpIcon
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            setShowCertificationDelete(!showCertificationDelete);
                            setCertificationId(item.rsmCertfctnId);
                            setUsrCerId(item.userCrtfctnId)
                            setCertificateSynced(item.isSyncedWithUserData)
                          }}
                        />
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <div className="d-flex">
              <MilitaryTechSharpIcon className="HeadingIcon material-symbols-sharp" />
              <p className="f-16 fw-bold ms-1">Awards</p>
            </div>
            <div>
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAward}
              />
            </div>
          </div>
          {userCcv?.resumeAwards &&
            userCcv?.resumeAwards?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeAwards.length - 1;
              const visibleIndex = userCcv?.resumeAwards
                .slice(0, index)
                .filter((prevItem) => prevItem.status !== 0).length + 1;
              return (
                <>
                  {item?.note &&
                    <p className="warningText d-flex"> <WarningAmberSharpIcon style={{ fontSize: '18px' }} />Warning: {item?.note}</p>}
                  <div
                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                      } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  >
                    <div className="d-flex align-items-start justify-content-between">
                      {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                      <div className="ms-2">
                        <div className="d-flex verified">
                          {item.status === 1 && <p className="f-16 fw-bold me-1">{visibleIndex}.</p>}
                          <p className="f-16 fw-bold me-2">
                            {item.name && (
                              <>
                                {item.name}
                                {item?.isOrgVrfd === true ? (
                                  <img src={resume.verified} width={18} height={18} className="ms-1" />
                                ) : item?.isOrgRgd === true ? (
                                  <img src={resume.register} width={18} height={18} className="ms-1" />
                                ) : (
                                  <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                )}
                                {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="ms-3">
                          {item.orgName && <p className=" me-1 f-14 fw-bold">{item.orgName}</p>}
                          <div className="d-flex align-items-center">
                            <p className="me-1 f-12">{item.recognition}</p>
                          </div>
                          {item.description && (
                            <>
                              <p className="f-12">{item.description}</p>
                            </>
                          )}
                          <div className="d-flex align-items-center">
                            {item.cityName && (
                              <>
                                <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                {/* <div className="dot"></div> */}
                              </>
                            )}
                            <p className="ms-2 f-12">
                              ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* {userData?.profileInfo?.isOwn && ( */}
                      <div className="d-flex align-items-center updateActions">
                        {item?.isSyncedWithUserData && <img src={resume.synced} width={15} height={15} />}
                        {/* {item.isSyncedWithUserData ? '' :
                        <> */}
                        <div className="form-switch form-check-primary ms-2">
                          <Input
                            type="switch"
                            defaultChecked={item?.status === 1}
                            name="icon-primary"
                            onClick={() => {
                              if (item?.status === 1) {
                                DisableAwards(item.rsmAwrdId, item);
                              } else {
                                EnableAwards(item.rsmAwrdId, item);
                              }
                            }}
                            className=""
                          />
                        </div>
                        {/* <span
                            className="material-symbols-sharp pointer me-2"
                            onClick={() => {
                              setShowAwardUpdate(!showAwardUpdate);
                              setAwardData(item);
                            }}
                          >
                            edit
                          </span> */}
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2"
                          onClick={() => {
                            setShowAwardUpdate(!showAwardUpdate);
                            setAwardData(item);
                          }}
                        />
                        {/* </>} */}
                        {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAwardDelete(!showAwardDelete);
                          setAwardId(item.rsmAwrdId);
                          setUsrAwrId(item.userAwardId)
                          setAwardSynced(item.isSyncedWithUserData)
                        }}
                      >
                        delete
                      </span> */}
                        <DeleteOutlineSharpIcon
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            setShowAwardDelete(!showAwardDelete);
                            setAwardId(item.rsmAwrdId);
                            setUsrAwrId(item.userAwardId)
                            setAwardSynced(item.isSyncedWithUserData)
                          }} />
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2">
            <div className="d-flex">
              <PsychologySharpIcon className="HeadingIcon material-symbols-sharp" />
              <p className="f-16 fw-bold ms-1">Skills</p>
            </div>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenAddSkill}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenAddSkill}
              />
            </div>
          </div>
          {/* <div className='analytics d-flex justify-content-between mt-3'> */}
          {userCcv?.resumeSkills &&
            userCcv?.resumeSkills?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeSkills.length - 1;
              return (
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  key={index}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center verified ">
                      <p className="f-16 fw-bold me-1">{index + 1}.</p>
                      <p className="f-16 pb-0 fw-bold  me-2">
                        {item.skillName}
                      </p>
                    </div>
                    <div className="d-flex align-items-center updateActions">
                      {item?.isSyncedWithUserData && <img src={resume.synced} width={15} className="me-2" />}
                      {item.isSyncedWithUserData ? '' :
                        // <span
                        //   className="material-symbols-sharp pointer me-2 ms-2"
                        //   onClick={() => {
                        //     setShowSkills(!showSkills);
                        //     setSkillsData(item);
                        //   }}
                        // >
                        //   edit
                        // </span>
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2 ms-2"
                          onClick={() => {
                            setShowSkills(!showSkills);
                            setSkillsData(item);
                          }}
                        />
                      }
                      {/* <span
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowSkillDelete(!showSkillDelete);
                          setSkillId(item.rsmSkllId);
                          setUsrSkllId(item?.usrSkllId)
                          setSkillSynced(item.isSyncedWithUserData)
                          setSkillDataId(item?.usrSkllId)
                        }}
                      >
                        delete
                      </span> */}
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowSkillDelete(!showSkillDelete);
                          setSkillId(item.rsmSkllId);
                          setUsrSkllId(item?.usrSkllId)
                          setSkillSynced(item.isSyncedWithUserData)
                          setSkillDataId(item?.usrSkllId)
                        }} />
                    </div>
                    {/* )} */}
                  </div>
                  <p className="ms-1 f-12">
                    {item.firmIndustryTypeName}
                  </p>
                  {item.endorsedCount && <p className="ms-1 f-12">
                    {/* Frontend Web Developer at HyperautoX Information
                          Technology LLC */}
                    {item.endorsedCount} endorsement
                  </p>}
                </div>
              );
            })}
        </div>
        <div className="UpdateProfile mb-3 p-3">
          <div className="d-flex justify-content-between mb-4 border-custom pb-2" >
            <div className="d-flex">
              <AttachFileSharpIcon className="HeadingIcon material-symbols" />
              <p className="f-16 fw-bold ms-1">Attachement</p>
            </div>
            <div>
              {/* <span
                className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAttachement}
              >
                add
              </span> */}
              <AddSharpIcon className="material-symbols-sharp pointer"
                onClick={handleOpenCCVAttachement}
              />
            </div>
          </div>
          {userCcv?.resumeAttachments &&
            userCcv?.resumeAttachments?.map((item, index) => {
              const isLastItem = index === userCcv?.resumeAttachments?.length - 1;
              return (
                <div
                  // className= "analytic-sub mt-2 mb-1 border-custom pb-3"
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                  key={index}
                >
                  <div className="d-flex align-items-start justify-content-between">
                    {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                    <div className="ms-2">
                      <div className="d-flex">
                        <p className="f-16 fw-bold me-1">{index + 1}.</p>
                        <p className="f-16 pb-1 fw-bold">
                          {item?.docName}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="form-switch form-check-primary ms-3">
                        <Input
                          type="switch"
                          defaultChecked={item?.status === 1}
                          name="icon-primary"
                          onClick={() => {
                            if (item?.status === 1) {
                              DisableAttachement(item.rsmAttchmntId);
                            } else {
                              EnableAttachement(item.rsmAttchmntId);
                            }
                          }}
                          className=""
                        />
                      </div>
                      <DeleteOutlineSharpIcon
                        className="material-symbols-sharp pointer delete-icon"
                        onClick={() => {
                          setShowAttachementDelete(!showAttachementDelete);
                          setAttachementId(item.rsmAttchmntId);
                        }} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div >
      <CCVSKill
        show={showAddSkill}
        handleClose={handleCloseAddSkill}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}

      />
      <UpdateCCV
        showUpdateCCV={showUpdateCCV}
        handleCloseCCV={handleCloseCCV}
        userCcv={userCcv}
        getUserCcv={getUserCcv}
      />
      <CCVEducation
        showCCVEducation={showCCVEducation}
        handleCloseCCVEducation={handleCloseCCVEducation}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVExperience
        showCCVExperience={showCCVExperience}
        handleCloseCCVExperience={handleCloseCCVExperience}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAward
        showCCVAward={showCCVAward}
        handleCloseCCVAward={handleCloseCCVAward}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAttachement
        showCCVAttachement={showCCVAttachement}
        setShowCCVAttachement={setShowCCVAttachement}
        handleCloseCCVAttachement={handleCloseCCVAttachement}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVCertification
        showCCVCertification={showCCVCertification}
        handleCloseCCVCertification={handleCloseCCVCertification}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVRole
        showCCVRole={showCCVRole}
        handleCloseCCVRole={handleCloseCCVRole}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
        ExperienceId={ExperienceId}
      />
      <CCVAchievement
        showCCVAchivement={showCCVAchivement}
        handleCloseCCVAchivement={handleCloseCCVAchivement}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
        ExperienceId={ExperienceId}
      />

      <CCVEducationUpdate
        showEducationUpdate={showEducationUpdate}
        setShowEducationUpdate={setShowEducationUpdate}
        handleUpdateEducationClose={handleUpdateEducationClose}
        resumeId={userCcv?.resumeInfo?.resumeId}
        getUserCcv={getUserCcv}
        educationData={educationData}
      />
      <CCVExperienceUpdate
        showExperienceUpdate={showExperienceUpdate}
        handleUpdateExperienceClose={handleUpdateExperienceClose}
        getUserCcv={getUserCcv}
        ExperienceData={ExperienceData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVCertificationUpdate
        showCertificateUpdate={showCertificateUpdate}
        handleUpdateCertificateClose={handleUpdateCertificateClose}
        getUserCcv={getUserCcv}
        certificateData={certificateData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVSkillUpdate
        showSkills={showSkills}
        handleUpdateSkillClose={handleUpdateSkillClose}
        getUserCcv={getUserCcv}
        skillsData={skillsData}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVAwardUpdate
        showAwardUpdate={showAwardUpdate}
        handleUpdateAwardClose={handleUpdateAwardClose}
        showAwardData={showAwardData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />


      {
        showResumeDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Are You Sure You want to Delete Resume?</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveResumeClose();
                    }} />
                </div>
                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeResume()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        showExperienceDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Are You Sure You want to Delete Experience?</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveExperienceClose();
                    }} />
                </div>
                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeExperience()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        showSkillDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6> Are You Sure You want to Delete Skill?</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveSkillClose();
                    }} />
                </div>
                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeSkill()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        showEducationDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Are You Sure You want to Delete Education?</h6>

                  {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveEducationClose();
                  }}
                >
                  close
                </span> */}
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveEducationClose();
                    }} />
                </div>

                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeEducation()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }



      {
        showCertificationDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6> Are You Sure You want to Delete Certification?</h6>

                  {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveCertificationClose();
                  }}
                >
                  close
                </span> */}
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveCertificationClose();
                    }} />
                </div>

                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeCertification()}
                  >
                    Delete
                  </button>
                </div>

              </div>
            </div>
          </div>
        )
      }



      {
        showAwardDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>  Are You Sure You want to Delete Award?</h6>

                  {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAwardClose();
                  }}
                >
                  close
                </span> */}
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveAwardClose();
                    }} />
                </div>

                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeAward()}
                  >
                    Delete
                  </button>
                </div>

              </div>
            </div>
          </div>
        )
      }

      {/* Delete Attachement */}

      {
        showAttachementDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6> Are You Sure You want to Delete Attachement? </h6>

                  {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveAttachementClose();
                  }}
                >
                  close
                </span> */}
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleRemoveAttachementClose();
                    }} />
                </div>

                <div className="text-center mt-2">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => removeAttachement()}
                  >
                    Delete
                  </button>
                </div>

              </div>
            </div>
          </div>
        )
      }

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      <CCVExperienceAchievemenUpdate
        handleCloseEditExperienceAchivement={
          handleCloseEditExperienceAchivement
        }
        showEditExperienceAchivement={showEditExperienceAchivement}
        experienceAchivementData={experienceAchivementData}
        ExperienceData={ExperienceData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVExpRoleUpdate
        handleCloseEditExperienceRole={
          handleCloseEditExperienceRole
        }
        showEditExperienceRole={showEditExperienceRole}
        experienceRoleData={experienceRoleData}
        ExperienceData={ExperienceData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />

      <CCVExperienceAchievementAdd
        showExperienceAchivement={showExperienceAchivement}
        handleCloseExperienceAchivement={handleCloseExperienceAchivement}
        ExperienceData={ExperienceData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />
      <CCVExpRoleAdd
        showExperienceRole={showExperienceRole}
        handleCloseExperienceRole={handleCloseExperienceRole}
        ExperienceData={ExperienceData}
        getUserCcv={getUserCcv}
        resumeId={userCcv?.resumeInfo?.resumeId}
      />

      {
        showExperienceAchievementDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6> Are You Sure You want to Delete Experience Achievement?</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleExperienceAchievementClose();
                    }}
                  />
                </div>

                <div className="mt-2 text-center">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => DeleteExperienceAchivement()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        showExperienceRoleDelete && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6> Are You Sure You want to Delete Experience Role?</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleExperienceRoleClose();
                    }}
                  />
                </div>

                <div className="mt-2 text-center">
                  <button
                    color=""
                    className="closebtn"
                    onClick={() => DeleteExperienceRole()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ManageResume;
