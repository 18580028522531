import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorTopic from "./EditorTopic";
import EditorTags from "./EditorTags";
import CoverArticle from "./CoverArticle";
import SettingUp from "./SettingUp";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';


const EditorPan = () => {
  const [value, setValue] = useState("");

  const [editorPanShow, setEditorPanShow] = useState(false);
  const handleEditorShow = () => setEditorPanShow(true);
  const handleEditorPanClose = () => setEditorPanShow(false);

  const handleSubmit = (event) => {
    // const form = event.currentTarget;
    // event.preventDefault();
    // if (form.checkValidity() === false) {
    // 	event.preventDefault();
    // 	event.stopPropagation();
    // }
    // addUomMaster();
    // setValidated(true);
  };

  useEffect(() => {
    if (editorPanShow) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [editorPanShow]);

  return (
    <>
      <div className="editor-pan-main-container">
        <div className="editor-pan-container p-3 mb-3">
          <div className="editor-pan-header d-flex justify-content-between">
            <div className="editor-left d-flex">
              <div className="editor-user-profile me-2 "></div>
              <span className="f-12 ">English</span>
            </div>
            <div className="draft-cont f-12 d-flex">
              Draft
              <KeyboardArrowDownSharpIcon className="material-symbols-sharp" />
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-1 draft-icon d-flex justify-content-center align-items-center">
              <EditNoteSharpIcon className="material-symbols-sharp" />
            </div>
            <div className="col-md-11 editor-content">
              <div className="editor-content-header">
                <div className="editor-main-text f-16 f-600 mb-1">
                  How to Create an Invoice for First Time
                </div>
                <div className="editor-sub-text f-10 f-400">
                  Last edited 25 minutes ago by{" "}
                  <span className="link-text">Ahmad Fawaid</span>
                </div>
              </div>
              <div className="text-editor mt-3">
                <ReactQuill theme="snow" value={value} onChange={setValue} />
              </div>
              <div className="editor-content-cont">
                <p className="f-12 f-400 mb-4 editor-tex">
                  You don’t need to have a full time ecommerce business to earn
                  a little extra money through your website. You don’t even need
                  to be there all the time. All you need to do is wait for the
                  day your advertisers will pay you.
                </p>

                <p className="f-12 f-400 mb-4 editor-tex">
                  However, this is not as easy as it seems. You can’t expect to
                  just make a website and watch the money roll in. You have to
                  exert first the effort to make the site popular and produce a
                  huge traffic flow. Advertisers would only post their banners
                  and ads on sites where they know there are many people who
                  will see them. The more traffic and visitors you have the
                  likely the chance that advertisers will want their ads on your
                  site. You can also have pay-per-click advertising in your
                  site. As each visitor clicks on an ad, the advertiser will pay
                  you for those redirects. Google’s Adsense and Yahoo’s Search
                  marketing are some of those that offer this performance based
                  marketing strategies.
                </p>

                <p className="f-12 f-400 editor-tex">
                  They can provide a way to make money online by simply placing
                  ads on your site. These ads are also links to the sites of the
                  advertisers. The advertisers pay Google and Yahoo for every
                  clicks done to their links and in return you get paid by these
                  search engines if those clicks were done on your site. The
                  best way to make a better profit is to ensure that there are
                  lots of people who will click on those links. Make sure that
                  your site gets many visitors by making your site informative
                  as well as entertaining. Your site must concentrate on a
                  certain niche so that you can laser-target your market.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="editor-pan-container p-3">
          <div className="editor-pan-header d-flex justify-content-between">
            <div className="editor-left d-flex">
              <div className="editor-user-profile me-2 "></div>
              <span className="f-12 ">English</span>
            </div>
            <div className="draft-cont f-12 d-flex">
              Draft
              <KeyboardArrowDownSharpIcon className="material-symbols-sharp" />

            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-1 draft-icon d-flex justify-content-center align-items-center">
              <EditNoteSharpIcon className="material-symbols-sharp" />

            </div>
            <div className="col-md-11 editor-content">
              <div className="editor-content-header">
                <div className="editor-main-text f-16 f-600">
                  How to Create an Invoice for First Time
                </div>
                <div className="editor-sub-text f-10 f-400">
                  Last edited 25 minutes ago by{" "}
                  <span className="link-text">Ahmad Fawaid</span>
                </div>
              </div>
              <div className="text-editor mt-3">
                <ReactQuill theme="snow" value={value} onChange={setValue} />
              </div>
              <div className="editor-content-cont">
                <p className="f-12 f-400 mb-4 editor-tex">
                  You don’t need to have a full time ecommerce business to earn
                  a little extra money through your website. You don’t even need
                  to be there all the time. All you need to do is wait for the
                  day your advertisers will pay you.
                </p>

                <p className="f-12 f-400 mb-4 editor-tex">
                  However, this is not as easy as it seems. You can’t expect to
                  just make a website and watch the money roll in. You have to
                  exert first the effort to make the site popular and produce a
                  huge traffic flow. Advertisers would only post their banners
                  and ads on sites where they know there are many people who
                  will see them. The more traffic and visitors you have the
                  likely the chance that advertisers will want their ads on your
                  site. You can also have pay-per-click advertising in your
                  site. As each visitor clicks on an ad, the advertiser will pay
                  you for those redirects. Google’s Adsense and Yahoo’s Search
                  marketing are some of those that offer this performance based
                  marketing strategies.
                </p>

                <p className="f-12 f-400 editor-tex">
                  They can provide a way to make money online by simply placing
                  ads on your site. These ads are also links to the sites of the
                  advertisers. The advertisers pay Google and Yahoo for every
                  clicks done to their links and in return you get paid by these
                  search engines if those clicks were done on your site. The
                  best way to make a better profit is to ensure that there are
                  lots of people who will click on those links. Make sure that
                  your site gets many visitors by making your site informative
                  as well as entertaining. Your site must concentrate on a
                  certain niche so that you can laser-target your market.
                </p>
              </div>
            </div>
          </div>
          <div className="cancel-button publishBtn editorfilterBtn">
            <button className="f-12 f-600" onClick={handleEditorShow}>
              Filter
            </button>
          </div>
        </div>

        <div className="editor-modal-sidebar">
          {/* <Modal
            show={editorPanShow}
            onHide={handleEditorPanClose}
            backdrop="static"
            keyboard={false}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <EditorTopic />
                    <hr />
                    <EditorTags />
                    <hr />
                    <CoverArticle />
                    <hr />
                    <SettingUp />
                    <hr />
                    <div className="editor-button-container d-flex justify-content-between mt-4">
                      <div className="cancel-button">
                        <button className="f-12 f-600">Cancel</button>
                      </div>
                      <div className="cancel-button publishBtn">
                        <button className="f-12 f-600">Publish Article</button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleEditorPanClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal> */}

          {editorPanShow && (
            <div
              className="modal fade show postAdd"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
            >
              <div
                className="modal-dialog  modal-dialog-centered "
                role="document"
              >
                <div className="modal-content d-flex justify-content-around p-2">
                  <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                    <h6>Website</h6>

                    {/* <span
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleEditorPanClose();
                      }}
                    >
                      close
                    </span> */}
                    <CloseSharpIcon
                      className="material-symbols-sharp me-2 pointer"
                      onClick={() => {
                        handleEditorPanClose();
                      }}
                    />
                  </div>

                  <Row>
                    <Col md={12}>
                      <EditorTopic />
                      <hr />
                      <EditorTags />
                      <hr />
                      <CoverArticle />
                      <hr />
                      <SettingUp />
                      <hr />
                      <div className="editor-button-container d-flex justify-content-between mt-4">
                        <div className="cancel-button">
                          <button className="f-12 f-600">Cancel</button>
                        </div>
                        <div className="cancel-button publishBtn">
                          <button className="f-12 f-600">
                            Publish Article
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex">
                    <Button variant="secondary" onClick={handleEditorPanClose}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditorPan;
