import React, { useEffect, useState } from "react";
import "../CreateCompany/CreateCompany.css";
import { Form } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../../selectStyles";
import axios from "../../API/axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import Flatpickr from 'react-flatpickr';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const CreateCompany = ({ showModal, closeModal, getCompanyList, GetOwnCompniesListsData, setUpdateList }) => {
  const sessionId = localStorage.getItem("sessionId");
  const [display, setDisplay] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyAbout, setCompanyAbout] = useState("");
  const [companyTagline, setCompanyTagline] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const [description, setDescription] = useState("");
  const [comincorpDate, setComincorpDate] = useState("");
  const [emailValid, setEmailValid] = useState(true); // New state for email validation
  //   State for toaster
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //   Create company function

  const handleSubmit = async () => {
    try {
      // Check if the email field is not empty before validating
      if (companyEmail !== "" && !emailRegex.test(companyEmail)) {
        setEmailValid(false);
        setWarningToastMessage("Invalid email format");
        setShowWarningToast(true);
        return;
      } else {
        setEmailValid(true);
      }
      setDisplay(true);
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        compName: companyName,
        cityId: cityId,
        email: companyEmail,
        about: companyAbout,
        industryId: industryOption,
        companyCategoryId: CategoryId,
        companySizeId: CompanySizeId,
        tagline: companyTagline,
        publicUrl: publicUrl,
        website: companyWebsite,
        incorpDate: moment(comincorpDate).format("DD/MM/YYYY"),
        avgAnumTurnOver: companyTurnover,
        description: description,
        backgroudImage: "backgroudImage string",
        logoImage: "logoImage string",
      };

      if (
        companyName === "" ||
        companyEmail === "" ||
        CategoryId === null ||
        CompanySizeId === null ||
        cityId === null ||
        industryOption === null
      ) {
        setWarningToastMessage("Please fill all the required fields");
        setShowWarningToast(true);
      } else {
        try {
          const res = await axios.post("/Company/CreateCompany", body);
          setToastMessage(res.data.message);
          setShowToast(true);
          reset();
          setTimeout(() => {
            closeModal();;
          }, 2000);

          getCompanyList()
          GetOwnCompniesListsData()
          setUpdateList(true)
        } catch (e) {
          console.log('ghgds', e);

          if (e.response && e.response.status === 404) {
            setWarningToastMessage(e.response.data.message);
            setShowWarningToast(true);
          } else {
            setErrorToastMessage(e.response.data.message);
            setShowErrorToast(true);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred during submission:", error);
    }
  };

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  console.log("citySearch", citySearch);

  //********City List End  ********//

  //************************Industry Get Start  ************************//
  const [industryList, setIndustryList] = useState("");
  const [industryOption, setIndustryOption] = useState(null);
  const getIndustry = () => {
    axios
      .get(`Master/GetFirmIndustryTypeDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fitid,
          label: item.name,
        }));
        setIndustryList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getIndustry();
  }, []);

  //************************Industry Get End  ************************//

  //********Category DDL Start  ********//
  const [Category, setCategory] = useState([]);
  const [CategoryId, setCategoryId] = useState(null);
  const getCategory = () => {
    axios
      .get(`Master/GetFirmCategoryDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fcid,
          label: item.name,
        }));
        setCategory(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  //********Category DDL End  ********//

  //********CompanySize DDL Start  ********//
  const [CompanySize, setCompanySize] = useState([]);
  const [CompanySizeId, setCompanySizeId] = useState(null);
  const getCompanySize = () => {
    axios
      .get(`Master/GetFirmSizeDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setCompanySize(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompanySize();
  }, []);
  //********CompanySize DDL End  ********//

  const reset = () => {
    setCompanyName("");
    setCompanyEmail("");
    setCompanyAbout("");
    setCompanyTagline("");
    setCompanyWebsite("");
    setCompanyTurnover("");
    setPublicUrl("");
    setDescription("");
    setComincorpDate("");
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showModal]);

  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };

  return (
    <>
      {showModal && (
        <div
          className="modal fade show modal-lg"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Create Company</h6>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>

              <Form>
                <div className="row settings CreateCompany">
                  <div className="col-md-6 mt-2 ">
                    <label htmlFor="about">
                      Company Name <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input type='text' /> */}
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      invalid={display && companyName === ""}
                    />
                    {display && !companyName ? (
                      <span className="error_msg_lbl">Enter Company Name </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Email Id <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input
                      type="email"
                      name="email"
                      id="email"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      invalid={display && companyEmail === ""}
                    /> */}
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      className={emailValid ? "" : "is-invalid"} // Apply invalid class if email is not valid
                    />
                    {display && !companyEmail ? (
                      <span className="error_msg_lbl">Enter Email Id </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">About Company </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="2"
                      cols="50"
                      value={companyAbout}
                      onChange={(e) => setCompanyAbout(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">City <span style={{ color: "red" }}>*</span> </label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Industry <span style={{ color: "red" }}>*</span> </label>
                    <Select
                      defaultValue={industryOption}
                      onChange={(e) => {
                        setIndustryOption(e.value);
                      }}
                      options={industryList}
                      styles={customStyles}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company Category
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={CategoryId}
                      onChange={(selectedOption) =>
                        setCategoryId(selectedOption.value)
                      }
                      options={Category}
                      placeholder="Select Category"
                      invalid={display && CategoryId === ""}
                      styles={customStyles}
                    />
                    {display && !CategoryId ? (
                      <span className="error_msg_lbl">
                        Enter Company Category{" "}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">
                      Company Size<span className="text-danger ms-2">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={CompanySizeId}
                      onChange={(selectedOption) =>
                        setCompanySizeId(selectedOption.value)
                      }
                      options={CompanySize}
                      placeholder="Select CompanySize"
                      invalid={display && CompanySizeId === ""}
                      styles={customStyles}
                    />
                    {display && !CompanySizeId ? (
                      <span className="error_msg_lbl">Enter Company Size </span>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Tagline </label>
                    <input
                      type="text"
                      value={companyTagline}
                      onChange={(e) => setCompanyTagline(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Website </label>
                    <input
                      type="text"
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Public URL </label>
                    <input
                      type="text"
                      value={publicUrl}
                      onChange={(e) => setPublicUrl(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Company Turn Over </label>
                    <input
                      type="text"
                      value={companyTurnover}
                      onChange={(e) => setCompanyTurnover(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="about">Incorporate Date </label>

                    <Flatpickr className='form-control' value={comincorpDate} onChange={date => setComincorpDate(date[0])} id='dob' options={{
                      maxDate: "today", // Disables future dates
                      onReady: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Call the function to stop scroll on year
                      },
                      onOpen: (selectedDates, dateStr, instance) => {
                        preventYearScroll(instance); // Also prevent scroll when the picker opens
                      },
                    }} />
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">Description </label>
                    <textarea
                      id="address"
                      name="about"
                      rows="2"
                      cols="50"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default CreateCompany;
