import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom';
import ReactSelect from "react-select";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
const Address = () => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const options = [
        { value: "Mumbai", label: "Mumbai" },
        { value: "Thane", label: "Thane" },
        { value: "Pune", label: "Pune" },
    ];

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showModal]);
    return (
        <>
            <div className='setting-header'>
                <h4>Manage Address </h4>
            </div>
            <div className='p-3 privacy'>
                <div>
                    <p className='sessionName'>Office</p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>India . Vikhroli East - 400083</p>
                        <div>
                            <EditSharpIcon className="material-symbols-sharp edit" />
                            <DeleteOutlineSharpIcon className="material-symbols-sharp" />

                        </div>
                    </div>
                </div>
                <div>
                    <p className='sessionName'>Home</p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>KSA . Zip Code: 22230</p>
                        <div>
                            <EditSharpIcon className="material-symbols-sharp edit" />
                            <DeleteOutlineSharpIcon className="material-symbols-sharp" />
                        </div>
                    </div>
                </div>
                <div>
                    <p className='sessionName'>Other </p>
                    <div className='d-flex justify-content-between sessionAction'>
                        <p className='sessionTime'>USA . Zip Code: 23422</p>
                        <div>
                            <EditSharpIcon className="material-symbols-sharp edit" />
                            <DeleteOutlineSharpIcon className="material-symbols-sharp" />
                        </div>
                    </div>
                </div>
                <div className='mt-3 d-flex align-items-center addAddress' onClick={openModal}>
                    <AddBoxSharpIcon className="material-symbols-sharp pointer" />

                    <span className='ms-2 pointer'>Add New Address</span>
                    {/* <button type='submit' className='btnDanger mt-3 text-center'>Sign out all</button> */}
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className='setting-header d-flex justify-content-between align-items-center'>
                                <h6>Add New Address</h6>
                                <CloseSharpIcon className="material-symbols-sharp me-2 pointer" onClick={closeModal} />

                            </div>
                            <Form>
                                <div className='row p-4 settings'>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Address type <span style={{ color: 'red' }}>*</span></label>
                                        <ReactSelect
                                            className="custom-select"
                                            styles={{
                                                // control: (provided) => ({
                                                //     ...provided,
                                                //     width: 170, 
                                                // }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    // width: 300,

                                                }),
                                            }}
                                            options={options}
                                            placeholder="Address Type"
                                        />

                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Country <span style={{ color: 'red' }}>*</span></label>
                                        <ReactSelect
                                            className="custom-select"
                                            styles={{
                                                // control: (provided) => ({
                                                //     ...provided,
                                                //     width: 170, 
                                                // }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    // width: 300,

                                                }),
                                            }}
                                            options={options}
                                            placeholder="Country"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">City <span style={{ color: 'red' }}>*</span></label>
                                        <ReactSelect
                                            className="custom-select"
                                            styles={{
                                                // control: (provided) => ({
                                                //     ...provided,
                                                //     width: 170, 
                                                // }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    // width: 300,

                                                }),
                                            }}
                                            options={options}
                                            placeholder="Address Type"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">Location <span style={{ color: 'red' }}>*</span></label>
                                        <ReactSelect
                                            className="custom-select"
                                            styles={{
                                                // control: (provided) => ({
                                                //     ...provided,
                                                //     width: 170, 
                                                // }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    // width: 300,

                                                }),
                                            }}
                                            options={options}
                                            placeholder="Address Type"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Address Line 1  </label>
                                        <textarea id="about" name="about" rows="4" cols="50">
                                        </textarea>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Address Line 2  </label>
                                        <textarea id="about" name="about" rows="4" cols="50">
                                        </textarea>
                                    </div>


                                    {/* <div className='text-end'>
                                        <button type='submit' className='mainBtn1 mt-3 text-center'>Update</button>
                                    </div> */}
                                    <div className='text-end mt-4'>
                                        <button type="button" className="mainBtn1">+ Add</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default Address