import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Button, Overlay, Popover } from "react-bootstrap";

const CCVCertificationUpdate = ({
    showCertificateUpdate,
    handleUpdateCertificateClose,
    getUserCcv,
    resumeId,
    certificateData
}) => {
    const sessionId = localStorage.getItem("sessionId");
    const [cerName, setCerName] = useState("");
    const [recognition, setRecognition] = useState("");
    const [startDate, setStartDate] = useState("");
    const [present, setPresent] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [description, setDescription] = useState("");
    const [credentialId, setCredentialId] = useState("");
    const [orgType, setOrgType] = useState("");
    // const [orgName, setOrgName] = useState("");

    const [cityData, setCityData] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [citySearch, setCitySearch] = useState(null);

    const [display, setDisplay] = useState(false);

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const [valueC, setValueC] = useState(null);
    const [pageC, setPageC] = useState(0);
    const [typeC, setTypeC] = useState("");
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [optionsC, setOptionsC] = useState([]);
    const [totalPagesCom, setTotalPagesCom] = useState(0);
    const logValueC = debounce((typeC) => {
        setTypeC(typeC);
    }, 250);

    console.log("certificateData", certificateData);

    const [valueIns, setValueIns] = useState(null);
    const [pageIns, setPageIns] = useState(0);
    const [typedIns, setTypedIns] = useState("");
    const [optionsIns, setOptionsIns] = useState([]);
    const [totalPagesIns, setTotalPagesIns] = useState(0);
    const logValueIns = debounce((typedIns) => {
        setTypedIns(typedIns);
    }, 250);

    const [orgData, setOrgData] = useState([]);
    const [orgId, setOrgId] = useState('');
    const getOrgType = () => {

        axios
            .get(`Master/GetOrgTypesDDL`)
            .then((response) => {
                const res = response.data.responseData;
                console.log("setOrgData", res);
                const data = res.map((item) => ({ value: item.id, label: item.name }));
                setOrgData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getOrgType();
    }, []);
    const [getOrgInsTId, setGenOrgInstId] = useState('')
    const [newOrgType, setnewOrgType] = useState(null);
    const UpdateCertification = async () => {
        setDisplay(true);
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            rsmId: resumeId,
            name: cerName,
            recognition: recognition,
            issuingOrgType: orgType === '' ? 4 : orgType,
            orgId: valuegnr === null ? JSON.stringify(getOrgInsTId) : valuegnr,
            credentialId: credentialId,
            description: description,
            locationId: null,
            cityId: cityId,
            issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
            isExpirable: present,
            expirationDate:
                endDate === "" ? "" : moment(endDate).format("YYYY-MM-DD"),
            rsmCrtfctnId: certificateData.rsmCertfctnId,
        };
        const data1 = {
            deviceType: "Web",
            sessionId: sessionId,
            name: cerName,
            recognition: recognition,
            issuingOrgType: orgType === '' ? 4 : orgType,
            orgId: valuegnr === null ? JSON.stringify(getOrgInsTId) : valuegnr,
            credentialId: credentialId,
            description: description,
            locationId: null,
            cityId: cityId,
            issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
            isExpirable: present,
            expirationDate:
                endDate === "" ? "" : moment(endDate).format("YYYY-MM-DD"),
            userCrtfctnId: certificateData.userCrtfctnId,
        };
        const apiUrl = certificateData.isSyncedWithUserData
            ? `UserData/UpdateCertificationInfo`
            : `ResumeData/UpdateRsmCertificationInfo`;

        const payload = certificateData.isSyncedWithUserData ? data1 : data;
        try {
            await axios
                .put(apiUrl, payload)
                .then((resData) => {
                    console.log("resData cert", resData);
                    //   toast.success(resData.data.message);
                    setToastMessage(resData.data.message);
                    setShowToast(true);
                    getUserCcv();
                    handleUpdateCertificateClose();
                    //   addReset();
                });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    const Companies = () => {
        setIsLoadingC(true);
        axios
            .get(
                `Master/GetCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
                }&pageSize=100`
            )
            .then((response) => {
                console.log(response);
                const data = response?.data?.responseData?.orgList.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                }));
                setPageC((prevPage) => prevPage + 1);
                setIsLoadingC(false);
                setOptionsC((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesCom(response.data.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingC(false);
            });
    };

    const handleOpenC = () => {
        setPageC(1);
    };

    const handleChangeC = (value) => {
        setValueC(value.value);
        setPageC(0);
    };

    const handleInputChangeC = (typed) => {
        logValueC(typed);
        if (!typed) setPageC(0);
    };

    const handleMenuScrollToBottomC = () => {
        if (pageC < totalPagesCom) {
            Companies();
        }
    };

    const renderOptionC = ({
        focusedOption,
        focusOption,
        key,
        labelKey,
        option,
        selectValue,
        style,
        valueArray,
    }) => {
        // Your rendering logic here
    };

    useEffect(() => {
        if (typeC) {
            Companies();
        }
    }, [typeC]);

    const addOptionsIns = () => {
        axios
            .get(
                `Master/GetInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
                }&pageSize=100`
            )
            .then((response) => {
                console.log(response.data.responseData.orgList);
                const data = response.data.responseData.orgList.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                }));
                setPageIns((prevPage) => prevPage + 1);
                setOptionsIns((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesIns(response.data.responseData.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenIns = () => {
        setPageIns(1);
    };

    const handleChangeIns = (valueIns) => {
        if (valueIns) {
            setValueIns(valueIns.value);
        } else {
            setValueIns(""); // or handle the clear case appropriately
        }

        setPageIns(0);
    };

    const handleInputChangeIns = (typedIns) => {
        logValueIns(typedIns);
        if (!typedIns) setPageIns(0);
    };

    const handleMenuScrollToBottomIns = () => {
        if (pageIns < totalPagesIns) {
            addOptionsIns();
        }
    };

    const renderOptionIns = ({
        focusedOption,
        focusOption,
        key,
        labelKey,
        option,
        selectValue,
        style,
        valueArray,
    }) => {
        // Your rendering logic here
    };

    useEffect(() => {
        if (typedIns) {
            addOptionsIns();
        }
    }, [typedIns]);

    const [valuegnr, setValuegnr] = useState(null);
    const [pagegnr, setPagegnr] = useState(0);
    const [typedgnr, setTypedgnr] = useState("");
    const [optionsgnr, setOptionsgnr] = useState([]);
    const [totalPagesgnr, setTotalPagesgnr] = useState(0);
    const logValuegnr = debounce((typedgnr) => {
        setTypedgnr(typedgnr);
    }, 250);
    console.log("typed", valuegnr);
    const addOptionsgnr = () => {
        axios
            .get(
                `Master/GetAllOrganizationsDDL?search=${typedgnr}&pageNumber=${pagegnr + 1
                }&pageSize=100&DeviceType=Web`
            )
            .then((response) => {
                console.log(response.data.responseData.orgList);
                const data = response.data.responseData.list.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                    typeId: item.typeId,
                    isOrgVrfd: item.isVerified,
                    isOrgRgd: item.isRgstrd,
                }));
                setPagegnr((prevPage) => prevPage + 1);
                setOptionsgnr((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesgnr(response.data.responseData.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpengnr = () => {
        setPagegnr(1);
    };

    const handleChangegnr = (valuegnr) => {
        if (valuegnr) {
            setValuegnr(valuegnr.value);
            setOrgType(valuegnr.typeId)
        } else {
            setValuegnr(""); // or handle the clear case appropriately
            setOrgType("")
        }

        setPagegnr(0);
    };

    const handleInputChangegnr = (typedgnr) => {
        logValuegnr(typedgnr);
        if (!typedgnr) setPagegnr(0);
    };

    const handleMenuScrollToBottomgnr = () => {
        if (pagegnr < totalPagesgnr) {
            addOptionsgnr();
        }
    };

    const renderOptiongnr = ({
        focusedOption,
        focusOption,
        key,
        labelKey,
        option,
        selectValue,
        style,
        valueArray,
    }) => {
        // Your rendering logic here
    };

    useEffect(() => {
        if (typedgnr) {
            addOptionsgnr();
        }
    }, [typedgnr]);

    const getCity = () => {
        axios
            .get(`Master/GetCitiesDDL?search=${citySearch}`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                }));
                setCityData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (citySearch) {
            getCity();
        }
    }, [citySearch]);


    useEffect(() => {
        if (showCertificateUpdate) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showCertificateUpdate]);


    useEffect(() => {
        if (certificateData) {
            setCerName(certificateData?.name);
            setRecognition(certificateData?.recognition);
            setCredentialId(certificateData?.credentialId)
            setStartDate(certificateData.issueDate ? moment(certificateData.issueDate).toDate() : null);
            setEndDate(certificateData.expirationDate ? moment(certificateData.expirationDate).toDate() : null);
            setDescription(certificateData?.description);
            setOrgId(certificateData?.issuingOrgType);
            setOrgType(certificateData?.issuingOrgType)
            setCityId(certificateData?.cityId);
            setCitySearch(certificateData?.cityName)
            setTypeC(certificateData?.orgId)
            setValueC(certificateData?.orgId)
            setValuegnr(certificateData?.orgUid)
            setTypedgnr(certificateData?.orgName)
            setPresent(certificateData?.isExpirable)
        }
    }, [certificateData]);
    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };
    const [showTooltip, setShowTooltip] = useState(false);
    const [OrganizationName, setOrganizationName] = useState("");
    const [InsName, setInsName] = useState("");
    const targetRef = useRef(null);
    const toggleTooltip = () => {
        console.log("Toggling Tooltip");
        setShowTooltip((prev) => !prev);
    };

    const [fileName, setFileName] = useState(""); // State to store selected filename
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            setFileName(file.name); // Set the filename in state
        }
    };



    const [orgCity, setOrgCity] = useState(null);
    const handleSaveInstitution = async () => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            gnrlOrgName: OrganizationName,
            type: 3,
            cityId: cityId,
            logoImage: fileName,
            locationId: null
        }

        try {
            const res = await axios.post("/General/AddGeneralOrgByUser", data);
            console.log("resposne new", res);
            setGenOrgInstId(res.data.responseData.id);
            setnewOrgType(res.data.responseData.value);
            // toast.success(res.data.message);
            setShowTooltip(false);
            setOrganizationName("");
            setOrgCity(null);
            setFileName("");
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // toast.warning(error.response.data.message);
            } else {
                // toast.error(error.response?.data?.message || "An error occurred");
            }
        }
    };
    return (
        <>
            {showCertificateUpdate && (
                <div
                    className="modal fade show modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="setting-header d-flex justify-content-between align-items-center">
                                <h6>Update Certification</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-3 pointer"
                                    onClick={() => {
                                        handleUpdateCertificateClose();
                                    }}
                                />
                            </div>
                            <Form>
                                <div className="row modal-p settings CreateCompany">
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Certificate Name
                                            <span className="text-danger ms-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue={cerName}
                                            onChange={(e) => setCerName(e.target.value)}
                                            invalid={display && cerName === ""}
                                        />
                                        {display && !cerName ? (
                                            <span className="error_msg_lbl">
                                                Enter Certificate Name{" "}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Organization
                                            <span className="text-danger ms-2">*</span>
                                        </label>
                                        <a
                                            href="#"
                                            ref={targetRef}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleTooltip();
                                            }}
                                            className="createModal f-14 ms-2"
                                        // isDisabled={!selectedIndustryId} // Disable until industry is selected
                                        >
                                            Create New
                                        </a>
                                        <Overlay
                                            target={targetRef.current}
                                            show={showTooltip}
                                            placement="bottom"
                                            rootClose
                                            container={document.querySelector(".modal")}
                                            onHide={() => setShowTooltip(false)} // Close when clicking outside
                                        >
                                            <Popover id="popover-basic">
                                                <Popover.Header as="h3" className="Dark-Body-Card-group">Create Organization</Popover.Header>
                                                <Popover.Body className="Dark-Body-Card-group">
                                                    <Form>
                                                        <div>
                                                            <label className="mb-1 Text-mb-Create">Organization name</label>
                                                            <input
                                                                type="text"
                                                                value={OrganizationName}
                                                                onChange={(e) => {
                                                                    setOrganizationName(e.target.value);
                                                                    setInsName(e.target.value);
                                                                }}
                                                                placeholder="Enter Organization name"
                                                                className="mb-2 form-control Dark-Input-from-group"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="mb-1 Text-mb-Create">City</label>
                                                            <Select
                                                                defaultValue={cityId}
                                                                onChange={(selectedOption) =>
                                                                    setCityId(selectedOption.value)
                                                                }
                                                                options={cityData}
                                                                placeholder={"Search and Select City"}
                                                                onInputChange={(inputValue) => {
                                                                    setCitySearch(inputValue);

                                                                }}
                                                                invalid={display && cityId === ""}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                        <div className=" mt-2 mb-2 Text-mb-Create">
                                                            <label className="mb-1">Institute Logo</label>
                                                            <input
                                                                type="file"
                                                                id="lastName"
                                                                placeholder=""
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={handleSaveInstitution}
                                                                className="me-2"
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                variant="secondary"
                                                                size="sm"
                                                                onClick={() => setShowTooltip(false)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Popover.Body>
                                            </Popover>
                                        </Overlay>
                                        <Select
                                            value={optionsgnr.filter((c) => c.value === valuegnr)}
                                            options={optionsgnr}
                                            onOpen={handleOpengnr}
                                            onChange={handleChangegnr}
                                            onMenuScrollToBottom={handleMenuScrollToBottomgnr}
                                            onInputChange={handleInputChangegnr}
                                            styles={customStyles}
                                            isClearable
                                            className="iconsDropdown"
                                            placeholder={"Search and Select General Org"}
                                            formatOptionLabel={(option) => (
                                                <div>
                                                    {option.label}
                                                    {option.isOrgVrfd === true ? (
                                                        <img src={resume.verified} width={15} className="ms-1" />
                                                    ) : option.isOrgRgd === true ? (
                                                        <img src={resume.register} width={15} className="ms-1" />
                                                    ) : (
                                                        <img src={resume.unregister} width={15} className="ms-1" />
                                                    )}
                                                </div>
                                            )}
                                        />
                                        {display && !valuegnr ? (
                                            <span className="error_msg_lbl">
                                                Please Select Organization Type{" "}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Recognition<span className="text-danger ms-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue={recognition}
                                            onChange={(e) => setRecognition(e.target.value)}
                                            invalid={display && recognition === ""}
                                        />
                                        {display && !recognition ? (
                                            <span className="error_msg_lbl">Enter Recognition </span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Credential Id<span className="text-danger ms-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue={credentialId}
                                            onChange={(e) => setCredentialId(e.target.value)}
                                            invalid={display && credentialId === ""}
                                        />
                                        {display && !credentialId ? (
                                            <span className="error_msg_lbl">
                                                Enter Credential Id{" "}
                                            </span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            City<span className="text-danger ms-2">*</span>
                                        </label>
                                        <Select
                                            // defaultValue={cityId}
                                            value={cityData.find((option) => option.value === cityId)}
                                            className="dropdownBorder"
                                            onChange={(selectedOption) =>
                                                setCityId(selectedOption.value)
                                            }
                                            options={cityData}
                                            placeholder={"Search and Select City"}
                                            onInputChange={(inputValue) => {
                                                setCitySearch(inputValue);
                                            }}
                                            invalid={display && cityId === ""}
                                            styles={customStyles}

                                        />
                                        {display && !cityId ? (
                                            <span className="error_msg_lbl">Please Select City </span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Description
                                            {/* <span className="text-danger ms-2">*</span> */}
                                        </label>
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows="4"
                                            cols="50"
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-12 mt-2 d-flex ">
                                        <input
                                            type="checkbox"
                                            style={{ width: "20px", height: "20px", marginRight: '5px' }}
                                            //   id={`check-${item.id}`}
                                            //   checked={selectedWorkLevelList.includes(item.id)}
                                            checked={present}
                                            onChange={() => setPresent(!present)}
                                        />
                                        <label htmlFor="about">IsExpirable</label>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Issue Date<span className="text-danger ms-2">*</span>
                                        </label>

                                        <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                                            maxDate: "today", // Disables future dates
                                            onReady: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Call the function to stop scroll on year
                                            },
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Also prevent scroll when the picker opens
                                            },
                                        }} />
                                        {display && !startDate ? (
                                            <span className="error_msg_lbl">
                                                Please Select Issue Date{" "}
                                            </span>
                                        ) : null}
                                    </div>
                                    {present === true ? <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Expiration Date
                                            {/* <span className="text-danger ms-2">*</span> */}
                                        </label>

                                        <Flatpickr className='form-control' value={endDate} onChange={date => setEndDate(date[0])} id='dob' options={{
                                            onReady: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Call the function to stop scroll on year
                                            },
                                            onOpen: (selectedDates, dateStr, instance) => {
                                                preventYearScroll(instance); // Also prevent scroll when the picker opens
                                            },
                                        }} />
                                    </div> : ''}




                                    <div className="text-end mt-4">
                                        <button
                                            type="button"
                                            className="closebtn me-2"
                                            onClick={handleUpdateCertificateClose}
                                            style={{ width: "60px" }}
                                        >
                                            close
                                        </button>
                                        <button
                                            type="button"
                                            className="mainBtn1"
                                            onClick={UpdateCertification}
                                            style={{ width: "70px" }}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />
            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />
            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default CCVCertificationUpdate;
