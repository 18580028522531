import React, { useEffect, useState } from "react";
import "../../assets/css/Message.css";
import { Button, Card, Col, Modal, NavLink, Row } from "react-bootstrap";
import ChatBox from "./ChatBox";
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { people } from "../../assets/Images/ImagesLink";
import moment from "moment/moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import MessageList from "./MessageList";
import ArchivedMessage from "./ArchivedMessage";
import { ShimmerDiv } from "shimmer-effects-react";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';

const Message = () => {
  const sessionId = localStorage.getItem("sessionId");
  const { UserId } = useParams();
  const [msgId, setMsgId] = useState("");
  const [connectionUser, setConnectionUser] = useState("");
  const [userName, setUserName] = useState("");
  const [chatList, setChatList] = useState("");
  //************************Message List Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [messageList, setmessageList] = useState("");
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState();
  const [archiveActive, setArchiveActive] = useState();
  const [archiveMessage, setArchiveMessage] = useState(false);

  const getMessageList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Message/GetMessageList`, data)
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        const res = response.data.responseData;
        localStorage.setItem(
          "MessageChatId",
          response.data.responseData.chatID
        );

        setmessageList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMessageList();
  }, []);

  //************************Message List End  ************************//

  //************************ Connection  List   ************************//
  const [ConnectionList, setConnectionList] = useState([]);
  const [searchconnectList, setSearchconnectList] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const navigate = useNavigate();
  const [unauthorisedModal, setUnauthorisedModal] = useState(false);
  // console.log('ConnectionList', ConnectionList);
  const ConnectionListAll = async (id) => {
    // console.log('calles');
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 10,
      type: "ConnectionList",
      search: searchUserName,
    };
    try {
      await axios.post("Connection/GetConnectionList", data).then((resData) => {
        setIsLoading(false);
        if (searchUserName !== "") {
          setConnectionList([]);
          setSearchconnectList(resData.data.responseData.viewList);
          console.log("resData.data.responseData.viewList", resData);

        } else {
          setSearchconnectList([]);
          // setConnectionList((prev) => [
          //   ...prev,
          //   ...resData.data.responseData.viewList,
          // ]);
          // const newPosts = resData.data.responseData.viewList;
          // setPostList((prev) => {
          //   const postSet = new Set(prev.map((post) => post.postId));
          //   const uniqueNewPosts = newPosts.filter(
          //     (post) => !postSet.has(post.postId)
          //   );
          //   return [...prev, ...uniqueNewPosts];
          // });
          setConnectionList((prev) => {
            const newViewList = resData?.data?.responseData?.viewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
            );
            return [...prev, ...newViewList];
          });
        }
      });
    } catch (error) {
      if ((error.code === 'ERR_NETWORK' && sessionId) || (error.code === "ERR_BAD_REQUEST" && sessionId)) {
        setUnauthorisedModal(true)
      }
    }
  };

  useEffect(() => {
    if (show) {
      ConnectionListAll();
    }
  }, [show, searchUserName]);

  //************************Connection List ************************//

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const handleSendMessage = (item) => {
    const userExistsInMessageList = messageList.some(
      (message) => message.chatInfo.toUserId === item.connectsWithUserUid
    );
    if (userExistsInMessageList) {
      // Perform the action if user exists in the message list
      // console.log("User exists in the message list");
      // Call the function you need to execute when user exists
    } else {
      setChatList("");
      setConnectionUser(item.connectsWithUserUid);
      setUserName(item.connectsWithUserName);
      setShow(false);
    }
  };

  //************************Archived List Start  ************************//
  const [archivedList, setArchivedList] = useState([]);
  const getArchivedList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Message/GetArchivedMessageList`, data)
      .then((response) => {
        const res = response.data.responseData;
        setArchivedList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getArchivedList();
  }, []);
  //************************Archived List End  ************************//

  //************************Message List Start  ************************//
  const [isLoadingChat, setIsLoadingChat] = useState(true);

  const [chatInfo, setChatInfo] = useState("");
  // console.log('chatList', chatList);
  const getChatList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      // "msgID": msgId,
      toUserUid: UserId,
    };
    localStorage.setItem("toUserUid", data.toUserUid);
    axios
      .post(`Message/GetUserChat`, data)
      .then((response) => {
        // console.log(response);
        setIsLoadingChat(false);
        const res = response.data.responseData.chatList;
        //alert('test');
        var chatId = localStorage.getItem("DeletedChatId");
        setChatList(res);
        setChatInfo(response.data.responseData.messageInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (UserId) {
      getChatList();
    }
  }, [UserId]);

  //************************Message List End  ************************//

  const [showClear, setShowClear] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const [isShown, setIsShown] = useState(true);
  //console.log('isShown', isShown);
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <div className="message-scroller">
              {isLoading ? (
                <div className="mt-3">
                  {/* <ShimmerThumbnail height={250} rounded /> */}
                  <ShimmerDiv mode="light" height={250} width={"100%"} />
                </div>
              ) : (
                messageList &&
                messageList?.map((item, index) => {
                  return (
                    <MessageList
                      showClear={showClear}
                      setShowClear={setShowClear}
                      isShown={isShown}
                      showDelete={showDelete}
                      setShowDelete={setShowDelete}
                      getChatList={getChatList}
                      item={item}
                      archiveMessage={archiveMessage}
                      index={index}
                      active={active}
                      setActive={setActive}
                      UserId={UserId}
                      setMsgId={setMsgId}
                      getArchivedList={getArchivedList}
                      getMessageList={getMessageList}
                      setIsShown={setIsShown}
                    />
                  );
                })
              )}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="message-scroller">
              {isLoading ? (
                <div className="mt-3">
                  {/* <ShimmerThumbnail height={250} rounded /> */}
                  <ShimmerDiv mode="light" height={250} width={"100%"} />
                </div>
              ) : (
                archivedList &&
                archivedList?.map((item, index) => {
                  return (
                    <ArchivedMessage
                      isShown={isShown}
                      getChatList={getChatList}
                      item={item}
                      archiveMessage={archiveMessage}
                      index={index}
                      archiveActive={archiveActive}
                      setArchiveActive={setArchiveActive}
                      setMsgId={setMsgId}
                      getArchivedList={getArchivedList}
                      getMessageList={getMessageList}
                      UserId={UserId}
                    />
                  );
                })
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show]);

  // useEffect(() => {
  //   // If a userUid is present in the URL, show the message container
  //   if (UserId) {
  //     setIsShown(false); // Automatically show the message box when redirected with UserId
  //   }
  // }, [UserId]);
  // const navigate = useNavigate();
  // const handleBackToList = () => {
  //   // Navigate back to the message list by clearing userUid from the URL
  //   navigate('/Message/All');
  //   setIsShown(true)
  // };

  return (
    <>
      <div
        className="row message-wrap"
        style={{
          position: "relative",
          zIndex: showClear || showDelete ? "2" : "1",
        }}
      >
        <h4 onClick={() => setIsShown((current) => !current)} className={`${!isShown ? 'd-md-none d-block' : 'd-none'} back-button`} >Back To List</h4>
        <div className="col-md-4 friends-list pe-0">

          <Card className={`${isShown ? "d-block" : "d-md-block d-none"
            } main-card`}>
            <div
              className={`${isShown ? "d-flex" : " d-md-flex d-none"
                } chatListCont d-flex  p-3 align-items-center justify-content-between`}
            >
              <h2 className="f-20 f-600">Message </h2>
              <button
                className="apply-btn newBtn d-flex align-items-center"
                onClick={() => {
                  setShow(true);
                }}
              >
                <AddSharpIcon className="material-symbols-sharp" />
                New
              </button>
            </div>
            <div className="tabButton d-flex p-3 w-100 ms-2 connectionTab">
              <NavLink
                onClick={() => {
                  setActiveTab(1);
                  setArchiveMessage(false);
                }}
                className={`text-center ${activeTab === 1 ? "activeTab" : ""}`}
              >
                <p className="f-14 tabName" onClick={getMessageList}>All</p>
              </NavLink>
              <NavLink
                onClick={() => {
                  setActiveTab(2);
                  setArchiveMessage(true);
                }}
                className={`text-center ${activeTab === 2 ? "activeTab" : ""}`}
              >
                <p className="f-14 tabName" onClick={getArchivedList}>Archived</p>
              </NavLink>
            </div>
            <div className="resume-section">{renderTabContent()}</div>
          </Card>
        </div>
        {console.log("chatList", chatList, UserId)}

        <div
          className={`${!isShown ? "d-block" : "d-md-block d-none"
            } col-md-8 messageBox ps-0 `
          }
          style={{ position: "relative", zIndex: "-1" }}
        >
          {UserId === "unknown" ? (
            <div className=""></div>
          ) : (
            <ChatBox
              isShown={isShown}
              isLoadingChat={isLoadingChat}
              chatInfo={chatInfo}
              getChatList={getChatList}
              userName={userName}
              chatList={chatList}
              setChatList={setChatList}
              UserId={UserId}
              msgId={msgId}
              connectionUser={connectionUser}
              getMessageList={getMessageList}
              setMsgId={setMsgId}
            />
          )}
        </div>
      </div>

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>My Connections</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </div>
              <Row>
                <Col md="12 notification-wrap connection-card">
                  <div className="mb-4">
                    <input
                      type="email"
                      className="form-control p-2"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Search user"
                      onChange={(e) => {
                        setSearchUserName(e.target.value);
                      }}
                      value={searchUserName}
                    />
                  </div>
                  {searchconnectList.length === 0
                    ? ConnectionList &&
                    ConnectionList.map((item, index) => (
                      // console.log('ConnectionList', item),
                      <Card className="mb-1" key={index}>
                        <Card.Body className="py-1">
                          <div className="d-lg-flex d-block align-items-center justify-content-between">
                            <img
                              src={
                                item.profilPic === ""
                                  ? people.DefaultProfile
                                  : item.profilPic
                              }
                              alt=""
                              height={40}
                              width={40}
                              style={{ borderRadius: "50px" }}
                              className="Company-card-Img"
                            />
                            <div className="ms-3">
                              <h6 className="notification-message mb-1">
                                {item.fullName}
                              </h6>
                              <p className="lastseen">{item.title}</p>
                              <p className="lastseen">{item.headLine}</p>
                              <p className="lastseen">
                                {item.followersCount} followers
                              </p>
                            </div>
                            <div className="ms-auto">
                              <Link
                                to={`/Message/${item.userUid}`}
                                onClick={() => {
                                  setShow(false);
                                }}
                              >
                                <button
                                  type="submit"
                                  className="wljSecondaryBtn d-flex align-items-center"
                                >
                                  <MailOutlineSharpIcon className="material-symbols-sharp" />{" "}
                                  Message
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))
                    : searchconnectList &&
                    searchconnectList.map((item, index) => (
                      // console.log('ConnectionList', item),
                      <Card className="mb-1" key={index}>
                        <Card.Body className="py-1">
                          <div className="d-lg-flex d-block align-items-center justify-content-between">
                            <img
                              src={
                                item.profilPic === ""
                                  ? people.DefaultProfile
                                  : item.profilPic
                              }
                              alt=""
                              height={40}
                              width={40}
                              style={{ borderRadius: "50px" }}
                              className="Company-card-Img"
                            />
                            <div className="ms-3">
                              <h6 className="notification-message mb-1">
                                {item.fullName}
                              </h6>
                              <p className="lastseen">{item.title}</p>
                              <p className="lastseen">{item.headLine}</p>
                              <p className="lastseen">
                                {item.followersCount} followers
                              </p>
                            </div>
                            <div className="ms-auto">
                              <Link
                                to={`/Message/${item.userUid}`}
                                onClick={() => {
                                  setShow(false);
                                }}
                              // onClick={() => handleSendMessage(item)}
                              >
                                <button
                                  type="submit"
                                  className="wljSecondaryBtn d-flex align-items-center"
                                >
                                  <MailOutlineSharpIcon className="material-symbols-sharp" />{" "}
                                  Message
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
      {unauthorisedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                  <h4 className="text-center AdBlocker">Warning!</h4>
                  <p className="text-center ad-p">
                    Your session has expired or you are not authorized to access this resource. Please log in again to continue.
                  </p>
                  <div className="text-center m-auto mt-3">
                    <button
                      type="button"
                      className="mainBtn1 "
                      onClick={() => {
                        setUnauthorisedModal(!unauthorisedModal)
                        navigate("/SignIn");
                        localStorage.clear();
                        sessionStorage.removeItem('hasModalShown');
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Message;