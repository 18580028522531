
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Button, Overlay, Popover } from "react-bootstrap";
const CCVAwardUpdate = ({
    showAwardUpdate,
    handleUpdateAwardClose,
    showAwardData,
    getUserCcv,
    resumeId
}) => {
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    console.log('showAwardData', showAwardData);
    const sessionId = localStorage.getItem("sessionId");
    const [cerName, setCerName] = useState('');
    const [recognition, setRecognition] = useState("");
    const [startDate, setStartDate] = useState("");
    const [description, setDescription] = useState("");
    const [orgType, setOrgType] = useState("");

    const [cityData, setCityData] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [citySearch, setCitySearch] = useState("");

    const [display, setDisplay] = useState(false);

    const getCity = () => {
        axios
            .get(`Master/GetCitiesDDL?search=${citySearch}`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                }));
                setCityData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (citySearch) {
            getCity();
        }
    }, [citySearch]);

    const [orgData, setOrgData] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const getOrgType = () => {
        axios
            .get(`Master/GetOrgTypesDDL`)
            .then((response) => {
                const res = response.data.responseData;
                const data = res.map((item) => ({ value: item.id, label: item.name }));
                setOrgData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getOrgType();
    }, []);

    const [valueC, setValueC] = useState(null);
    const [pageC, setPageC] = useState(0);
    const [typeC, setTypeC] = useState("");
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [optionsC, setOptionsC] = useState([]);
    const [totalPagesCom, setTotalPagesCom] = useState(0);

    const logValueC = debounce((typeC) => {
        setTypeC(typeC);
    }, 250);

    const Companies = () => {
        setIsLoadingC(true);
        axios
            .get(
                `Master/GetCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
                }&pageSize=100`
            )
            .then((response) => {
                console.log(response);
                const data = response?.data?.responseData?.orgList.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                }));
                setPageC((prevPage) => prevPage + 1);
                setIsLoadingC(false);
                setOptionsC((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesCom(response.data.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingC(false);
            });
    };

    const handleOpenC = () => {
        setPageC(1);
    };

    const handleChangeC = (value) => {
        setValueC(value.value);
        setPageC(0);
    };

    const handleInputChangeC = (typed) => {
        logValueC(typed);
        if (!typed) setPageC(0);
    };

    const handleMenuScrollToBottomC = () => {
        if (pageC < totalPagesCom) {
            Companies();
        }
    };
    useEffect(() => {
        if (typeC) {
            Companies();
        }
    }, [typeC]);
    //********Institution DDL Start  ********//
    const [valueIns, setValueIns] = useState(null);
    const [pageIns, setPageIns] = useState(0);
    const [typedIns, setTypedIns] = useState("");
    const [optionsIns, setOptionsIns] = useState([]);
    const [totalPagesIns, setTotalPagesIns] = useState(0);
    const logValueIns = debounce((typedIns) => {
        setTypedIns(typedIns);
    }, 250);
    // console.log('typed', typed);
    const addOptionsIns = () => {
        axios
            .get(
                `Master/GetInstitutionsDDL?search=${typedIns}&pageNumber=${pageIns + 1
                }&pageSize=100`
            )
            .then((response) => {
                console.log(response.data.responseData.orgList);
                const data = response.data.responseData.orgList.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                }));
                setPageIns((prevPage) => prevPage + 1);
                setOptionsIns((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesIns(response.data.responseData.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenIns = () => {
        setPageIns(1);
    };

    const handleChangeIns = (valueIns) => {
        if (valueIns) {
            setValueIns(valueIns.value);
        } else {
            setValueIns(""); // or handle the clear case appropriately
        }

        setPageIns(0);
    };

    const handleInputChangeIns = (typedIns) => {
        logValueIns(typedIns);
        if (!typedIns) setPageIns(0);
    };

    const handleMenuScrollToBottomIns = () => {
        if (pageIns < totalPagesIns) {
            addOptionsIns();
        }
    };

    useEffect(() => {
        if (typedIns) {
            addOptionsIns();
        }
    }, [typedIns]);

    //********General Org DDL Start  ********//
    const [valuegnr, setValuegnr] = useState(null);
    const [pagegnr, setPagegnr] = useState(0);
    const [typedgnr, setTypedgnr] = useState("");
    const [optionsgnr, setOptionsgnr] = useState([]);
    const [totalPagesgnr, setTotalPagesgnr] = useState(0);
    const logValuegnr = debounce((typedgnr) => {
        setTypedgnr(typedgnr);
    }, 250);
    console.log("typed", valuegnr);
    const addOptionsgnr = () => {
        axios
            .get(
                `Master/GetAllOrganizationsDDL?search=${typedgnr}&pageNumber=${pagegnr + 1
                }&pageSize=100&DeviceType=Web`
            )
            .then((response) => {
                console.log(response.data.responseData.orgList);
                const data = response.data.responseData.list.map((item) => ({
                    value: item.uiDno,
                    label: item.name,
                    typeId: item.typeId,
                    isOrgVrfd: item.isVerified,
                    isOrgRgd: item.isRgstrd,
                }));
                setPagegnr((prevPage) => prevPage + 1);
                setOptionsgnr((prevOptions) => [...prevOptions, ...data]);
                setTotalPagesgnr(response.data.responseData.paging.totalPages);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpengnr = () => {
        setPagegnr(1);
    };

    const handleChangegnr = (valuegnr) => {
        if (valuegnr) {
            setValuegnr(valuegnr.value);
            setOrgType(valuegnr.typeId)
        } else {
            setValuegnr(""); // or handle the clear case appropriately
            setOrgType("")
        }

        setPagegnr(0);
    };

    const handleInputChangegnr = (typedgnr) => {
        logValuegnr(typedgnr);
        if (!typedgnr) setPagegnr(0);
    };

    const handleMenuScrollToBottomgnr = () => {
        if (pagegnr < totalPagesgnr) {
            addOptionsgnr();
        }
    };
    useEffect(() => {
        if (typedgnr) {
            addOptionsgnr();
        }
    }, [typedgnr]);
    const [getOrgInsTId, setGenOrgInstId] = useState('')
    const [newOrgType, setnewOrgType] = useState(null);
    const UpdateAward = async () => {
        setDisplay(true);
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            rsmId: resumeId,
            name: cerName,
            recognition: recognition,
            issuingOrgType: orgType === '' ? 4 : orgType,
            orgId: valuegnr === null ? JSON.stringify(getOrgInsTId) : valuegnr,
            description: description,
            issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
            // "locationId": valueLocation === '' ? '' : valueLocation,
            locationId: null,
            cityId: cityId === "" ? "" : cityId,
            rsmAwardId: showAwardData?.rsmAwrdId,
        };
        const data1 = {
            deviceType: "Web",
            sessionId: sessionId,
            name: cerName,
            recognition: recognition,
            issuingOrgType: orgType === '' ? 4 : orgType,
            orgId: valuegnr === null ? JSON.stringify(getOrgInsTId) : valuegnr,
            description: description,
            issueDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
            // "locationId": valueLocation === '' ? '' : valueLocation,
            locationId: null,
            cityId: cityId === "" ? "" : cityId,
            userAwardId: showAwardData?.userAwardId,
        };
        const apiUrl = showAwardData.isSyncedWithUserData
            ? `UserData/UpdateAwardInfo`
            : `ResumeData/UpdateAwardInfo`;

        const payload = showAwardData.isSyncedWithUserData ? data1 : data;
        try {
            await axios.put(apiUrl, payload).then((resData) => {
                console.log("resData award", resData);
                // toast.success(resData.data.message);
                setToastMessage(resData.data.message);
                setShowToast(true);
                getUserCcv();
                handleUpdateAwardClose();
                // addReset()
            });
        } catch (error) {
            console.log(error);
            // toast.error(error.response.data.message);
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };


    useEffect(() => {
        if (showAwardUpdate) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showAwardUpdate]);


    useEffect(() => {
        if (showAwardData) {
            setCerName(showAwardData?.name);
            setRecognition(showAwardData?.recognition);
            setStartDate(showAwardData.issueDate ? moment(showAwardData.issueDate).toDate() : null);
            setDescription(showAwardData?.description);
            setOrgId(showAwardData?.issuerOrgType);
            setOrgType(showAwardData?.issuerOrgType);
            setCityId(showAwardData?.cityId);
            setCitySearch(showAwardData?.cityName)
            setTypeC(showAwardData?.orgId)
            setValueC(showAwardData?.orgId)
            setValuegnr(showAwardData?.orgUid)
            setTypedgnr(showAwardData?.orgName)
        }
    }, [showAwardData]);
    // console.log('showAwardData', cityId);

    const preventYearScroll = (datePickerInstance) => {
        const yearElement = datePickerInstance.currentYearElement;
        if (yearElement) {
            yearElement.addEventListener("wheel", (e) => {
                e.preventDefault(); // Stops mouse scroll
            });
        }
    };

    const [showTooltip, setShowTooltip] = useState(false);
    const [OrganizationName, setOrganizationName] = useState("");
    const [InsName, setInsName] = useState("");
    const targetRef = useRef(null);
    const toggleTooltip = () => {
        console.log("Toggling Tooltip");
        setShowTooltip((prev) => !prev);
    };

    const [fileName, setFileName] = useState(""); // State to store selected filename
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            setFileName(file.name); // Set the filename in state
        }
    };



    const [orgCity, setOrgCity] = useState(null);
    const handleSaveInstitution = async () => {
        // setDisplay(true);

        // const formData = new FormData();
        // formData.append("orgName", OrganizationName);
        // formData.append("OrgType", "UnRegdInstitution");
        // formData.append("CityID", orgCity);
        // formData.append("OrgLogo", fileName);
        // formData.append("sessionId", sessionId);
        // console.log("formData", formData);

        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            gnrlOrgName: OrganizationName,
            type: 3,
            cityId: cityId,
            logoImage: fileName,
            locationId: null
        }

        try {
            const res = await axios.post("/General/AddGeneralOrgByUser", data);
            console.log("resposne new", res);
            setGenOrgInstId(res.data.responseData.id);
            setnewOrgType(res.data.responseData.value);
            // toast.success(res.data.message);
            setShowTooltip(false);
            setOrganizationName("");
            setOrgCity(null);
            setFileName("");
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // toast.warning(error.response.data.message);
            } else {
                // toast.error(error.response?.data?.message || "An error occurred");
            }
        }
    };

    return (
        <>
            {showAwardUpdate && (
                <div
                    className="modal fade show modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="setting-header d-flex justify-content-between align-items-center">
                                <h6>Update Award</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-3 pointer"
                                    onClick={() => {
                                        handleUpdateAwardClose();
                                        // addReset()
                                    }}
                                />
                            </div>
                            <Form>
                                <div className="row modal-p settings CreateCompany">
                                    <div className="col-md-6 mt-2 ">
                                        <label htmlFor="about">
                                            Award Name<span className="text-danger ms-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={cerName}
                                            onChange={(e) => setCerName(e.target.value)}
                                            invalid={display && cerName === ""}
                                        />
                                        {display && !cerName ? (
                                            <span className="error_msg_lbl">Enter Award Name </span>
                                        ) : null}
                                    </div>


                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Organization
                                            <span className="text-danger ms-2">*</span>
                                        </label>
                                        <a
                                            href="#"
                                            ref={targetRef}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleTooltip();
                                            }}
                                            className="createModal f-14 ms-2"
                                        // isDisabled={!selectedIndustryId} // Disable until industry is selected
                                        >
                                            Create New
                                        </a>
                                        <Overlay
                                            target={targetRef.current}
                                            show={showTooltip}
                                            placement="bottom"
                                            rootClose
                                            container={document.querySelector(".modal")}
                                            onHide={() => setShowTooltip(false)} // Close when clicking outside
                                        >
                                            <Popover id="popover-basic">
                                                <Popover.Header as="h3" className="Dark-Body-Card-group">Create Organization</Popover.Header>
                                                <Popover.Body className="Dark-Body-Card-group">
                                                    <Form>
                                                        <div>
                                                            <label className="mb-1 Text-mb-Create">Organization name</label>
                                                            <input
                                                                type="text"
                                                                value={OrganizationName}
                                                                onChange={(e) => {
                                                                    setOrganizationName(e.target.value);
                                                                    setInsName(e.target.value);
                                                                }}
                                                                placeholder="Enter Organization name"
                                                                className="mb-2 form-control Dark-Input-from-group"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="mb-1 Text-mb-Create">City</label>
                                                            <Select
                                                                defaultValue={cityId}
                                                                onChange={(selectedOption) =>
                                                                    setCityId(selectedOption.value)
                                                                }
                                                                options={cityData}
                                                                placeholder={"Search and Select City"}
                                                                onInputChange={(inputValue) => {
                                                                    setCitySearch(inputValue);

                                                                }}
                                                                invalid={display && cityId === ""}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                        <div className=" mt-2 mb-2 Text-mb-Create">
                                                            <label className="mb-1">Institute Logo</label>
                                                            <input
                                                                type="file"
                                                                id="lastName"
                                                                // className="form-control Dark-Input-from-group"
                                                                placeholder=""
                                                                onChange={handleFileChange}
                                                            //   onChange={(e) => {
                                                            //     handleProfileImageChange(e);
                                                            //   }}
                                                            //   invalid={displays && profileImage === ""}
                                                            />
                                                            {/* {displays && !profileImage ? (
                          <span className="error_msg_lbl text-red">
                            Upload Image{" "}
                          </span>
                        ) : null} */}
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={handleSaveInstitution}
                                                                className="me-2"
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                variant="secondary"
                                                                size="sm"
                                                                onClick={() => setShowTooltip(false)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Popover.Body>
                                            </Popover>
                                        </Overlay>
                                        <Select
                                            value={optionsgnr.filter((c) => c.value === valuegnr)}
                                            options={optionsgnr}
                                            onOpen={handleOpengnr}
                                            onChange={handleChangegnr}
                                            onMenuScrollToBottom={handleMenuScrollToBottomgnr}
                                            onInputChange={handleInputChangegnr}
                                            styles={customStyles}
                                            isClearable
                                            className="iconsDropdown"
                                            placeholder={"Search and Select General Org"}
                                            formatOptionLabel={(option) => (
                                                <div>
                                                    {option.label}
                                                    {option.isOrgVrfd === true ? (
                                                        <img src={resume.verified} width={15} className="ms-1" />
                                                    ) : option.isOrgRgd === true ? (
                                                        <img src={resume.register} width={15} className="ms-1" />
                                                    ) : (
                                                        <img src={resume.unregister} width={15} className="ms-1" />
                                                    )}
                                                </div>
                                            )}
                                        />
                                        {display && !valuegnr ? (
                                            <span className="error_msg_lbl">
                                                Please Select Organization Type{" "}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Recognition
                                        </label>
                                        <input
                                            type="text"
                                            value={recognition}
                                            onChange={(e) => setRecognition(e.target.value)}
                                        // invalid={display && recognition === ""}
                                        />
                                        {/* {display && !recognition ? (
                      <span className="error_msg_lbl">Enter Recognition </span>
                    ) : null} */}
                                    </div>

                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            City<span className="text-danger ms-2">*</span>
                                        </label>
                                        <Select
                                            // defaultValue={cityId}
                                            value={cityData.find((option) => option.value === cityId)}
                                            className="dropdownBorder"
                                            onChange={(selectedOption) =>
                                                setCityId(selectedOption.value)
                                            }
                                            options={cityData}
                                            placeholder={"Search and Select City"}
                                            onInputChange={(inputValue) => {
                                                setCitySearch(inputValue);
                                            }}
                                            styles={customStyles}
                                            invalid={display && cityId === ""}
                                        />
                                        {display && !cityId ? (
                                            <span className="error_msg_lbl">Enter City </span>
                                        ) : null}
                                    </div>


                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="about">
                                            Issue Date<span className="text-danger ms-2">*</span>
                                        </label>

                                        <Flatpickr className='form-control' value={startDate}
                                            onChange={date => setStartDate(date[0])} id='dob'
                                            options={{
                                                maxDate: "today", // Disables future dates
                                                onReady: (selectedDates, dateStr, instance) => {
                                                    preventYearScroll(instance); // Call the function to stop scroll on year
                                                },
                                                onOpen: (selectedDates, dateStr, instance) => {
                                                    preventYearScroll(instance); // Also prevent scroll when the picker opens
                                                },
                                            }} />
                                        {display && !startDate ? (
                                            <span className="error_msg_lbl">
                                                Please Select Issue Date{" "}
                                            </span>
                                        ) : null}

                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">
                                            Description
                                        </label>
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows="4"
                                            cols="50"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        // invalid={display && description === ""}
                                        ></textarea>
                                        {/* {display && !description ? (
                      <span className="error_msg_lbl">Enter Description </span>
                    ) : null} */}
                                    </div>

                                    <div className="text-end mt-4">
                                        <button
                                            type="button"
                                            className="closebtn me-2"
                                            onClick={handleUpdateAwardClose}
                                            style={{ width: "60px" }}
                                        >
                                            close
                                        </button>
                                        <button
                                            type="button"
                                            className="mainBtn1"
                                            onClick={UpdateAward}
                                            style={{ width: "70px" }}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default CCVAwardUpdate;
