import { useEffect, useRef } from "react";

const Advertisement = () => {
    const adRef = useRef(null);

    useEffect(() => {
        // Check if the ad script is loaded and if the current ad instance is not already initialized
        if (adRef.current && window.adsbygoogle && !adRef.current.isInitialized) {
            try {
                window.adsbygoogle.push({});
                adRef.current.isInitialized = true; // Mark this ad as initialized
            } catch (error) {
                console.error("Ad initialization error:", error);
            }
        }

        const handleAdClick = (event) => {
            // Check if the clicked target is a link within the ads
            const target = event.target.closest('a');
            if (target && target.getAttribute('target') === '_top') {
                event.preventDefault(); // Prevent the default action
                target.setAttribute('target', '_blank');
                target.setAttribute('rel', 'noopener noreferrer'); // For security
                window.open(target.href, '_blank'); // Open the link in a new tab
            }
        };

        // Add event listener to the document for delegated handling
        document.addEventListener('click', handleAdClick);

        // Cleanup
        return () => {
            document.removeEventListener('click', handleAdClick);
        };
    }, []);

    return (
        <div className="advertisementview mb-3">
            <div
                className="advertisementContainer"
                style={{
                    width: "100%",
                    height: "300px",
                    overflow: "hidden",
                    margin: "20px auto",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <ins
                    className="adsbygoogle"
                    style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        target: '_blank',
                    }}
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-8691062301030113"
                    data-ad-slot="7349331462"
                    data-full-width-responsive="true"
                    ref={adRef}
                ></ins>
            </div>
        </div>
    );
};

export default Advertisement;

