import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
// import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { NewsFeed, resume } from "../../assets/Images/ImagesLink";
import AddSkill from "./AddSkill";
import { useParams } from "react-router-dom";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import UpdateSkills from "./UpdateSkills";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const Skills = ({ skillList, setSkillId, skillId, userData, showUndoEndorseModal, setShowUndoEndorseModal, setShowEndorseModal, showEndorseModal }) => {
  const { uid } = useParams();
  const [showAddSkill, setShowAddSkill] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleOpenAddSkill = () => setShowAddSkill(true);
  const handleCloseAddSkill = () => setShowAddSkill(false);

  const userUid = localStorage.getItem("userUid");
  const sessionId = localStorage.getItem("sessionId");

  //************************User Skill Get Start  ************************//
  // const [skillList, setSkillList] = useState([]);

  // const getUserSkill = () => {
  //   axios
  //     .post(`UserSkill/GetUserLinkedSkills?DeviceType=Web&UID=${uid}`)
  //     .then((response) => {
  //       const res = response.data.responseData;
  //       setSkillList(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // useEffect(() => {
  //   getUserSkill();
  // }, []);

  //************************Remove Skill start  ************************//
  const [show, setShow] = useState(false);
  const handleRemoveSkillClose = () => {
    setShow(false);
  };
  // const [skillId, setSkillId] = useState("");

  const removeSkills = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      usrSkllId: skillId,
      // skllId: skillId
    };
    try {
      await axios.post(`UserSkill/UnlinkSkill`, responseData).then((res) => {
        setShow(!show);
        // toast.success("Skill Deleted");
        console.log(res);
        setToastMessage(res.data.message);
        setShowToast(true);
        // getUserSkill();
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const [skillsData, setSkillsData] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const handleUpdateSkillClose = () => {
    setShowSkills(false);
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show]);

  return (
    <>
      <div className="skills-container-fluid">
        <div className="UpdateProfile mb-3 p-3 pt-0">
          <div className="d-flex justify-content-end mb-4">

          </div>

          {skillList &&
            skillList?.map((item, index) => {
              const isLastItem = index === skillList.length - 1;
              return (
                // <div
                //   className={`analytic-sub mt-2 mb-1 ${isLastItem ? '' : 'border-custom'} pb-3`}
                //   key={index}
                // >
                //   <div className="d-flex justify-content-between mb-2">
                //     <div className="d-flex align-items-center verified ">
                //       <p className="f-14 ms-1 pb-0 fw-bold  me-2">
                //         {item.skillName}
                //       </p>
                //       {item?.isVerified === true ? (
                //         <span className="check material-symbols-sharp">
                //           check_circle
                //         </span>
                //       ) : (
                //         <span className=" material-symbols-sharp">
                //           check_circle
                //         </span>
                //       )}
                //     </div>
                //     {userData?.profileInfo?.isOwn && (
                //       <div className="d-flex align-items-center updateActions">
                //         <span
                //           className="material-symbols-sharp pointer me-2"
                //           onClick={() => {
                //             setShowSkills(!showSkills);
                //             setSkillsData(item);
                //           }}
                //         >
                //           edit
                //         </span>
                //         <span
                //           className="material-symbols-sharp pointer delete-icon"
                //           onClick={() => {
                //             setShow(!show);
                //             setSkillId(item.usrSkllId);
                //           }}
                //         >
                //           delete
                //         </span>
                //       </div>
                //     )}
                //   </div>
                //   <div className="d-flex align-items-start">
                //     {/* <img
                //     src={NewsFeed.companyLogo}
                //     alt=""
                //     width={20}
                //     height={20}
                //   /> */}
                //     <div className="ms-2">
                //       <p className="ms-1 f-12">
                //         {/* Frontend Web Developer at HyperautoX Information
                //       Technology LLC */}
                //         {item.endorsedCount} endorsement
                //       </p>
                //     </div>
                //   </div>
                //   {uid !== userUid && (
                //     <div className="endorseBtn mt-3 text-start">
                //       {item.isEndorsedByYou === true ? (
                //         <button
                //           className="button followStyle"
                //           onClick={() => {
                //             setShowUndoEndorseModal(!showUndoEndorseModal);
                //             setSkillId(item.usrSkllId);
                //           }}
                //           style={{ width: '100px' }}
                //         >Endorsed
                //         </button>
                //       ) : (
                //         <button
                //           className="button followStyle"
                //           onClick={() => {
                //             setShowEndorseModal(!showEndorseModal);
                //             setSkillId(item.usrSkllId);
                //           }}
                //         >
                //           Endorse
                //         </button>
                //       )}
                //     </div>
                //   )}
                // </div>
                <div
                  className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                    } pb-3`}
                  key={index}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center verified ">
                      <p className="f-14 ms-1 pb-0 fw-bold  me-2">
                        {item.skillName}
                      </p>
                    </div>
                    {userData?.profileInfo?.isOwn && (
                      <div className="d-flex align-items-center updateActions">
                        {item?.isSyncedWithResume && <img src={resume.synced} width={15} className="me-2" />}
                        <EditSharpIcon
                          className="material-symbols-sharp pointer me-2 ms-2"
                          onClick={() => {
                            setShowSkills(!showSkills);
                            setSkillsData(item);
                          }}
                        />
                        <DeleteOutlineSharpIcon
                          className="material-symbols-sharp pointer delete-icon"
                          onClick={() => {
                            setShow(!show);
                            setSkillId(item.usrSkllId);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <p className="ms-1 f-12 text-start">
                    {item.firmIndustryTypeName}
                  </p>
                  {item.endorsedCount && <p className="ms-1 f-12">
                    {/* Frontend Web Developer at HyperautoX Information
                      Technology LLC */}
                    {item.endorsedCount} endorsement
                  </p>}
                  {uid !== userUid && (
                    <div className="endorseBtn mt-3">
                      {item.isEndorsedByYou === true ? (
                        <button
                          className="button followStyle"
                          onClick={() => {
                            setShowUndoEndorseModal(!showUndoEndorseModal);
                            setSkillId(item.usrSkllId);
                          }}
                          style={{ width: "100px" }}
                        >
                          Endorsed
                        </button>
                      ) : (
                        <button
                          className="button followStyle"
                          onClick={() => {
                            setShowEndorseModal(!showEndorseModal);
                            setSkillId(item.usrSkllId);
                          }}
                        >
                          Endorse
                        </button>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <AddSkill
        show={showAddSkill}
        handleClose={handleCloseAddSkill}
      // getUserSkill={getUserSkill}
      />

      {/* <Modal
        show={show}
        size="md"
        centered
        onHide={handleRemoveSkillClose}
        toggle={() => setShow(!show)}
        // className='modal-dialog-centered m'
      >
        <Modal.Header
          className="bg-transparent p-4"
          closeButton
          toggle={() => {
            setShow(!show);
          }}
        >
          <h6 className="f-16 fw-bold">
            Are You Sure You want to delete skill?
          </h6>
        </Modal.Header>
        <Modal.Body className="text-center">
          <button color="" className="closebtn" onClick={() => removeSkills()}>
            Delete
          </button>
        </Modal.Body>
      </Modal> */}

      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete skill</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveSkillClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeSkills()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
      <UpdateSkills
        showSkills={showSkills}
        handleUpdateSkillClose={handleUpdateSkillClose}
        userUId={userUid}
        // getUserProfile={getUserProfile}
        skillsData={skillsData}
      />
    </>
  );
};

export default Skills;
