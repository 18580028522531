

import React, { useState, useEffect } from "react";
import "../../assets/css/Blogs.css";
import { blogs } from "../../assets/Images/ImagesLink";
import BlogCard from "./BlogCard";
import BlogsNav from "./BlogsNav";

const Blogs = () => {
  const [tab, setTab] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    // Cleanup script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="blogContainer">
      <div className="blogTab">
        <BlogsNav setTab={setTab} tab={tab} />
      </div>
      <div className="blogCard">
        <BlogCard tab={tab} />
      </div>
    </div>
  );
};

export default Blogs;

