import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom'
import axios from '../../API/axios';
import toast from 'react-hot-toast';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ErrorToast from '../ErrorToast';
import WarningToast from '../WarningToast';
import SuccessToast from '../SuccessToast';
const CCVRole = ({ showCCVRole, handleCloseCCVRole, ExperienceId, getUserCcv }) => {
    const sessionId = localStorage.getItem("sessionId");
    const [role, setRole] = useState('')
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const AddRole = async () => {
        const data =
        {
            "deviceType": "Web",
            "sessionId": sessionId,
            "ccvExpId": ExperienceId,
            "role": role
        }
        try {
            await axios.post(`CcvAction/AddCcvExperienceRole`, data).then((resData) => {
                console.log('resData', resData);
                // toast.success(resData.data.message)
                setToastMessage(resData.data.message);
                setShowToast(true);
                getUserCcv()
                handleCloseCCVRole()
                setRole('')
            });
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 404) {
                setWarningToastMessage(error.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(error.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    useEffect(() => {
        if (showCCVRole) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showCCVRole]);
    return (
        <>
            {showCCVRole && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className='setting-header d-flex justify-content-between align-items-center'>
                                <h6>Add Role</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer" onClick={handleCloseCCVRole}
                                />
                            </div>
                            <Form>
                                <div className='row p-4 settings CreateCompany'>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Role</label>
                                        <input type='text'
                                            value={role}
                                            onChange={e => setRole(e.target.value)}
                                        />
                                    </div>

                                    <div className='text-end mt-4'>
                                        <button type="button" className="mainBtn1"
                                            onClick={AddRole}
                                            style={{ width: '140px' }}
                                        >Add Role</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )
            }
            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default CCVRole