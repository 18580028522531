
import React, { useEffect, useRef, useState } from 'react'
import { post } from '../../assets/Images/ImagesLink'
// import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import axios from '../../API/axios';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import SuccessToast from '../SuccessToast';
import WarningToast from '../WarningToast';
import ErrorToast from '../ErrorToast';
import { Link } from 'react-router-dom';
import locationIcon from "../../assets/Images/connection/locationIcon.png";
import userAvatar from "../../assets/Images/connection/Group 647.png";
import { ShimmerDiv } from 'shimmer-effects-react';
import { set } from 'lodash';
import { Dropdown } from "react-bootstrap";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';

const Follows = ({ uid, getUserProfileStat, userData }) => {
    const sessionId = localStorage.getItem("sessionId");
    //************************ Follower  List   ************************//
    const [isError, setIsError] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [FollowerList, setFollowerList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [nextBatchLoading, setNextBatchLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    console.log('FollowerList', FollowerList);
    const FollowerListAll = async (id) => {
        const data = {
            deviceType: "Web",
            pageNumber: page,
            pageSize: 20,
            userUid: uid
        };
        setNextBatchLoading(true);
        try {
            await axios.post(`UserFollow/GetUserEntityFollowingList?DeviceType=Web&Type=Company&UserUid=${uid}`, data).then((resData) => {
                // setFollowerList(resData.data.responseData.viewList);
                console.log("connect data", resData.data.message);


                setIsLoading(false);

                if (resData.data.status === "Warning") {
                    setIsError(true);
                } else {
                    setFollowerList(resData?.data?.responseData);
                }
                setNextBatchLoading(false);
                if (page >= resData.data.responseData.paging.totalPages) {
                    setHasMoreData(false);
                }
                setTotalPages(resData.data.responseData.paging.totalPages);

            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsError(true);
            } else {
                console.log(error);
            }
            setIsLoading(false);
            setNextBatchLoading(false);
        }
    };

    useEffect(() => {
        if (page <= totalPages) {
            FollowerListAll();
        }
    }, [page]);

    //************************Follower List ************************//
    //************************ Follow User Start  ************************//
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const FollowUser = async (id) => {
        const data = {
            deviceType: "Web",
            sessionId: sessionId,
            userUID: id,
        };
        try {
            await axios.post("UserFollow/FollowUser", data).then((resData) => {
                // console.log("resDataaaaa", resData);
                setFollowerList((prev) => prev.filter((item) => item.userUid !== id));
                getUserProfileStat()
                setToastMessage(resData.data.message);
                setShowToast(true);
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };
    //************************ Follow User End  ************************//


    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [unfollow, setUnfollow] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    console.log('selectedUserId', selectedUserId, unfollow);

    const unFollowUser = async (id) => {
        const responseData = {
            deviceType: "Web",
            sessionId: sessionId,
            uiD_ID: selectedUserId,
        };
        console.log("unfllow new res", responseData);
        try {
            await axios.post(`Entity/Unfollow/Company`, responseData).then((res) => {
                // toast.success("User Unfollowed");
                // console.log("res", res);
                setToastMessage(res.data.message);
                setShowToast(true);
                setFollowerList((prev) => prev.filter((item) => item.userUid !== selectedUserId));
                FollowerListAll()
                getUserProfileStat();
                setUnfollow(true)
            });
        } catch (e) {
            console.log('cxc', e);
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggleDropdown = (index) => {
        setShowDropdown((prev) => (prev === index ? null : index));
    };

    const handleShowModal = (item) => {
        setSelectedItem(item);
        console.log("unfollow item", item);
        setSelectedUserId(item.uid);
        setShowModal(true);
    };
    //   console.log("selectedUserId", selectedUserId);

    const handleUnfollowCloseModal = () => {
        setShowModal(false);
        setSelectedItem(null);
    };

    const handleConfirmAction = () => {
        unFollowUser();
        handleUnfollowCloseModal();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(null);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showModal]);

    // const FollowCompany = async (id) => {
    //     const data = {
    //         deviceType: "Web",
    //         sessionId: sessionId,
    //         uiD_ID: id,
    //     };
    //     try {
    //         await axios.post("Entity/Unfollow/Company", data).then((resData) => {
    //             console.log("followed");
    //             setToastMessage(resData.data.message);
    //             setShowToast(true);
    //         });
    //     } catch (e) {
    //         if (e.response && e.response.status === 404) {
    //             setWarningToastMessage(e.response.data.message);
    //             setShowWarningToast(true);
    //         } else {
    //             setErrorToastMessage(e.response.data.message);
    //             setShowErrorToast(true);
    //         }
    //     }
    // };

    return (
        <>

            {isLoading ? (
                <div className="mt-3">
                    {/* <ShimmerThumbnail height={250} rounded /> */}
                    <ShimmerDiv mode="light" height={250} width={'100%'} />
                </div>
            ) : isError ? (
                <div className="mt-2 text-center">
                    <img src={post.errorSmall} alt="404 Not Found" width={200} />
                </div>
            ) : (
                FollowerList &&
                FollowerList?.map((item, index) => {
                    console.log('connect dataaaaa', item);
                    return (
                        <>
                            <div className="aboutMe mb-3 p-3" key={index}>
                                <div className="d-flex align-items-center">
                                    <Link to={`/Company/${item.uid}`}>
                                        <div className="ImgAvatar">
                                            <img src={item?.image} alt="" width={60} style={{ borderRadius: '50%' }} />
                                        </div>
                                    </Link>
                                    <div className="ms-3">
                                        <div className="userName">
                                            <Link to={`/Company/${item.uid}`}>
                                                <h1>{item?.name} </h1>
                                            </Link>
                                        </div>
                                        <div className="designation">
                                            <h1>{item?.title}</h1>
                                        </div>
                                        <div className="designation">
                                            <h1>{item?.headLine}</h1>
                                        </div>
                                        <div className="location">
                                            <div>
                                                {item?.location && (
                                                    <>
                                                        {/* <img src={locationIcon} alt="" /> */}
                                                        <LocationOnSharpIcon className="ConnectionIcons" />
                                                        <span className="locationText">
                                                            {item?.location}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="vectorUserImage">
                                            {/* <img src={userAvatar} alt="" /> */}
                                            <AccountCircleSharpIcon className="ConnectionIcons" />
                                            <span className="followerText ms-2">
                                                {item?.followersCount} Followers
                                            </span>
                                        </div>
                                    </div>

                                    <div className="ms-auto">
                                        {userData?.profileInfo.isOwn === true ? (
                                            selectedUserId === item.uid && unfollow !== false ?
                                                '' : <div
                                                    onClick={() => handleToggleDropdown(index)}
                                                    className="postMenu"
                                                >
                                                    <MoreHorizSharpIcon className="material-symbols-sharp"
                                                    />
                                                    {showDropdown === index && (
                                                        <Dropdown.Menu
                                                            show={showDropdown === index}
                                                            ref={dropdownRef}
                                                            className="InteractionModal"
                                                            align="start"
                                                        >
                                                            <Dropdown.Item
                                                                as="button"
                                                                className="dropdownList p-0"
                                                            >
                                                                <Link onClick={() => handleShowModal(item)}>
                                                                    <div className="d-flex align-items-center">
                                                                        <ReportGmailerrorredSharpIcon className="material-symbols-sharp me-2"
                                                                        />
                                                                        <span className="dropdownTxt f-14">
                                                                            Unfollow Company
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    )}
                                                </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                </div>
                            </div>

                        </>
                    );
                })
            )}
            {hasMoreData ? (''
            ) : (
                <p className="text-center">No more data available</p >
            )}
            {nextBatchLoading && (
                <div className="text-center mb-4">
                    <img src={post?.loader} width={50} />
                </div>
            )}


            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog  modal-dialog-centered " role="document">
                        <div className="modal-content d-flex justify-content-around p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                                <h6>
                                    Are you sure you want to unfollow{" "}
                                    <b>{selectedItem?.fullName} ?</b>
                                </h6>

                                <span
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={() => {
                                        handleUnfollowCloseModal();
                                    }}
                                >
                                    close
                                </span>
                            </div>

                            <div className="text-end mt-2">
                                <button
                                    color=""
                                    className="closebtn"
                                    onClick={() => handleConfirmAction()}
                                >
                                    Unfollow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    )
}

export default Follows