import React, { useState } from 'react'
import Following from './Following';
import Follows from './Follows';
import { NavLink } from 'react-router-dom';

const FollowingTab = ({ uid,
    getUserProfileStat,
    userData,
    getUserProfile }) => {

    const [activeTab, setActiveTab] = useState(1);

    const renderTabContent = () => {
        switch (activeTab) {
            case 1:
                return (
                    <Following
                        userData={userData}
                        uid={uid}
                        getUserProfile={getUserProfile}
                        getUserProfileStat={getUserProfileStat}
                    />
                );
            case 2:
                return <Follows uid={uid} getUserProfileStat={getUserProfileStat} userData={userData} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="tabButton d-flex p-3 w-100 mt-2 ps-0 connectionTab">
                <NavLink
                    onClick={() => setActiveTab(1)}
                    className={`text-center me-4   ${activeTab === 1 ? "activeTab" : ""
                        }`}
                >
                    <p className="f-12 tabName">People</p>
                </NavLink>
                <NavLink
                    onClick={() => setActiveTab(2)}
                    className={`text-center   ${activeTab === 2 ? "activeTab" : ""
                        }`}
                >
                    <p className="f-12 tabName">Company</p>
                </NavLink>
            </div>

            <div>
                {renderTabContent()}
            </div>
        </>
    )
}

export default FollowingTab