



import React, { useEffect, useState } from "react";
import BackHandSharpIcon from '@mui/icons-material/BackHandSharp';
const AdblockDetector = () => {
    const [adblockStatus, setAdblockStatus] = useState(false); // Default to false (no adblock)

    useEffect(() => {
        const detectAdblock = () => {
            const scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            scriptElement.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";

            scriptElement.onload = () => {
                setAdblockStatus(false); // No adblock detected
            };

            scriptElement.onerror = () => {
                setAdblockStatus(true); // Adblock detected
            };

            document.body.appendChild(scriptElement);

            return () => {
                document.body.removeChild(scriptElement);
            };
        };

        detectAdblock();
    }, []);

    useEffect(() => {
        if (adblockStatus) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [adblockStatus]);

    // Only render modal if adblock is detected
    if (!adblockStatus) return null;

    return (
        <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content d-flex justify-content-around p-2">
                    <div className="d-flex justify-content-between align-items-center p-2">
                        <div>
                            <h4 className="text-center AdBlocker">Adblocker Detected!</h4>
                            <p className="text-center ad-p">
                                Our website is made possible by displaying online advertisements to our visitors. Please consider supporting us by disabling your ad blocker on our website.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdblockDetector;
