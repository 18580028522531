import React, { useEffect, useRef, useState } from "react";
import { post } from "../../assets/Images/ImagesLink";
// import { NewsFeed, post } from '../../assets/Images/ImagesLink'
import axios from "../../API/axios";
import { ShimmerThumbnail } from "react-shimmer-effects";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Link } from "react-router-dom";
import locationIcon from "../../assets/Images/connection/locationIcon.png";
import userAvatar from "../../assets/Images/connection/Group 647.png";
import { Dropdown } from "react-bootstrap";
import { ShimmerDiv } from "shimmer-effects-react";
import ModalForGuest from "../ModalForGuest";
import PeopleInteraction from "../People/PeopleInteraction";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';

const Followers = ({ uid, getUserProfileStat }) => {
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");

  //************************ Follower  List   ************************//
  const [followedId, setFollowedId] = useState("");
  const [isfollowed, setIsfollowed] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [FollowerList, setFollowerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log("FollowerList", FollowerList);
  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const FollowerListAll = async (id) => {
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 20,
      userUid: uid,
    };
    setNextBatchLoading(true);
    try {
      await axios.post("UserFollow/GetFollowersList", data).then((resData) => {
        // setFollowerList(resData.data.responseData.viewList);
        console.log("connect data", resData.data.message);

        setIsLoading(false);
        // if(resData.data.message==='No Followers found!'){
        //     setIsError(true);
        // }else{

        // }

        if (resData.data.status === "Warning") {
          setIsError(true);
        } else {
          setFollowerList((prev) => {
            const newViewList =
              resData?.data?.responseData?.followersList.filter(
                (item) =>
                  !prev.some((prevItem) => prevItem.userUid === item.userUid)
              );
            return [...prev, ...newViewList];
          });
        }
        setTotalPages(resData.data.responseData.paging.totalPages);
        setNextBatchLoading(false);
        if (page >= resData.data.responseData.paging.totalPages) {
          setHasMoreData(false);
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }
      setIsLoading(false);
      setNextBatchLoading(false);
    }
  };

  useEffect(() => {
    if (page <= totalPages) {
      FollowerListAll();
    }
  }, [page]);


  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);
  //************************Follower List ************************//
  //************************ Follow User Start  ************************//
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const FollowUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        // console.log("resDataaaaa", resData);
        // setFollowerList((prev) => prev.filter((item) => item.userUid !== id));
        getUserProfileStat();
        setToastMessage(resData.data.message);
        setShowToast(true);
        setIsfollowed(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Follow User End  ************************//
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleShowModal = (item) => {
    setSelectedItem(item);
    // console.log("unfollow item", item);
    setSelectedUserId(item.userUid);
    setShowModal(true);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = (index) => {
    setShowDropdown((prev) => (prev === index ? null : index));
  };
  const handleUnfollowCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const unFollowUser = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: selectedUserId,
    };
    console.log("unfllow new res", responseData);
    try {
      await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
        // toast.success("User Unfollowed");
        // console.log("res", res);
        setToastMessage(res.data.message);
        setShowToast(true);
        // setFollowerList((prev) => prev.filter((item) => item.userUid !== id));
        getUserProfileStat();
        setIsfollowed(false);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const handleConfirmAction = () => {
    unFollowUser();
    handleUnfollowCloseModal();
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showModal]);

  const [followModal, setFollowModal] = useState(false);
  const closeModalData = () => {
    setFollowModal(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="mt-3">
          {/* <ShimmerThumbnail height={250} rounded /> */}
          <ShimmerDiv mode="light" height={250} width={"100%"} />
        </div>
      ) : isError ? (
        <div className="mt-2 text-center">
          <img src={post.errorSmall} alt="404 Not Found" width={200} />
        </div>
      ) : (
        FollowerList &&
        FollowerList?.map((item, index) => {
          // console.log('connect data', item);
          return (
            <>
              <div className="aboutMe mb-3 p-3" key={index}>
                <div className="d-flex align-items-center">
                  <Link to={`/User/${item.userUid}`}>
                    <div className="ImgAvatar">
                      <img
                        src={item?.profilPic}
                        alt=""
                        width={60}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </Link>
                  <div className="ms-3">
                    <div className="userName">
                      <Link to={`/User/${item.userUid}`}>
                        <h1>
                          {item?.fullName}{" "}
                          <span className="f-12 fw-100">{item?.userName}</span>
                          <span className='f-12 fw-100'>{item?.nameTag}</span>
                        </h1>
                      </Link>

                    </div>
                    <div className="designation">
                      <h1>{item?.title}</h1>
                    </div>
                    <div className="designation">
                      <h1>{item?.headLine}</h1>
                    </div>
                    <div className="location">
                      <div>
                        {item?.location && (
                          <>
                            {/* <img src={locationIcon} alt="" /> */}
                            <LocationOnSharpIcon className="ConnectionIcons" />
                            <span className="locationText">
                              {item?.location}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="vectorUserImage">
                      {/* <img src={userAvatar} alt="" /> */}
                      <AccountCircleSharpIcon className="ConnectionIcons" />
                      <span className="followerText ms-2">
                        {item?.followersCount} Followers
                      </span>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <PeopleInteraction userUid={item?.userUid} isFollowedByYou={item?.isFollowedByYou} getUserProfileList={FollowerListAll} index={index} />
                  </div>
                </div>
              </div>
            </>
          );
        })
      )}
      {hasMoreData ? (''
      ) : (
        <p className="text-center">No more data available</p >
      )}
      {nextBatchLoading && (
        <div className="text-center mb-4">
          <img src={post?.loader} width={50} />
        </div>
      )}

      {followModal && <ModalForGuest closeModalData={closeModalData} />}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>
                  Are you sure you want to unfollow{" "}
                  <b>{selectedItem?.fullName} ?</b>
                </h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUnfollowCloseModal();
                  }}
                />
              </div>

              <div className="text-end mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => handleConfirmAction()}
                >
                  Unfollow
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Followers;
