import React, { useEffect, useState } from "react";
// import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import axios from "../../API/axios";
import { debounce } from "lodash";
// import { toast } from "react-hot-toast";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const UpdateSkills = ({ showSkills, handleUpdateSkillClose, getUserProfile, userUid, skillsData }) => {


  const [display, setDisplay] = useState(false);
  const [value, setValue] = useState(null);
  const [page, setPage] = useState(0);
  const [typed, setTyped] = useState(skillsData?.skillName);
  const [isLoading, setIsLoading] = useState(false);
  const [newCreateoption, setNewCreateoption] = useState("");
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const sessionId = localStorage.getItem("sessionId");

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [errorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [industryList, setIndustryList] = useState([]);

  const getIndustry = () => {
    axios
      .get(`Master/GetFirmIndustryTypeDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fitid,
          label: item.name,
        }));
        setIndustryList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIndustry();
  }, []);

  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetSkillsByIndustryDDL?search=${typed}&Fitid=${selectedOption?.value}&pageNumber=${page + 1}&pageSize=100`
      )
      .then((response) => {
        const data = response.data.responseData.data.map((item) => ({
          value: item.skllId,
          label: item.name,
        }));
        setPage((prevPage) => prevPage + 1);
        setIsLoading(false);
        setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPages(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setPage(1);
  };

  const handleChange = (value) => {
    setValue(value);
    setPage(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (page < totalPages) {
      addOptions();
    }
  };

  useEffect(() => {
    if (skillsData) {
      setValue({ value: skillsData.skllId, label: skillsData.skillName });
      setTyped(skillsData.skillName);
      setSelectedOption(skillsData.fitid);
    }
  }, [skillsData]);

  const UpdateSkill = async () => {
    setDisplay(true);
    const postdatadetails = {
      deviceType: "Web",
      sessionId: sessionId,
      fitid: selectedOption?.value,
      skillName: value.label,
      usrSkllId: skillsData.usrSkllId
    };
    try {
      await axios
        .post("UserSkill/UpdateUserSkill", postdatadetails)
        .then((resData) => {
          setToastMessage(resData.data.message);
          setShowToast(true);
          handleUpdateSkillClose();
          setSelectedOption(null);
          setValue(null);
          setTyped("");
          setOptions([]);
          setPage(0);
          setTotalPages(0);
          getUserProfile()
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    if (skillsData.fitid) {
      const initialOption = industryList.find(option => option.value === skillsData.fitid);
      setSelectedOption(initialOption);
    }
  }, [skillsData, industryList]);

  const handleIndustryChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setOptions([]);
    setPage(0);
  };

  useEffect(() => {
    if (showSkills) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showSkills]);

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  return (
    <>
      {showSkills && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Update Skill</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleUpdateSkillClose();
                  }}
                />
              </div>
              <div className="mb-4">
                <Select
                  options={industryList}
                  value={selectedOption}
                  styles={customStyles}
                  onChange={handleIndustryChange}
                  placeholder="Select industry"
                  className="dropdownBorder"
                  isClearable
                />
                {display && !selectedOption ? (
                  <span className="error_msg_lbl">Please Select industry </span>
                ) : null}
              </div>
              <div>
                <Select
                  value={value}
                  options={options}
                  onOpen={handleOpen}
                  onChange={handleChange}
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  onInputChange={handleInputChange}
                  className="dropdownBorder"
                  isClearable
                  placeholder={"Search and Select Skill"}
                  invalid={display && value === ""}
                  styles={customStyles}
                />
                {display && !value ? (
                  <span className="error_msg_lbl">Please Select Skill </span>
                ) : null}
              </div>
              <div className='mt-2 text-end'>
                <button
                  type="button"
                  className="closebtn me-2"
                  onClick={handleUpdateSkillClose}
                  style={{ width: "60px" }}
                >
                  close
                </button>
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={UpdateSkill}
                  style={{ width: "70px" }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />
      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />
      <ErrorToast
        show={showErrorToast}
        message={errorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default UpdateSkills;
