// advertisementContainer
import React, { useState, useRef, useEffect } from "react";
import { header, people } from "../../assets/Images/ImagesLink";
import "../../assets/css/header.css";
import Select from "react-select";
import axios from "../../API/axios";
import { debounce } from "lodash";
import { Button, Dropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Toggle from "../../Toggle";
import LeftNavigation from "./LeftNavigation";
import CreateCompany from "../CreateCompany/CreateCompany";
import TopNotification from "./TopNotification";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import AdminPanelSettingsSharpIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import base58 from "base-58";
import { Buffer } from "buffer";
import TopMessage from "./TopMessage";
import moment from "moment";
const suggestions = [
  "Apple",
  "Banana",
  "Cherry",
  "Date",
  "Elderberry",
  "Fig",
  "Grape",
  "Honeydew",
  "Kiwi",
  "Lemon",
];
const Header = ({ isDark, setIsDark }) => {
  const guestModeData = localStorage.getItem("guestMode");
  const sessionId = localStorage.getItem("sessionId");
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const userUid = localStorage.getItem("userUid");
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
    console.log("Menu is toggling...");
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownRes, setShowDropdownRes] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const toggleDropdownRes = () => {
    setShowDropdownRes(!showDropdownRes);
  };
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //************************Search Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };
  const [options, setOptions] = useState([]);
  const [searchUser, setSearchUser] = useState([]);

  const [searchComp, setSearchComp] = useState([]);
  const [searchJob, setSearchJob] = useState([]);
  const [searchPost, setSearchPost] = useState([]);
  const [searchBlog, setSearchBlog] = useState([]);

  const addOptions = () => {
    // setIsLoading(true);
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 100,
    };
    axios
      .post(`SMFeatures/GlobalSearch?search=${inputValue}`, data)
      .then((response) => {
        console.log("Response:", response);

        if (
          !response.data ||
          !response.data.responseData ||
          !response.data.responseData.searchdata
        ) {
          console.log("Invalid response structure:", response);
          return;
        }

        let data = response.data.responseData.searchdata;
        console.log("Original Data:", data);

        // Process the data to extract the type and followers count
        data = data.map((item) => {
          const [type, followers] = item.type.split(",");
          return {
            ...item,
            type,
            followers: parseInt(followers, 10),
          };
        });

        console.log("Processed Data:", data);


        // const addUniqueItems = (prev, data, type) => {
        //   const existingIds = new Set(prev.map((item) => item.id));
        //   const uniqueItems = data.filter(
        //     (item) => item.type === type && !existingIds.has(item.id)
        //   );
        //   return [...prev, ...uniqueItems];
        // };

        // setSearchUser((prev) => addUniqueItems(prev, data, "User"));
        // setSearchComp((prev) => addUniqueItems(prev, data, "Company"));
        // setSearchJob((prev) => addUniqueItems(prev, data, "Job"));
        // setSearchPost((prev) => addUniqueItems(prev, data, "Post"));

        const addUniqueItems = (data, type) => {
          const uniqueItems = data.filter((item) => item.type === type);
          return uniqueItems;
        };

        // Clear the previous results and set the new data
        setSearchUser(addUniqueItems(data, "User"));
        setSearchComp(addUniqueItems(data, "Company"));
        setSearchJob(addUniqueItems(data, "Job"));
        setSearchPost(addUniqueItems(data, "Post"));
        setSearchBlog(addUniqueItems(data, "Blog"));

        setIsLoading(false);
        setTotalPages(response.data.responseData.paging.totalPages);

        setOptions((prev) => {
          const postSet = new Set(prev.map((post) => post.id));
          const uniqueNewPosts = data.filter((post) => !postSet.has(post.id));
          return [...prev, ...uniqueNewPosts];
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    if (inputValue) {
      addOptions(page);
    } else {
      setOptions([]);
      setSearchUser([]);
      setSearchComp([]);
      setSearchJob([]);
      setSearchPost([]);
    }
  }, [inputValue, page]);

  const dropdownRef1 = useRef(null);

  const handleInfiniteScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = dropdownRef1.current;
    console.log("ScrollTop:", scrollTop);
    console.log("ClientHeight:", clientHeight);
    console.log("ScrollHeight:", scrollHeight);

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      console.log("Bottom reached, increasing page");
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (dropdownRef1.current) {
      dropdownRef1.current.addEventListener("scroll", handleInfiniteScroll);
      return () =>
        dropdownRef1.current.removeEventListener(
          "scroll",
          handleInfiniteScroll
        );
    }
  }, [dropdownRef1]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       dropdownRef1.current &&
  //       !dropdownRef1.current.contains(event.target)
  //     ) {
  //       setOptions([]);
  //       setInputValue("");
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef1]);

  // Outside click dropdown 2

  // const dropdownRef = useRef(null);
  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropdown(false);
  //   }
  // };
  // useEffect(() => {
  //   if (showDropdown) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showDropdown]);

  //************************ Search End  ************************//

  // const profilPic = window.localStorage.getItem("profilPic");
  const [profilPic, setProfilPic] = useState(localStorage.getItem("profilPic"));

  useEffect(() => {
    const handleProfilePicUpdate = (event) => {
      setProfilPic(event.detail);
    };

    window.addEventListener("profilePicUpdated", handleProfilePicUpdate);

    return () => {
      window.removeEventListener("profilePicUpdated", handleProfilePicUpdate);
    };
  }, []);

  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/NewsFeed") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const searchContainerRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target) &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setOptions([]);
        setInputValue("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //   const base64SessionId = btoa(sessionId);

  const [encodedValue, setEncodedValue] = useState("");
  const [encodedTokenValue, setEncodedTokenValue] = useState("");

  // const handleEncode = () => {
  //   const encoded = base58.encode(Buffer.from(sessionId));

  //   setEncodedValue(encoded);
  //   const encodedToken = base58.encode(Buffer.from(token));
  //   setEncodedTokenValue(encodedToken);
  //   // console.log(encoded);
  // };

  // useEffect(() => {
  //   if (!guestModeData) {
  //     handleEncode();
  //   }
  // }, []);

  const handleEncode = () => {
    // Ensure sessionId and token are converted to strings if they are not already
    const sessionIdStr = typeof sessionId === 'string' ? sessionId : JSON.stringify(sessionId);
    const tokenStr = typeof token === 'string' ? token : JSON.stringify(token);

    const encoded = base58.encode(Buffer.from(sessionIdStr));
    setEncodedValue(encoded);

    const encodedToken = base58.encode(Buffer.from(tokenStr));
    setEncodedTokenValue(encodedToken);
  };

  useEffect(() => {
    if (!guestModeData) {
      handleEncode();
    }
  }, []);


  const handleAction = () => {
    console.log("action is working");
  };

  const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false);
  const handleCloseDeleteReviewModal = () => setShowDeleteReviewModal(false);

  return (
    <>
      <div className="mainheader headerLayout desktopView">
        <div className="row">
          <div className="col-md-3 left">
            <NavLink to="/NewsFeed" onClick={handleLogoClick}>
              {isDark ? (
                <div className="Logo">
                  <img src={header.whiteoglogo} alt="" />
                </div>
              ) : (
                <div className="Logo">
                  <img src={header.oglogo} alt="" />
                </div>
              )}
            </NavLink>
          </div>

          <div className="col-md-6 center ps-0" ref={searchContainerRef}>
            <div className="search-container">
              <SearchSharpIcon className="material-symbols-sharp" />
              <div className="search-input-container">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  placeholder="Search.."
                  className="search-input"
                />

                {(options.length > 0 || inputValue !== "") && (
                  <ul className="suggestions-dropdown" ref={dropdownRef1}>
                    {searchUser.length > 0 && (
                      <div style={{ margin: "4px 16px" }}>
                        <p style={{ margin: "0", fontWeight: "800" }}>People</p>
                      </div>
                    )}
                    {searchUser.map((item, index) => (
                      <Link to={`/User/${item?.id}`} key={index}>
                        <li
                          className="suggestion-item d-flex"
                          onClick={() => {
                            setOptions([]);
                            setInputValue("");
                          }}
                        >
                          <img
                            src={item?.image}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                          <div>
                            <p className="ms-2">{item?.title}</p>
                            <p className="ms-2 f-12">{item?.additionalInfo}</p>
                            <p className="ms-2 f-12">{item?.description}</p>
                            <p className="ms-2 f-12">
                              {item?.followers} followers
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}
                    {searchBlog.length > 0 && (
                      <div style={{ margin: "4px 16px" }}>
                        <p style={{ margin: "0", fontWeight: "800" }}>Blog</p>
                      </div>
                    )}
                    {searchBlog.map((item, index) => (

                      // <Link to={`/blogs/${item.title}`} key={index}>
                      <Link to={"/blogs/" + item.additionalInfo}>
                        <li
                          className="suggestion-item d-flex"
                          onClick={() => {
                            setOptions([]);
                            setInputValue("");
                          }}
                        >
                          <img
                            src={item?.image}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                          <div>
                            <p className="ms-2">{item?.title}</p>
                            <p className="ms-2 f-12">{item?.additionalInfo}</p>
                            {/* <p className="ms-2 f-12">{item?.description}</p> */}
                            <p className="ms-2 f-12">
                              {item?.description
                                ? moment(item.description).format("dddd, MMMM D, YYYY")
                                : ""}
                            </p>
                            {/* <p className="ms-2 f-12">
                              {item?.followers} followers
                            </p> */}
                          </div>
                        </li>
                      </Link>
                    ))}
                    {searchComp.length > 0 && (
                      <div style={{ margin: "4px 16px" }}>
                        <p style={{ margin: "0", fontWeight: "800" }}>
                          Company
                        </p>
                      </div>
                    )}
                    {searchComp.map((item, index) => (
                      <Link to={`/Company/${item?.id}`} key={index}>
                        <li
                          className="suggestion-item d-flex"
                          onClick={() => {
                            setOptions([]);
                            setInputValue("");
                          }}
                        >
                          <img
                            src={item?.image}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                          <div>
                            <p className="ms-2">{item?.title}</p>
                            <p className="ms-2 f-12">{item?.additionalInfo}</p>
                            <p className="ms-2 f-12">{item?.description}</p>
                            <p className="ms-2 f-12">
                              {item?.followers} followers
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}
                    {searchJob.length > 0 && (
                      <div style={{ margin: "4px 16px" }}>
                        <p style={{ margin: "0", fontWeight: "800" }}>Job</p>
                      </div>
                    )}
                    {searchJob.map((item, index) => (
                      <Link to={`/JobDetail/${item?.id}`} key={index}>
                        <li
                          className="suggestion-item d-flex"
                          onClick={() => {
                            setOptions([]);
                            setInputValue("");
                          }}
                        >
                          <img
                            src={item?.image}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                          <div>
                            <p className="ms-2">{item?.title}</p>
                            <p className="ms-2 f-12">{item?.additionalInfo}</p>
                            <p className="ms-2 f-12">{item?.description}</p>
                            <p className="ms-2 f-12">
                              {item?.followers} followers
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}
                    {searchPost.length > 0 && (
                      <div style={{ margin: "4px 16px" }}>
                        <p style={{ margin: "0", fontWeight: "800" }}>Post</p>
                      </div>
                    )}
                    {searchPost.map((item, index) => (
                      <Link to={`/Post/${item?.id}`} key={index}>
                        <li
                          className="suggestion-item d-flex"
                          onClick={() => {
                            setOptions([]);
                            setInputValue("");
                          }}
                        >
                          <img
                            src={item?.image}
                            alt=""
                            width={30}
                            height={30}
                            style={{ borderRadius: "50px" }}
                          />
                          <div>
                            <p className="ms-2">{item?.title}</p>
                            <p className="ms-2 f-12">{item?.additionalInfo}</p>
                            <p className="ms-2 f-12">{item?.description}</p>
                            <p className="ms-2 f-12">
                              {item?.followers} followers
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}

                  </ul>
                )}
              </div>
            </div>
          </div>

          {/* --------------------- Guest Mode ---------------------------- */}

          <div className="col-md-3 right">
            {guestModeData === "true" ? (
              // <div>
              <div className="header-action guestMode d-flex justify-content-end align-items-center">
                <Toggle
                  isChecked={isDark}
                  handleChange={() => setIsDark(!isDark)}
                />
                <button
                  className="mainBtn guesBtn px-1 f-14"
                  style={{ height: "40px" }}
                >
                  Guest Mode
                </button>
              </div>
            ) : (
              // </div>
              <div className="header-action d-flex align-items-start justify-content-end">
                {/* <Link to="/Notification" className="mt-1"> */}
                <div className="notification d-flex justify-content-center  align-items-center mt-1">
                  <TopNotification />
                </div>
                {/* </Link> */}
                {/* <Link to="/Message" className="mt-1"> */}
                <div className="notification d-flex justify-content-center  align-items-center mt-1">
                  <TopMessage />
                </div>
                {/* </Link> */}
                <div className="user mt-1 desktop-dropdown">
                  <img
                    src={profilPic}
                    width={40}
                    height={40}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                    alt=""
                    onClick={() => setShowDropdown(!showDropdown)}
                  />

                  {showDropdown && (
                    <Dropdown.Menu show={showDropdown} ref={dropdownRef}>
                      <Dropdown.Item as="button" className="dropdownList">
                        <Link to={`/User/${userUid}`} onClick={toggleDropdown}>
                          <PersonOutlineSharpIcon className="material-symbols-sharp me-2" />
                          <span className="dropdownTxt">Profile</span>
                        </Link>
                      </Dropdown.Item>

                      <Dropdown.Item as="button" className="dropdownList">
                        <Link to="/Settings/profile" onClick={toggleDropdown}>
                          <SettingsSharpIcon className="material-symbols-sharp me-2" />
                          <span className="dropdownTxt">Setting</span>
                        </Link>
                      </Dropdown.Item>

                      {userRole === "Admin" ? (
                        <Dropdown.Item as="button" className="dropdownList">
                          <Link
                            to={`https://admin-test.wlj.app/${encodedValue}/${encodedTokenValue}`}
                            target="blank"
                            onClick={toggleDropdown}
                          >
                            <AdminPanelSettingsSharpIcon className="material-symbols-sharp me-2" />
                            <span className="dropdownTxt">Go To Admin</span>
                          </Link>
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}

                      <Dropdown.Divider />

                      <Dropdown.Item as="button" className="dropdownList">
                        <Link
                          className="d-flex align-items-center"
                          to="/signIn"
                          onClick={() => {
                            localStorage.clear();
                            sessionStorage.removeItem('hasModalShown');
                          }}
                        >
                          <PowerSettingsNewSharpIcon className="material-symbols-sharp me-2" />
                          <span className="dropdownTxt">Logout</span>
                        </Link>
                      </Dropdown.Item>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="py-1"
                      >
                        <Toggle
                          isChecked={isDark}
                          handleChange={() => setIsDark(!isDark)}
                        />
                      </div>
                    </Dropdown.Menu>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*------------------- Profile dropdown for Mobile view ------------------------------- */}

      <div className="mainheader align-items-center mobileView">
        <MenuSharpIcon className="material-symbols-sharp pointer" onClick={toggleMenu} />
        <div className="search-container">
          <div className="search-icon">
            <SearchSharpIcon className="material-symbols-sharp" />

          </div>
          <div className="search-input-container">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              placeholder="Search.."
              className="search-input"
            />

            {(options.length > 0 || inputValue !== "") && (
              <ul className="suggestions-dropdown" ref={dropdownRef1}>
                {searchUser.length > 0 && (
                  <div style={{ margin: "4px 16px" }}>
                    <p style={{ margin: "0", fontWeight: "800" }}>People</p>
                  </div>
                )}
                {searchUser.map((item, index) => (
                  <Link to={`/User/${item?.id}`} key={index}>
                    <li
                      className="suggestion-item d-flex"
                      onClick={() => {
                        setOptions([]);
                        setInputValue("");
                      }}
                    >
                      <img
                        src={item?.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                      />
                      <div>
                        <p className="ms-2">{item?.title}</p>
                        <p className="ms-2 f-12">{item?.additionalInfo}</p>
                        <p className="ms-2 f-12">{item?.description}</p>
                        <p className="ms-2 f-12">{item?.followers} followers</p>
                      </div>
                    </li>
                  </Link>
                ))}
                {searchComp.length > 0 && (
                  <div style={{ margin: "4px 16px" }}>
                    <p style={{ margin: "0", fontWeight: "800" }}>Company</p>
                  </div>
                )}
                {searchComp.map((item, index) => (
                  <Link to={`/Company/${item?.id}`} key={index}>
                    <li
                      className="suggestion-item d-flex"
                      onClick={() => {
                        setOptions([]);
                        setInputValue("");
                      }}
                    >
                      <img
                        src={item?.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                      />
                      <div>
                        <p className="ms-2">{item?.title}</p>
                        <p className="ms-2 f-12">{item?.additionalInfo}</p>
                        <p className="ms-2 f-12">{item?.description}</p>
                        <p className="ms-2 f-12">{item?.followers} followers</p>
                      </div>
                    </li>
                  </Link>
                ))}
                {searchJob.length > 0 && (
                  <div style={{ margin: "4px 16px" }}>
                    <p style={{ margin: "0", fontWeight: "800" }}>Job</p>
                  </div>
                )}
                {searchJob.map((item, index) => (
                  <Link to={`/JobDetail/${item?.id}`} key={index}>
                    <li
                      className="suggestion-item d-flex"
                      onClick={() => {
                        setOptions([]);
                        setInputValue("");
                      }}
                    >
                      <img
                        src={item?.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                      />
                      <div>
                        <p className="ms-2">{item?.title}</p>
                        <p className="ms-2 f-12">{item?.additionalInfo}</p>
                        <p className="ms-2 f-12">{item?.description}</p>
                        <p className="ms-2 f-12">{item?.followers} followers</p>
                      </div>
                    </li>
                  </Link>
                ))}
                {searchPost.length > 0 && (
                  <div style={{ margin: "4px 16px" }}>
                    <p style={{ margin: "0", fontWeight: "800" }}>Post</p>
                  </div>
                )}
                {searchPost.map((item, index) => (
                  <Link to={`/Post/${item?.id}`} key={index}>
                    <li
                      className="suggestion-item d-flex"
                      onClick={() => {
                        setOptions([]);
                        setInputValue("");
                      }}
                    >
                      <img
                        src={item?.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "50px" }}
                      />
                      <div>
                        <p className="ms-2">{item?.title}</p>
                        <p className="ms-2 f-12">{item?.additionalInfo}</p>
                        <p className="ms-2 f-12">{item?.description}</p>
                        <p className="ms-2 f-12">{item?.followers} followers</p>
                      </div>
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="header-action d-flex justify-content-between align-items-end ">
          <div className="user ">
            <img
              src={profilPic}
              width={40}
              height={40}
              style={{ cursor: "pointer", borderRadius: "50%" }}
              alt=""
              onClick={() => setShowDropdownRes(!showDropdownRes)}
            />
            {showDropdownRes && (
              <Dropdown.Menu show={showDropdownRes}>
                <Dropdown.Item className="dropdownList">
                  <Link to={`/User/${userUid}`} onClick={toggleDropdownRes}>
                    <PersonOutlineSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt">Profile</span>
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item as="button" className="dropdownList">
                  <Link to="/Settings/profile" onClick={toggleDropdownRes}>
                    <SettingsSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt">Setting</span>
                  </Link>
                </Dropdown.Item>

                {userRole === "Admin" ? (
                  <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      to={`https://admin-test.wlj.app/${encodedValue}/${encodedTokenValue}`}
                      target="blank"
                      onClick={toggleDropdownRes}
                    >
                      <AdminPanelSettingsSharpIcon className="material-symbols-sharp me-2" />
                      <span className="dropdownTxt">Go To Admin</span>
                    </Link>
                  </Dropdown.Item>
                ) : (
                  ""
                )}

                <Dropdown.Divider />

                <Dropdown.Item as="button" className="dropdownList">
                  <Link
                    className="d-flex align-items-center"
                    to="/signIn"
                    onClick={() => {
                      localStorage.clear();
                      sessionStorage.removeItem('hasModalShown');
                    }}
                  >
                    <span className="dropdownTxt">Logout</span>
                  </Link>
                </Dropdown.Item>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="py-1"
                >
                  <Toggle
                    isChecked={isDark}
                    handleChange={() => setIsDark(!isDark)}
                  />
                </div>
              </Dropdown.Menu>
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <LeftNavigation
          isOpen={isOpen}
          toggleMenu={toggleMenu}
          setIsOpen={setIsOpen}
          openModal={openModal}
        />
      )}
      {showModal && (
        <CreateCompany
          openModal={openModal}
          showModal={showModal}
          closeModal={closeModal}
          getCompanyList={''}
        />
      )}
    </>
  );
};

export default Header;
