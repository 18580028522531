import React, { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import { ShimmerThumbnail } from "react-shimmer-effects";
import axios from "../../API/axios";
import { icons, JobSearch, post } from "../../assets/Images/ImagesLink";
import JobAction from "../JobSearch/JobAction";
import ModalForGuest from "../ModalForGuest";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ShimmerDiv } from "shimmer-effects-react";
import JobApply from "../JobSearch/JobApply";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import ExpiredJobAction from "../JobSearch/ExpiredJobAction";
import AddJob from "../ManageJob/AddJob";
import DraftJob from "../ManageJob/DraftJob";

const Jobs = ({ CompUid, companyDetails }) => {
  console.log('companyDetails', companyDetails?.isOwned);

  const [showModal, setShowModal] = useState(false);
  const sessionId = localStorage.getItem("sessionId");
  const [activeTab, setActiveTab] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [expiredjobList, setExpiredJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isError1, setIsError1] = useState(false);
  const [unauthorisedModal, setUnauthorisedModal] = useState(false);

  console.log('jobList', jobList);
  const [nextBatchLoading, setNextBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  console.log('hasMoreData', hasMoreData);
  const navigate = useNavigate();
  const AllJobList = async (page) => {
    setLoading(false);
    setNextBatchLoading(true);
    const Data = {
      deviceType: "Web",
      search: null,
      countryIDs: [],
      cityIDs: [],
      locationIDs: [],
      jobtypeIDs: [],
      companyIDs: [],
      workLevelTypeIDs: [],
      workLocationTypeIDs: [],
      salaryTypeIDs: [],
      designationIDs: [],
      pageNumber: page,
      pageSize: 10,
    };
    try {
      setLoading(true);
      await axios
        .get(
          `JobsBy/Company?DeviceType=Web&CompUid=${CompUid}&pageNumber=${page}&pageSize=10`
        )
        .then((resData) => {
          console.log("isLoading", resData.status, resData.status === 204);

          setIsLoading(false);
          // setJobList((prev) => [...prev, ...resData.data.responseData.viewJobs]);

          if (resData.status === 204) {
            setIsError(true);
            console.log('isLoading aara ahi');
          } else {

            const newJobs = resData.data.responseData.viewJobs;

            // Ensure only unique jobs are added to the job list
            setJobList((prev) => {
              const jobSet = new Set(prev.map((job) => job.jobId)); // Assuming job objects have a unique 'id' field
              const uniqueNewJobs = newJobs.filter((job) => !jobSet.has(job.jobId));
              console.log('Unique new jobs:', uniqueNewJobs); // Debug log to confirm unique jobs
              return [...prev, ...uniqueNewJobs];
            });
            setTotalPages(resData.data.responseData.paging.totalPages);
            if (page >= resData.data.responseData.paging.totalPages) {
              setHasMoreData(false);
            }
          }
          setNextBatchLoading(false);
          setLoading(false);
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.log(error);
      }
      setNextBatchLoading(false);
    }
  };

  useEffect(() => {
    if (page <= totalPages) {
      AllJobList(page);
    }
  }, [page, CompUid]);

  const [nextBatchLoading1, setNextBatchLoading1] = useState(false);
  const [hasMoreData1, setHasMoreData1] = useState(true);
  const ExpiredJobList = async (page) => {
    setLoading1(false);
    setNextBatchLoading1(true)
    const Data = {
      deviceType: "Web",
      search: null,
      countryIDs: [],
      cityIDs: [],
      locationIDs: [],
      jobtypeIDs: [],
      companyIDs: [],
      workLevelTypeIDs: [],
      workLocationTypeIDs: [],
      salaryTypeIDs: [],
      designationIDs: [],
      pageNumber: page1,
      pageSize: 10,
    };
    try {
      setLoading1(true);
      await axios
        .get(
          `JobsBy/CompanyExpiredJobs?DeviceType=Web&CompUid=${CompUid}&pageNumber=${page1}&pageSize=10`
        )
        .then((resData) => {
          console.log("isLoading", resData.status, resData.status === 204);

          setIsLoading1(false);
          // setJobList((prev) => [...prev, ...resData.data.responseData.viewJobs]);

          if (resData.status === 204) {
            setIsError1(true);
          } else {

            const newJobs = resData.data.responseData.viewJobs;

            // Ensure only unique jobs are added to the job list
            setExpiredJobList((prev) => {
              const jobSet = new Set(prev.map((job) => job.jobId)); // Assuming job objects have a unique 'id' field
              const uniqueNewJobs = newJobs.filter((job) => !jobSet.has(job.jobId));
              console.log('Unique new jobs:', uniqueNewJobs); // Debug log to confirm unique jobs
              return [...prev, ...uniqueNewJobs];
            });
            setTotalPages1(resData.data.responseData.paging.totalPages);
          }
          setLoading1(false);
          setNextBatchLoading1(false)
          if (page1 >= resData.data.responseData.paging.totalPages) {
            setHasMoreData1(false);
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError1(true);
      } else {
        console.log(error);
      }
      setNextBatchLoading1(false)
    }
  };

  useEffect(() => {
    if (page1 <= totalPages1) {
      ExpiredJobList(page1);
    }
  }, [page1, CompUid]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
        setPage1((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const userUid = localStorage.getItem("userUid");
  const guestModeData = localStorage.getItem("guestMode");

  const [bookmarkModal, setBookmarkModal] = useState(false);
  const [appliedJobId, setAppliedJobId] = useState("");
  const [showAppliedModal, setShowAppliedModal] = useState(false);
  const handleCloseModal = () => setShowAppliedModal(false);
  const handleShow = () => setShowAppliedModal(true);
  // const closeModalData = () => {
  //   setBookmarkModal(false);
  // };

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isappliedF, setIsappliedF] = useState(false);
  const [isUnapplyF, setIsUnapplyF] = useState(false);
  // console.log('isappliedF', isappliedF);
  const applyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: jobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      // console.log('body', body);
      const res = await axios.post("/JobAction/ApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      // closeModal();
      // setJobList([]);
      AllJobList(page);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const unapplyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: appliedJobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      const res = await axios.post("/JobAction/UnApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      handleCloseModal();
      AllJobList(page);
    } catch (e) {
      console.log(e.response.data.message);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //********Apply Job Start  ********//

  const closeModalData = () => {
    setBookmarkModal(false);
  };

  //************************User Profile Stats Start  ************************//
  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  //************************User Profile Stats End  ************************//
  const [profileConfig, setProfileConfig] = useState([]);
  const userConfig = async () => {
    console.log('errrrrrrr');
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      if ((error.code === 'ERR_NETWORK' && sessionId) || (error.code === "ERR_BAD_REQUEST" && sessionId)) {
        setUnauthorisedModal(true)
      }
    }
  };

  const [showResumeModal, setShowResumeModal] = useState(false);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);

  const [isSaved, setIsSaved] = useState(false);
  const [savedJobs, setSavedJobs] = useState(new Set());
  // const [jobId, setJobId] = useState('');
  const saveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/SaveJob`, responseData).then((res) => {
        console.log("save post", res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(true);
        setSavedJobs((prev) => new Set(prev).add(jobId));
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Save Post End  ************************//

  //************************UnSave Post start  ************************//
  const unsaveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/UnSaveJob`, responseData).then((res) => {
        // console.log(res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(false);
        setSavedJobs((prev) => {
          const newSavedJobs = new Set(prev);
          newSavedJobs.delete(jobId);
          return newSavedJobs;
        });
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnSave Post End  ************************//

  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  useEffect(() => {
    if (showAppliedModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showAppliedModal]);
  console.log('isLoading', isLoading, isError);

  // const [activeTab, setActiveTab] = useState(1);
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>

            {isLoading ? (
              <div className="mt-3">
                <div className="text-center">
                  <img src={post?.loader} width={50} />
                </div>
              </div>
            ) : isError ? (
              <div className="mt-2 error-image-cont error-image-job">
                <img src={post.errorSmall} alt="404 Not Found" width={200} />
              </div>
            ) : (
              jobList &&
              jobList
                .filter((item) => !item.isDeleted)
                ?.map((item, index) => {
                  const now = moment();
                  const itemTime = moment.utc(item?.creationTime);
                  const duration = moment.duration(now.diff(itemTime));

                  const years = duration.years();
                  const months = duration.months();
                  const days = duration.days();
                  const hours = duration.hours();
                  const minutes = duration.minutes();

                  let result = "";

                  if (years > 0) {
                    result = `${years} year${years > 1 ? "s" : ""} ago`;
                  } else if (months > 0) {
                    result = `${months} month${months > 1 ? "s" : ""} ago`;
                  } else if (days > 0) {
                    result = `${days} day${days > 1 ? "s" : ""} ago`;
                  } else if (hours > 0) {
                    result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
                  } else if (minutes > 0) {
                    result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
                  } else {
                    result = "just now";
                  }

                  console.log(result);
                  return (
                    <div className="col-md-6 mb-3 ps-0 d-flex align-items-stretch">
                      <div className="jobCard p-3 w-100 d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <Link to={`/JobDetail/${item.jobId}`}>
                              <div className="pointer me-2">
                                <img
                                  src={item?.compLogo}
                                  alt=""
                                  width={50}
                                  style={{ borderRadius: "50px" }}
                                />
                              </div>
                            </Link> */}
                          {/* <div className='f-14 f-700'>{item.compName}</div> */}
                          {/* <span className="f-14 f-700">{item.compName}</span> */}
                          <p className="f-16 fw-bold pointer">
                            {/* {item.title.length > 10
                                ? `${item.title.substring(0, 10)}...`
                                : item.title} */}
                            {item.title}
                          </p>
                          <JobAction
                            item={item}
                            AllJobList={AllJobList}
                            setJobList={setJobList}
                            jobList={jobList}
                            totalPages={totalPages}
                            setTotalPages={setTotalPages}
                            page={page}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4 emoji-container">
                          <Link to={`/JobDetail/${item.jobId}`}>
                            {/* <p
                                className="f-16 fw-bold pointer"
                              // onClick={() => {
                              //   setShowjobDetails(true);
                              //   setJobId(item.jobId);
                              // }}
                              >
                                {item.title}
                              </p> */}
                            {/* <p className="f-16 fw-bold pointer">
                                {item.title.length > 10
                                  ? `${item.title.substring(0, 10)}...`
                                  : item.title}
                              </p> */}
                          </Link>
                          <span className="emoji-name job-name-details">
                            {item.title}
                          </span>

                          {savedJobs.has(item?.jobId) || item?.isSaved === true ? (
                            <div className="d-flex align-items-center postAction">
                              {/* <span
                                  className="material-symbols-sharp pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      unsaveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                >
                                  bookmark
                                </span> */}
                              <BookmarkBorderSharpIcon
                                className="material-symbols-sharp pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    unsaveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center postAction">
                              {/* <span
                                  className="material-symbols-outlined pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      saveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                >
                                  bookmark
                                </span> */}
                              <BookmarkBorderSharpIcon
                                className="material-symbols-outlined pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    saveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              />
                            </div>
                          )}
                        </div>
                        {/* <p className="f-14">{item.shortDescription}</p> */}
                        <p className="f-14">
                          {item?.shortDescription?.length > 20
                            ? `${item.shortDescription.substring(0, 25)}...`
                            : item.shortDescription}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                          <small className="f-8">Posted {result}</small>
                          <div className="badge badge-date">
                            {item?.expiringInDays} days Left
                          </div>
                        </div>
                        <div className="jobType mt-2 mb-3">
                          <div className="badge badge-job me-3">
                            {item.jbTypName}
                          </div>
                          <div className="badge badge-job me-3">
                            {item.workLocationTypeName}
                          </div>
                          <div className="badge badge-job">
                            {item.designationName}
                          </div>
                        </div>


                        <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={AllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                      </div>
                    </div>
                  );
                })
            )}
            {hasMoreData ? (''
            ) : (
              <p className="text-center">No more data available</p >
            )}
            {
              nextBatchLoading && (
                <div className="text-center mb-4">
                  <img src={post?.loader} width={50} />
                </div>
              )
            }
          </>

        );
      case 2:
        return (
          <>
            {isLoading1 ? (
              <div className="mt-3">
                <div className="text-center">
                  <img src={post?.loader} width={50} />
                </div>
              </div>
            ) : isError1 ? (
              <div className="mt-2 error-image-cont error-image-job">
                <img src={post.errorSmall} alt="404 Not Found" width={200} />
              </div>
            ) : (
              expiredjobList &&
              expiredjobList
                .filter((item) => !item.isDeleted)
                ?.map((item, index) => {
                  const now = moment();
                  const itemTime = moment.utc(item?.creationTime);
                  const duration = moment.duration(now.diff(itemTime));

                  const years = duration.years();
                  const months = duration.months();
                  const days = duration.days();
                  const hours = duration.hours();
                  const minutes = duration.minutes();

                  let result = "";

                  if (years > 0) {
                    result = `${years} year${years > 1 ? "s" : ""} ago`;
                  } else if (months > 0) {
                    result = `${months} month${months > 1 ? "s" : ""} ago`;
                  } else if (days > 0) {
                    result = `${days} day${days > 1 ? "s" : ""} ago`;
                  } else if (hours > 0) {
                    result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
                  } else if (minutes > 0) {
                    result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
                  } else {
                    result = "just now";
                  }

                  console.log(result);
                  return (
                    <div className="col-md-6 mb-3 ps-0 d-flex align-items-stretch">
                      <div className="jobCard p-3 w-100 d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <Link to={`/JobDetail/${item.jobId}`}>
                            <div className="pointer me-2">
                              <img
                                src={item?.compLogo}
                                alt=""
                                width={50}
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          </Link> */}
                          {/* <div className='f-14 f-700'>{item.compName}</div> */}
                          {/* <span className="f-14 f-700">{item.compName}</span> */}
                          <p className="f-16 fw-bold pointer">
                            {/* {item.title.length > 10
                              ? `${item.title.substring(0, 10)}...`
                              : item.title} */}
                            {item.title}
                          </p>
                          <ExpiredJobAction
                            item={item}
                            AllJobList={ExpiredJobAction}
                            setJobList={setExpiredJobList}
                            jobList={expiredjobList}
                            totalPages={totalPages1}
                            setTotalPages={setTotalPages1}
                            page={page1}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4 emoji-container">
                          <Link to={`/JobDetail/${item.jobId}`}>
                            {/* <p
                              className="f-16 fw-bold pointer"
                            // onClick={() => {
                            //   setShowjobDetails(true);
                            //   setJobId(item.jobId);
                            // }}
                            >
                              {item.title}
                            </p> */}
                            {/* <p className="f-16 fw-bold pointer">
                              {item.title.length > 10
                                ? `${item.title.substring(0, 10)}...`
                                : item.title}
                            </p> */}
                          </Link>
                          <span className="emoji-name job-name-details">
                            {item.title}
                          </span>

                          {savedJobs.has(item?.jobId) || item?.isSaved === true ? (
                            <div className="d-flex align-items-center postAction">
                              {/* <span
                                className="material-symbols-sharp pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    unsaveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              >
                                bookmark
                              </span> */}
                              <BookmarkBorderSharpIcon
                                className="material-symbols-sharp pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    unsaveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center postAction">
                              {/* <span
                                className="material-symbols-outlined pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    saveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              >
                                bookmark
                              </span> */}
                              <BookmarkBorderSharpIcon
                                className="material-symbols-outlined pointer"
                                onClick={() => {
                                  if (guestModeData !== "true") {
                                    saveJob(item?.jobId);
                                  }
                                }}
                                disabled={guestModeData === "true"}
                              />
                            </div>
                          )}
                        </div>
                        {/* <p className="f-14">{item.shortDescription}</p> */}
                        <p className="f-14">
                          {item.shortDescription.length > 20
                            ? `${item.shortDescription.substring(0, 25)}...`
                            : item.shortDescription}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                          <small className="f-8">Posted {result}</small>
                          <div className="badge badge-date">
                            {/* {item?.expiringInDays} days Left */}
                            expired {-item?.expiringInDays} days ago
                          </div>
                        </div>
                        <div className="jobType mt-2 mb-3">
                          <div className="badge badge-job me-3">
                            {item.jbTypName}
                          </div>
                          <div className="badge badge-job me-3">
                            {item.workLocationTypeName}
                          </div>
                          <div className="badge badge-job">
                            {item.designationName}
                          </div>
                        </div>


                        <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={AllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                      </div>
                    </div>
                  );
                })
            )}
            {hasMoreData1 ? (''
            ) : (
              <p className="text-center">No more data available</p >
            )}
            {
              nextBatchLoading1 && (
                <div className="text-center">
                  <img src={post?.loader} width={50} />
                </div>
              )
            }
          </>
        )
      default:
        return null;
    }
  };

  const [showJobModal, setShowJobModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [showAddReactionModal, setShowAddReactionModal] = useState(false);

  const openJobModal = () => {
    setShowJobModal(true);
  };
  const closeJobModal = () => {
    setShowJobModal(false);
  };
  const [showJobDraftModal, setShowJobDraftModal] = useState(false);
  const openJobDraftModal = () => {
    setShowJobDraftModal(true);
  };
  const closeJobDraftModal = () => {
    setShowJobDraftModal(false);
  };
  return (
    <>
      <div className="row">
        {companyDetails?.isOwned === false ? <>
          {isLoading ? (
            <div className="mt-3">
              <div className="text-center">
                <img src={post?.loader} width={50} />
              </div>
            </div>
          ) : isError ? (
            <div className="mt-2 error-image-cont error-image-job">
              <img src={post.errorSmall} alt="404 Not Found" width={200} />
            </div>
          ) : (
            jobList &&
            jobList
              .filter((item) => !item.isDeleted)
              ?.map((item, index) => {
                const now = moment();
                const itemTime = moment.utc(item?.creationTime);
                const duration = moment.duration(now.diff(itemTime));

                const years = duration.years();
                const months = duration.months();
                const days = duration.days();
                const hours = duration.hours();
                const minutes = duration.minutes();

                let result = "";

                if (years > 0) {
                  result = `${years} year${years > 1 ? "s" : ""} ago`;
                } else if (months > 0) {
                  result = `${months} month${months > 1 ? "s" : ""} ago`;
                } else if (days > 0) {
                  result = `${days} day${days > 1 ? "s" : ""} ago`;
                } else if (hours > 0) {
                  result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
                } else if (minutes > 0) {
                  result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
                } else {
                  result = "just now";
                }

                console.log(result);
                return (
                  <div className="col-md-6 mb-3 ps-0 d-flex align-items-stretch">
                    <div className="jobCard p-3 w-100 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <Link to={`/JobDetail/${item.jobId}`}>
                              <div className="pointer me-2">
                                <img
                                  src={item?.compLogo}
                                  alt=""
                                  width={50}
                                  style={{ borderRadius: "50px" }}
                                />
                              </div>
                            </Link> */}
                        {/* <div className='f-14 f-700'>{item.compName}</div> */}
                        {/* <span className="f-14 f-700">{item.compName}</span> */}
                        <p className="f-16 fw-bold pointer">
                          {/* {item.title.length > 10
                                ? `${item.title.substring(0, 10)}...`
                                : item.title} */}
                          {item.title}
                        </p>
                        <JobAction
                          item={item}
                          AllJobList={AllJobList}
                          setJobList={setJobList}
                          jobList={jobList}
                          totalPages={totalPages}
                          setTotalPages={setTotalPages}
                          page={page}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-4 emoji-container">
                        <Link to={`/JobDetail/${item.jobId}`}>
                          {/* <p
                                className="f-16 fw-bold pointer"
                              // onClick={() => {
                              //   setShowjobDetails(true);
                              //   setJobId(item.jobId);
                              // }}
                              >
                                {item.title}
                              </p> */}
                          {/* <p className="f-16 fw-bold pointer">
                                {item.title.length > 10
                                  ? `${item.title.substring(0, 10)}...`
                                  : item.title}
                              </p> */}
                        </Link>
                        <span className="emoji-name job-name-details">
                          {item.title}
                        </span>

                        {savedJobs.has(item?.jobId) || item?.isSaved === true ? (
                          <div className="d-flex align-items-center postAction">
                            {/* <span
                                  className="material-symbols-sharp pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      unsaveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                >
                                  bookmark
                                </span> */}
                            <BookmarkBorderSharpIcon
                              className="material-symbols-sharp pointer"
                              onClick={() => {
                                if (guestModeData !== "true") {
                                  unsaveJob(item?.jobId);
                                }
                              }}
                              disabled={guestModeData === "true"}
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center postAction">
                            {/* <span
                                  className="material-symbols-outlined pointer"
                                  onClick={() => {
                                    if (guestModeData !== "true") {
                                      saveJob(item?.jobId);
                                    }
                                  }}
                                  disabled={guestModeData === "true"}
                                >
                                  bookmark
                                </span> */}
                            <BookmarkBorderSharpIcon
                              className="material-symbols-outlined pointer"
                              onClick={() => {
                                if (guestModeData !== "true") {
                                  saveJob(item?.jobId);
                                }
                              }}
                              disabled={guestModeData === "true"}
                            />
                          </div>
                        )}
                      </div>
                      {/* <p className="f-14">{item.shortDescription}</p> */}
                      <p className="f-14">
                        {item.shortDescription.length > 20
                          ? `${item.shortDescription.substring(0, 25)}...`
                          : item.shortDescription}
                      </p>
                      <div className="d-flex justify-content-between align-items-center mt-1 job-card-post-date">
                        <small className="f-8">Posted {result}</small>
                        <div className="badge badge-date">
                          {item?.expiringInDays} days Left
                        </div>
                      </div>
                      <div className="jobType mt-2 mb-3">
                        <div className="badge badge-job me-3">
                          {item.jbTypName}
                        </div>
                        <div className="badge badge-job me-3">
                          {item.workLocationTypeName}
                        </div>
                        <div className="badge badge-job">
                          {item.designationName}
                        </div>
                      </div>


                      <JobApply isAppliedByYou={item?.isApplied} jobId={item?.jobId} getAllJobList={AllJobList} item={item} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />
                    </div>
                  </div>
                );
              })
          )}
          {hasMoreData ? (''
          ) : (
            <p className="text-center">No more data available</p >
          )}
          {
            nextBatchLoading && (
              <div className="text-center mb-4">
                <img src={post?.loader} width={50} />
              </div>
            )
          }
        </> : <>
          <div className="d-flex align-items-center justify-content-between mb-4 border-custom pb-2 pt-2">
            <div className="tabButton d-flex  connectionTab">
              <NavLink
                onClick={() => setActiveTab(1)}
                className={`text-center me-4   ${activeTab === 1 ? "activeTab" : ""
                  }`}
              >
                <p className="f-12 tabName">Active</p>
              </NavLink>
              <NavLink
                onClick={() => setActiveTab(2)}
                className={`text-center   ${activeTab === 2 ? "activeTab" : ""
                  }`}
              >
                <p className="f-12 tabName">Expired</p>
              </NavLink>
            </div>
            <div className="text-end">
              <button className="mainBtn1 " onClick={(e) => {
                if (guestModeData === "true") {
                  setShowJobDraftModal(false);
                } else {
                  openJobDraftModal(); // Open job draft modal otherwise
                }
              }}>
                Create job
              </button>
            </div>
          </div>
          <div className="row">
            {renderTabContent()}
          </div>
        </>}

      </div>

      {bookmarkModal && <ModalForGuest closeModalData={closeModalData} />}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {showAppliedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure want to unapply ?</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>
              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={() => unapplyNew()}
                >
                  UnApply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showJobModal && (
        <AddJob
          openModal={openJobModal}
          showModal={showJobModal}
          closeModal={closeJobModal}
        />
      )}
      {showJobDraftModal && (
        <DraftJob
          openModal={openJobDraftModal}
          showModal={showJobDraftModal}
          closeModal={closeJobDraftModal}
        />
      )}
      {unauthorisedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                  <h4 className="text-center AdBlocker">Warning!</h4>
                  <p className="text-center ad-p">
                    Your session has expired or you are not authorized to access this resource. Please log in again to continue.
                  </p>
                  <div className="text-center m-auto mt-3">
                    <button
                      type="button"
                      className="mainBtn1 "
                      onClick={() => {
                        setUnauthorisedModal(!unauthorisedModal)
                        navigate("/SignIn");
                        localStorage.clear();
                        sessionStorage.removeItem('hasModalShown');
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Jobs;
