import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../API/axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SinglePostInteraction from "./SinglePostInteraction";
import SinglePostComment from "./SinglePostComment";
import SinglePostEngagement from "./SinglePostEngagement";
import { header, post } from "../../assets/Images/ImagesLink";
import LikesDetails from "./LikesDetails";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const ImageSliderModal = ({
  setIspostmodalOpen,
  ispostmodalOpen,
  onRequestClose,
  isOpen,
  singlepostId,
  setSinglePostId,
  setSinglePostContent,
  dLike,
  openModal,
  updateLikeCount,
  setDLike,
  isfollowed,
  setIsfollowed,
  sliderSettings,
  initialSlideIndex,
  setInitialSlideIndex,
  imageArray,
  setPostList,
}) => {

  const guestModeData = localStorage.getItem("guestMode");
  const sessionId = localStorage.getItem("sessionId");
  const [singlepostList, setSinglePostList] = useState("");

  const AllPostList = async () => {
    try {
      await axios
        .get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${singlepostId}`)
        .then((resData) => {
          setSinglePostList(resData.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllPostList();
  }, [singlepostId]);

  const settings = {
    dots: false,
    arrows: singlepostList?.mediaFilesList?.length > 1,
    infinite: singlepostList?.mediaFilesList?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [showModal, setShowModal] = useState(false);
  const [postLikedType, setPostLikedType] = useState(false);
  const [postId, setPostId] = useState("");

  const handleShowModal = (item) => {
    setPostId(item.postId);
    setPostLikedType(item.likedType);
    setShowModal(true);
  };
  const handleLikesCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isOpen) {
        if (e.key === "ArrowLeft") {
          document.querySelector(".slick-prev").click();
        } else if (e.key === "ArrowRight") {
          document.querySelector(".slick-next").click();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const [loading, setLoading] = useState(true); // State for loading status
  // Function to handle image loading
  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when image is fully loaded
  };
  // console.log('singlepostList', singlepostList);


  console.log('isModalOpen', isOpen);


  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  const addTargetBlankToAnchors = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;

    const anchors = div.querySelectorAll('a');
    anchors.forEach(anchor => {
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer');
    });

    return div.innerHTML;
  };

  const linkifyContent = (htmlContent) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return htmlContent.replace(urlPattern, function (url) {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  return (
    <>
      {isOpen && (
        <div
          className={` ${singlepostList?.mediaFilesList?.length === 0
            ? "modal-md"
            : "modal-xl"
            } modal fade show postAdd`}
          // className="modal modal-xl fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-dialog-centered m-auto"
            role="document"
            style={{ height: "100vh" }}
          >
            <div
              className="modal-content ImagesliderScroller d-flex  m-auto"
              style={{ overflowY: "auto", height: "100vh" }}
            >
              <div className="modalBorder d-flex justify-content-between align-items-center p-3">
                <div className="Logo">
                  <img src={header.mainLogo} alt="" />
                </div>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    onRequestClose();
                    setIspostmodalOpen(false);
                    setSinglePostId("");
                    setInitialSlideIndex(0);
                  }}
                />
              </div>

              <div className="row w-100 m-0">
                <div
                  className={` ${singlepostList?.mediaFilesList?.length === 0
                    ? "d-none"
                    : "col-md-8"
                    } p-0`}
                >
                  <div className="image-slider-modal text-center">
                    {imageArray?.length > 1 ? (
                      <Slider initialSlide={initialSlideIndex}>
                        {singlepostList?.mediaFilesList?.map((image, index) => (
                          <div key={index}>
                            <img
                              src={image.mediaName}
                              alt={`Slide ${index}`}
                              className="img-fluid m-auto modal-image"
                              onLoad={handleImageLoad} // Call handleImageLoad when image loads
                            />
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <img
                        src={singlepostList?.mediaFilesList?.[0]?.mediaName}
                        // alt="Single Image"
                        className="img-fluid m-auto modal-image"
                        onLoad={handleImageLoad} // Call handleImageLoad when image loads
                      />
                    )}
                  </div>
                </div>
                <div
                  className={` ${singlepostList?.mediaFilesList?.length === 0
                    ? "col-md-12"
                    : "col-md-4"
                    } p-0`}
                  style={{ overflowY: "auto", maxHeight: "90vh" }}
                >
                  <div className="featured-card user-postOnlyContent p-2 m-2">
                    <div className="feature-content p-2 d-flex justify-content-between">
                      <Link to={`/User/${singlepostList.userUid}`}>
                        <div className="d-flex align-items-center">
                          <div className="ImgAvatar">
                            <img
                              src={singlepostList.userAvatar}
                              alt=""
                              style={{ borderRadius: "50px" }}
                            />
                          </div>
                          <div className="ms-2">
                            <h4 className="f-14 fw-bold mb-0">
                              {singlepostList.userName}
                            </h4>
                            <p className="f-12">
                              {/* {moment(singlepostList.creationTime).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )} */}
                              {moment
                                .utc(singlepostList.creationTime)
                                .local()
                                .format("DD MMMM YYYY [at] h:mm a")}
                            </p>
                          </div>
                        </div>
                      </Link>

                      {guestModeData === "true" ? null : (
                        <div className="row">
                          <div>
                            <SinglePostInteraction
                              postId={singlepostList?.postId}
                              AllPostList={AllPostList}
                              singlepostList={singlepostList}
                              setSinglePostList={setSinglePostList}
                              isfollowed={isfollowed}
                              setIsfollowed={setIsfollowed}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {singlepostList?.postContent && (
                      <div className="f-14 p-2 postContent">
                        <div
                          className='postContentDiv'
                          dangerouslySetInnerHTML={{
                            __html: linkifyContent(
                              addTargetBlankToAnchors(singlepostList?.postContent)
                            )
                          }}
                        />
                      </div>
                    )}
                    {/* <p className="f-14 p-2">{singlepostList?.postContent}</p> */}
                    <div className="feed-post p-2">
                      {guestModeData === "true" ? null : (
                        <div className="row">
                          <div className="addComment">
                            <SinglePostComment
                              isOwnedPost={singlepostList?.isOwned}
                              postId={singlepostList?.postId}
                              AllPostList={AllPostList}
                              singlepostList={singlepostList}
                              setSinglePostList={setSinglePostList}
                              ispostmodalOpen={ispostmodalOpen}
                              setIspostmodalOpen={setIspostmodalOpen}
                              setPostList={setPostList}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`postData d-flex align-items-center ${singlepostList?.likeCount === 0 && singlepostList?.commentCount === 0 && singlepostList?.shareCount === 0
                      ? ""
                      : "border-custom1"
                      }`}>
                      <div className="ms-2">
                        {singlepostList?.likeCount + dLike === 0 ? (
                          ""
                        ) : (
                          <p
                            className="f-10 like-details"
                            onClick={() => handleShowModal(singlepostList)}
                          >
                            {singlepostList?.likeCount + dLike} likes
                          </p>
                        )}
                      </div>
                      <div className="ms-auto d-flex">
                        {singlepostList?.commentCount === 0 ? (
                          ""
                        ) : (
                          <p className="f-10 me-2 pointer">
                            {singlepostList?.commentCount} comments
                          </p>
                        )}

                        <p className="f-10">
                          {singlepostList?.shareCount === 0
                            ? ""
                            : `&  ${singlepostList?.shareCount} Shares`}
                        </p>
                      </div>
                    </div>
                    <div className="postengagement">
                      <SinglePostEngagement
                        isOwnedPost={singlepostList?.isOwned}
                        postId={singlepostList?.postId}
                        AllPostList={AllPostList}
                        singlepostList={singlepostList}
                        setSinglePostList={setSinglePostList}
                        ispostmodalOpen={ispostmodalOpen}
                        setIspostmodalOpen={setIspostmodalOpen}
                        openModal={openModal}
                        setSinglePostId={setSinglePostId}
                        setSinglePostContent={setSinglePostContent}
                        setDLike={setDLike}
                        updateLikeCount={updateLikeCount}
                        setPostList={setPostList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {showModal && ( */}
      <LikesDetails
        showModal={showModal}
        handleLikesCloseModal={handleLikesCloseModal}
        postId={postId}
        postLikedType={postLikedType}
      />
      {/* )} */}
    </>
  );
};

export default ImageSliderModal;
