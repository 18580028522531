import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import { header, resume } from "../../assets/Images/ImagesLink";
import QRCode from "react-qr-code";
import PinDropSharpIcon from '@mui/icons-material/PinDropSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import MyLocationSharpIcon from '@mui/icons-material/MyLocationSharp';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SupervisedUserCircleSharpIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import WorkspacePremiumSharpIcon from '@mui/icons-material/WorkspacePremiumSharp';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import PhoneIphoneSharpIcon from '@mui/icons-material/PhoneIphoneSharp';
import SummarizeSharpIcon from '@mui/icons-material/SummarizeSharp';
import TitleSharpIcon from '@mui/icons-material/TitleSharp';
import SubtitlesSharpIcon from '@mui/icons-material/SubtitlesSharp';


const ResumePdf = forwardRef(({
    ccvInfo,
    ccvEducation,
    ccvCertification,
    ccvAward,
    ccvExperience,
    CCVAttachement,
    skills,
    UserId
}, ref) => {

    return (
        <div
            id="pdfContent"
            ref={ref}
            style={{
                width: "100%",
                backgroundColor: "white",
                padding: "20px",
                marginBottom: "10px",
                marginTop: "10px",
                fontFamily: "PlusJakartaSans-Regular",
                boxSizing: "border-box"
            }}
        >
            {
                ccvInfo === "" ? (
                    <h5 className="mt-5 d-flex justify-content-center">
                        No Data Available
                    </h5>
                ) : (
                    <div className="user-resume UpdateProfile">
                        <div className="resume-header d-flex align-items-center px-3 py-3">
                            <div className="resume-profile-image">
                                <img src={ccvInfo?.userImage} alt="" srcset="" height={{}} />
                            </div>
                            <div className="resumeHeader ms-3">
                                <h3>{ccvInfo?.fullName}</h3>
                                <div className="uid">
                                    <span className="activeIcon"></span>
                                    <h4 className="ms-3 mb-0">{ccvInfo?.userUid}</h4>
                                </div>
                            </div>
                            <div className="d-flex ms-auto">
                                {/* <Button className="f-13   mt-2" onClick={downloadResume}>
                  Download
                </Button> */}
                                <div className="resume-logo weLinkImage">
                                    <img src={header.WeLinkWhite} alt="" srcset="" />
                                </div>
                                <QRCode
                                    value={`https://welinkjobs.com/Resume/${UserId}`}
                                    className="ms-1"
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        border: "1px solid white",
                                    }}
                                />
                                {/* <DownloadSharpIcon onClick={downloadResume} className='pointer ms-2' style={{ color: 'white' }} /> */}
                            </div>
                        </div>
                        {(ccvEducation.length === 0 || ccvExperience.length === 0) && (
                            <p className="warningText"> Warning: Incomplete Resume.</p>
                        )}
                        <div className="row border-custom">
                            <div className="col-md-6">
                                {ccvInfo?.fullName &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1  pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <PersonSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        Full Name
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.fullName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                {ccvInfo?.title &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1  pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <SubtitlesSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        Title
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.title}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row border-custom">
                            <div className="col-md-6">
                                {ccvInfo?.dob &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1 pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <CalendarMonthSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        Date Of Birth
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.dob}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                {ccvInfo?.currentCity &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1 pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <MyLocationSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        City
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.currentCity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row border-custom">
                            <div className="col-md-6">
                                {ccvInfo?.email &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1  pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <MailOutlineSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        Email
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                {ccvInfo?.phoneNumber &&
                                    <div
                                        className={`analytic-sub mt-2 mb-1 pb-3`}
                                    >
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="ms-2">
                                                <div className="d-flex verified">
                                                    <PhoneIphoneSharpIcon className="HeadingIcon material-symbols-sharp" />
                                                    <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                        Phone Number
                                                    </p>
                                                </div>
                                                <p className="ms-1 f-12">
                                                    {ccvInfo?.phoneNumber}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {ccvInfo?.webSiteLink &&
                            <div
                                className={`analytic-sub mt-2 mb-1 border-custom pb-3`}
                            >
                                <div className="d-flex align-items-start justify-content-between">
                                    <div className="ms-2">
                                        <div className="d-flex verified">
                                            <LanguageSharpIcon className="HeadingIcon material-symbols-sharp" />
                                            <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                Website
                                            </p>
                                        </div>
                                        <a
                                            className="ms-1 f-12"
                                            href={
                                                ccvInfo?.webSiteLink?.startsWith('http://') || ccvInfo?.webSiteLink?.startsWith('https://')
                                                    ? ccvInfo?.webSiteLink
                                                    : `http://${ccvInfo?.webSiteLink}`
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {ccvInfo?.webSiteLink}
                                        </a>
                                    </div>

                                </div>
                            </div>
                        }
                        {ccvInfo?.summary &&
                            <div
                                className={`analytic-sub mt-2 mb-1 pb-3`}
                            >
                                <div className="d-flex align-items-start justify-content-between">
                                    <div className="ms-2">
                                        <div className="d-flex verified">
                                            <SummarizeSharpIcon className="HeadingIcon material-symbols-sharp" />
                                            <p className="f-14 ms-1 pb-1 fw-bold me-2">
                                                Summary
                                            </p>
                                        </div>

                                        <p className="ms-1 f-12">
                                            {ccvInfo?.summary}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        }
                        {ccvEducation.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3">
                                <div className="d-flex justify-content-between mb-2 border-custom pb-2">
                                    <div className="d-flex">
                                        <SchoolOutlinedIcon className="HeadingIcon material-symbols-sharp" />
                                        <p className="f-16 fw-bold ms-1">Education</p>
                                    </div>
                                </div>
                                {ccvEducation &&
                                    ccvEducation.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            // console.log('itegfsdfdm', item);
                                            const isLastItem = index === ccvEducation?.length - 1;
                                            return (

                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                    key={index}
                                                >
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="ms-2">
                                                            <div className="d-flex verified">
                                                                <p className="f-14 ms-1 fw-bold me-2">
                                                                    {item.institutionName && (
                                                                        <>
                                                                            {item.institutionName}
                                                                            {/* {item?.isOrgVrfd === true ? (
                                                                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                                                                            ) : item?.isOrgRgd === true ? (
                                                                                <img src={resume.register} width={18} height={18} className="ms-1" />
                                                                            ) : (
                                                                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                                                            )}
                                                                            {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="d-flex">
                                                                {item.field && <p className="ms-1 me-1 f-14 fw-bold">Field: {item.field}</p>}
                                                                {item.field && <div className="dot"></div>}
                                                                {item.degree && <p className="ms-2 f-14 fw-bold">{item.degree}</p>}
                                                            </div>
                                                            {item.activities && <p className="f-12">Activity: {item.activities}</p>}
                                                            {item.grade && <p className="f-12">Grade: {item.grade}</p>}
                                                            {item.description && <p className="f-12">{item.description}</p>}
                                                            <div className="d-flex align-items-center">
                                                                {item.cityName && (
                                                                    <>
                                                                        <PlaceSharpIcon className="material-symbols-sharp locationIcon" />
                                                                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                                                        <div className="dot"></div>
                                                                        <p className="ms-2 f-12">
                                                                            ({moment(item.startYear).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endYear).format("YYYY")})
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        })}
                            </div>}
                        {ccvExperience.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3 page-break">
                                <div className="d-flex justify-content-between mb-2 border-custom ">
                                    <div className="d-flex">
                                        <BusinessCenterOutlinedIcon className="HeadingIcon material-symbols-sharp" />
                                        <p className="f-16 fw-bold ms-1">Experience</p>
                                    </div>
                                </div>
                                {ccvExperience &&
                                    ccvExperience.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            // console.log('ExperienceId', item);
                                            const isLastItem = index === ccvExperience.length - 1;
                                            return (
                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                >
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="ms-2">
                                                            <div className="d-flex verified">
                                                                <p className="f-14 ms-1 fw-bold me-2">
                                                                    {item.jobTitle && (
                                                                        <>
                                                                            {item.jobTitle}
                                                                            {/* {item?.isOrgVrfd === true ? (
                                                                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                                                                            ) : item?.isOrgRgd === true ? (
                                                                                <img src={resume.register} width={18} height={18} className="ms-1" />
                                                                            ) : (
                                                                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                                                            )}
                                                                            {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            {item.companyName && <p className="ms-1 me-1 f-14 fw-bold">{item.companyName}</p>}
                                                            <div className="d-flex align-items-center">
                                                                {item.jobTypeName && <p className="ms-1 me-2 f-12">{item.jobTypeName}</p>}
                                                                {item.cityName && (
                                                                    <>
                                                                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                                                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                                                        <div className="dot"></div>
                                                                        <p className="ms-2 f-12">
                                                                            ({moment(item.startDate).format("YYYY")} - {item.isPresent === true ? "Present" : moment(item.endDate).format("YYYY")})
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            {item.positionHeadline && <p className="f-12">{item.positionHeadline}</p>}
                                                            {item.description && <p className="f-12">{item.description}</p>}
                                                        </div>
                                                    </div>
                                                    {item.rsmExperienceAchievements.length === 0 ? '' : <div className="mt-3 ARCard">
                                                        <div className="d-flex justify-content-between border-custom">
                                                            <div className=" d-flex">
                                                                <EmojiEventsOutlinedIcon className="HeadingIcon material-symbols-sharp" style={{ marginTop: '2px' }} />
                                                                <h6 className="f-14 ms-1">
                                                                    Experience Achievement
                                                                </h6>
                                                            </div>
                                                        </div>

                                                        {item.rsmExperienceAchievements &&
                                                            item.rsmExperienceAchievements
                                                                .filter((item) => !item.isDeleted)
                                                                ?.map((roleItem, index) => {
                                                                    return (
                                                                        <div
                                                                            className="d-flex align-items-start justify-content-between mt-2"
                                                                            key={index}
                                                                        >
                                                                            <div className="ms-2">
                                                                                <div className="d-flex verified">
                                                                                    <p className="f-14 ms-1  me-2 m-0">
                                                                                        {roleItem.achievement}
                                                                                    </p>
                                                                                </div>
                                                                                <p className="f-14 ms-1 me-2 m-0">
                                                                                    {roleItem.achivementDate}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                    </div>}
                                                    {item.rsmExperienceRoles.length === 0 ? '' : <div className="mt-3 ARCard">
                                                        <div className="d-flex justify-content-between border-custom">
                                                            <div className="d-flex" >
                                                                <SupervisedUserCircleSharpIcon className="HeadingIcon material-symbols-sharp" style={{ marginTop: '2px' }} />
                                                                <h6 className="f-14 ms-1">
                                                                    Experience Role
                                                                </h6>
                                                            </div>
                                                        </div>

                                                        {item.rsmExperienceRoles &&
                                                            item.rsmExperienceRoles
                                                                .filter((item) => !item.isDeleted)
                                                                ?.map((roleItem, index) => {
                                                                    return (
                                                                        <div
                                                                            className="d-flex align-items-start justify-content-between mt-2"
                                                                            key={index}
                                                                        >
                                                                            <div className="ms-2">
                                                                                <div className="d-flex verified">
                                                                                    <p className="f-14 ms-1 me-2 m-0">
                                                                                        {roleItem.role}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                    </div>}

                                                </div>
                                            );
                                        })}
                            </div>
                        }

                        {ccvCertification.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3 page-break">
                                <div className="d-flex justify-content-between mb-2 border-custom pb-2">
                                    <div className="d-flex">
                                        <WorkspacePremiumSharpIcon className="HeadingIcon material-symbols-sharp" />
                                        <p className="f-16 fw-bold ms-1">Certification</p>
                                    </div>
                                </div>
                                {/* <div className='analytics d-flex justify-content-between mt-3'> */}
                                {/* ccvCertifications */}
                                {ccvCertification &&
                                    ccvCertification.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            // console.log('item', item);
                                            const isLastItem = index === ccvCertification.length - 1;
                                            return (

                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                >
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                                                        <div className="ms-2">
                                                            <div className="d-flex verified">
                                                                <p className="f-14 ms-1 fw-bold me-2">
                                                                    {item.name && (
                                                                        <>
                                                                            {item.name}
                                                                            {/* {item?.isOrgVrfd === true ? (
                                                                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                                                                            ) : item?.isOrgRgd === true ? (
                                                                                <img src={resume.register} width={18} height={18} className="ms-1" />
                                                                            ) : (
                                                                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                                                            )}
                                                                            {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            {item.orgName && <p className="ms-1 me-1 f-14 fw-bold">{item.orgName}</p>}
                                                            <div className="d-flex align-items-center">
                                                                {item.recognition && <p className="ms-1 me-1 f-12">{item.recognition}</p>}
                                                                {item.credentialId && (
                                                                    <>
                                                                        <div className="dot"></div>
                                                                        <p className="ms-2 f-12">ID: {item.credentialId}</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                {item.cityName && (
                                                                    <>
                                                                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                                                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                                                        <div className="dot"></div>
                                                                        <p className="ms-2 f-12">
                                                                            ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </div>
                        }
                        {ccvAward.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3 page-break">
                                <div className="d-flex justify-content-between mb-2 border-custom pb-2">
                                    <div className="d-flex">
                                        <MilitaryTechSharpIcon className="HeadingIcon material-symbols-sharp" />
                                        <p className="f-16 fw-bold ms-1">Awards</p>
                                    </div>
                                </div>
                                {ccvAward &&
                                    ccvAward.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            // console.log('item', item);
                                            const isLastItem = index === ccvAward.length - 1;
                                            return (
                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                >
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        {/* <img src={NewsFeed.companyLogo} alt="" width={40} height={40} /> */}
                                                        <div className="ms-2">
                                                            <div className="d-flex verified">
                                                                <p className="f-14 ms-1 fw-bold me-2">
                                                                    {item.name && (
                                                                        <>
                                                                            {item.name}
                                                                            {/* {item?.isOrgVrfd === true ? (
                                                                                <img src={resume.verified} width={18} height={18} className="ms-1" />
                                                                            ) : item?.isOrgRgd === true ? (
                                                                                <img src={resume.register} width={18} height={18} className="ms-1" />
                                                                            ) : (
                                                                                <img src={resume.unregister} width={18} height={18} className="ms-1" />
                                                                            )}
                                                                            {item?.isInstitutionDelete && <img src={resume.deleted} width={15} height={15} />} */}
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            {item.orgName && <p className="ms-1 me-1 f-14 fw-bold">{item.orgName}</p>}
                                                            <div className="d-flex align-items-center">
                                                                {item.recognition && <p className="ms-1 me-1 f-12">{item.recognition}</p>}
                                                                {item.description && (
                                                                    <>
                                                                        <div className="dot"></div>
                                                                        <p className="ms-2 f-12">{item.description}</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                {item.cityName && (
                                                                    <>
                                                                        <PlaceSharpIcon className="material-symbols-sharp locationIcon ms-3" />
                                                                        <p className="ms-1 me-1 f-12">{item.cityName}</p>
                                                                        {/* <div className="dot"></div> */}
                                                                    </>
                                                                )}
                                                                <p className="ms-2 f-12">
                                                                    ({moment(item.issueDate).format("YYYY")} - {moment(item.expirationDate).format("YYYY")})
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </div>
                        }
                        {skills.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3 page-break">
                                <div className="d-flex justify-content-between mb-2 border-custom pb-2">
                                    <div className="d-flex">
                                        <PsychologySharpIcon className="HeadingIcon material-symbols-sharp" />
                                        <p className="f-16 fw-bold ms-1">Skills</p>
                                    </div>
                                </div>
                                {/* <div className='analytics d-flex justify-content-between mt-3'> */}
                                {skills &&
                                    skills.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            console.log('ExperienceId', item);
                                            const isLastItem = index === skills.length - 1;
                                            return (
                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                    key={index}
                                                >
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <div className="d-flex align-items-center verified ">
                                                            <p className="f-14 ms-1 pb-0 fw-bold  me-2">
                                                                {item.skillName}
                                                            </p>
                                                        </div>
                                                        {/* )} */}
                                                    </div>
                                                    <p className="ms-1 f-12">
                                                        {item.firmIndustryTypeName}
                                                    </p>
                                                    {item.endorsedCount && <p className="ms-1 f-12">
                                                        {/* Frontend Web Developer at HyperautoX Information
                          Technology LLC */}
                                                        {item.endorsedCount} endorsement
                                                    </p>}
                                                </div>
                                            );
                                        })}
                            </div>
                        }
                        {/* {CCVAttachement.length === 0 ? '' :
                            <div className="UpdateProfile pb-0 p-3 page-break">
                                <div className="d-flex justify-content-between mb-2 border-custom pb-2" >
                                    <div className="d-flex">
                                        <AttachFileSharpIcon className="HeadingIcon material-symbols" />
                                        <p className="f-16 fw-bold ms-1">Attachement</p>
                                    </div>
                                </div>
                                {CCVAttachement &&
                                    CCVAttachement.filter(item => item?.status !== 0)
                                        ?.map((item, index) => {
                                            const isLastItem = index === CCVAttachement?.length - 1;
                                            return (
                                                <div
                                                    className={`analytic-sub mt-2 mb-1 ${isLastItem ? "" : "border-custom"
                                                        } pb-3 ${item?.status === 0 ? 'disableText' : ''}`}
                                                    key={index}
                                                >
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="ms-2">
                                                            <div className="d-flex">
                                                                <p className="f-14 ms-1 pb-1 fw-bold">
                                                                    {item?.docName}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </div>
                        } */}
                    </div>
                )
            }
        </div>
    );
});

export default ResumePdf;