import React, { useEffect, useState } from "react";
import { notificationImages } from "../../assets/Images/ImagesLink";
import "../../assets/css/JobNotification.css";
import {
  Table,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Navigation/Header";
import LeftNavigation from "../Navigation/LeftNavigation";
import ReactSelect from "react-select";
import axios from "../../API/axios";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import HiringStatus from "./HiringStatus";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

// import { Link } from 'react-router-dom'
const Interview = () => {
  const navigate = useNavigate();


  const sessionId = localStorage.getItem("sessionId");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  //************************Job Application Start  ************************//
  const [loading, setLoading] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [jobApplication, setJobApplication] = useState([]);
  const getJobApplication = (page) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 12
    };
    axios
      .post(`JobApplication/GetAllInterviews`, data)
      .then((response) => {
        // console.log("getJobApplication", response);
        // const res = response.data.responseData.jobApplications;
        // setJobApplication(res);
        if (response.status === 204) {
          setErrorImage(true)
        } else {
          setTotalPages(response.data.responseData.paging.totalPages);
          const newPosts = response.data.responseData.interviewsList;
          setJobApplication((prev) => {
            const postSet = new Set(prev.map((post) => post.jbApplctnId));
            const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.jbApplctnId));
            return [...prev, ...uniqueNewPosts];
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (page <= totalPages) {
      getJobApplication(page);
    }
  }, [page]);

  const handleInfiniteScroll = async () => {
    try {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  //************************Job Application End  ************************//


  const [interviewModal, setInterviewModal] = useState(false)
  const [interviewDetails, setInterviewDetails] = useState('')

  return (
    <>
      <div className="jobHeader p-3 d-flex justify-content-between">
        <div className="friendsHeaderText">
          <h1>Interviews</h1>
        </div>
        <a
          className="linkSearch f-14 d-flex align-items-center  pointer"
          onClick={() => navigate('/JobApplications')}
        >
          <ArrowBackSharpIcon className="material-symbols-sharp" />
          Job Applications
        </a>
      </div>
      {errorImage ? <div className="mt-2 text-center">
        <img
          src={`https://cdn.wlj.app/Images/Default%20Images/404/PNG/No%20Result%202.png`}
          alt="404 Not Found"
          width={'400px'}
        />
      </div> :
        <div className="job-notification-main-container">
          <div className="job-notification-card ">
            <Card className="transaction-table vh-100">
              <Table responsive className="notification-table">
                <thead>
                  <tr className="f-12 f-600 table-header">
                    <th>Candidates Name</th>
                    <th>Description</th>
                    <th>Apply On</th>
                    <th>Status</th>
                    {/* <th>Remark</th> */}
                    <th >CV </th>
                  </tr>
                </thead>
                <tbody>
                  {jobApplication &&
                    jobApplication?.map((item, index) => {
                      console.log("get job item", item);
                      return (
                        <tr >
                          <td className="row-gap">
                            <div className="card-image-text d-flex align-items-center">
                              <div className="card-image me-1">
                                <img
                                  src={item?.userProfilePic}
                                  alt=""
                                  srcset=""
                                  width={40}
                                  height={40}
                                  style={{ borderRadius: '50%' }}
                                />
                              </div>
                              <div>
                                {/* <ul>
                                <li> */}
                                <Link
                                  to={`/User/${item.userUid}`}
                                  tag="a"
                                  // onClick={(e) => e.preventDefault()}
                                  target="blank"
                                >
                                  <div className="card-text f-14 f-500 d-flex">
                                    {item?.userName}
                                  </div>
                                  <div className="card-text f-14 f-500 d-flex">
                                    {item?.userCurrentDesignation}
                                  </div>
                                </Link>

                              </div>
                            </div>
                          </td>
                          <td className="w-20">
                            {" "}
                            <div className="job-desc-text f-14 f-400 ">

                              {/* <Link
                                className="job-desc-text "
                                to={`/JobDetail/${item.jobId}`} target="_blank"> */}
                              {item?.jobTitle}
                              <small className="d-block f-10">{item?.shortDescription}</small>
                              <button
                                className="wljSecondaryBtn"
                                style={{ width: '120px' }}
                                onClick={() =>
                                  navigate(`/JobDetail/${item.jobId}`)
                                  //  to={`/JobDetail/${item.jobId}`} target="_blank"
                                }
                              >
                                {/* <span> */}
                                Job Detail
                                {/* </span> */}
                              </button>

                              {/* </Link> */}

                            </div>
                          </td>
                          <td>
                            <div className="apply-on-text f-14 f-400">
                              {/* Jun 21, 2019 */}
                              {moment(item.appliedTime).format('ll')}
                            </div>
                          </td>
                          <td>
                            <HiringStatus item={item} />
                            <button
                              className="wljSecondaryBtn mt-2"
                              style={{ width: '120px' }}
                              onClick={() => {
                                setInterviewModal(true)
                                setInterviewDetails(item)
                              }}
                            >
                              {/* <span> */}
                              Interview Detail
                              {/* </span> */}
                            </button>
                          </td>
                          {/* <td>
                            {" "}
                            <div className="recent-work d-flex align-items-center">

                              <div className="recent-text f-14 f-400">
                                {item?.remark}
                              </div>
                            </div>
                          </td> */}
                          <td>
                            {" "}
                            <div className="actions-cont">
                              <ul className="d-flex justify-content-center mb-0 p-0">
                                <li className="me-2">
                                  <Link to={`/Resume/${item.userUid}`} target="_blank">
                                    <img
                                      src={notificationImages.notifyImg4}
                                      alt=""
                                      srcset=""
                                    />
                                  </Link>
                                </li>

                              </ul>
                            </div>
                          </td>
                        </tr>
                      )
                    })}



                </tbody>
              </Table>
            </Card>
          </div>
        </div>}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {interviewModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Interview Details</h6>

                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setInterviewModal(false);
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setInterviewModal(false);
                  }}
                />
              </div>
              <Row>
                <Col md={12}>
                  <div className="jobdata-content-cont d-flex flex-column p-3 pb-0">
                    {/* <h6>Company Details</h6> */}

                    <p className="fw-bold d-flex w-100"><img src={interviewDetails.compLogo} width={50} height={50} />
                      <div>
                        <p className="ms-2 fw-bold">{interviewDetails.compName}</p>
                        <p className="ms-2">{interviewDetails.jobTitle}</p>
                        <p className="ms-2">{interviewDetails.shortDescription}</p>
                      </div>
                    </p>
                    {/* <p className="fw-bold d-flex w-100">Place Detail: <p className="ms-2">{interviewDetails.intrvwPlaceDetails}</p> </p>
                    <p className="fw-bold d-flex">Place Type: <p className="ms-2">{interviewDetails.intrvwPlaceType}</p> </p>
                    <p className="fw-bold d-flex">Schedule Time: <p className="ms-2">{interviewDetails.scheduleTime}</p> </p>
                    <p className="fw-bold d-flex">Remark: <p className="ms-2 remark">{interviewDetails.remark}</p> </p> */}
                  </div>
                  <div className="jobdata-content-cont d-flex flex-column p-3 pb-0">
                    <p className="fw-bold d-flex w-100">Hiring Status: <p className="ms-2">{interviewDetails.hiringStatusName}</p> </p>
                    <p className="fw-bold d-flex w-100">Place Detail: <p className="ms-2">{interviewDetails.intrvwPlaceDetails}</p> </p>
                    <p className="fw-bold d-flex">Place Type: <p className="ms-2">{interviewDetails.intrvwPlaceType}</p> </p>
                    <p className="fw-bold d-flex">Schedule Time: <p className="ms-2"> {moment(interviewDetails.scheduleTime).format("MMMM D, YYYY, hh:mm A")}</p> </p>
                    <p p className="fw-bold d-flex" > Remark: <p className="ms-2 remark">{interviewDetails.remark}</p> </p>
                  </div>
                </Col>
              </Row>

              {/* <div className="text-center mt-2">
                <button
                  color=""
                  className="mainBtn1"
                  onClick={() => {
                    setInterviewModal(false);
                  }
                  }
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default Interview;
