import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import axios from "../../API/axios";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import { toast } from "react-hot-toast";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const options = [
  { value: "html", label: "HTML" },
  { value: "css", label: "CSS" },
  { value: "javascript", label: "JavaScript" },
  { value: "react", label: "React" },
];

const AddSkillModal = ({ show, handleClose, getUserSkill, getUserProfile }) => {
  const sessionId = localStorage.getItem("sessionId");
  const [display, setDisplay] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //************************Industry Get Start  ************************//
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState("");
  const [industryList, setIndustryList] = useState("");
  // console.log(selectedOption);
  const getIndustry = () => {
    axios
      .get(`Master/GetFirmIndustryTypeDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.fitid,
          label: item.name,
        }));
        setIndustryList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getIndustry();
  }, []);

  //************************Industry Get End  ************************//

  //************************Get Skill DDL Start  ************************//
  const [value, setValue] = useState(null);
  const [page, setPage] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [skillCheck, setSkillCheck] = useState(false);
  const [newCreateoption, setNewCreateoption] = useState("");
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  // console.log('value', value);
  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);
  // console.log('typed', typed);
  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetSkillsByIndustryDDL?search=${typed}&Fitid=${selectedOption.value
        }&pageNumber=${page + 1}&pageSize=100`
      )
      .then((response) => {
        // console.log(response);
        const data = response.data.responseData.data.map((item) => ({
          value: item.skllId,
          label: item.name,
        }));
        setPage((prevPage) => prevPage + 1);
        setIsLoading(false);
        setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPages(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setPage(1);
  };

  const handleChange = (value) => {
    setValue(value);
    setPage(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (page < totalPages) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  //************************ Get Skill DDL End  ************************//

  //******** Add Skill Start  ********//
  const AddSkill = async () => {
    setDisplay(true);
    console.log(skillCheck);
    // if (skillCheck === true) {
    if (selectedOption === null || value === null) {
      setWarningToastMessage('Please fill all the required fields');
      setShowWarningToast(true);
    } else {
      try {
        const postdatadetails = {
          deviceType: "Web",
          sessionId: sessionId,
          fitid: selectedOption.value,
          skillName: value.label,
        };
        console.log(postdatadetails);
        await axios
          .post("UserSkill/AddUserSkill", postdatadetails)
          .then((resData) => {
            // console.log('resData', resData);
            // toast.success(resData.data.message);
            setToastMessage(resData.data.message);
            setShowToast(true);
            getUserSkill();
            handleClose();
            setSelectedOption("");
            setSelectedSkill("");
            setValue(null);
            setTyped("");
            setOptions([]);
            setPage(0);
            setTotalPages(0);
            getUserProfile()
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }
    // } else {
    //   if (value === null) {
    //     setWarningToastMessage('Please fill all the required fields');
    //     setShowWarningToast(true);
    //   } else {
    //     try {
    //       const postdatadetails = {
    //         deviceType: "Web",
    //         sessionId,
    //         skllId: value.value,
    //       };
    //       console.log("postdatadetails", postdatadetails);
    //       await axios
    //         .post("UserSkill/LinkSkill", postdatadetails)
    //         .then((resData) => {
    //           // console.log('resData', resData);
    //           // toast.success(resData.data.message);
    //           setToastMessage(resData.data.message);
    //           setShowToast(true);
    //           getUserSkill();
    //           handleClose();
    //           setSelectedOption("");
    //           setSelectedSkill("");
    //           setValue(null);
    //           setTyped("");
    //           setOptions([]);
    //           setPage(0);
    //           setTotalPages(0);
    //           getUserProfile()
    //         });
    //     } catch (error) {
    //       if (error.response && error.response.status === 404) {
    //         setWarningToastMessage(error.response.data.message);
    //         setShowWarningToast(true);
    //       } else {
    //         setErrorToastMessage(error.response.data.message);
    //         setShowErrorToast(true);
    //       }
    //     }
    //   }
    // }
  };
  //******** Add Skill End  ********//

  const handleCreateOption = (newOption) => {
    setSkillCheck(true);
    setNewCreateoption(newOption);

    const newOptionObject = {
      label: newOption,
      value: newOption.toLowerCase().replace(/\W/g, ""),
    };

    // Set the newly created option as the selected value
    setValue(newOptionObject);
  };


  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show]);

  return (
    <>
      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Add Skill</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
              <div className=" pb-4  p-2">
                <div className="mb-4">
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={industryList}
                    placeholder={"Select industry"}
                    invalid={display && selectedOption === ""}
                    className="dropdownBorder"
                    styles={customStyles}
                  />
                  {display && !selectedOption ? (
                    <span className="error_msg_lbl">
                      Please Select industry{" "}
                    </span>
                  ) : null}
                </div>
                <div>
                  <CreatableSelect
                    value={value}
                    options={options}
                    optionRenderer={renderOption}
                    onOpen={handleOpen}
                    onChange={handleChange}
                    onMenuScrollToBottom={handleMenuScrollToBottom}
                    onInputChange={handleInputChange}
                    isClearable

                    // onCreateOption={handleCreateOption}
                    placeholder={"Search and Select Skill"}
                    invalid={display && value === ""}
                    styles={customStyles}
                    className="dropdownBorder"
                  />
                  {display && !value ? (
                    <span className="error_msg_lbl">Please Select Skill </span>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12 text-end mt-4 mb-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="closebtn me-2"
                  onClick={handleClose}
                  style={{ width: "60px" }}
                >
                  close
                </button>
                <button
                  type="button"
                  className="mainBtn1 me-4"
                  onClick={AddSkill}
                  style={{ width: "70px" }}

                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default AddSkillModal;
