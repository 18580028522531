import React, { useEffect } from 'react'

const AdSense = () => {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        } catch (err) { }
    }, [])


    useEffect(() => {
        const ensureLinksOpenInNewTab = () => {
            const adFrames = document.querySelectorAll('ins.adsbygoogle iframe');
            adFrames.forEach((iframe) => {
                try {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                    if (iframeDoc) {
                        const adLinks = iframeDoc.querySelectorAll('a[target="_top"]');
                        adLinks.forEach((link) => {
                            link.setAttribute('target', '_blank');
                        });
                    }
                } catch (error) {
                    console.warn('Unable to modify ad links:', error);
                }
            });
        };

        const interval = setInterval(ensureLinksOpenInNewTab, 1000); // Check periodically for newly rendered ads
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='Adwrapper' style={{ width: '100%', height: '200px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '100%', height: '200px' }}
                            data-ad-client="ca-pub-8691062301030113"
                            data-ad-slot="8816511677"></ins>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='Adwrapper' style={{ width: '100%', height: '200px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ins className="adsbygoogle"
                            style={{ display: 'inline-block', width: '100%', height: '200px' }}
                            data-ad-client="ca-pub-8691062301030113"
                            data-ad-slot="7427145058"></ins>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdSense