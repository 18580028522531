import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/userAuth.css";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ProgressBars from "../../Views/Articles/ProgressBars";
import ProgressBar from "../../Views/Auth/ProgressBar";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import { Formik, useFormik } from "formik";
import axios from "../../API/axios";
import { toast } from "react-hot-toast";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import NewApp from "./NewApp";
import { createProfileValidations } from "../Validations";
import FloatingLabelInput from "react-floating-label-input";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import Flatpickr from 'react-flatpickr'
import Select from "react-select";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const UserAuth = ({ setShowUserAuthModal, onClose, data, showUserAuthModal, fetchData }) => {
  console.log('hhjjhg', data);


  const sessionId = localStorage.getItem("sessionId");
  const fileInputRef = useRef(null);
  const fileInputRefBg = useRef(null);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  //   State for dropdowns
  const [genderValue, setGenderValue] = useState("");
  const [genderOptionList, setGenderOptionList] = useState([]);
  const [languageValue, setlanguageValue] = useState(null);
  const [languageOptionList, setlanguageOptionsList] = useState([]);
  const [locationValue, setlocationValue] = useState("");

  //   state for Next Previous Button
  const [activeStep, setActiveStep] = useState(1);

  //   State for Date Time Picker
  const [startDate, setStartDate] = useState("");

  //   State for Image selection
  const [selectedImage, setSelectedImage] = useState(null);
  const [bgSelectedImage, setBgSelectedImage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [backgroudImage, setBackgroundImage] = useState(null);

  // console.log(profilePic, backgroudImage);
  const [loading, setLoading] = useState(false);

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);

  //********City List End  ********//

  const initialValues = {
    firstName: "",
    lastName: "",
    dob: "",
    additionalName: "",
  };

  // const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
  //   useFormik({
  //     initialValues,
  //     validationSchema: createProfileValidations,

  console.log('startDate', startDate, data);


  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append(
        "Dob",
        startDate === null ? "" : moment(startDate).format("YYYY-MM-DD")
      );
      // formData.append("CountryID", "");
      formData.append("CityID", cityId === null ? '' : cityId);
      // formData.append("LocationId", locationValue);
      formData.append("GenderId", genderValue === null ? '' : genderValue);
      // formData.append("LangId", languageValue);
      // formData.append("BackgroudImage", backgroudImage);
      // formData.append(
      //   "AdditionalName",
      //   values.additionalName || data.additionalName
      // );
      // formData.append("ProfilPic", profilePic);
      formData.append("LastName", data.lastName);
      formData.append("FirstName", data.firstName);

      const res = await axios.post(
        "/UserProfile/UpdateCompleteInfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setToastMessage(res.data.message);
      setShowToast(true);
      setTimeout(() => {
        onClose(true);
        setShowUserAuthModal(false)
      }, 2000);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  }
  // });

  // Get Gender Data

  const getGenderData = () => {
    try {
      axios
        .get(`/Master/GetGenderDDL`)
        .then((response) => {
          // console.log("gender data", response.data.responseData);
          setGenderOptionList(response.data.responseData);
          // setLoading(false);
        })
        .catch((error) => { });
    } catch (error) { }
  };

  let genderOptions = genderOptionList.map((val) => {
    return { value: val.gndrId, label: val.name, id: val.gndrId };
  });

  const selectGender = (val) => {
    // console.log("gender", val.value);
    setGenderValue(val.value);
  };

  useEffect(() => {
    getGenderData();
  }, []);

  //   Get Language Data

  const getLanguageData = () => {
    try {
      axios
        .get(`/Master/GetLanguageDDL`)
        .then((response) => {
          // console.log("language data", response.data.responseData);
          setlanguageOptionsList(response.data.responseData);
          // setLoading(false);
        })
        .catch((error) => { });
    } catch (error) {
    }
  };

  let languageOptions = languageOptionList.map((val) => {
    return { value: val.langId, label: val.name, id: val.langId };
  });

  const selectLanguage = (val) => {
    // console.log("lang", val.value);
    setlanguageValue(val.value);
  };

  useEffect(() => {
    getLanguageData();
  }, []);

  // Location

  const selectLocation = (val) => {
    // console.log("location", val.LocId);
    setlocationValue(val.LocId);
  };

  const options = [
    { value: "Mumbai", label: "Mumbai", LocId: "1" },
    { value: "Thane", label: "Thane", LocId: "2" },
    { value: "Pune", label: "Pune", LocId: "3" },
  ];

  // Functions

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleBgClick = () => {
    fileInputRefBg.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setProfilePic(event.target.files[0].name);
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  const handleBgImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setBackgroundImage(event.target.files[0].name);
    setBgSelectedImage(URL.createObjectURL(selectedFile));
  };




  useEffect(() => {
    if (data) {
      setStartDate(data?.dob)
      setCityId(data?.cityID)
      setCitySearch(data?.cityName)
      setGenderValue(data?.genderId)
    }
  }, [data])





  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };




  return (
    <div>
      {showUserAuthModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <div className="user-form-main-text px-2 f-500">
                  Complete your Profile
                </div>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    onClose();
                  }}
                >
                  close
                </span> */}
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    onClose();
                  }} />
              </div>

              <div className="px-3 pb-2">
                <div className="col-md-12 mt-2 userInput">
                  <Form.Label>DOB</Form.Label>
                  {/* <div> */}
                  <Flatpickr className='form-control' value={startDate} onChange={date => setStartDate(date[0])} id='dob' options={{
                    maxDate: "today", // Disables future dates
                    onReady: (selectedDates, dateStr, instance) => {
                      preventYearScroll(instance); // Call the function to stop scroll on year
                    },
                    onOpen: (selectedDates, dateStr, instance) => {
                      preventYearScroll(instance); // Also prevent scroll when the picker opens
                    },
                  }} />
                  {/* </div> */}
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="about">Gender</label>
                  <ReactSelect
                    value={genderOptions.find((option) => option.value === genderValue)}
                    options={genderOptions}
                    onChange={selectGender}
                    loadOptions={selectGender}
                    placeholder="Select Gender"
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <label htmlFor="about">City </label>
                  <Select
                    // defaultValue={cityId}
                    value={cityData.find((option) => option.value === cityId)}
                    onChange={(selectedOption) =>
                      setCityId(selectedOption.value)
                    }
                    options={cityData}
                    placeholder={"Search and Select City"}
                    onInputChange={(inputValue) => {
                      setCitySearch(inputValue);
                    }}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="text-end px-3">
                <button
                  type="submit"
                  className="mainBtn1"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              {/* <div className="bs-stepper">
                <div className="bs-stepper-content p-0">
                  <div
                    id="logins-part"
                    className={`content ${activeStep === 1 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="logins-part-trigger"
                  >
                    <Row>
                      <Col md={12}>
                        <div className="form-progress-cont mb-4 d-flex align-items-center px-3 progress-bar-yellow">
                          <span className="f-12 f-600 me-2">1 of 3 steps</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "33%" }}
                              aria-valuenow="33"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <hr />
                        <Form onSubmit={handleSubmit}>
                          <div className="row p-4 pt-0 settings CreateCompany createProfile p-0">
                            <div className="col-md-12 mt-2 ps-0 mb-2 userInput">
                              <Form.Label className="mb-0">
                                First Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <input
                                id="firstName"
                                label="First Name"
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName && touched.firstName ? (
                                <p
                                  className="form-error"
                                  style={{ color: "red" }}
                                >
                                  {errors.firstName}
                                </p>
                              ) : null}
                            </div>

                            <div className="col-md-12 mt-2 ps-0 mb-2 userInput">
                              <Form.Label>
                                Last Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <input
                                id="lastName"
                                label="Last Name"
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName && touched.lastName ? (
                                <p
                                  className="form-error"
                                  style={{ color: "red" }}
                                >
                                  {errors.lastName}
                                </p>
                              ) : null}
                            </div>

                            

                            <div className="col-md-12 mt-2 ps-0 mb-2 userInput">
                              <Form.Label>Additional Name</Form.Label>
                              <input
                                id="additionalName"
                                label="Additional Name"
                                type="text"
                                name="additionalName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 text-end mt-4">
                            <button
                              type="button"
                              className="mainBtn1"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                  <div
                    id="information-part"
                    className={`content ${activeStep === 2 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="information-part-trigger"
                  >
                    <div className="form-progress-cont mb-4 d-flex align-items-center px-3 progress-bar-blue">
                      <span className="f-12 f-600 me-2">2 of 3 steps</span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "66%" }}
                          aria-valuenow="66"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col md={12}>
                        <Formik
                          initialValues={{
                            langId: "",
                            genderId: "",
                            locationId: "",
                          }}
                          
                          onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                              alert(JSON.stringify(values, null, 2));
                              setSubmitting(false);
                            }, 400);
                          }}
                        >
                          <Form>
                            <div className="row p-4 pt-0 settings CreateCompany">
                              <div className="col-md-12 mt-2">
                                <label htmlFor="about">Language</label>
                                <ReactSelect
                                  options={languageOptions}
                                  onChange={selectLanguage}
                                  loadOptions={selectLanguage}
                                  placeholder="Select Language"
                                />
                              </div>
                              <div className="col-md-12 mt-2">
                                <label htmlFor="about">Gender</label>
                                <ReactSelect
                                  options={genderOptions}
                                  onChange={selectGender}
                                  loadOptions={selectGender}
                                  placeholder="Select Gender"
                                />
                              </div>

                              <div className="text-end mt-5 d-flex justify-content-between">
                                <div className="previousBtnCont d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="previousBtn"
                                    onClick={handlePrev}
                                  >
                                    Previous
                                  </button>
                                </div>

                                <button
                                  type="button"
                                  className="mainBtn1"
                                  onClick={handleNext}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </Col>
                    </Row>
                  </div>
                  <div
                    id="information-part"
                    className={`content ${activeStep === 3 ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby="information-part-trigger"
                  >
                    <div className="form-progress-cont mb-4 d-flex align-items-center px-3 progress-bar-green">
                      <span className="f-12 f-600 me-2">3 of 3 steps</span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col md={12}>
                        <Form>
                          <div className="row p-4 pt-0 settings CreateCompany">
                            <div>
                              <div className="d-flex">
                                <div>
                                  <div className="upload-image-text f-12 f-400 mb-2">
                                    Upload profile picture
                                  </div>
                                  

                                  <div className="uploadImage-cont m-auto mb-3 d-flex justify-content-center align-items-center">
                                    <img
                                      src={userAuthImage.dummy1}
                                      alt="Upload Image"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClick}
                                    />
                                    <p className="ms-2">Choose File</p>
                                  </div>
                                  <div className="empty-line"></div>
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleImageChange}
                                    accept="image/*"
                                  />
                                </div>
                                <div className="uploaded-image">
                                  {selectedImage && (
                                    <img src={selectedImage} alt="Selected" />
                                  )}
                                  <img src={data?.profilPic} alt="Selected" />
                                </div>
                              </div>

                              <div className="d-flex">
                                <div>
                                  <div className="upload-image-text f-12 f-400 mb-2 mt-4">
                                    Upload background picture
                                  </div>
                                  
                                  <div className="uploadImage-cont m-auto mb-3 d-flex justify-content-center align-items-center">
                                    <img
                                      src={userAuthImage.dummy1}
                                      alt="Upload Image"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={handleBgClick}
                                    />
                                    <p className="ms-2">Choose File</p>
                                  </div>
                                  <div className="empty-line"></div>
                                  <input
                                    type="file"
                                    ref={fileInputRefBg}
                                    style={{ display: "none" }}
                                    onChange={handleBgImageChange}
                                    accept="image/*"
                                  />
                                </div>
                                <div className="uploaded-image">
                                  {bgSelectedImage && (
                                    <img src={bgSelectedImage} alt="Selected" />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="text-end mt-4 d-flex justify-content-between">
                              <div className="previousBtnCont d-flex align-items-center">
                                <button
                                  type="button"
                                  className="previousBtn"
                                  onClick={handlePrev}
                                >
                                  Previous
                                </button>
                              </div>
                              <button
                                type="submit"
                                className="mainBtn1"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </div>
  );
};

export default UserAuth;
