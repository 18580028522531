// import { icons } from '@react-icons/all-files'
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import axios from "../../API/axios";
import { people, icons, post } from "../../assets/Images/ImagesLink";
import CreateCompany from "../CreateCompany/CreateCompany";
import UpdateCompany from "../CreateCompany/UpdateCompany";
import AddSharpIcon from '@mui/icons-material/AddSharp';

import CompanyActions from "./CompanyActions";
import { ShimmerDiv } from "shimmer-effects-react";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
// import { Link } from 'react-router-dom';
const MyCompanies = ({ GetOwnCompniesListsData, setUpdateList }) => {
  const userUid = localStorage.getItem("userUid");
  const sessionId = localStorage.getItem("sessionId");
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  //************************Company list Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [companyList, setcompanyList] = useState("");
  // const [tableUpadate, setTableUpdate] = useState('')
  console.log("companyList", companyList);

  const getCompanyList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Company/GetOwnCompaniesList`, data)
      .then((response) => {
        // console.log(response.data.responseData);
        setIsLoading(false);
        const res = response.data.responseData;
        setcompanyList(res);
        // setTableUpdate(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompanyList();
  }, []);

  const [showUpdateModal, setShowUpdateModal] = useState(false);


  //************************Company list End  ************************//

  const [profileConfig, setProfileConfig] = useState([]);
  const navigate = useNavigate();
  const [unauthorisedModal, setUnauthorisedModal] = useState(false);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      if ((error.code === 'ERR_NETWORK' && sessionId) || (error.code === "ERR_BAD_REQUEST" && sessionId)) {
        setUnauthorisedModal(true)
      }
    }
  };

  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        // console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };

  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);
  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  const [showResumeModal, setShowResumeModal] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  return (
    <>
      <div className="friend-main-container mt-3">
        <Row className="mt-3 ">
          {/* <div className="headerSection  d-block d-md-flex justify-content-between"> */}
          <Col md={7}>
            <div className="manageCompnies">
              <h1 className="f-16 fw-bold">Manage Companies</h1>
            </div>
          </Col>
          <Col md={5} className="d-flex justify-content-end compnies-create-btn">

            <button
              type="button"
              className="mainBtn1 createCompBtn d-flex align-items-center"
              onClick={(e) => {
                e.preventDefault();
                (profileConfig.hasCcv === null || profileConfig.hasCcv === false) &&
                  userPer?.percentage < 60
                  ? setShowResumeModal(!showResumeModal)
                  : openModal()
              }}
            >
              Create Company
            </button>
          </Col>
          {/* </div> */}
        </Row>
        <div className="frindsCardContainer">
          <Row>
            {isLoading ? (
              <div className="mt-3">
                {/* <ShimmerThumbnail height={250} rounded /> */}
                <ShimmerDiv mode="light" height={250} width={'100%'} />
              </div>
            ) : (
              companyList.length === 0 ? <div className="mt-2 error-image-cont">
                <img src={post.errorbig} alt="404 Not Found" />
              </div> :
                companyList &&
                companyList.map((item, index) => {
                  console.log("own company", item);
                  return (
                    <Col md={6} sm={12} key={index} className="compnies-card">
                      <div className="PeopleCard">
                        <div className="PeopleCardHeader">
                          <img
                            src={item?.backGroundImage}
                            alt=""
                            width="100%"
                            height="120px"
                          />
                          <div className="overlay"></div>
                          <div className="centerAlignment">
                            <Link to={`/Company/${item.compUid}`}>
                              <div className="">
                                <img
                                  src={
                                    item.logoImage === null
                                      ? people.DefaultProfile
                                      : item.logoImage
                                  }
                                  alt=""
                                  srcset=""
                                  style={{ borderRadius: "50px" }}
                                  width={50}
                                  height={50}
                                />
                              </div>
                            </Link>
                            <div className=" d-block d-md-flex align-items-baseline justify-content-between">
                              <div className=" ">
                                <div className="mainCardText mb-0">
                                  <Link to={`/Company/${item.compUid}`}>
                                    <h1>{item.compName}</h1>
                                  </Link>
                                </div>
                                <div className="mainCardText">
                                  <h1>{item.nameTag}</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="PeopleCardContainer p-2 ms-3  d-flex align-items-baseline justify-content-between">
                          <div>
                            <div className="mainCardText"></div>

                            <div className="cardSubText">
                              <h1>{item.industryName}</h1>
                            </div>
                          </div>
                          <div
                            className="follower-button d-flex flex-column align-items-end"
                            style={{ width: "100px" }}
                          >
                            {/* <CompanyActions compUid={item.compUid} getCompanyList={getCompanyList} item={item} /> */}
                            <CompanyActions showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} compUid={item.compUid} getCompanyList={getCompanyList}
                            //  setShowUpdateModal={setShowUpdateModal} compUid={companyDetails.compUid} getCompanyList={CompanyDetails} item={companyDetails} 
                            />


                            <div className="cardSubText mt-2">
                              <h1>{item.follwersCounts} followers</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
            )}
          </Row>
        </div>
      </div>
      {showModal && (
        <CreateCompany
          openModal={openModal}
          showModal={showModal}
          closeModal={closeModal}
          getCompanyList={getCompanyList}
          GetOwnCompniesListsData={GetOwnCompniesListsData}
          setUpdateList={setUpdateList}
        />
      )}


      {showResumeModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Please complete your profile up to 60% and must have valid resume to  Create Company</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShowResumeModal(false);
                  }}
                />
              </div>
              <div className="text-center mt-2">
                <Link to={`/User/${userUid}`}>
                  <button
                    color=""
                    className="closebtn"
                    style={{ width: "150px" }}
                  // onClick={() => {
                  //   userCcv !== "True" && AddUserCcv();
                  // }}
                  >
                    Manage Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
      {unauthorisedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                  <h4 className="text-center AdBlocker">Warning!</h4>
                  <p className="text-center ad-p">
                    Your session has expired or you are not authorized to access this resource. Please log in again to continue.
                  </p>
                  <div className="text-center m-auto mt-3">
                    <button
                      type="button"
                      className="mainBtn1 "
                      onClick={() => {
                        setUnauthorisedModal(!unauthorisedModal)
                        navigate("/SignIn");
                        localStorage.clear();
                        sessionStorage.removeItem('hasModalShown');
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyCompanies;
