import React, { useEffect, useState } from "react";
import FeaturedJobs from "./FeaturedJobs";
import "../../assets/css/JobSearch.css";
import "../JobDetails/JobDetails.css";
import { JobSearch } from "../../assets/Images/ImagesLink";
import axios from "../../API/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import moment from "moment";
import ModalForGuest from "../ModalForGuest";
import { Modal } from "react-bootstrap";
import JobApply from "../JobSearch/JobApply";
import DetailJobApply from "./DetailJobApply";
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Helmet } from "react-helmet";


const JobDetails = ({ setShowjobDetails }) => {
  const { JobId } = useParams();
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [bookmarkModal, setBookmarkModal] = useState(false);
  const closeModalData = () => {
    setBookmarkModal(false);
  };
  const [appliedJobId, setAppliedJobId] = useState(false);
  const [showAppliedModal, setShowAppliedModal] = useState(false);
  const handleCloseModal = () => setShowAppliedModal(false);
  const handleShow = () => setShowAppliedModal(true);
  const userUid = localStorage.getItem("userUid");
  //************************User Profile Stats Start  ************************//
  const [userPer, setUserPer] = useState([]);
  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserPer(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  //************************User Profile Stats End  ************************//
  const [profileConfig, setProfileConfig] = useState([]);
  const navigate = useNavigate();
  const [unauthorisedModal, setUnauthorisedModal] = useState(false);
  const userConfig = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post("User/GetUserConfig", data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setProfileConfig(resData.data.responseData);
      });
    } catch (error) {
      if ((error.code === 'ERR_NETWORK' && sessionId) || (error.code === "ERR_BAD_REQUEST" && sessionId)) {
        setUnauthorisedModal(true)
      }
    }
  };

  const [showResumeModal, setShowResumeModal] = useState(false);

  const [userCcv, setUserCcv] = useState("");
  const CheckUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .get(`Ccv/CheckUserCcv?DeviceType=Web&UserUid=${userUid}`)
        .then((resData) => {
          // console.log('resData', resData.data.responseData);
          setUserCcv(resData.data.responseData);
        });
    } catch (error) {
      console.log(error.response);
      // if (error.response && error.response.status === 404) {
      //   setWarningToastMessage(error.response.data.message);
      //   setShowWarningToast(true);
      // } else {
      //   setErrorToastMessage(error.response.data.message);
      //   setShowErrorToast(true);
      // }
    }
  };
  useEffect(() => {
    userConfig();
    CheckUserCcv();
  }, []);

  const AddUserCcv = async () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios.post(`Ccv/AddUserCcv`, data).then((resData) => {
        // console.log('resData', resData.data.responseData);
        setUserCcv(resData.data.responseData);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [jobData, setJobData] = useState("");
  const JobDetails = async () => {
    try {
      // setLoading(true);
      await axios
        .get(`Job/GetJobDetails?DeviceType=Web&JobId=${JobId}`)
        .then((resData) => {
          console.log("resData", resData.data.responseData.viewJob);
          setJobData(resData.data.responseData.viewJob);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    JobDetails();
  }, [JobId]);
  // console.log('jobData', jobData);

  const [savedJobs, setSavedJobs] = useState(new Set());
  // const [jobId, setJobId] = useState('');
  const saveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/SaveJob`, responseData).then((res) => {
        console.log("save post", res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(true);
        setSavedJobs((prev) => new Set(prev).add(jobId));
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Save Post End  ************************//

  //************************UnSave Post start  ************************//
  const unsaveJob = async (jobId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      jobId: jobId,
    };
    try {
      await axios.post(`JobAction/UnSaveJob`, responseData).then((res) => {
        // console.log(res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        // AllJobList();
        // setIsSaved(false);
        setSavedJobs((prev) => {
          const newSavedJobs = new Set(prev);
          newSavedJobs.delete(jobId);
          return newSavedJobs;
        });
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************UnSave Post End  ************************//
  const now = moment();
  const itemTime = moment.utc(jobData?.creationTime);
  const duration = moment.duration(now.diff(itemTime));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let result = "";

  if (years > 0) {
    result = `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    result = `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    result = `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    result = `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    result = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    result = "just now";
  }

  console.log(result);

  //************************Apply and unapply job  ************************//
  const [showModal, setShowModal] = useState(false);
  const [isappliedF, setIsappliedF] = useState(false);

  const applyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: jobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      // console.log('body', body);
      const res = await axios.post("/JobAction/ApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      JobDetails();
      // closeModal();
      // setJobList([]);
      // getAllJobList(page);
      // setAppliedId(jobId);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const unapplyNew = async (jobId) => {
    try {
      const body = {
        deviceType: "Web",
        sessionId: sessionId,
        jobId: appliedJobId,
        type: 1,
        userUid: userUid,
        // hiringStatus: 1,
        // rateType: 1,
        // isRead: true,
      };
      const res = await axios.post("/JobAction/UnApplyJob", body);
      setToastMessage(res.data.message);
      setShowToast(true);
      handleCloseModal();
    } catch (e) {
      console.log(e.response.data.message);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Apply and unapply job  ************************//
  console.log("JobSearch", jobData);

  useEffect(() => {
    if (showAppliedModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showAppliedModal]);

  console.log('jobData', jobData);

  return (
    <>
      <Helmet>
        <title>{`${jobData?.title}`} | Welinkjobs</title>
        <meta name="description" content={`${jobData?.fullDescription}`} />
        <meta name="keywords" content="newsfeed, updates, latest news" />
        <meta property="og:title" content={`${jobData?.title} Profile | Welinkjobs`} />
        <meta property="og:description" content={`${jobData?.fullDescription}`} />
        <meta property="og:url" content="https://welinkjobs.com/NewsFeeds" />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113"></script>
      </Helmet>
      <div className="p-5 mt-5">
        <div className="row mt-3 jobdetail-sideLinks">

          <div className="col-md-6 jobdetail-left">
            <div className="SingleJob">
              {/* <div>
                <img src={JobSearch.companyHeader} alt="" />
              </div> */}
              {/* <Link to={`/Company/${jobData.compUid}`}> */}
              <img
                src={jobData?.compBackgroundImage}
              // alt=""
              // srcset=""
              // width={50}
              // height={50}
              // style={{ borderRadius: "50px" }}
              />
              {/* </Link> */}
              <div className="company-sub-image">
                <Link to={`/Company/${jobData.compUid}`}>
                  <img
                    src={jobData?.compLogo}
                    alt=""
                    srcset=""
                    width={50}
                    height={50}
                    style={{ borderRadius: "50px" }}
                  />
                </Link>
              </div>
              <div className="single-job-main-content p-3 mt-3">
                <div className="singlejob-cont">
                  <div className="singlejob-header d-flex justify-content-between">
                    <div className="lead-product-text f-20 f-600 d-flex flex-column">
                      <Link to={`/Company/${jobData.compUid}`}>
                        <span className="">{jobData.compName}</span>
                      </Link>
                      <div>{jobData?.title}</div>
                    </div>

                    <div className="lead-product-right">

                      {savedJobs.has(jobData?.jobId) ||
                        jobData?.isSaved === true ? (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(jobData?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkSharpIcon
                            className="material-symbols-sharp pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                unsaveJob(jobData?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          />
                        </div>
                      ) : (
                        <div className="d-flex align-items-center postAction">
                          {/* <span
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(jobData?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          >
                            bookmark
                          </span> */}
                          <BookmarkBorderSharpIcon
                            className="material-symbols-outlined pointer"
                            onClick={() => {
                              if (guestModeData !== "true") {
                                saveJob(jobData?.jobId);
                              }
                            }}
                            disabled={guestModeData === "true"}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="singlejob-sub-header d-flex justify-content-between mt-2">
                    <div className="lead-product-text f-12 f-500">
                      {jobData.locationName}{" "}
                      <span className="f-12 f-400">
                        {jobData.cityName}
                      </span>
                    </div>
                    <div className="post-text f-12 f-400">
                      <span className="f-12 f-400 me-2">Posted {result}</span>

                    </div>
                  </div>

                  <div className="singlejob-sub-header d-flex justify-content-between mt-2">
                    <div className="lead-product-text">

                    </div>
                    <div className="lead-product-text f-12 f-500">
                      Designation{" "}
                      <span className="f-12 f-400 ms-2">
                        {jobData?.designationName}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="employee-details d-flex justify-content-between mt-4">
                  <div className="employee-custom-details">
                    <h6 className="f-10">Location Type</h6>
                    <h2 className="f-12 f-500">
                      {jobData?.workLocationTypeName}
                    </h2>
                  </div>
                  <div className="employee-custom-details">
                    <h6 className="f-10">Work Level</h6>
                    <h2 className="f-12 f-500">{jobData.workLevelTypeName}</h2>
                  </div>
                  <div className="employee-custom-details">
                    <h6 className="f-10">Employee Type</h6>
                    <h2 className="f-12 f-500">{jobData?.jbTypName}</h2>
                  </div>

                  <div className="employee-custom-details">
                    <h6 className="f-10">Offer Salary</h6>
                    <h2 className="f-12 f-500">
                      {jobData?.maxSalary === null ? 'N.A' :
                        `$${jobData?.maxSalary} / ${jobData?.salaryTypeName}`}
                    </h2>
                  </div>
                </div>

                <div className="overview-cont mt-4">
                  <h3 className="f-14 f-600">Overview</h3>
                  <p className="f-12 f-400">{jobData.fullDescription}</p>
                </div>
                <hr />

                <div className="single-job-bottom-cont d-flex justify-content-between align-items-baseline">
                  <div className="apply-que-text">
                    {jobData?.isOwned !== true ?
                      <h2 className="f-14 f-600">
                        Are you Interested in this Jobs?
                      </h2> : <div className="badge badge-job1 me-3">
                        You Owned this company
                      </div>}
                  </div>
                  {jobData &&
                    <DetailJobApply isAppliedByYou={jobData?.isApplied} jobId={jobData?.jobId} getAllJobList={JobDetails} item={jobData} profileConfig={profileConfig} userPer={userPer} AddUserCcv={AddUserCcv} userCcv={userCcv} />}

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 jobdetail-right">
            <FeaturedJobs JobId={JobId} JobDetails={JobDetails} />
          </div>

        </div>
      </div>
      {bookmarkModal && <ModalForGuest closeModalData={closeModalData} />}



      {showAppliedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure want to unapply ?</h6>


                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>
              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={() => unapplyNew()}
                >
                  UnApply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {unauthorisedModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                  <h4 className="text-center AdBlocker">Warning!</h4>
                  <p className="text-center ad-p">
                    Your session has expired or you are not authorized to access this resource. Please log in again to continue.
                  </p>
                  <div className="text-center m-auto mt-3">
                    <button
                      type="button"
                      className="mainBtn1 "
                      onClick={() => {
                        setUnauthorisedModal(!unauthorisedModal)
                        navigate("/SignIn");
                        localStorage.clear();
                        sessionStorage.removeItem('hasModalShown');
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetails;
