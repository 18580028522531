import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { people } from "../../assets/Images/ImagesLink";
import AddJob from "../ManageJob/AddJob";
import DraftJob from "../ManageJob/DraftJob";
import Select from "react-select";
import ModalForGuest from "../ModalForGuest";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';
import WorkOutlineSharpIcon from '@mui/icons-material/WorkOutlineSharp';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import CompanyWarning from "../CompanyWarning";

const AddPost = ({
  AllPostList,
  PostOriginType,
  setPostList,
  postOriginId,
}) => {

  const Privacy = [
    { value: "Public", label: "Public", icon: <PublicSharpIcon /> },
    { value: "Friends", label: "Friends", icon: <GroupSharpIcon /> },
    { value: "OnlyMe", label: "OnlyMe", icon: <LockSharpIcon /> },
    { value: "Custom", label: "Custom", icon: <TuneSharpIcon /> },
  ];

  const guestModeData = localStorage.getItem("guestMode");
  const ownCompanyList = localStorage.getItem("ownCompanyList");
  console.log('ownCompanyList', ownCompanyList);

  const profilPic = localStorage.getItem("profilPic");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [showJobModal, setShowJobModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [showAddReactionModal, setShowAddReactionModal] = useState(false);

  const openJobModal = () => {
    setShowJobModal(true);
  };
  const closeJobModal = () => {
    setShowJobModal(false);
  };
  const [showJobDraftModal, setShowJobDraftModal] = useState(false);
  const openJobDraftModal = () => {
    setShowJobDraftModal(true);
  };
  const closeJobDraftModal = () => {
    setShowJobDraftModal(false);
  };
  const [showwarningmodal, setShowwarningmodal] = useState(false);
  const openWarningModal = () => {
    setShowwarningmodal(true);
  };
  const closewarningmodal = () => {
    setShowwarningmodal(false);
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState(false);

  const openModal = () => {
    {
      guestModeData === "true" ? setShowModalData(true) : setShow(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShow(false)
  };
  const closeModalData = () => {
    setShowModalData(false);
  };
  const [showDraftModal, setShowDraftModal] = useState(false);

  const openDraftModal = () => {
    setShowDraftModal(true);
  };

  const closeDraftModal = () => {
    setShowDraftModal(false);
  };
  const textareaRef = useRef(null);
  const [postData, setPostData] = useState("");

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  const sessionId = localStorage.getItem("sessionId");
  const userUid = localStorage.getItem("userUid");

  //************************ Add Post   ************************//
  const [postContent, setPostContent] = useState("");
  const [postID, setPostID] = useState("");
  const [display, setDisplay] = useState(false);

  //************************ Add Post   ************************//

  const [images, setImages] = useState([]);



  const [selectedFiles, setSelectedFiles] = useState([]);
  const imageSelect = (event) => {
    console.log("image selected", event);
    const selectedFiles = event.target.files;
    const newImages = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const imageName = selectedFiles[i].name;
      if (!checkDuplicate(imageName)) {
        const imageUrl = URL.createObjectURL(selectedFiles[i]);
        newImages.push({
          name: imageName,
          url: imageUrl,
          file: selectedFiles[i],
        });
      } else {
        alert(`${imageName} is already added to the list`);
      }
    }

    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...newImages];
      console.log("Updated images list:", updatedImages);
      return updatedImages;
    });
    console.log('selected images', newImages);

    event.target.value = null; // Reset file input
    // handleImageChange(newImages)
  };

  const checkDuplicate = (name) => {
    return images.some((image) => image.name === name);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className="image_container d-flex justify-content-center position-relative"
      >
        <img src={image.url} alt={image.name} />
        <span className="position-absolute" onClick={() => deleteImage(index)}>
          &times;
        </span>
      </div>
    ));
  };

  const fileInputRef = useRef(null);

  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageNames, setImageNames] = useState("");
  const [imageNamesFile, setImageNamesFile] = useState("");
  const inputFileRef = useRef(null);

  const handleImageChange = (images) => {
    console.log("selected images", images);
    if (postID === "") {
      SavePostAsDraft(images);
    } else {
      UpdatePostAsDraft(images);
    }
  };

  const handleRemoveImage = (index) => {
    UpdatePostAsDraft();
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageNames((prevNames) => {
      const namesArray = prevNames.split(", ");
      namesArray.splice(index, 1);
      return namesArray.join(", ");
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePost = () => {
    setShow(false);
    setShowModal(false);
    setShowSecondModal(true);
  };

  const handleShow = () => setShow(true);
  const [showSecondModal, setShowSecondModal] = useState(false);

  // const Privacy = [
  //   { value: "Public", label: "Public" },
  //   { value: "Friends", label: "Friends" },
  //   { value: "OnlyMe", label: "OnlyMe" },
  //   { value: "Custom", label: "Custom" },
  // ];



  const [privacyType, setPrivacyType] = useState("Public");


  const [isloading, setIsloading] = useState(false);


  console.log('image is selected', images, selectedFiles);

  const determinePostCategory = (postContent, imageNameArray) => {
    console.log('postContent', postContent, imageNameArray);

    const containsVideo = (names) => {
      const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv", ".wmv"];
      return names.some((name) => videoExtensions.includes(name.slice(name.lastIndexOf(".")).toLowerCase()));
    };

    const hasPostContent = postContent !== "";
    const hasImages = imageNameArray.length > 0 && !containsVideo(imageNameArray);
    const hasVideos = imageNameArray.length > 0 && containsVideo(imageNameArray);

    if ((hasPostContent && hasImages) || (hasPostContent && hasVideos) || (hasImages && hasVideos)) {
      return "UserStory";
    } else if (hasPostContent) {
      return "Text";
    } else if (hasImages) {
      return "Image";
    } else if (hasVideos) {
      return "Video";
    } else {
      return "UserStory";
    }
  };


  const handleSubmit = async () => {

    console.log("posted", images.length <= 0);
    setIsloading(true)
    try {
      setDisplay(true);

      if (postContent === "" && images.length <= 0) {
        setErrorToastMessage('Please add content or image');
        setShowErrorToast(true);
      } else {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("PrivacyType", privacyType || "Public");
        formData.append("PostOriginType", PostOriginType);
        const imageNameArray = images.map((image) => image.name);
        const postCategory = determinePostCategory(postContent, imageNameArray);
        formData.append("PostCategory", postCategory);
        formData.append("PostContent", postContent);
        images.forEach((file, index) => {
          formData.append(`FilesToUpload`, file.file);
        })
        formData.append("PostOriginID", postOriginId);
        console.log("form data", formData);
        const res = await axios.post("/Posts/CreatePost", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("response1: ", res.data);
        setToastMessage(res.data.message);
        setShowToast(true);
        closeModal();
        setShowModal(false);
        handleClose(false);
        AllPostList();
        const newPostId = res.data.responseData.id;
        console.log('newPostId', newPostId);
        const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${newPostId}`);
        console.log('newPostId', postDetailsResponse);
        const newPost = postDetailsResponse.data.responseData;
        setPostList((prev) => [newPost, ...prev]);
        setPostContent("");
        setImages([]);
        setPostID("");
        setShowSecondModal(false);
        setIsloading(false)
      }
    } catch (error) {
      console.log('newPostId', error);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
      setIsloading(false)
    }
  };


  const determinePostCategory1 = (postContent, imageNameArray) => {
    console.log('postContent', postContent, imageNameArray);

    const containsVideo = (names) => {
      const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv", ".wmv"];
      return names.some((name) => videoExtensions.includes(name.slice(name.lastIndexOf(".")).toLowerCase()));
    };

    const hasPostContent = postContent !== "";
    const hasImages = imageNameArray.length > 0 && !containsVideo(imageNameArray);
    const hasVideos = imageNameArray.length > 0 && containsVideo(imageNameArray);

    if ((hasPostContent && hasImages) || (hasPostContent && hasVideos) || (hasImages && hasVideos)) {
      return "UserStory";
    } else if (hasPostContent) {
      return "Text";
    } else if (hasImages) {
      return "Image";
    } else if (hasVideos) {
      return "Video";
    } else {
      return "UserStory";
    }
  };

  const SavePostAsDraft = async (images) => {
    console.log("selected images", images);
    try {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append("PrivacyType", privacyType || "Public");
      formData.append("PostOriginType", PostOriginType);
      const imageNameArray = images.map((image) => image.name);
      const postCategory = determinePostCategory1(postContent, imageNameArray);
      formData.append("PostCategory", postCategory);
      formData.append("PostContent", postContent);
      images.forEach((file, index) => {
        formData.append(`FilesToUpload`, file.file);
      })
      formData.append("PostOriginID", postOriginId);
      const res = await axios.post("/Posts/SavePostAsDraft", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPostID(res.data.responseData.postId);
      AllPostList(1);

    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const UpdatePostAsDraft = async (images) => {
    try {
      const formData = new FormData();
      formData.append("DeviceType", "Web");
      formData.append("sessionId", sessionId);
      formData.append("PrivacyType", privacyType || "Public");
      formData.append("PostOriginType", PostOriginType);
      const imageNameArray = images.map((image) => image.name);
      const postCategory = determinePostCategory(postContent, imageNameArray);
      formData.append("PostCategory", postCategory);
      formData.append("PostContent", postContent);
      images.forEach((file, index) => {
        formData.append(`FilesToUpload`, file.file);
      })
      formData.append("PostOriginID", postOriginId);
      formData.append("PostId", postID);
      const res = await axios.post("/Posts/UpdatePostDraft", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPostID(res.data.responseData.postId);
      setPostContent("");
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  useEffect(() => {
    if (show || showModal || showDraftModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show, showModal, showDraftModal]);

  const handlePaste = (e) => {
    const isCtrlV = e.ctrlKey && e.key === 'v'; // Check if Ctrl+V was pressed

    if (isCtrlV) {
      e.preventDefault(); // Prevent the default paste action
      alert('Please use Ctrl + Shift + V to paste.'); // Show alert
    }
  };

  useEffect(() => {
    if (showJobDraftModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showJobDraftModal]);
  return (
    <>
      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className=" modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Post Something</h6>
                {/* <Select
                  defaultValue={Privacy.find(option => option.value === privacyType)}
                  onChange={(selectedOption) => {
                    setPrivacyType(selectedOption.value);
                  }}
                  options={Privacy}
                  placeholder={"Select Privacy"}
                  styles={customStyles}
                /> */}
                <Select
                  defaultValue={Privacy.find(option => option.value === privacyType)}
                  onChange={(selectedOption) => {
                    setPrivacyType(selectedOption.value);
                  }}
                  options={Privacy}
                  placeholder={"Select Privacy"}
                  styles={customStyles}
                  formatOptionLabel={({ label, icon }) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {icon}
                      <span style={{ marginLeft: 8 }}>{label}</span>
                    </div>
                  )}
                />
                <CloseSharpIcon className="pointer" onClick={() => {
                  if (postContent === "" && images.length <= 0) {
                    closeModal();
                  } else {
                    openDraftModal();
                  }
                }} />
              </div>
              <div className="m-3">
                {/* <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="What do you want to talk about?"
                  name="about"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                  style={{ width: "100%" }}
                ></textarea> */}
                {/* <ReactQuill
                  value={postContent}
                  onChange={setPostContent}
                  placeholder="What do you want to talk about?"
                  // modules={{
                  //   toolbar: [
                  //     [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  //     [{ size: [] }],
                  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  //     ['link'],
                  //     ['clean']
                  //   ]
                  // }}
                  modules={{
                    toolbar: false, // Hide the toolbar
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link'
                  ]}
                /> */}
                <ReactQuill
                  value={postContent}
                  onChange={setPostContent}
                  onKeyDown={handlePaste} // Use onKeyDown to detect key presses
                  placeholder="What do you want to talk about?"
                  modules={{
                    toolbar: false,
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link',
                  ]}
                />
              </div>

              <div className="modal-border d-flex justify-content-center align-items-center">
                <input
                  id="fileInput"
                  type="file"
                  onChange={imageSelect}
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  multiple
                />

                {images.length > 0 && (
                  <div className="post-selected d-flex justify-content-center flex-wrap">
                    {images.map((image, index) => (
                      <div key={index} className="position-relative m-2">
                        <img
                          src={image.url}
                          alt={`Selected Image ${index}`}
                          style={{
                            maxWidth: "200px",
                            maxHeight: "300px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          onClick={() => removeImage(index)}
                          className="btn btn-danger position-absolute top-0 end-0"
                          style={{
                            transform: "translate(50%, -50%)",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="m-3 d-flex align-items-center">

                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon className="pointer me-2" onClick={() => document.getElementById("image").click()} />

                </form>
                {/* <WorkOutlineSharpIcon className="pointer me-2" onClick={(e) => {
                  guestModeData === "true"
                    ? setShowJobDraftModal(false)
                    : openJobDraftModal();
                }} /> */}
                <WorkOutlineSharpIcon
                  className="pointer me-2"
                  onClick={(e) => {
                    if (guestModeData === "true") {
                      setShowJobDraftModal(false);
                    } else if (ownCompanyList === '0') {
                      openWarningModal();  // Open warning modal if the list is empty
                    } else {
                      openJobDraftModal(); // Open job draft modal otherwise
                    }
                  }}
                />

                <button className="mainBtn ms-auto" disabled={isloading} onClick={handleSubmit}>
                  {isloading === true ? 'Posting..' : 'Post'}
                </button>
              </div>

              <div
                className="card-body d-flex flex-wrap justify-content-start"
                id="container"
              >
                {/* {renderImages()} */}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="featured-card mb-3 feature-responsive">
        <div className="feature-header p-2 d-flex justify-content-between">
          <h4 className="f-14 fw-bold">Post Something</h4>
        </div>
        <div className="d-flex align-items-center p-3">
          <div className="ImgAvatar">
            {guestModeData ? <img src={people.DefaultProfile} alt=""
              height={36}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            /> : <img src={profilPic} alt=""
              height={36}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />}

          </div>
          <p className="f-16 pointer AddPost ms-2" onClick={openModal}>
            What's on your mind?
          </p>
          <div className="ms-auto d-flex">
            <form className="form" action="#" method="post" id="form">
              <input
                type="file"
                name="Image"
                id="image"
                multiple
                className="d-none"
                onChange={imageSelect}
              />
              <ImageSharpIcon className="me-2 pointer" onClick={handleShow} />
            </form>
            {/* <WorkOutlineSharpIcon className="me-2 pointer" onClick={(e) => {
              guestModeData === "true"
                ? setShowJobDraftModal(false)
                : //   e.preventDefault();
                openJobDraftModal();
            }} /> */}
            <WorkOutlineSharpIcon
              className="pointer me-2"
              onClick={(e) => {
                if (guestModeData === "true") {
                  setShowJobDraftModal(false);
                } else if (ownCompanyList === '0') {
                  openWarningModal();  // Open warning modal if the list is empty
                } else {
                  openJobDraftModal(); // Open job draft modal otherwise
                }
              }}
            />

          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-around">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Post Something</h6>
                <Select
                  defaultValue={privacyType}
                  onChange={(e) => {
                    setPrivacyType(e.label);
                  }}
                  options={Privacy}
                  placeholder={"Select Privacy"}
                  styles={customStyles}

                />
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    if (postContent === "" && images.length <= 0) {
                      closeModal();
                    } else {
                      openDraftModal();
                    }
                  }}
                />
              </div>
              <div className="d-flex align-items-center m-3 modalBorder ">
                <div className="ImgAvatar">
                  <img src={profilPic} alt=""
                    height={36}
                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                </div>
                <p className="f-16  AddPost ms-2">What's on your mind?</p>
              </div>
              <div className="m-3">
                {/* <textarea
                  autoFocus={true}
                  id="about"
                  placeholder="What do you want to talk about?"
                  name="about"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  invalid={display && postContent === ""}
                  rows="4"
                  cols="65"
                  style={{ width: "100%" }}
                ></textarea> */}
                <ReactQuill
                  value={postContent}
                  onChange={setPostContent}
                  placeholder="What do you want to talk about?"
                  // modules={{
                  //   toolbar: [
                  //     [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  //     [{ size: [] }],
                  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  //     ['link'],
                  //     ['clean']
                  //   ]
                  // }}
                  modules={{
                    toolbar: false, // Hide the toolbar
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link'
                  ]}
                />
              </div>
              <div className="m-3 d-flex align-items-center">

                <form className="form" action="#" method="post" id="form">
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    className="d-none"
                    onChange={imageSelect}
                  />
                  <ImageSharpIcon
                    className="material-symbols-sharp me-2 mt-2 pointer"
                    // onClick={() => document.getElementById("image").click()}
                    onClick={handleShow}
                  />
                </form>
                {/* <WorkOutlineSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={(e) => {
                    guestModeData === "true"
                      ? setShowJobDraftModal(false)
                      : //   e.preventDefault();
                      openJobDraftModal();
                  }}
                /> */}
                <WorkOutlineSharpIcon
                  className="pointer me-2"
                  onClick={(e) => {
                    if (guestModeData === "true") {
                      setShowJobDraftModal(false);
                    } else if (ownCompanyList === '0') {
                      openWarningModal();  // Open warning modal if the list is empty
                    } else {
                      openJobDraftModal(); // Open job draft modal otherwise
                    }
                  }}
                />


                <button className="mainBtn ms-auto" disabled={isloading} onClick={handleSubmit}>
                  {isloading === true ? 'Posting..' : 'Post'}
                </button>
              </div>

              <div
                className="card-body d-flex flex-wrap justify-content-start"
                id="container"
              >
                {renderImages()}
              </div>
            </div>
          </div>
        </div>
      )}
      {showDraftModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6 className="modalHeader">Save this post as a draft</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeDraftModal}
                />
              </div>
              <div className="d-flex align-items-center modalBorder ">
                <p className="f-14 ms-2 p-2">
                  The post you started will be here when you return.
                </p>
              </div>
              <div className="m-3 d-flex align-items-center">
                <button
                  className="wljSecondaryBtn ms-auto me-3"
                  onClick={() => {
                    closeModal();
                    closeDraftModal();
                    setPostContent("");
                  }}
                >
                  Discard
                </button>
                <button
                  className="wljPrimaryBtn"
                  onClick={() => {
                    if (postID === "") {
                      SavePostAsDraft(images);
                      setShowSecondModal(false);
                      closeModal();
                      closeDraftModal();
                      setToastMessage('Post saved as draft successfully!')
                      setShowToast(true)
                      setPostContent("");
                      setImages([]);
                    } else {
                      UpdatePostAsDraft(images);
                      setShowSecondModal(false);
                      closeModal();
                      closeDraftModal();
                      setToastMessage('Post saved as draft successfully!')
                      setShowToast(true)
                      setPostContent("");
                      setImages([]);
                    }
                  }}
                >
                  Save as draft
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModalData && <ModalForGuest closeModalData={closeModalData} />}
      {showwarningmodal && <CompanyWarning closewarningmodal={closewarningmodal} />}

      {showJobModal && (
        <AddJob
          openModal={openJobModal}
          showModal={showJobModal}
          closeModal={closeJobModal}
        />
      )}

      {showJobDraftModal && (
        <DraftJob
          openModal={openJobDraftModal}
          showModal={showJobDraftModal}
          closeModal={closeJobDraftModal}
        />
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default AddPost;
