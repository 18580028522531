import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { debounce, set } from "lodash";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import Flatpickr from 'react-flatpickr';
import { resume } from "../../assets/Images/ImagesLink";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Button, Overlay, Popover } from "react-bootstrap";
import { Input } from "reactstrap";
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

const ProfileExperienceUpdate = ({
  showExperienceUpdate,
  handleUpdateExperienceClose,
  getUserProfile,
  ExperienceData,
}) => {
  const sessionId = localStorage.getItem("sessionId");
  const [startDate, setStartDate] = useState("");
  const [present, setPresent] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);

  const [display, setDisplay] = useState(false);
  // console.log("ExperienceData1", ExperienceData);
  const [description, setDescription] = useState("");


  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [value, setValue] = useState(null);
  const [pageD, setPageD] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalPagesDesignation, setTotalPagesDesignation] = useState(0);

  const [pageC, setPageC] = useState(0);
  const [typeC, setTypeC] = useState("");
  const [valueC, setValueC] = useState(null);
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [optionsC, setOptionsC] = useState([]);
  const [totalPagesCom, setTotalPagesCom] = useState(0);
  console.log('typeC', typeC, valueC);

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  const logValueC = debounce((typeC) => {
    setTypeC(typeC);
  }, 250);
  console.log('endDate', endDate);
  const [getOrgInsTId, setGenOrgInstId] = useState('');
  const [newOrgType, setnewOrgType] = useState(null);
  const UpdateExperience = async () => {
    setDisplay(true);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userExpId: ExperienceData?.userExpId,
      startDate: startDate === "" ? "" : moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate === "" || endDate === null ? "" : moment(endDate).format("YYYY-MM-DD"),
      isPresent: present,
      jobTitle: typed,
      companyUid: valueC === null ? JSON.stringify(getOrgInsTId) : valueC,
      description: description,
      locationId: null,
      cityId: cityId,
      positionHeadline: headline,
      jobType: jobTypeId,
      workLocationType: workLocationId,
      workLevelType: workTypeId,
    };
    console.log("UpdateExperience", data);
    if (
      // valueC === null ||
      value === null ||
      jobTypeId === null ||
      workLocationId === null ||
      workTypeId === null ||
      cityId === null ||
      startDate === ""
    ) {
      setErrorToastMessage("Please fill all the required fields");
      setShowErrorToast(true);
    } else {
      try {
        await axios
          .put(`UserData/UpdateWorkExperienceInfo`, data)
          .then((resData) => {
            console.log("resData exp", resData.data.responseData);
            getUserProfile();
            setToastMessage(resData.data.message);
            setShowToast(true);
            handleUpdateExperienceClose();
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setWarningToastMessage(error.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(error.response.data.message);
          setShowErrorToast(true);
        }
      }
    }

  };

  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `Master/GetDesignationDDL?search=${typed}&pageNumber=${pageD + 1
        }&pageSize=100`
      )
      .then((response) => {
        console.log(response);
        const data = response?.data?.responseData?.data;
        // Check if data is an array
        if (Array.isArray(data)) {
          const mappedData = data.map((item) => ({
            value: item.dsgntnId,
            label: item.name,
          }));

          setOptions((prevOptions) => [...prevOptions, ...mappedData]);
        } else {
          console.error("Expected data to be an array, but got:", data);
        }
        setPageD((prevPage) => prevPage + 1);
        setIsLoading(false);
        // setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesDesignation(response.data.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setPageD(1);
  };
  const handleChange = (value) => {
    if (value === null) {
      setValue(null);
    } else {
      setValue(value.label);
    }
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPageD(0);
  };

  const handleMenuScrollToBottom = () => {
    if (pageD < totalPagesDesignation) {
      addOptions();
    }
  };

  const handleOpenC = () => {
    setPageC(1);
  };

  const handleChangeC = (value) => {
    if (value === null) {
      setValueC(null);
    } else {
      setValueC(value.value);
    }

    setPageC(0);
  };

  const handleInputChangeC = (typed) => {
    logValueC(typed);
    if (!typed) setPageC(0);
  };

  const handleMenuScrollToBottomC = () => {
    if (pageC < totalPagesCom) {
      Companies();
    }
  };

  const Companies = () => {
    setIsLoadingC(true);
    axios
      .get(
        `Master/GetAllCompaniesDDL?search=${typeC}&pageNumber=${pageC + 1
        }&pageSize=100&DeviceType=Web`
      )
      .then((response) => {
        console.log(response);
        const data = response?.data?.responseData?.list.map((item) => ({
          value: item.uiDno,
          label: item.name,
          isOrgVrfd: item.isVerified,
          isOrgRgd: item.isRgstrd,
        }));
        setPageC((prevPage) => prevPage + 1);
        setIsLoadingC(false);
        setOptionsC((prevOptions) => [...prevOptions, ...data]);
        setTotalPagesCom(response.data.responseData.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingC(false);
      });
  };

  useEffect(() => {
    if (typeC) {
      Companies();
    }
  }, [typeC]);

  console.log("optionsC", optionsC);
  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  const renderOptionC = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  console.log("optionsC", optionsC);
  const [headline, setHeadline] = useState("");

  //********Job Type List Start  ********//
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const getJobType = () => {
    axios
      .get(`Master/GetJobTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbTypId,
          label: item.name,
        }));
        setJobTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getJobType();
  }, []);
  //********Job Type List End  ********//

  //********Work Level Type List Start  ********//
  const [workTypeData, setWorkTypeData] = useState([]);
  const [workTypeId, setWorkTypeId] = useState(null);
  const getWorkType = () => {
    axios
      .get(`Master/GetWorkLevelTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkType();
  }, []);
  //********Work Level Type List End  ********//

  //********Work Location Type List Start  ********//
  const [workLocationData, setWorkLocationData] = useState([]);
  const [workLocationId, setWorkLocationId] = useState(null);
  const getWorkLocationType = () => {
    axios
      .get(`Master/GetWorkLocationTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkLocationType();
  }, []);
  //********Work Location Type List End  ********//

  useEffect(() => {
    if (showExperienceUpdate) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showExperienceUpdate]);

  useEffect(() => {
    if (ExperienceData) {
      setValue(ExperienceData?.dsgntnID);
      setTyped(ExperienceData?.jobTitle);
      setValueC(ExperienceData?.companyUid);
      setTypeC(ExperienceData?.companyName);
      setHeadline(ExperienceData?.positionHeadline);
      setJobTypeId(ExperienceData?.jobType);
      setWorkTypeId(ExperienceData?.workLevelType);
      setWorkLocationId(ExperienceData?.workLocationType);
      setCityId(ExperienceData?.cityId);
      setCitySearch(ExperienceData?.cityName);
      setDescription(ExperienceData?.description);
      setStartDate(ExperienceData?.startDate);
      setEndDate(ExperienceData?.endDate);
      setPresent(ExperienceData?.isPresent);
      setPageC(0)
      setPageD(0)
    }
  }, [ExperienceData]);
  const preventYearScroll = (datePickerInstance) => {
    const yearElement = datePickerInstance.currentYearElement;
    if (yearElement) {
      yearElement.addEventListener("wheel", (e) => {
        e.preventDefault(); // Stops mouse scroll
      });
    }
  };

  const [checkCurrent, setCheckCurrent] = useState('')
  console.log('checkCurrent', checkCurrent);

  const CheckCurrentExperienceStatus = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`ResumeData/CheckCurrentRsmExperienceStatus`, responseData)
        .then((res) => {
          setCheckCurrent(res?.data?.responseData)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    if (showExperienceUpdate) {
      CheckCurrentExperienceStatus()
    }
  }, [])

  const [showTooltip, setShowTooltip] = useState(false);
  const [OrganizationName, setOrganizationName] = useState("");
  const [InsName, setInsName] = useState("");
  const targetRef = useRef(null);
  const toggleTooltip = () => {
    console.log("Toggling Tooltip");
    setShowTooltip((prev) => !prev);
  };

  const [fileName, setFileName] = useState(""); // State to store selected filename
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFileName(file.name); // Set the filename in state
    }
  };



  const [orgCity, setOrgCity] = useState(null);
  const handleSaveInstitution = async () => {
    // setDisplay(true);

    // const formData = new FormData();
    // formData.append("orgName", OrganizationName);
    // formData.append("OrgType", "UnRegdInstitution");
    // formData.append("CityID", orgCity);
    // formData.append("OrgLogo", fileName);
    // formData.append("sessionId", sessionId);
    // console.log("formData", formData);

    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      gnrlOrgName: OrganizationName,
      type: 1,
      cityId: cityId,
      logoImage: fileName,
      locationId: null
    }

    try {
      const res = await axios.post("/General/AddGeneralOrgByUser", data);
      console.log("resposne new", res);
      setGenOrgInstId(res.data.responseData.id);
      setnewOrgType(res.data.responseData.value);
      // toast.success(res.data.message);
      setShowTooltip(false);
      setOrganizationName("");
      setOrgCity(null);
      setFileName("");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // toast.warning(error.response.data.message);
      } else {
        // toast.error(error.response?.data?.message || "An error occurred");
      }
    }
  };

  //************************Enable Experience  ************************//
  const [status, setStatus] = useState('')
  const EnableExperience = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/EnableWorkExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          // getUserProfile();
          setStatus(1)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Enable Experience End  ************************//
  //************************Disable Experience  ************************//
  const DisableExperience = async (Id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: Id,
    };
    try {
      await axios
        .put(`UserData/DisableWorkExperienceInfo`, responseData)
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          // getUserProfile();
          setStatus(0)
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************Disable Experience End  ************************//

  //************************Remove Experience Start  ************************//

  const [showExperienceDelete, setShowExperienceDelete] = useState(false);
  const handleRemoveExperienceClose = () => {
    setShowExperienceDelete(false);
  };

  const [ExperienceId, setExperienceId] = useState("");

  const removeExperience = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      id: ExperienceId,
      // skllId: skillId
    };
    try {
      await axios
        .delete(`UserData/DeleteWorkExperienceInfo`, { data: responseData })
        .then((res) => {
          setShowExperienceDelete(!showExperienceDelete);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          getUserProfile();
        });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  console.log("ExperienceData", ExperienceData);

  //************************Remove Experience End  ************************//

  return (
    <>
      {showExperienceUpdate && (
        <div className="profile-experince-upadate">
          <div
            className="modal fade show modal-lg"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="setting-header d-flex justify-content-between align-items-center p-2">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0 me-3">Experience Info</h6>
                    <div className="form-switch form-check-primary ms-2">
                      <Input
                        type="switch"
                        defaultChecked={ExperienceData?.status === 1 || status === 1}
                        name="icon-primary"
                        onClick={() => {
                          if (ExperienceData?.status === 1 || status === 1) {
                            DisableExperience(ExperienceData.userExpId);
                          } else {
                            EnableExperience(ExperienceData.userExpId);
                          }
                        }}
                        className=""
                      />
                    </div>
                    <DeleteOutlineSharpIcon
                      className="material-symbols-sharp pointer delete-icon"
                      onClick={() => {
                        setShowExperienceDelete(!showExperienceDelete);
                        setExperienceId(ExperienceData.userExpId);
                      }}
                    />
                  </div>

                  <CloseSharpIcon
                    className="material-symbols-sharp me-3 pointer"
                    onClick={handleUpdateExperienceClose}
                  />
                </div>
                <Form>
                  <div className="row modal-p settings CreateCompany">
                    <div className="col-md-6 ">
                      <label htmlFor="about">
                        Designation (Job Title)
                        <span className="text-danger ms-2">*</span>
                      </label>

                      <Select
                        value={options.find((option) => option.value === value)}
                        options={options}
                        optionRenderer={renderOption}
                        placeholder={"Search and Select Designation"}
                        onOpen={handleOpen}
                        styles={customStyles}
                        onChange={handleChange}
                        onMenuScrollToBottom={handleMenuScrollToBottom}
                        onInputChange={handleInputChange}
                        isClearable
                      />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Company<span className="text-danger ms-2">*</span>
                      </label>
                      <a
                        href="#"
                        ref={targetRef}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleTooltip();
                        }}
                        className="createModal f-14 ms-2"
                      // isDisabled={!selectedIndustryId} // Disable until industry is selected
                      >
                        Create New
                      </a>
                      <Overlay
                        target={targetRef.current}
                        show={showTooltip}
                        placement="bottom"
                        rootClose
                        container={document.querySelector(".modal")}
                        onHide={() => setShowTooltip(false)} // Close when clicking outside
                      >
                        <Popover id="popover-basic">
                          <Popover.Header as="h3" className="Dark-Body-Card-group">Create Company</Popover.Header>
                          <Popover.Body className="Dark-Body-Card-group">
                            <Form>
                              <div>
                                <label className="mb-1 Text-mb-Create">Company name</label>
                                <input
                                  type="text"
                                  value={OrganizationName}
                                  onChange={(e) => {
                                    setOrganizationName(e.target.value);
                                    setInsName(e.target.value);
                                  }}
                                  placeholder="Enter Company name"
                                  className="mb-2 form-control Dark-Input-from-group"
                                />
                              </div>
                              <div>
                                <label className="mb-1 Text-mb-Create">City</label>
                                <Select
                                  defaultValue={cityId}
                                  onChange={(selectedOption) =>
                                    setCityId(selectedOption.value)
                                  }
                                  options={cityData}
                                  placeholder={"Search and Select City"}
                                  onInputChange={(inputValue) => {
                                    setCitySearch(inputValue);

                                  }}
                                  invalid={display && cityId === ""}
                                  styles={customStyles}
                                />
                              </div>
                              <div className=" mt-2 mb-2 Text-mb-Create">
                                <label className="mb-1">Institute Logo</label>
                                <input
                                  type="file"
                                  id="lastName"
                                  // className="form-control Dark-Input-from-group"
                                  placeholder=""
                                  onChange={handleFileChange}
                                //   onChange={(e) => {
                                //     handleProfileImageChange(e);
                                //   }}
                                //   invalid={displays && profileImage === ""}
                                />
                                {/* {displays && !profileImage ? (
                          <span className="error_msg_lbl text-red">
                            Upload Image{" "}
                          </span>
                        ) : null} */}
                              </div>
                              <div className="d-flex justify-content-end">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={handleSaveInstitution}
                                  className="me-2"
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => setShowTooltip(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                      <Select
                        value={optionsC.find(
                          (option) => option.value === valueC
                        )}
                        options={optionsC}
                        optionRenderer={renderOptionC}
                        placeholder={"Search and Select Designation"}
                        onOpen={handleOpenC}
                        onChange={handleChangeC}
                        onMenuScrollToBottom={handleMenuScrollToBottomC}
                        onInputChange={handleInputChangeC}
                        isClearable
                        formatOptionLabel={(option) => (
                          <div>
                            {option.label}
                            {option.isOrgVrfd === true ? (
                              <img src={resume.verified} width={15} className="ms-1" />
                            ) : option.isOrgRgd === true ? (
                              <img src={resume.register} width={15} className="ms-1" />
                            ) : (
                              <img src={resume.unregister} width={15} className="ms-1" />
                            )}
                          </div>
                        )}
                        className="iconsDropdown"
                        styles={customStyles}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Profile Headline
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        rows="2"
                        cols="50"
                        value={headline}
                        onChange={(e) => setHeadline(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Employment type (Job Type){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <Select
                        value={jobTypeData.find(
                          (options) => options.value === jobTypeId
                        )}
                        onChange={(e) => {
                          setJobTypeId(e.value);
                        }}
                        options={jobTypeData}
                        styles={customStyles}
                        placeholder={"Select Job Type"}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Work Level Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        value={workTypeData.find(
                          (options) => options.value === workTypeId
                        )}
                        onChange={(e) => {
                          setWorkTypeId(e.value);
                        }}
                        options={workTypeData}
                        styles={customStyles}
                        placeholder={"Select Work Level Type"}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Work Location Type{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        value={workLocationData.find(
                          (options) => options.value === workLocationId
                        )}
                        onChange={(e) => {
                          setWorkLocationId(e.value);
                        }}
                        options={workLocationData}
                        styles={customStyles}
                        placeholder={"Select Work Location Type"}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        City<span className="text-danger ms-2">*</span>
                      </label>
                      <Select
                        value={cityData.find(
                          (options) => options.value === cityId
                        )}
                        onChange={(selectedOption) =>
                          setCityId(selectedOption.value)
                        }
                        options={cityData}
                        placeholder={"Search and Select City"}
                        onInputChange={(inputValue) => {
                          setCitySearch(inputValue);
                        }}
                        styles={customStyles}
                      />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Description
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        rows="2"
                        cols="50"
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    {checkCurrent === false || present === true ? <div className="col-md-12 mt-2 d-flex align-items-center">
                      <input
                        type="checkbox"
                        style={{ width: "20px", height: "20px", marginRight: "5px" }}
                        //   id={`check-${item.id}`}
                        checked={present}
                        onChange={() => setPresent(!present)}
                      />
                      <label htmlFor="about">IsPresent</label>
                    </div> : ''}
                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        Start Year<span className="text-danger ms-2">*</span>
                      </label>

                      <Flatpickr
                        className="form-control"
                        value={startDate}
                        onChange={(date) => setStartDate(date[0])}
                        id="dob"
                        options={{
                          maxDate: "today", // Disables future dates
                          onReady: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Call the function to stop scroll on year
                          },
                          onOpen: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Also prevent scroll when the picker opens
                          },
                        }}
                      />

                      {display && !startDate ? (
                        <span className="error_msg_lbl">
                          Please Select Start Year{" "}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="about">
                        End Year
                      </label>

                      <Flatpickr
                        className="form-control"
                        value={endDate}
                        onChange={(date) => setEndDate(date[0])}
                        id="dob"
                        options={{
                          maxDate: "today", // Disables future dates
                          onReady: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Call the function to stop scroll on year
                          },
                          onOpen: (selectedDates, dateStr, instance) => {
                            preventYearScroll(instance); // Also prevent scroll when the picker opens
                          },
                        }}
                      />
                    </div>

                    <div className="text-end mt-4">
                      <button
                        type="button"
                        className="closebtn me-2"
                        onClick={handleUpdateExperienceClose}
                        style={{ width: "60px" }}
                      >
                        close
                      </button>
                      <button
                        type="button"
                        className="mainBtn1"
                        onClick={UpdateExperience}
                        style={{ width: "70px" }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
      {showExperienceDelete && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are You Sure You want to Delete Experience?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleRemoveExperienceClose();
                  }}
                />
              </div>

              <div className="mt-2 text-center">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => removeExperience()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileExperienceUpdate;
