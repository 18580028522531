import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import "../CreateCompany/CreateCompany.css";
import Select from "react-select";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { customStyles } from "../../selectStyles";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
const options = [
  { value: "Mumbai", label: "Mumbai" },
  { value: "Thane", label: "Thane" },
  { value: "Pune", label: "Pune" },
];
const AddJob = ({ showModal, closeModal, openModal }) => {
  const sessionId = localStorage.getItem("sessionId");

  const [jobTitle, setjobTitle] = useState("");
  const [jobDescription, setjobDescription] = useState("");
  const [minimumSalary, setMinimumSalary] = useState("");
  const [maximumSalary, setMaximumSalary] = useState("");
  const [currency, setCurrency] = useState("");
  const [hashtags, setHashtags] = useState("");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const reset = () => {
    setjobTitle("");
    setjobDescription("");
    setMinimumSalary("");
    setMaximumSalary("");
    setCurrency("");
    setHashtags("");
    setCompanyData([]);
    setCityData([]);
    setWorkLocationData([]);
    setWorkTypeData([]);
    setsalaryData([]);
    setsourceTypeData([]);
    setJobTypeData([]);
    setOptions([]);
  };

  //********Company List Start  ********//
  const [companyData, setCompanyData] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const getCompany = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Company/GetOwnCompaniesList`, data)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.compUid,
          label: item.compName,
        }));
        setCompanyData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCompany();
  }, []);
  //********Company List End  ********//

  //********Job Type List Start  ********//
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const getJobType = () => {
    axios
      .get(`Master/GetJobTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbTypId,
          label: item.name,
        }));
        setJobTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getJobType();
  }, []);
  //********Job Type List End  ********//

  //********Work Level Type List Start  ********//
  const [workTypeData, setWorkTypeData] = useState([]);
  const [workTypeId, setWorkTypeId] = useState(null);
  const getWorkType = () => {
    axios
      .get(`Master/GetWorkLevelTypesDDL`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkType();
  }, []);
  //********Work Level Type List End  ********//

  //********Work Location Type List Start  ********//
  const [workLocationData, setWorkLocationData] = useState([]);
  const [workLocationId, setWorkLocationId] = useState(null);
  const getWorkLocationType = () => {
    axios
      .get(`Master/GetWorkLocationTypesDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setWorkLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWorkLocationType();
  }, []);
  //********Work Location Type List End  ********//
  //********Salary Type List Start  ********//
  const [salaryData, setsalaryData] = useState([]);
  const [salaryId, setsalaryId] = useState(null);
  const getsalaryType = () => {
    axios
      .get(`Master/GetSalaryTypesDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.jbSlryTypId,
          label: item.name,
        }));
        setsalaryData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getsalaryType();
  }, []);
  //********Salary Type List End  ********//
  //********Work Location Type List Start  ********//
  const [sourceTypeData, setsourceTypeData] = useState([]);
  const [sourceTypeId, setsourceTypeId] = useState("");
  const getsourceTypeType = () => {
    axios
      .get(`Master/GetSourceTypesDDL`)
      .then((response) => {
        // console.log(response.data.responseData);
        const res = response.data.responseData;
        const data = res.map((item) => ({ value: item.id, label: item.name }));
        setsourceTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getsourceTypeType();
  }, []);
  //********Work Location Type List End  ********//

  //********City List Start  ********//
  const [cityData, setCityData] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [citySearch, setCitySearch] = useState(null);
  const getCity = () => {
    axios
      .get(`Master/GetCitiesDDL?search=${citySearch}`)
      .then((response) => {
        const res = response.data.responseData;
        const data = res.map((item) => ({
          value: item.cityId,
          label: item.cityName,
        }));
        setCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (citySearch) {
      getCity();
    }
  }, [citySearch]);
  //********City List End  ********//

  //************************Designation Start  ************************//
  const [value, setValue] = useState(null);
  const [page, setPage] = useState(0);
  const [typed, setTyped] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const logValue = debounce((typed) => {
    setTyped(typed);
  }, 250);

  const addOptions = () => {
    setIsLoading(true);
    axios
      .get(
        `MasterDDLs/GetDesignationDDL?search=${typed}&pageNumber=${page + 1
        }&pageSize=100`
      )
      .then((response) => {
        const data = response.data.data.map((item) => ({
          value: item.dsgntnId,
          label: item.name,
        }));
        setPage((prevPage) => prevPage + 1);
        setIsLoading(false);
        setOptions((prevOptions) => [...prevOptions, ...data]);
        setTotalPages(response.data.paging.totalPages);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    setPage(1);
  };

  const handleChange = (value) => {
    setValue(value);
    setPage(0);
  };

  const handleInputChange = (typed) => {
    logValue(typed);
    if (!typed) setPage(0);
  };

  const handleMenuScrollToBottom = () => {
    if (page < totalPages) {
      addOptions();
    }
  };

  const renderOption = ({
    focusedOption,
    focusOption,
    key,
    labelKey,
    option,
    selectValue,
    style,
    valueArray,
  }) => {
    // Your rendering logic here
  };

  useEffect(() => {
    if (typed) {
      addOptions();
    }
  }, [typed]);

  //************************ Designation End  ************************//

  const handleSubmit = async () => {
    try {
      // setDisplay(true)
      if (jobTitle && jobDescription && minimumSalary && maximumSalary !== "") {
        const body = {
          deviceType: "Web",
          sessionId: sessionId,
          compUid: companyId,
          locationId: null,
          cityID: cityId,
          jbTypId: jobTypeId,
          workLevelType: workTypeId,
          workLocationType: workLocationId,
          // "designationId": value,
          designationId: 1,
          salaryTypeId: salaryId,
          minSalary: minimumSalary,
          maxSalary: maximumSalary,
          currency: null,
          title: jobTitle,
          description: jobDescription,
          hashTags: hashtags,
          sourceTypeId: null,
          receiveApplicantsType: 1,
        };
        // console.log('body', body);
        const res = await axios.post("/Job/AddJob", body);
        // console.log("response: ", res.data.message)
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        reset();
        closeModal();
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center">
                <h6>Add Job</h6>
                {/* <span
                  className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                >
                  close
                </span> */}
                <CloseSharpIcon className="material-symbols-sharp me-2 pointer"
                  onClick={closeModal}
                />
              </div>
              <Form>
                <div className="row p-4 settings CreateCompany">
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={jobTitle}
                      onChange={(e) => setjobTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows="4"
                      cols="50"
                      value={jobDescription}
                      onChange={(e) => setjobDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Company Name <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      defaultValue={companyId}
                      onChange={(e) => {
                        setCompanyId(e.value);
                      }}
                      options={companyData}
                      placeholder={"Select Company"}
                      styles={customStyles}
                    />
                  </div>
                  {/* <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Location Name </label>
                                        <Select
                                            options={options}
                                        />
                                    </div> */}
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      City Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={cityId}
                      onChange={(selectedOption) =>
                        setCityId(selectedOption.value)
                      }
                      options={cityData}
                      placeholder={"Search and Select City"}
                      onInputChange={(inputValue) => {
                        setCitySearch(inputValue);
                      }}
                      styles={customStyles}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Job Type <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      defaultValue={jobTypeId}
                      onChange={(e) => {
                        setJobTypeId(e.value);
                      }}
                      options={jobTypeData}
                      placeholder={"Select Job Type"}
                      styles={customStyles}

                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Work Level Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={workTypeId}
                      onChange={(e) => {
                        setWorkTypeId(e.value);
                      }}
                      options={workTypeData}
                      placeholder={"Select Work Level Type"}
                      styles={customStyles}

                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Work Location Type <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input type='text' /> */}
                    <Select
                      defaultValue={workLocationId}
                      onChange={(e) => {
                        setWorkLocationId(e.value);
                      }}
                      options={workLocationData}
                      placeholder={"Select Work Location Type"}
                      styles={customStyles}

                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Designation <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      value={value}
                      options={options}
                      optionRenderer={renderOption}
                      placeholder={"Search and Select Designation"}
                      onOpen={handleOpen}
                      onChange={handleChange}
                      onMenuScrollToBottom={handleMenuScrollToBottom}
                      onInputChange={handleInputChange}
                      isClearable
                      styles={customStyles}

                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Salary Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      defaultValue={salaryId}
                      onChange={(e) => {
                        setsalaryId(e.value);
                      }}
                      options={salaryData}
                      placeholder={"Select Salary Type"}
                      styles={customStyles}

                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Minimum Salary <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={minimumSalary}
                      onChange={(e) => setMinimumSalary(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Maximum Salary <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={maximumSalary}
                      onChange={(e) => setMaximumSalary(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Currency <span style={{ color: 'red' }}>*</span></label>
                                        <input type='text'
                                            value={currency}
                                            onChange={e => setCurrency(e.target.value)} />
                                    </div> */}

                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Hashtags <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={hashtags}
                      onChange={(e) => setHashtags(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Source Type <span style={{ color: 'red' }}>*</span></label>
                                       
                                        <Select
                                            defaultValue={sourceTypeId}
                                            onChange={setsourceTypeId}
                                            options={sourceTypeData}
                                            placeholder={'Select Source Type'}
                                        />
                                    </div> */}
                  {/* <div className="col-md-12 mt-2">
                                        <label htmlFor="about">Receive Applicants Type <span style={{ color: 'red' }}>*</span></label>
                                        
                                        <Select
                                            options={options}
                                        />
                                    </div> */}

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="mainBtn1"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default AddJob;
