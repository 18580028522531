import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
const NotificationAction = ({ setNotificationList, markAsRead, notifiID, NotificationList, page, item, setNoticationRead, noticationRead }) => {
  console.log("page", page);

  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputFileRef = useRef(null);
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //************************Delete Post start  ************************//
  const [show, setShow] = useState(false);
  const handleDeleteClose = () => {
    setShow(false);
  };

  const deleteNoti = async (event) => {
    event.preventDefault();
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(`General/DeleteNotification?ID=${notifiID}`, responseData)
        .then((res) => {
          setShow(!show);
          // toast.success(res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);

          setNotificationList((prev) => prev.filter((item) => item.ntfctnId !== notifiID));
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//
  // const [noId, setNoId] = useState('')
  const unreadNotification = async (noId) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    try {
      await axios
        .post(
          `General/MarkNotificationAsUnRead?ID=${notifiID}`,
          responseData
        )
        .then((res) => {
          setToastMessage(res.data.message);
          setShowToast(true);
          NotificationList(page);
          markAsRead(notifiID)
          setShowDropdown(!showDropdown)
        });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [show]);

  return (
    <>
      <div
        className="postMenu pointer"
        onClick={(e) => {
          e.preventDefault(); // Prevents the default behavior (navigation)
          e.stopPropagation(); // Prevents card click
          setShowDropdown(!showDropdown)
        }}
      >
        <MoreHorizSharpIcon className="material-symbols-sharp" />
        {showDropdown && (
          <Dropdown.Menu
            show={showDropdown}
            ref={dropdownRef}
            className="InteractionModal"
          >
            <Dropdown.Item as="button" className="dropdownList">
              <Link
                onClick={(e) => {
                  setShow(!show);
                  toggleDropdown();
                  e.preventDefault(); // Prevents the default behavior (navigation)
                  e.stopPropagation()
                }}
              >
                <div className="d-flex align-items-center">
                  <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                  <span className="dropdownTxt f-14">Delete</span>
                </div>
                <p className="f-12 dropdownPara">
                  Once Deleted it can't be recovered
                </p>
              </Link>
            </Dropdown.Item>
            {item?.isView === true ? (
              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={(e) => {
                    unreadNotification(item?.ntfctnId);
                    e.preventDefault(); // Prevents the default behavior (navigation)
                    e.stopPropagation()
                    // setNoId(item?.ntfctnId)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <NotificationsNoneSharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">
                      UnRead Notification
                    </span>
                  </div>
                  <p className="f-12 dropdownPara">Unread</p>
                </Link>
              </Dropdown.Item>
            ) : (
              ""
            )}
          </Dropdown.Menu>
        )}
      </div>



      {show && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Are you sure you want to delete Notification?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={(e) => {
                    deleteNoti(e)
                    e.preventDefault(); // Prevents the default behavior (navigation)
                    e.stopPropagation()
                  }}
                >
                  Delete
                </button>
              </div>


            </div>
          </div>
        </div>
      )}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default NotificationAction;
