import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
// import { post } from '../../assets/Images/ImagesLink'
import like from "../../assets/Images/Post/f4ly07ldn7194ciimghrumv3l.svg";
import celebrate from "../../assets/Images/Post/3c4dl0u9dy2zjlon6tf5jxlqo.svg";
import support from "../../assets/Images/Post/9whrgl1hq2kfxjqr9gqwoqrdi.svg";
import love from "../../assets/Images/Post/asmf650x603bcwgefb4heo6bm.svg";
import insightful from "../../assets/Images/Post/39axkb4qe8q95ieljrhqhkxvl.svg";
import funny from "../../assets/Images/Post/ktcgulanbxpl0foz1uckibdl.svg";
import likegif from "../../assets/Images/Post/Animation - 1714999935336.gif";
import { motion } from "framer-motion";
import Comment from "./Comment";
import CommentAction from "./CommentAction";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Col, Modal, Row } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { post } from "../../assets/Images/ImagesLink";
import ModalForGuest from "../ModalForGuest";
import ThumbUpAltSharpIcon from '@mui/icons-material/ThumbUpAltSharp';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const PostEngagement = ({
  item,
  postId,
  AllPostList,
  postList,
  setPage,
  setPostList,
  page,
  setTotalPages,
  totalPages,
  setDLike,
  updateLikeCount,
  updateShareCount,
  openModal,
  setSinglePostContent,
  setSinglePostId,
  setIspostmodalOpen,
  ispostmodalOpen,
  emojiList,
  isSavedPage
}) => {
  const [showComments, setShowComments] = useState(false);
  console.log("engage postId", postId);
  const guestModeData = localStorage.getItem("guestMode");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const toggleComments = () => {
    setShowComments(!showComments);
  };
  // console.log(showComments);

  console.log("item", item);
  const sessionId = localStorage.getItem("sessionId");
  //************************Save Post start  ************************//
  const [isSaved, setIsSaved] = useState(false);
  const savePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/SavePost`, responseData).then((res) => {
        console.log("save post", res);
        // toast.success(res.data.message);
        setIsSaved(true);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Save Post End  ************************//

  //************************UnSave Post start  ************************//
  const unsavePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      const res = await axios.post(`PostAction/UnsavePost`, responseData)
      // .then((res) => {
      // console.log(res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setIsSaved(false);
      if (isSavedPage) {
        setPostList((prevPostList) =>
          prevPostList.filter((post) => post.postId !== postId)
        );
      } else {
        const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
        console.log('postDetailsResponse', postDetailsResponse);
        const updatedPost = postDetailsResponse.data.responseData;
        console.log('updatedPost', updatedPost);

        // Update the existing post in the list
        setPostList((prev) =>
          prev.map((post) =>
            post.postId === updatedPost.postId ? updatedPost : post
          )
        );
      }
      // });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnSave Post End  ************************//

  //************************like Post start  ************************//
  const [selectedImg, setSelectedImage] = useState("");
  const [isLiking, setIsLiking] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likedType, setLikedType] = useState(null);
  const [changeLikedType, setChangeLikedType] = useState(null);
  const LikePost = async (liketype, imageName) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      likeType: liketype,
    };
    // console.log(responseData);
    setIsLiking(true);
    setLikedType(liketype);

    setTimeout(async () => {
      try {
        await axios.post(`PostAction/LikePost`, responseData).then((res) => {
          console.log("likedType", res, item.likedType);
          // toast.success('gdhghdg', res.data.message);
          setToastMessage(res.data.message);
          setShowToast(true);
          AllPostList();
          setSelectedImage(imageName);
          setIsHovered(false);
          setIsLiking(false);
          setIsLiked(true);
          setLikedType(liketype);
          // setDLike(1)
          updateLikeCount(postId, 1);
        });
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setWarningToastMessage(e.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(e.response.data.message);
          setShowErrorToast(true);
        }
      }
    }, 1000);
  };




  const ReLikePost = async (liketype, imageName) => {

    try {
      const responseData = {
        deviceType: "Web",
        sessionId: sessionId,
        postId: postId,
        likeType: liketype,
      };
      // console.log(responseData);
      setIsLiking(true);
      setLikedType(liketype);

      const res = await axios.post("/PostAction/ChangeLikeType", responseData);

      console.log("likedTyphgjhhje", res, item.likedType);
      // toast.success('gdhghdg', res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setSelectedImage(imageName);
      setIsHovered(false);
      setIsLiking(false);
      setIsLiked(true);
      setLikedType(liketype);
      setChangeLikedType(liketype)

    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }

  };



  //************************like Post End  ************************//
  //************************unlike Post start  ************************//
  const UnLikePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    // console.log(responseData);

    try {
      const res = await axios.post(`PostAction/UnlikePost`, responseData)
      // .then((res) => {
      // console.log(res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setIsHovered(false);
      setIsLiked(false);
      setLikedType(null);
      // setDLike(-1)
      updateLikeCount(postId, -1);
      console.log("hghdgshgd", likedType, item.likedType);


      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      console.log('postDetailsResponse', postDetailsResponse);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);

      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
      // Update the existing post in the list
      // setPostList(updatedPost)

    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************unlike Post End  ************************//
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredRelike, setIsHoveredRelike] = useState(false);
  const list = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const [shareModal, setShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState();
  const sharePost = async () => {
    const sharePost = {
      deviceType: "Web",
      id: postId,
      type: 1,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", sharePost).then((res) => {
        console.log("share", res);
        setShareUrl(res.data.responseData.value);
        updateShareCount(postId, 1);
        // setToastMessage(res.data.message);
        // setShowToast(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = () => {
    console.log("likes is pressed");
  };

  useEffect(() => {
    if (shareModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [shareModal]);

  const [followModal, setFollowModal] = useState(false);
  const closeModalData = () => {
    setFollowModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between pt-3">

        <div className="d-flex align-items-center postAction likeActions ms-2">
          {console.log('changeLikedType', isLiked, likedType, changeLikedType)}

          {isLiked === true || item?.isLiked === true ? (

            <motion.div
              className="like-container pointer d-flex align-items-center"
              onClick={() => UnLikePost()}
              whileHover={{ scale: 1.3 }}
              onMouseEnter={() => setIsHoveredRelike(true)}
              onMouseLeave={() => setIsHoveredRelike(false)}
            >
              {item?.likedType === 1 || likedType === 1 || changeLikedType === 1 ? (
                <>
                  <img src={post.LikeGif} width={25} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ) : item?.likedType === 2 || likedType === 2 || changeLikedType === 2 ? (
                <>
                  <img src={post.LoveGif} width={25} />
                  <p className="f-12 ms-1">Love</p>
                </>
              ) : item?.likedType === 3 || likedType === 3 || changeLikedType === 3 ? (
                <>
                  <img src={post.LaughingGif} width={25} />
                  <p className="f-12 ms-1">Laugh</p>
                </>
              ) : item?.likedType === 4 || likedType === 4 || changeLikedType === 4 ? (
                <>
                  <img src={post.WowGif} width={25} />
                  <p className="f-12 ms-1">Wow</p>
                </>
              ) : item?.likedType === 5 || likedType === 5 || changeLikedType === 5 ? (
                <>
                  <img src={post.AngryGif} width={25} />
                  <p className="f-12 ms-1">Angry</p>
                </>
              ) : (
                <>
                  <img src={post.LikeGif} width={25} />
                  <p className="f-12 ms-1">Like</p>
                </>
              )}
            </motion.div>
          ) : isLiking ? (
            (console.log("likedType", likedType),
              likedType === 1 || changeLikedType === 1 ? (
                <>
                  {/* <img src={post.LikeGif} width={30} /> */}
                  <img src={likegif} alt="loading" width={50} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ) : likedType === 2 || changeLikedType === 2 ? (
                <>
                  <img src={post.LoveGif} width={25} />
                  <p className="f-12 ms-1">Love</p>
                </>
              ) : likedType === 3 || changeLikedType === 3 ? (
                <>
                  <img src={post.LaughingGif} width={25} />
                  <p className="f-12 ms-1">Laugh</p>
                </>
              ) : likedType === 4 || changeLikedType === 4 ? (
                <>
                  <img src={post.WowGif} width={25} />
                  <p className="f-12 ms-1">Wow</p>
                </>
              ) : likedType === 5 || changeLikedType === 5 ? (
                <>
                  <img src={post.AngryGif} width={25} />
                  <p className="f-12 ms-1">Angry</p>
                </>
              ) : (
                <>
                  {/* <img src={post.LikeGif} width={30} /> */}
                  <img src={likegif} alt="loading" width={50} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ))
          ) : (
            <>
              {guestModeData === "true" ? (
                <div className="d-flex align-items-center">
                  <motion.span
                    className="pointer pe-1 "
                    onClick={() => {
                      setFollowModal(true);
                    }}
                  >
                    <ThumbUpAltOutlinedIcon />
                  </motion.span>
                  <p
                    className="f-12 pointer ms-1"
                    onClick={() => {
                      setFollowModal(true);
                    }}
                  >
                    Like
                  </p>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <motion.span
                    whileHover={{ scale: 1.3 }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => { LikePost(1, 'Like') }}
                    className="pointer  pe-1"
                  >
                    <ThumbUpAltOutlinedIcon />
                  </motion.span>
                  <motion.p
                    className="f-12 pointer ms-1"
                    whileHover={{ scale: 1.3 }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => { LikePost(1, 'Like') }}
                  >
                    Like
                  </motion.p>
                </div>

              )}


            </>
          )}
          {isHovered && (
            <motion.div
              className="reactionsHolder"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              variants={list}
            >
              {emojiList?.map((item) => {
                return (
                  <div className="emoji-container" key={item.value}>
                    <motion.img
                      whileHover={{ scale: 1.5 }}
                      src={item.url}
                      alt=""
                      onClick={() => {
                        LikePost(item.value, item.name);
                      }}
                    />
                    <span className="emoji-name">{item.name}</span>
                  </div>
                );
              })}
            </motion.div>
          )}
          {isHoveredRelike && (
            <motion.div
              className="reactionsHolder"
              onMouseEnter={() => setIsHoveredRelike(true)}
              onMouseLeave={() => setIsHoveredRelike(false)}
              variants={list}
            >
              {emojiList?.map((item) => {
                return (
                  <div className="emoji-container" key={item.value}>
                    <motion.img
                      whileHover={{ scale: 1.5 }}
                      src={item.url}
                      alt=""
                      onClick={() => {
                        ReLikePost(item.value, item.name);
                      }}
                    />
                    <span className="emoji-name">{item.name}</span>
                  </div>
                );
              })}

            </motion.div>
          )}
        </div>
        {ispostmodalOpen === undefined ||
          ispostmodalOpen === true ||
          guestModeData === "true" ? (
          <div className="d-flex align-items-center postAction">
            <ChatOutlinedIcon className="pointer" onClick={() => {
              setFollowModal(true);
            }} />
            <p
              className="f-12 pointer ms-1"
              onClick={() => {
                setFollowModal(true);
              }}
            >
              Comment
            </p>
          </div>
        ) : (
          <div
            className="d-flex align-items-center postAction"
            onClick={() => {
              openModal(item.mediaFilesList.map((file) => file.mediaName));
              setSinglePostContent(item);
              setIspostmodalOpen(true);
              setSinglePostId(item?.postId);
            }}
          >
            <ChatOutlinedIcon className="pointer" />
            <p className="f-12 pointer ms-1">Comment</p>
          </div>
        )}

        <div className="d-flex align-items-center postAction">
          <ShareOutlinedIcon onClick={(e) => {
            e.preventDefault();
            setShareModal(true);
            sharePost();
          }} className="pointer" />
          <p className="f-12 pointer ms-1" onClick={(e) => {
            e.preventDefault();
            setShareModal(true);
            sharePost();
          }}>Share</p>
        </div>
        {console.log("isSaved", item, isSaved, item?.isSaved, guestModeData)}

        {guestModeData === "true" ? <div className="d-flex align-items-center postAction">
          {/* <span
            className="material-symbols-outlined pointer"
            onClick={() => {
              setFollowModal(true);
            }}
            disabled={guestModeData === "true"}
          >
            bookmark
          </span> */}
          {/* <BookmarkBorderOutlinedIcon /> */}
          <BookmarkBorderOutlinedIcon className=" material-symbols-outlined pointer" onClick={() => {
            setFollowModal(true);
          }}
            disabled={guestModeData === "true"} />
          <p className="f-12 pointer ms-1" onClick={() => {
            setFollowModal(true);
          }}>Save</p>
        </div> : isSaved === true || item?.isSaved === true ? (
          <div className="d-flex align-items-center postAction">
            {/* <span
              className="material-symbols-sharp pointer"
              onClick={() => {
                unsavePost();
              }}
            >
              bookmark
            </span> */}
            <BookmarkOutlinedIcon onClick={() => {
              unsavePost();
            }} className="material-symbols-sharp pointer" />
            <p className="f-12 pointer ms-1" onClick={() => {
              unsavePost();
            }}>Saved</p>
          </div>
        ) : (
          <div className="d-flex align-items-center postAction">
            {/* <span
              className="material-symbols-outlined pointer"
              onClick={() => {
                savePost();
              }}
            >
              bookmark
            </span> */}
            <BookmarkBorderOutlinedIcon className="pointer" onClick={() => {
              savePost();
            }} />
            <p className="f-12 pointer me-1" onClick={() => {
              savePost();
            }}>Save</p>
          </div>
        )}

      </div>

      {ispostmodalOpen === undefined || ispostmodalOpen === true ? (
        <Comment
          item={item}
          isOwnedPost={item?.isOwned}
          postId={item?.postId}
          AllPostList={AllPostList}
          postList={postList}
          setPage={setPage}
          setPostList={setPostList}
          page={page}
          setTotalPages={setTotalPages}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      {followModal && <ModalForGuest closeModalData={closeModalData} />}
      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />


      {shareModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Share Post</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShareModal(false);
                  }}
                />
              </div>

              <div className="mt-3">
                <FacebookShareButton
                  url={shareUrl}
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <FacebookIcon size={40} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <WhatsappIcon size={40} />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <LinkedinIcon size={40} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <TwitterIcon size={40} />
                </TwitterShareButton>

                <Row>
                  <Col
                    className="mt-1 d-flex justify-content-between"
                    xs={2}
                    lg={2}
                  ></Col>
                  <Col
                    className="mt-1 d-flex justify-content-between"
                    xs={2}
                    lg={2}
                  ></Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PostEngagement;
