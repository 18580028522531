import React, { useEffect, useRef, useState } from "react";
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import axios from "../../API/axios";
import { post } from "../../assets/Images/ImagesLink";
import AddPost from "./AddPost";
import PostInteractionModal from "./PostInteractionModal";
import { Link } from "react-router-dom";
import ImageSliderModal from "./ImageSliderModal";
import Loading from "../Blogs/Loading";
import moment from "moment";
import PostEngagement from "./PostEngagement";
import PostComment from "./PostComment";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import Comment from "./Comment";
import LikesDetails from "./LikesDetails";
import { warning } from "framer-motion";
import {
  ShimmerCategoryItems,
  ShimmerDiv,
  ShimmerTitle,
} from "shimmer-effects-react";
import pin from "../../assets/Images/svg/pin.svg";
import PostComponent from "./PostComponent";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Advertisement from "./PostAdvertisement";

const AllPost = ({ onPostsLoaded }) => {
  const sessionId = localStorage.getItem("sessionId");
  const guestModeData = localStorage.getItem("guestMode");
  const isDark = localStorage.getItem("isDark");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isError, setIsError] = useState(false);
  const [dLike, setDLike] = useState(0);

  const userUid = localStorage.getItem("userUid");
  const [nextBatchLoading, setNextBatchLoading] = useState("");
  const AllPostList = async (page) => {
    const data = {
      deviceType: "Web",
      pageNumber: page,
      pageSize: 12,
    };
    try {
      setLoading(true);
      setNextBatchLoading(true);
      const resData = await axios.post(`SMFeatures/NewsFeeds?SortBy=Recent`, data);
      setIsLoading(false);

      setTotalPages(resData.data.responseData.paging.totalPages);
      const newPosts = resData.data.responseData.postdetails;

      setPostList((prev) => {
        const postSet = new Set(prev.map((post) => post.postId));
        const uniqueNewPosts = newPosts.filter((post) => !postSet.has(post.postId));
        return [...prev, ...uniqueNewPosts];
      });
      if (page >= resData.data.responseData.paging.totalPages) {
        setHasMoreData(false);
      }
      setNextBatchLoading(false);
      onPostsLoaded()

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsError(true);
      } else {
        console.error(error);
      }
      // setIsLoading(false);
      setNextBatchLoading(false);
    }
  };
  const [hasMoreData, setHasMoreData] = useState(true);
  useEffect(() => {

    if (page <= totalPages) {
      AllPostList(page);
    }
  }, [page]);
  console.log('hasMoreData', hasMoreData, page, totalPages);

  // Function to interleave ads after every 12 posts
  const getPostListWithAds = (posts) => {
    const result = [];
    posts.forEach((post, index) => {
      result.push(post);
      // Insert AdComponent after every 12 posts
      if ((index + 1) % 12 === 0) {
        result.push({ isAd: true }); // Add a placeholder object for ads
      }
    });
    return result;
  };

  const postsWithAds = getPostListWithAds(postList);

  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  const updateLikeCount = (postId, delta) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, likeCount: post.likeCount + delta }
          : post
      )
    );
  };

  const updateCommentCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, commentCount: post.commentCount + change }
          : post
      )
    );
  };

  const updateShareCount = (postId, change) => {
    setPostList((prev) =>
      prev.map((post) =>
        post.postId === postId
          ? { ...post, shareCount: post.shareCount + change }
          : post
      )
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [singlePostContent, setSinglePostContent] = useState("");
  const [singlepostId, setSinglePostId] = useState("");
  const [ispostmodalOpen, setIspostmodalOpen] = useState(false);

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  });

  const [mediaFiles, setMediaFiles] = useState([]);
  const openModal = (mediaFiles, initialSlide) => {
    setSliderSettings((prevSettings) => ({
      ...prevSettings,
      initialSlide: initialSlide,
    }));
    setMediaFiles(mediaFiles);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isfollowed, setIsfollowed] = useState(false);

  const [emojiList, setemojiList] = useState();
  const GetEmoji = async () => {
    try {
      await axios.get("Master/GetEmojiDDL").then((res) => {
        setemojiList(res.data.responseData);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetEmoji();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [postLikedType, setPostLikedType] = useState(false);
  const [postId, setPostId] = useState("");
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const [imageArray, setImageArray] = useState([]);

  const handleShowModal = (item) => {
    setPostId(item.postId);
    setPostLikedType(item.likedType);
    setShowModal(true);
  };
  const handleLikesCloseModal = () => {
    setShowModal(false);
  };


  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
  }, []);

  const [showDeletePost, setShowDeletePost] = useState(false)

  useEffect(() => {
    if (isModalOpen || showDeletePost) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [isModalOpen, showDeletePost])



  const [userPer, setUserPer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        const res = response.data.responseData;
        setUserPer(res.percentage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingalert(false); // Set loading to false once the data is fetched
      });
  };

  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

  // Condition to show the alert
  const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;

  return (
    <>

      {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )}
      <AddPost
        AllPostList={AllPostList}
        PostOriginType={"User"}
        postOriginId={userUid}
        setPostList={setPostList}
      />
      {/* <iframe width="100%" height="360" src="https://www.videoindexer.ai/embed/player/00000000-0000-0000-0000-000000000000/b073f41919/?&locale=en" frameborder="0" allowfullscreen></iframe>
      <iframe width="100%" height="360" src="https://www.videoindexer.ai/embed/player/3933d146-5f68-41d4-93a3-d2f7454d4a8d/6pbyh5r4m4/?accessToken=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJWZXJzaW9uIjoiMi4wLjAuMCIsIktleVZlcnNpb24iOiI3ZmJkMDkxOGRmMWM0NGNjYTI3ZTA2NGQyYWZkYWViOSIsIkFjY291bnRJZCI6IjM5MzNkMTQ2LTVmNjgtNDFkNC05M2EzLWQyZjc0NTRkNGE4ZCIsIkFjY291bnRUeXBlIjoiQXJtIiwiVmlkZW9JZCI6IjZwYnloNXI0bTQiLCJQZXJtaXNzaW9uIjoiUmVhZGVyIiwiRXh0ZXJuYWxVc2VySWQiOiI4NURFOTNDMEVDNTE0M0I0QjEwN0RBQTkwNEVGRTVBQiIsIlVzZXJUeXBlIjoiTWljcm9zb2Z0Q29ycEFhZCIsIklzc3VlckxvY2F0aW9uIjoiY2VudHJhbGluZGlhIiwibmJmIjoxNzM0OTM1ODgyLCJleHAiOjE3MzQ5Mzk3ODIsImlzcyI6Imh0dHBzOi8vYXBpLnZpZGVvaW5kZXhlci5haS8iLCJhdWQiOiJodHRwczovL2FwaS52aWRlb2luZGV4ZXIuYWkvIn0.W3qUv1oKbrsxgaBpeerGiFyrqedtO_k-uf8nCL99-0YgRNpP2tOexq240fXxXCy8BmZ3-TZ5_HwrIDRTgYyn8-atqVrU9kninfWlDsFcKgaHXV1nYz9X-jpMVGzb3QaPFNsITKucX3EeuFNoiU0Y6UjD-zvrvT73Mw2IpeBDOTa2TCrDbJr1XfU0x1ar8reDbSD0w834utPQC9ZJLyRXj52iVTgBGUXWcI-D0z7Q4bgD9FXh0NFKSnpZ1qlU8z4Ex72co87gkhoRj9Xv7EHRYOKqnOMeVZKvSCyLCOjyGCyj4xH7e7QJTfiok-bR6ZA2KtxPAErpDMdks-1oWMBYoA&locale=en&location=centralindia" frameborder="0" allowfullscreen></iframe>
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/C4YmtAKeGSg" frameborder="0" allowfullscreen></iframe> */}


      {isLoading ? (
        <div className="mt-3">
          <div className="shimmer-cont">
            <div className="shimmer-header d-flex">
              <ShimmerDiv mode="light" height={60} width={60} rounded={50} />
              <div className="shimmer-title ms-3">
                <ShimmerTitle mode="light" line={2} gap={8} />
              </div>
            </div>
            <div className="square-shimmer mt-2">
              <ShimmerDiv mode="light" height={100} width={100} />
            </div>
          </div>
        </div>
      ) : isError ? (
        <div className="mt-2 error-image-cont">
          <img src={post.errorbig} alt="404 Not Found" />
        </div>
      ) : (
        <>
          {
            postsWithAds.filter((item) => !item.isHidden).map((item, index) =>
              item.isAd ? (
                <Advertisement key={`ad-${index}`} />
              ) : (
                <div
                  className="featured-card user-postOnlyContent p-2 mb-3"
                  key={index}
                >

                  <div className="feature-content p-2 d-flex justify-content-between">
                    <Link
                      to={
                        item?.postOriginType === "Company"
                          ? `/Company/${item?.userUid}`
                          : `/User/${item.userUid}`
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="ImgAvatar">
                          <img
                            src={item?.userAvatar}
                            alt=""
                            style={{ borderRadius: "50px" }}
                          />
                        </div>

                        <div className="ms-2">
                          <h4 className="f-14 fw-bold mb-0">{item.userName} </h4>
                          <p className="f-12">{item?.title}</p>
                          <p className="f-12">{item?.additionalInfo}</p>
                          <p className="f-10 label">
                            {moment
                              .utc(item.creationTime)
                              .local()
                              .format("DD MMMM YYYY [at] h:mm a")}
                          </p>
                        </div>
                      </div>
                    </Link>

                    {guestModeData === "true" ? (
                      ""
                    ) : (
                      <div className="row">
                        <div>
                          <PostInteractionModal
                            postId={item?.postId}
                            AllPostList={AllPostList}
                            postList={postList}
                            setPage={setPage}
                            setPostList={setPostList}
                            page={page}
                            setTotalPages={setTotalPages}
                            totalPages={totalPages}
                            item={item}
                            PostOriginType={"User"}
                            postOriginId={userUid}
                            isfollowed={isfollowed}
                            setIsfollowed={setIsfollowed}
                            setShowDeletePost={setShowDeletePost}
                            showDeletePost={showDeletePost}
                            ispinned={item?.isPinned}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <PostComponent item={item} />

                  <div
                    className="feed-post"
                    style={{
                      paddingTop: item?.postMediaCount === 0 ? "60px" : "4px",
                    }}
                  >
                    <div className="row">
                      {item.mediaFilesList.slice(0, 4).map((file, idx) => (
                        <div
                          key={idx}
                          className={`col-${item.mediaFilesList.length === 1 ? "12" : "6"
                            } ${item.mediaFilesList.length === 3 && idx === 0
                              ? "col-12"
                              : ""
                            }`}
                          onClick={() => {
                            setInitialSlideIndex(idx);
                            setImageArray(item.mediaFilesList);
                            console.warn("===========1============>>", idx);
                            let imageData = item.mediaFilesList.map(
                              (file) => file.mediaName
                            );
                            openModal(imageData, idx);
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                          style={{
                            position: "relative",
                            padding:
                              item.mediaFilesList.length === 3 && idx === 0
                                ? "0 0 5px 0"
                                : item.mediaFilesList.length === 3 && idx === 1
                                  ? "0 5px 5px 0"
                                  : item.mediaFilesList.length === 4 && idx === 2
                                    ? "0 5px 0 0"
                                    : item.mediaFilesList.length === 4 && idx === 0
                                      ? "0 5px 5px 0"
                                      : item.mediaFilesList.length === 2 && idx === 0
                                        ? "0 5px 5px 0"
                                        : "",
                          }}
                        >
                          <img
                            src={file.mediaName}
                            alt=""
                            className="img-fluid"
                            width={"100%"}
                          />
                          {idx === 3 && item.postMediaCount > 4 && (
                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50 text-white">
                              +{item.postMediaCount - 4}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {guestModeData === "true" ? (
                      ""
                    ) : (
                      <div className="row comment-gradient">
                        <div
                          className="addComment"
                          onClick={() => {
                            setInitialSlideIndex(1);
                            setImageArray(item.mediaFilesList);
                            let imageData = item.mediaFilesList.map(
                              (file) => file.mediaName
                            );
                            openModal(imageData, 1);
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                        >
                          <div
                            className="postCommentWrapper"
                            onClick={(event) => event.stopPropagation()}
                          >
                            <PostComment
                              item={item}
                              postId={item?.postId}
                              AllPostList={AllPostList}
                              postList={postList}
                              setPage={setPage}
                              setPostList={setPostList}
                              page={page}
                              setTotalPages={setTotalPages}
                              totalPages={totalPages}
                              updateCommentCount={updateCommentCount}
                              ispostmodalOpen={ispostmodalOpen}
                              setIspostmodalOpen={setIspostmodalOpen}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={`postData d-flex align-items-center ${item?.likeCount === 0 && item?.commentCount === 0 && item?.shareCount === 0
                    ? ""
                    : "border-custom1"
                    }`}>
                    <div className="ms-2">
                      {item?.likeCount === 0 ? (
                        ""
                      ) : (
                        <p
                          className="f-10 like-details"
                          onClick={() => handleShowModal(item)}
                        >
                          {item?.likeCount + dLike} likes
                        </p>
                      )}
                    </div>
                    <div className="ms-auto d-flex">
                      {item?.commentCount === 0 ? (
                        ""
                      ) : (
                        <p
                          className="f-10 me-2 pointer"
                          onClick={() => {
                            openModal(
                              item.mediaFilesList.map((file) => file.mediaName)
                            );
                            setSinglePostContent(item);
                            setSinglePostId(item?.postId);
                          }}
                        >
                          {" "}
                          {item?.commentCount} comments{" "}
                        </p>
                      )}

                      <p className="f-10">
                        {item?.shareCount === 0
                          ? ""
                          : `&  ${item?.shareCount} Shares`}
                      </p>
                    </div>
                  </div>
                  <div className="postengagement">
                    <PostEngagement
                      item={item}
                      postId={item?.postId}
                      AllPostList={AllPostList}
                      postList={postList}
                      setPage={setPage}
                      setPostList={setPostList}
                      page={page}
                      setTotalPages={setTotalPages}
                      totalPages={totalPages}
                      updateLikeCount={updateLikeCount}
                      updateShareCount={updateShareCount}
                      openModal={openModal}
                      setSinglePostContent={setSinglePostContent}
                      setSinglePostId={setSinglePostId}
                      ispostmodalOpen={ispostmodalOpen}
                      setIspostmodalOpen={setIspostmodalOpen}
                      emojiList={emojiList}
                    />
                  </div>
                  <Comment
                    item={item}
                    isOwnedPost={item?.isOwned}
                    postId={item?.postId}
                    AllPostList={AllPostList}
                    postList={postList}
                    setPage={setPage}
                    setPostList={setPostList}
                    page={page}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    emojiList={emojiList}
                  />
                </div>
              )
            )}

        </>
      )}


      {hasMoreData ? (''
      ) : (
        <p className="text-center">No more data available</p >
      )}
      {
        nextBatchLoading && (
          <div className="text-center">
            <img src={post?.loader} width={50} />
          </div>
        )
      }




      {
        isModalOpen && (
          <ImageSliderModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            images={modalImages}
            item={singlePostContent}
            singlepostId={singlepostId}
            setSinglePostId={setSinglePostId}
            updateShareCount={updateShareCount}
            updateLikeCount={updateLikeCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={page}
            setPostList={setPostList}
            setPage={setPage}
            postList={postList}
            AllPostList={AllPostList}
            PostEngagement={PostEngagement}
            dLike={dLike}
            updateCommentCount={updateCommentCount}
            ispostmodalOpen={ispostmodalOpen}
            setIspostmodalOpen={setIspostmodalOpen}
            openModal={openModal}
            setSinglePostContent={setSinglePostContent}
            setDLike={setDLike}
            setShowModal={showModal}
            handleLikesCloseModal={handleLikesCloseModal}
            isfollowed={isfollowed}
            setIsfollowed={setIsfollowed}
            initialSlideIndex={initialSlideIndex}
            setInitialSlideIndex={setInitialSlideIndex}
            imageArray={imageArray}
          />
        )
      }
      {/* {showModal &&  */}
      <LikesDetails
        showModal={showModal}
        handleLikesCloseModal={handleLikesCloseModal}
        postId={postId}
        postLikedType={postLikedType}
      />
      {/* } */}


      {
        loading && (
          <div className="row">
            <div className="col-md-12">
              <Loading />
            </div>
          </div>
        )
      }
      {
        showToast && (
          <SuccessToast
            show={showToast}
            message={toastMessage}
            onClose={() => setShowToast(false)}
          />
        )
      }

      {
        showWarningToast && (
          <WarningToast
            show={showWarningToast}
            message={warningToastMessage}
            onClose={() => setShowWarningToast(false)}
          />
        )
      }
      {
        showErrorToast && (
          <ErrorToast
            show={showErrorToast}
            message={ErrorToastMessage}
            onClose={() => setShowErrorToast(false)}
          />
        )
      }
    </>
  );
};

export default AllPost;
