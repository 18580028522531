import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../API/axios";
// import { post } from '../../assets/Images/ImagesLink'
import like from "../../assets/Images/Post/f4ly07ldn7194ciimghrumv3l.svg";
import celebrate from "../../assets/Images/Post/3c4dl0u9dy2zjlon6tf5jxlqo.svg";
import support from "../../assets/Images/Post/9whrgl1hq2kfxjqr9gqwoqrdi.svg";
import love from "../../assets/Images/Post/asmf650x603bcwgefb4heo6bm.svg";
import insightful from "../../assets/Images/Post/39axkb4qe8q95ieljrhqhkxvl.svg";
import funny from "../../assets/Images/Post/ktcgulanbxpl0foz1uckibdl.svg";
import likegif from "../../assets/Images/Post/Animation - 1714999935336.gif";
import { motion } from "framer-motion";
import Comment from "./Comment";
import CommentAction from "./CommentAction";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import { Col, Modal, Row } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import SingleComment from "./SingleComment";
import { post } from "../../assets/Images/ImagesLink";
import ModalForGuest from "../ModalForGuest";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
const SinglePostEngagement = ({
  isOwnedPost,
  postId,
  AllPostList,
  singlepostList,
  setSinglePostList,
  setPostList,
  // item,
  // setPage,
  // page,
  // setTotalPages,
  // totalPages,
  // setDLike,
  // updateLikeCount,
  // updateShareCount,
  openModal,
  setSinglePostId,
  setSinglePostContent,
  // setSinglePostId,
  setIspostmodalOpen,
  ispostmodalOpen,
  setDLike,
  updateLikeCount
}) => {
  console.log("engagement", singlepostList);
  const [showComments, setShowComments] = useState(false);

  const guestModeData = localStorage.getItem("guestMode");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  // console.log('hdghjgd', item);
  const toggleComments = () => {
    setShowComments(!showComments);
  };
  // console.log(showComments);

  // console.log('item', item);
  const sessionId = localStorage.getItem("sessionId");
  //************************Save Post start  ************************//
  const [isSaved, setIsSaved] = useState(false);
  const savePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      const res = await axios.post(`PostAction/SavePost`, responseData)
      // .then((res) => {
      console.log("save post", res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setIsSaved(true);
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      console.log('postDetailsResponse', postDetailsResponse);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
      // });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };


  //************************Save Post End  ************************//

  //************************UnSave Post start  ************************//
  const unsavePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      const res = await axios.post(`PostAction/UnsavePost`, responseData)
      // .then((res) => {
      // console.log(res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setIsSaved(false);
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      console.log('postDetailsResponse', postDetailsResponse);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setPostList((prev) =>
        prev.map((post) =>
          post.postId === updatedPost.postId ? updatedPost : post
        )
      );
      // });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnSave Post End  ************************//

  //************************like Post start  ************************//
  const [selectedImg, setSelectedImage] = useState("");
  const [isLiking, setIsLiking] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likedType, setLikedType] = useState(null);
  const [changeLikedType, setChangeLikedType] = useState(null);
  const LikePost = async (liketype, imageName) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      likeType: liketype,
    };
    // console.log(responseData);
    setIsLiking(true);
    setLikedType(liketype)
    setTimeout(async () => {
      try {
        const res = await axios.post("PostAction/LikePost", responseData);
        // await axios.post(`PostAction/LikePost`, responseData).then((res) => {
        // console.log(res);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        setSelectedImage(imageName);
        setIsHovered(false);
        setIsLiking(false);
        setIsLiked(true);
        setLikedType(liketype)
        // setDLike(1)
        updateLikeCount(postId, 1);
        // const newPostId = postId;
        // console.log('newPostId', newPostId);

        // Fetch the full post details using the post ID
        const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
        console.log('postDetailsResponse', postDetailsResponse);
        const updatedPost = postDetailsResponse.data.responseData;
        console.log('updatedPost', updatedPost);

        // Update the existing post in the list
        setSinglePostList(updatedPost)
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setWarningToastMessage(e.response.data.message);
          setShowWarningToast(true);
        } else {
          setErrorToastMessage(e.response.data.message);
          setShowErrorToast(true);
        }
      }
    }, 1000);
  };

  //************************like Post End  ************************//
  //************************Relike Post End  ************************//
  const ReLikePost = async (liketype, imageName) => {

    try {
      const responseData = {
        deviceType: "Web",
        sessionId: sessionId,
        postId: postId,
        likeType: liketype,
      };
      // console.log(responseData);
      setIsLiking(true);
      setLikedType(liketype);

      const res = await axios.post("/PostAction/ChangeLikeType", responseData);
      // toast.success('gdhghdg', res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setSelectedImage(imageName);
      setIsHovered(false);
      setIsLiking(false);
      setIsLiked(true);
      setLikedType(liketype);
      setChangeLikedType(likedType)

      // Fetch the full post details using the post ID
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setSinglePostList(updatedPost)

    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }

  };

  //************************Relike Post End  ************************//
  //************************unlike Post start  ************************//
  const UnLikePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    // console.log(responseData);
    try {
      const res = await axios.post(`PostAction/UnlikePost`, responseData)
      //  .then((res) => {
      // console.log(res);
      // toast.success(res.data.message);
      setToastMessage(res.data.message);
      setShowToast(true);
      AllPostList();
      setIsHovered(false);
      setIsLiked(false);
      // setDLike(-1)
      updateLikeCount(postId, -1);
      setLikedType(null)
      const postDetailsResponse = await axios.get(`/Posts/GetPostDetails?DeviceType=Web&PostId=${postId}`);
      console.log('postDetailsResponse', postDetailsResponse);
      const updatedPost = postDetailsResponse.data.responseData;
      console.log('updatedPost', updatedPost);

      // Update the existing post in the list
      setSinglePostList(updatedPost)
      // });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************unlike Post End  ************************//
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredRelike, setIsHoveredRelike] = useState(false);
  const list = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const [shareModal, setShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState();
  const sharePost = async () => {
    const sharePost = {
      deviceType: "Web",
      id: postId,
      type: 1,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", sharePost).then((res) => {
        console.log("share", res);
        setShareUrl(res.data.responseData.value);
        // updateShareCount(postId, 1)
      });
    } catch (error) {
      console.log(error);
    }
  };


  const [emojiList, setemojiList] = useState();
  // console.log('emojiList', emojiList);
  const GetEmoji = async () => {
    try {
      await axios.get("Master/GetEmojiDDL").then((res) => {
        // console.log("share", res);
        setemojiList(res.data.responseData);
        // updateShareCount(postId, 1);
      });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    GetEmoji()
  }, [])

  useEffect(() => {
    if (shareModal) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add('modal-open'); // When a modal opens
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = '';
      document.body.classList.remove('modal-open'); // When a modal closes
    }
  }, [shareModal]);


  const [followModal, setFollowModal] = useState(false);
  const closeModalData = () => {
    setFollowModal(false);
  };
  return (
    <>
      <div className="d-flex justify-content-between bordertop-custom pt-2 pb-3">
        <div className="d-flex align-items-center postAction likeActions">
          {console.log('changeLikedType', isLiked, likedType, changeLikedType)}

          {isLiked === true || singlepostList?.isLiked === true ? (

            <motion.div
              className="like-container pointer d-flex align-items-center"
              onClick={() => UnLikePost()}
              whileHover={{ scale: 1.3 }}
              onMouseEnter={() => setIsHoveredRelike(true)}
              onMouseLeave={() => setIsHoveredRelike(false)}
            >
              {singlepostList?.likedType === 1 || likedType === 1 || changeLikedType === 1 ? (
                <>
                  <img src={post.LikeGif} width={25} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ) : singlepostList?.likedType === 2 || likedType === 2 || changeLikedType === 2 ? (
                <>
                  <img src={post.LoveGif} width={25} />
                  <p className="f-12 ms-1">Love</p>
                </>
              ) : singlepostList?.likedType === 3 || likedType === 3 || changeLikedType === 3 ? (
                <>
                  <img src={post.LaughingGif} width={25} />
                  <p className="f-12 ms-1">Laugh</p>
                </>
              ) : singlepostList?.likedType === 4 || likedType === 4 || changeLikedType === 4 ? (
                <>
                  <img src={post.WowGif} width={25} />
                  <p className="f-12 ms-1">Wow</p>
                </>
              ) : singlepostList?.likedType === 5 || likedType === 5 || changeLikedType === 5 ? (
                <>
                  <img src={post.AngryGif} width={25} />
                  <p className="f-12 ms-1">Angry</p>
                </>
              ) : (
                <>
                  <img src={post.LikeGif} width={25} />
                  <p className="f-12 ms-1">Like</p>
                </>
              )}
            </motion.div>
          ) : isLiking ? (
            (console.log("likedType", likedType),
              likedType === 1 ? (
                <>
                  {/* <img src={post.LikeGif} width={30} /> */}
                  <img src={likegif} alt="loading" width={50} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ) : likedType === 2 ? (
                <>
                  <img src={post.LoveGif} width={25} />
                  <p className="f-12 ms-1">Love</p>
                </>
              ) : likedType === 3 ? (
                <>
                  <img src={post.LaughingGif} width={25} />
                  <p className="f-12 ms-1">Laugh</p>
                </>
              ) : likedType === 4 ? (
                <>
                  <img src={post.WowGif} width={25} />
                  <p className="f-12 ms-1">Wow</p>
                </>
              ) : likedType === 5 ? (
                <>
                  <img src={post.AngryGif} width={25} />
                  <p className="f-12 ms-1">Angry</p>
                </>
              ) : (
                <>
                  {/* <img src={post.LikeGif} width={30} /> */}
                  <img src={likegif} alt="loading" width={50} />
                  <p className="f-12 ms-1">Like</p>
                </>
              ))
          ) : (
            <>
              {guestModeData === "true" ? (
                <div className="d-flex align-items-center">
                  <motion.span
                    className="material-symbols-sharp pointer pe-1 "
                    onClick={() => {
                      setFollowModal(true);
                    }}
                  >
                    <ThumbUpAltOutlinedIcon />
                  </motion.span>
                  <p
                    className="f-12 pointer ms-1"
                    onClick={() => {
                      setFollowModal(true);
                    }}
                  >
                    Like
                  </p>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <motion.span
                    whileHover={{ scale: 1.3 }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => { LikePost(1, 'Like') }}
                    className="material-symbols-sharp pointer  pe-1"
                  >
                    <ThumbUpAltOutlinedIcon />
                  </motion.span>
                  <motion.p
                    className="f-12 pointer ms-1 mb-0"
                    whileHover={{ scale: 1.3 }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => { LikePost(1, 'Like') }}
                  >
                    Like
                  </motion.p>
                </div>

              )}


            </>
          )}
          {isHovered && (
            <motion.div
              className="reactionsHolder"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              variants={list}
            >
              {emojiList?.map((item) => {
                return (
                  <div className="emoji-container" key={item.value}>
                    <motion.img
                      whileHover={{ scale: 1.5 }}
                      src={item.url}
                      alt=""
                      onClick={() => {
                        LikePost(item.value, item.name);
                      }}
                    />
                    <span className="emoji-name">{item.name}</span>
                  </div>
                );
              })}
            </motion.div>
          )}
          {isHoveredRelike && (
            <motion.div
              className="reactionsHolder"
              onMouseEnter={() => setIsHoveredRelike(true)}
              onMouseLeave={() => setIsHoveredRelike(false)}
              variants={list}
            >
              {emojiList?.map((item) => {
                return (
                  <div className="emoji-container" key={item.value}>
                    <motion.img
                      whileHover={{ scale: 1.5 }}
                      src={item.url}
                      alt=""
                      onClick={() => {
                        ReLikePost(item.value, item.name);
                      }}
                    />
                    <span className="emoji-name">{item.name}</span>
                  </div>
                );
              })}

            </motion.div>
          )}
        </div>
        {guestModeData === "true" ? (
          <div
            className="d-flex align-items-center postAction"
            onClick={() => {
              setFollowModal(true);
            }}
          >
            <ChatOutlinedIcon className="material-symbols-sharp pointer" />

            <p className="f-14 pointer ms-1">Comment</p>
          </div>
        ) : (
          <div
            className="d-flex align-items-center postAction"
          // onClick={() => {
          //   openModal(singlepostList?.mediaFilesList.map((file) => file.mediaName));
          //   setSinglePostContent(singlepostList);
          //   setIspostmodalOpen(true);
          //   setSinglePostId(singlepostList?.postId);
          // }}
          >
            <ChatOutlinedIcon className="material-symbols-sharp pointer" />
            <p className="f-14 pointer ms-1">Comment</p>
          </div>
        )}

        <div className="d-flex align-items-center postAction">
          <ShareOutlinedIcon
            className="material-symbols-sharp pointer"
            onClick={(e) => {
              e.preventDefault();
              setShareModal(true);
              sharePost();
            }}
          />
          <p className="f-12 pointer ms-1"
            onClick={(e) => {
              e.preventDefault();
              setShareModal(true);
              sharePost();
            }}>Share</p>
        </div>

        {isSaved === true || singlepostList?.isSaved === true ? (
          <div className="d-flex align-items-center postAction">
            <BookmarkOutlinedIcon
              className="material-symbols-sharp pointer"
              onClick={() => {
                if (guestModeData !== "true") {
                  unsavePost();
                } else {
                  setFollowModal(true);
                }
              }}
              disabled={guestModeData === "true"}
            />
            <p className="f-12 pointer ms-1" onClick={() => {
              if (guestModeData !== "true") {
                unsavePost();
              } else {
                setFollowModal(true);
              }
            }}>Saved</p>
          </div>
        ) : (
          <div className="d-flex align-items-center postAction">
            <BookmarkBorderOutlinedIcon
              className="material-symbols-outlined pointer"
              onClick={() => {
                if (guestModeData !== "true") {
                  savePost();
                } else {
                  setFollowModal(true);
                }
              }}
              disabled={guestModeData === "true"}
            />
            <p className="f-12 pointer ms-1" onClick={() => {
              if (guestModeData !== "true") {
                savePost();
              } else {
                setFollowModal(true);
              }
            }}>Save</p>
          </div>
        )}
      </div>

      {/* {ispostmodalOpen === undefined || ispostmodalOpen === true ? ( */}
      <SingleComment
        isOwnedPost={isOwnedPost}
        // item={item}
        postId={singlepostList?.postId}
        AllPostList={AllPostList}
        singlepostList={singlepostList}
        // setPage={setPage}
        setSinglePostList={setSinglePostList}
        emojiList={emojiList}
        setPostList={setPostList}
      // page={page}
      // setTotalPages={setTotalPages}
      // totalPages={totalPages}
      />
      {/* ) : (
        ""
      )} */}

      {/* <Comment
        item={item}
        postId={postList?.postId}
        AllPostList={AllPostList}
        postList={postList}
        setPage={setPage}
        setPostList={setPostList}
        page={page}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
      /> */}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {/* <Modal
        show={shareModal}
        onHide={() => setShareModal(false)}
        size="xl"
        centered
        toggle={() => setShareModal(!shareModal)}
      >
        <Modal.Header
          closeButton
          className="bg-transparent p-2 border-bottom"
          toggle={() => setShareModal(!shareModal)}
        >
          <h5 className="main-heading">Share Job</h5>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <FacebookShareButton
            url={shareUrl}
            onClick={() => {
              setShareModal(false);
            }}
          >
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <WhatsappShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <LinkedinIcon size={40} />
          </LinkedinShareButton>
          <TwitterShareButton
            url={shareUrl}
            className="ms-2"
            onClick={() => {
              setShareModal(false);
            }}
          >
            <TwitterIcon size={40} />
          </TwitterShareButton>

          <Row>
            <Col
              className="mt-1 d-flex justify-content-between"
              xs={2}
              lg={2}
            ></Col>
            <Col
              className="mt-1 d-flex justify-content-between"
              xs={2}
              lg={2}
            ></Col>
          </Row>
        </Modal.Body>
      </Modal> */}

      {shareModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6>Share Post</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    setShareModal(false);
                  }}
                />
              </div>

              <div className="mt-3">
                <FacebookShareButton
                  url={shareUrl}
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <FacebookIcon size={40} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <WhatsappIcon size={40} />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <LinkedinIcon size={40} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  className="ms-2"
                  onClick={() => {
                    setShareModal(false);
                  }}
                >
                  <TwitterIcon size={40} />
                </TwitterShareButton>
                {/* </>
          )} */}
                <Row>
                  <Col
                    className="mt-1 d-flex justify-content-between"
                    xs={2}
                    lg={2}
                  ></Col>
                  <Col
                    className="mt-1 d-flex justify-content-between"
                    xs={2}
                    lg={2}
                  ></Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        isOpen={shareModal}
        toggle={() => setShareModal(!shareModal)}
        className='modal-dialog-centered upload-postModal'
      >
        <Modal.Header className='bg-transparent p-2 border-bottom' toggle={() => setShareModal(!shareModal)}>
          <h5 className='main-heading'>Share Job</h5>
        </Modal.Header>
        <Modal.Body className='mx-50 pb-2'>
          <FacebookShareButton url={shareUrl} onClick={() => { setShareModal(false) }}>
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl} className='ms-2' onClick={() => { setShareModal(false) }}>
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
          <LinkedinShareButton url={shareUrl} className='ms-2' onClick={() => { setShareModal(false) }}>
            <LinkedinIcon size={40} />
          </LinkedinShareButton>
          <TwitterShareButton url={shareUrl} className='ms-2' onClick={() => { setShareModal(false) }}>
            <TwitterIcon size={40} />
          </TwitterShareButton>
          <Row>
            <Col className='mt-1 d-flex justify-content-between' xs={2} lg={2}>
            </Col>
            <Col className='mt-1 d-flex justify-content-between' xs={2} lg={2}>
            </Col>
          </Row>
        </Modal.Body>
      </Modal> */}
      {followModal && <ModalForGuest closeModalData={closeModalData} />}
    </>
  );
};

export default SinglePostEngagement;
