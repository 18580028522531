
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
// import { Form } from 'react-router-dom';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "../../API/axios";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";

const CCVExperienceAchievementAdd = ({
    getUserCcv,
    handleCloseExperienceAchivement,
    showExperienceAchivement,
    ExperienceData,
    resumeId
}) => {
    console.log("experienceAchivementData", ExperienceData);
    const sessionId = localStorage.getItem("sessionId");
    const userType = localStorage.getItem("userType");
    const { CandidateId } = useParams();
    const [achivement, setAchivement] = useState("");
    const [achivementDate, setAchivementDate] = useState("");
    const [display, setDisplay] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisplay(true);
        const body = {
            deviceType: "Web",
            sessionId: sessionId,
            userExpId: ExperienceData.rsmExpId,
            id: resumeId,
            achievement: achivement,
            achivementDate: moment(achivementDate).format("YYYY-MM-DD"),
        };
        const body1 = {
            deviceType: "Web",
            sessionId: sessionId,
            userExpId: ExperienceData.userExprncId,
            achievement: achivement,
            achivementDate: moment(achivementDate).format("YYYY-MM-DD"),
        };
        console.log("body====>", body);
        if (achivement === '' || achivementDate === '') {
            toast.error('Please fill all the required fields');
        } else {
            const apiUrl = ExperienceData.isSyncedWithUserData
                ? `/UserData/AddExperienceAcheivement`
                : `ResumeData/AddExperienceAcheivement`;

            const payload = ExperienceData.isSyncedWithUserData ? body1 : body;
            try {
                const res = await axios.post(apiUrl, payload);
                // toast.success(res.data.message);
                handleCloseExperienceAchivement();
                getUserCcv();
                setAchivement("");
                setAchivementDate('')
                setDisplay(false)
                setToastMessage(res.data.message);
                setShowToast(true);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setWarningToastMessage(error.response.data.message);
                    setShowWarningToast(true);
                } else {
                    setErrorToastMessage(error.response.data.message);
                    setShowErrorToast(true);
                }
            }
        }
    };

    useEffect(() => {
        if (showExperienceAchivement) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showExperienceAchivement]);


    return (
        <>
            {showExperienceAchivement && (
                <div
                    className="modal fade show modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="setting-header d-flex justify-content-between align-items-center">
                                <h6>Add Experience Achievement</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-3 pointer"
                                    onClick={handleCloseExperienceAchivement}
                                />
                            </div>
                            <Form>
                                <div className="row modal-p settings CreateCompany">
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="grade">
                                                <Form.Label className="class-type">Achivement <span style={{ color: "red" }} className="ms-2">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="Dark-Input-from-group"
                                                    value={achivement}
                                                    onChange={(e) => setAchivement(e.target.value)}
                                                    placeholder="Enter Achivement"
                                                />
                                                {display && !achivement ? (
                                                    <span className="error_msg_lbl">Add Achievement </span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <Form.Group controlId="endYear">
                                                <Form.Label className="class-type">Achivement Date <span style={{ color: "red" }} className="ms-2">*</span></Form.Label>
                                                <Flatpickr
                                                    type="number"
                                                    value={achivementDate}
                                                    onChange={(date) => setAchivementDate(date[0])}
                                                    placeholder="Enter Achivement Date"
                                                />
                                                {display && !achivementDate ? (
                                                    <span className="error_msg_lbl">Select Achievement Date </span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="text-end mt-4">
                                        <button
                                            type="button"
                                            className="closebtn me-2"
                                            onClick={handleCloseExperienceAchivement}
                                            style={{ width: "60px" }}
                                        >
                                            close
                                        </button>
                                        <button
                                            type="button"
                                            className="mainBtn1"
                                            onClick={handleSubmit}
                                            style={{ width: "70px" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}

            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>



    );
};

export default CCVExperienceAchievementAdd;
