
import React, { useEffect, useRef, useState } from "react";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import axios from "../../API/axios";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import ReactQuill from "react-quill";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddAPhotoSharpIcon from '@mui/icons-material/AddAPhotoSharp';
const UpdateDraft = ({
    showModal,
    closeModal,
    item,
    PostOriginType,
    postOriginId,
    postId,
    setShowModal,
    setPostList,
    AllPostList,
    DraftPost
}) => {



    const [removedImageIds, setRemovedImageIds] = useState([]); // To track removed image IDs
    const sessionId = localStorage.getItem("sessionId");
    const userUid = localStorage.getItem("userUid");
    const inputFileRef = useRef(null);

    const [postData, setPostData] = useState("");
    const [privacyType, setPrivacyType] = useState(null);
    const [imageNames, setImageNames] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);

    const [images, setImages] = useState([]);
    const [showPost, setShowPost] = useState(false);
    const [display, setDisplay] = useState(false);
    const [postID, setPostID] = useState("");

    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const [warningToastMessage, setWarningToastMessage] = useState("");
    const [showWarningToast, setShowWarningToast] = useState(false);

    const [ErrorToastMessage, setErrorToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const [postDataItem, setPostDataItem] = useState('')
    console.log('postDataItem', postDataItem);
    const [postContent, setPostContent] = useState('');
    let mediaFilesImage = postDataItem?.mediaFilesList;
    const GetPostData = async () => {
        const data = {
            "deviceType": "Web",
            "sessionId": sessionId,
            "postId": postId
        }
        try {
            await axios
                .post(`/Posts/GetPostData`, data)
                .then((resData) => {
                    console.log('postDataItem', resData);
                    setPostContent(resData.data.responseData.postContent)
                    setPostDataItem(resData.data.responseData);
                    setUserSelectedImage(resData.data.responseData.mediaFilesList || []); // Initialize the state with the media files list
                });
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        // if (showModal) {
        GetPostData()
        // }
    }, [])


    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            console.log("formData", formData);
            formData.append("PostId", postId);
            formData.append("DeviceType", "Web");
            formData.append("sessionId", sessionId);
            formData.append(
                "PrivacyType",
                privacyType === null ? "Public" : privacyType
            );
            formData.append("PostOriginType", postDataItem.postOriginType);
            const imageNameArray = imageNames
                .split(",")
                .filter((name) => name.trim() !== "");

            const containsVideo = (names) => {
                const videoExtensions = [
                    ".mp4",
                    ".webm",
                    ".avi",
                    ".mov",
                    ".mkv",
                    ".flv",
                    ".wmv",
                ];
                return names.some((name) =>
                    videoExtensions.includes(
                        name.slice(name.lastIndexOf(".")).toLowerCase()
                    )
                );
            };

            let postCategory;
            const hasPostContent = postContent !== "";
            const hasImages =
                imageNameArray.length > 0 && !containsVideo(imageNameArray);
            const hasVideos =
                imageNameArray.length > 0 && containsVideo(imageNameArray);

            if (hasPostContent && hasImages && hasVideos) {
                postCategory = "UserStory";
            } else if (hasPostContent && hasImages) {
                postCategory = "UserStory";
            } else if (hasPostContent && hasVideos) {
                postCategory = "UserStory";
            } else if (hasImages && hasVideos) {
                postCategory = "UserStory";
            } else if (hasPostContent) {
                postCategory = "Text";
            } else if (hasImages) {
                postCategory = "Image";
            } else if (hasVideos) {
                postCategory = "Video";
            } else {
                postCategory = "UserStory";
            }
            formData.append("PostCategory", postCategory);
            formData.append("PostContent", postContent);
            //  userSelectedImage,images
            // userSelectedImage.forEach((file, index) => {
            //   formData.append(`OldFilesToDelete`, file.mediaName);
            // });
            removedImageIds.forEach((id) => {
                formData.append("OldFilesToDelete", id);
            });

            images.forEach((file, index) => {
                formData.append(`FilesToUpload`, file.file);
            })
            formData.append("PostOriginID", postDataItem.postOriginId);
            formData.append("PostId", postId);

            const res = await axios.post("/Posts/UpdatePostDraft", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("response: ", res.data.message);
            setPostContent("");
            setToastMessage("Post draft updated successfully!");
            setShowToast(true);

            closeModal();
            setTimeout(() => {
                DraftPost()
            }, 2000);
        } catch (e) {
            console.log(e);
            if (e.response && e.response.status === 404) {
                setWarningToastMessage(e.response.data.message);
                setShowWarningToast(true);
            } else {
                setErrorToastMessage(e.response.data.message);
                setShowErrorToast(true);
            }
        }
    };

    const imageSelect = (event) => {
        console.log("image selected", event);
        const selectedFiles = event.target.files;
        console.log("selectedFiles", selectedFiles);
        const newImages = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const imageName = selectedFiles[i].name;
            if (!checkDuplicate(imageName)) {
                const imageUrl = URL.createObjectURL(selectedFiles[i]);
                newImages.push({
                    name: imageName,
                    url: imageUrl,
                    file: selectedFiles[i],
                });
            } else {
                alert(`${imageName} is already added to the list`);
            }
        }

        setImages((prevImages) => [...prevImages, ...newImages]);
        event.target.value = null; // Reset file input
        console.log("image selected1", images);
    };

    const checkDuplicate = (name) => {
        return images.some((image) => image.name === name);
    };

    const [userSelectedImage, setUserSelectedImage] = useState({});
    console.log('image index', userSelectedImage);

    // const userImages = (index) => {
    //   console.log("image index", index);
    //   setUserSelectedImage((prevImages) => {
    //     const newImages = [...prevImages];
    //     newImages.splice(index, 1);
    //     return newImages;
    //   });
    // };
    const userImages = (index) => {
        const removedImage = userSelectedImage[index];
        setRemovedImageIds((prevIds) => [...prevIds, removedImage.pstMid]); // Add removed image ID to the state
        setUserSelectedImage((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    useEffect(() => {
        setUserSelectedImage(postDataItem?.mediaFilesList); // Initialize the state with the media files list
        console.log("useEffect is run");
    }, [postDataItem?.mediaFilesList]);

    console.log("userSelectedImage", userSelectedImage, images);

    const handleShowPost = () => {
        setShowModal(false);
        setShowPost(true);
    };

    const handleClosePost = () => {
        setShowPost(false);
    };
    const mediaFiles = postDataItem?.mediaFilesList?.map((item) => item?.pstMid);

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const fileNames = files.map((file) => file.name);
        setImageNames(fileNames.join(", "));
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setImageNames((prevNames) => {
            const namesArray = prevNames.split(", ");
            namesArray.splice(index, 1);
            return namesArray.join(", ");
        });
    };

    const renderImages = () => {
        return images.map((image, index) => (
            <div
                key={index}
                className="image_container d-flex justify-content-center position-relative"
            >
                <img src={image.url} alt={image.name} />
                <span className="position-absolute" onClick={() => deleteImage(index)}>
                    &times;
                </span>
            </div>
        ));
    };

    //   const [ userSelectedImages , setUserSelectedImages ] = useState ([]);

    //   setUserSelectedImages(mediaFiles);
    //   console.log("selectedImagesData", mediaFiles);

    const deleteImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const Privacy = [
        { value: "Public", label: "Public" },
        { value: "Friends", label: "Friends" },
        { value: "OnlyMe", label: "OnlyMe" },
        { value: "Custom", label: "Custom" },
    ];

    // useEffect(() => {
    //     if (inputFileRef.current) {
    //         inputFileRef.current.click();
    //     }
    // }, []);

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open'); // When a modal opens
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = '';
            document.body.classList.remove('modal-open'); // When a modal closes
        }
    }, [showModal]);

    console.log('images', images, postDataItem);
    const handlePaste = (e) => {
        const isCtrlV = e.ctrlKey && e.key === 'v'; // Check if Ctrl+V was pressed

        if (isCtrlV) {
            e.preventDefault(); // Prevent the default paste action
            alert('Please use Ctrl + Shift + V to paste.'); // Show alert
        }
    };
    return (
        <>
            {showModal && (
                <div
                    className="modal fade show postAdd"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content p-2">
                            <div className="modalBorder d-flex justify-content-between align-items-center">
                                <h6>Update {postDataItem?.userName}'s Post Draft</h6>
                                <CloseSharpIcon
                                    className="material-symbols-sharp me-2 pointer"
                                    onClick={closeModal}
                                />
                            </div>
                            {/* <div className="d-flex align-items-center m-3 pb-3 modalBorder ">
                                <div className="ImgAvatar">
                                    <img src={postDataItem?.userAvatar} alt="" style={{ borderRadius: '50%' }} />
                                </div>
                                <p className="f-16 AddPost ms-2">What's on your mind?</p>
                            </div> */}
                            <div className="m-3">
                                {/* <textarea
                                    autoFocus={true}
                                    id="about"
                                    placeholder="Update your post here"
                                    name="about"
                                    defaultValue={postDataItem?.postContent}
                                    onChange={(e) => setPostContent(e.target.value)}
                                    rows="4"
                                    cols="65"
                                ></textarea> */}
                                <ReactQuill
                                    value={postContent}
                                    onChange={setPostContent}
                                    onKeyDown={handlePaste} // Use onKeyDown to detect key presses
                                    placeholder="Update Post Here"
                                    // modules={{
                                    //   toolbar: [
                                    //     [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    //     [{ size: [] }],
                                    //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                    //     ['link'],
                                    //     ['clean']
                                    //   ]
                                    // }}
                                    modules={{
                                        toolbar: false, // Hide the toolbar
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link'
                                    ]}
                                />
                            </div>

                            <div className="modal-border d-flex justify-content-center align-items-center">
                                <input
                                    id="fileInput"
                                    type="file"
                                    onChange={imageSelect}
                                    style={{ display: "none" }}
                                    ref={inputFileRef}
                                    multiple
                                />

                                {images?.length > 0 && (
                                    <div className="post-selected d-flex justify-content-center flex-wrap">
                                        {images?.map((image, index) => (
                                            <div key={index} className="position-relative m-2">
                                                <img
                                                    src={image.url}
                                                    alt={`Selected Image ${index}`}
                                                    style={{
                                                        maxWidth: "200px",
                                                        maxHeight: "300px",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                <button
                                                    onClick={() => removeImage(index)}
                                                    className="btn btn-danger position-absolute top-0 end-0"
                                                    style={{
                                                        transform: "translate(50%, -50%)",
                                                        borderRadius: "50%",
                                                        width: "24px",
                                                        height: "24px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: "0",
                                                    }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {userSelectedImage?.length > 0 && (
                                <div className="post-selected d-flex justify-content-center flex-wrap">
                                    {userSelectedImage.map((imageUrl, index) => (
                                        <div key={index} className="position-relative m-2">
                                            <img
                                                src={imageUrl.mediaName}
                                                alt={`Selected Image ${index}`}
                                                style={{
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <button
                                                onClick={() => userImages(index)}
                                                className="btn btn-danger position-absolute top-0 end-0"
                                                style={{
                                                    transform: "translate(50%, -50%)",
                                                    borderRadius: "50%",
                                                    width: "24px",
                                                    height: "24px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "0",
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="m-3 mb-1 d-flex align-items-center">


                                <form className="form" action="#" method="post" id="form">
                                    <input
                                        type="file"
                                        name="Image"
                                        id="image"
                                        multiple
                                        className="d-none"
                                        onChange={imageSelect}
                                    />
                                    <AddAPhotoSharpIcon
                                        className="material-symbols-sharp me-2 mt-2 pointer"
                                        onClick={() => inputFileRef.current.click()} // Trigger the file input click
                                    />
                                </form>


                                <button className="mainBtn ms-auto" onClick={() => {
                                    handleSubmit()
                                    setToastMessage("Post draft updated successfully!");
                                    setShowToast(true);
                                }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <SuccessToast
                show={showToast}
                message={toastMessage}
                onClose={() => setShowToast(false)}
            />

            <WarningToast
                show={showWarningToast}
                message={warningToastMessage}
                onClose={() => setShowWarningToast(false)}
            />

            <ErrorToast
                show={showErrorToast}
                message={ErrorToastMessage}
                onClose={() => setShowErrorToast(false)}
            />
        </>
    );
};

export default UpdateDraft;
