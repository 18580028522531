
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../API/axios';
import { people } from '../../assets/Images/ImagesLink';
import MessageInteraction from './MessageInteraction';

const ArchivedMessage = ({ isShown, getChatList, archiveMessage, item, index, archiveActive, setArchiveActive, UserId, setMsgId, getArchivedList, getMessageList }) => {
    // console.log(item);
    const sessionId = localStorage.getItem('sessionId')
    const [archivedList, setArchivedList] = useState([])
    const formattedTime = moment(item.lastMsgTime).fromNow();

    return (
        <>
            <Link to={`/Message/${item?.chatInfo?.toUserUid}`} onClick={() => { }}>
                <div onClick={() => {
                    setArchiveActive(index)
                    // setToUserId(item.chatInfo.toUserUid)
                    setMsgId(item.chatInfo.msgID)
                }} className={`${isShown ? 'd-block' : 'd-md-block d-none'} pointer`}>
                    <div key={index} className={`${archiveActive === index ? 'activeMsg' : ''} message-cards d-flex align-items-center mb-2`}>
                        {/* <div className="empty-msg-card ms-3 me-3"></div> */}
                        <div className="me-3 ms-2">
                            <img src={item.chatInfo.toUserImage === '' ? people.DefaultProfile : item.chatInfo.toUserImage} alt="" width={40} height={40} style={{ borderRadius: '50px' }} />
                        </div>
                        <div className="msg-content w-100 me-2">
                            <div className="name-time d-flex justify-content-between align-items-baseline">
                                <div className="message-user-name darkmode f-12 f-600">{item.chatInfo.toUserFullName}</div>
                                <div className="message-time  darkmode f-10 f-400">{moment.utc(item.lastMsgTime).local().format("LT")}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className="messageText darkmode f-10 f-400"><p className='messageText'>
                                    {item.lastMsg.split(' ').slice(0, 5).join(' ')}
                                    {item.lastMsg.split(' ').length > 5 && '...'}
                                </p>
                                </div>
                                <MessageInteraction chatId={item.chatID} isRead={item.lastMsgIsRead} isPined={item.isPined} getChatList={getChatList} archiveMessage={archiveMessage} msgId={item.chatInfo.msgID} UserId={item.chatInfo.toUserUid} getArchivedList={getArchivedList} getMessageList={getMessageList} />
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

        </>
    )
}

export default ArchivedMessage