import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Component, isProtected, redirectTo }) => {
    const token = localStorage.getItem("token");
    const guestMode = localStorage.getItem("guestMode") === "true";

    const guestAccessibleRoutes = [
        "/NewsFeed",
        "/Blogs",
        "/Companies",
        "/People",
        "/SearchJob",
        "/User/",
        "/Company/",
        "/Post/",
        "/JobDetail/",
        "/SignUp",
        "/SignIn",
    ];

    const authRestrictedRoutes = ["/SignUp", "/signIn"]; // Routes restricted for authenticated users
    const currentPath = window.location.pathname;

    // Check if the current route is accessible in guest mode
    const isGuestAccessible = guestAccessibleRoutes.some((route) =>
        currentPath.startsWith(route)
    );

    if (token && authRestrictedRoutes.some((route) => currentPath.startsWith(route))) {
        // Redirect authenticated users away from SignIn or SignUp
        return <Navigate to="/NewsFeed" replace />;
    }

    if (guestMode && isGuestAccessible) {
        // Allow access for guest mode routes
        return <Component />;
    }

    if (guestMode && !isGuestAccessible) {
        // Redirect guests trying to access protected routes
        return <Navigate to="/NewsFeed" replace />;
    }

    if (isProtected && !token) {
        // Redirect unauthenticated users trying to access protected routes
        return <Navigate to={redirectTo} replace />;
    }

    return <Component />;
};

export default PrivateRoute;
